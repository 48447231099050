<template>
    <div>
        <ExternalHeaderGreenCard></ExternalHeaderGreenCard>
        <div>

            <div style="padding-top: 5rem; margin-top: 2rem;">
                <div class="d-table">
                <div class="d-table-cell" style="padding-bottom: 1rem">
                    <div class="container" style="margin-top: 14px">
                        <h2 class="title-mobile-center">Select Product</h2>
                        <div class="mobile-center" style="display: flex;">
                        <img  height="50px"  src="./resources/flag.png" alt="how to self-petition for a green card">
                        <h5 style="color: #5457c1; padding-left: 10px; margin-bottom: 0px; margin: auto 0">Next: customize order</h5>
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <div class="d-table" style="background-color: #f9fafb; padding-bottom: 4rem">
                <div class="d-table-cell">
                    <div class="container">

                        <div class="row" style="margin-top: 4rem;">
                            <div class="col-sm-4" style="margin-bottom: 5rem;">
                                <div class="pricing-table" style="margin-bottom: 0px; height: 5rem; position: relative;">
                                    <div style="font-size: 4rem; width: 40%; position: absolute; top: 100%; left: 50%; transform: translate(-50%, -50%); border-radius: 50%; border: 2px solid; background: #ffffff; border-color: #44ce6f; box-shadow: 10px 10px 10px 0px rgba(68, 206, 111, .25); z-index: 1;">&#9997;&#127996;</div>
                                </div>

                                <div class="pricing-table" style="margin-bottom: 0px; margin-top: 10px; padding-top: 5rem">
                                    <div class="price" style="height: 50px">
                                        <span v-if="isPromo" style="color: #0e314c">
                                            <sup>$</sup>234
                                            <sup style="color: #b30000; font-size: 13px; margin-left: 3px;">$</sup><span style="color: #b30000; font-size: 16px; text-decoration: line-through">360</span>
                                        </span>
                                        <span v-if="!isPromo" style="color: #0e314c"><sup>$</sup>360</span>
                                    </div>
                                    <h3 style="color: #5457c1">Starter Kit</h3>

                                    <h6 style="height: 35px; padding: 5px 10px">Profession-specific standard template</h6>
                                    <div style="color: #949494">Delivery: within 24h</div>

                                    <div class="pricing-footer" style="margin-top: 2rem">
                                        <a href="/checkout-starter-kit" v-on:click="sendEvent('checkout-starterKit')" class="btn btn-primary">SELECT</a>
                                    </div>
                                </div>

                                <div class="pricing-table" style="margin-top: 10px; margin-bottom: 0px; padding: 20px 10px">
                                    <div style="min-height: 70px;">Standardized EB-2 NIW template tailored for your profession, requiring your customization</div>
                                    <ul class="select-product-list">
                                        <li><vue-feather type="check"></vue-feather>Sample I-140 cover letter for your profession</li>
                                        <li><vue-feather type="check"></vue-feather>Profession specific NIW builder</li>
                                        <li><vue-feather type="check"></vue-feather>Our original I-140 cover letter (*banking)</li>
                                        <li><vue-feather type="check"></vue-feather>Our three original reference letters (*banking)</li>
                                        <li><vue-feather type="check"></vue-feather>Continuation of the endeavor (*banking)</li>
                                        <li><vue-feather type="check"></vue-feather>Steps to prepare reference letters</li>
                                        <li><vue-feather type="check"></vue-feather>Guide to understand VISA Bulletin</li>
                                        <li><vue-feather type="check"></vue-feather>Access to <img src="./resources/discord-logo.svg" style="width: 90px; margin-left: 2px; margin-right: 2px" alt="discord eb2 niw"/> community</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-sm-4" style="margin-bottom: 4rem;">
                                <div style="position: relative; top: -25px; border: 1px solid #ffffff; border-radius: 5px 5px 0 0; background: #5457c1; font-weight: 500; color: #ffffff; display: inline-block;">
                                    <div style="padding: 0px 6px">Best Seller</div>
                                </div> 
                                <div style="border: solid 1px #5457c1; border-radius: 5px; position: relative; top: -25px;">
                                    <div class="pricing-table" style="margin-bottom: 0px; height: 5rem; position: relative; background: #5457c1; border-radius: 3px 3px 0 0">
                                        <div style="font-size: 4rem; width: 40%; position: absolute; top: 100%; left: 50%; transform: translate(-50%, -50%); border-radius: 50%; border: 2px solid; background: #ffffff; border-color: #44ce6f; box-shadow: 10px 10px 10px 0px rgba(68, 206, 111, .25); z-index: 1;">&#129302;</div>
                                    </div>
                                    <div class="pricing-table" style="margin-bottom: 0px; margin-top: 10px; padding-top: 5rem">
                                        <div class="price" style="height: 50px">
                                            <span v-if="isPromo" style="color: #0e314c">
                                                <sup>$</sup>650
                                                <sup style="color: #b30000; font-size: 13px; margin-left: 3px;">$</sup><span style="color: #b30000; font-size: 16px; text-decoration: line-through">1,000</span>
                                            </span>
                                            <span v-if="!isPromo" style="color: #0e314c"><sup>$</sup>1,000</span>
                                        </div>
                                        <h3 style="color: #5457c1">Selfpetition AI</h3>

                                        <h6 style="height: 35px; padding: 5px 10px">Custom petition reflecting your case</h6>
                                        <div style="color: #949494">Delivery: first draft within 48h</div>

                                        <div class="pricing-footer" style="margin-top: 2rem">
                                            <a href="/checkout-selfpetition-ai" v-on:click="sendEvent('checkout-selfpetitionAI')" class="btn btn-primary">SELECT</a>
                                        </div>
                                    </div>

                                    <div class="pricing-table" style="margin-top: 10px; margin-bottom: 0px; padding: 20px 10px">
                                        <div style="min-height: 70px;">Unlike Starter Kit, Selfpetition AI handles the customization for you by leveraging your resume and information that you provide</div>
                                        <ul class="select-product-list">
                                            <li><vue-feather type="check"></vue-feather>AI trained on EB2 NIW cases and USCIS data</li>
                                            <li><vue-feather type="check"></vue-feather>Custom cover letter (9-13 pages)</li>
                                            <li><vue-feather type="check"></vue-feather>Three reference letters (each 1-2 pages)</li>
                                            <li><vue-feather type="check"></vue-feather>Excludes forms, package assembly and filing</li>
                                            <li><vue-feather type="check"></vue-feather>Unlimited revisions</li>
                                            <li><vue-feather type="check"></vue-feather>Option to buy more reference letters</li>
                                            <li><vue-feather type="check"></vue-feather>Satisfaction guarantee refund policy</li>
                                            <li><vue-feather type="check"></vue-feather>Access to <img src="./resources/discord-logo.svg" style="width: 90px; margin-left: 2px; margin-right: 2px" alt="discord eb2 niw"/> community</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-4" style="margin-bottom: 4rem;">
                                <div class="pricing-table" style="margin-bottom: 0px; height: 5rem; position: relative;">
                                    <div style="font-size: 4rem; width: 40%; position: absolute; top: 100%; left: 50%; transform: translate(-50%, -50%); border-radius: 50%; border: 2px solid; background: #ffffff; border-color: #44ce6f; box-shadow: 10px 10px 10px 0px rgba(68, 206, 111, .25); z-index: 1;">&#127919;</div>
                                </div>
                                <div class="pricing-table" style="margin-bottom: 0px; margin-top: 10px; padding-top: 5rem">
                                    <div class="price" style="height: 50px">
                                        <span v-if="isPromo" style="color: #0e314c">
                                            <sup>$</sup>325
                                            <sup style="color: #b30000; font-size: 13px; margin-left: 3px;">$</sup><span style="color: #b30000; font-size: 16px; text-decoration: line-through">500</span>
                                        </span>
                                        <span v-if="!isPromo" style="color: #0e314c"><sup>$</sup>500</span>
                                        <div v-if="!isPromo" style="color: #0e314c; font-size: 10px; margin-left: 10px">per prong</div>
                                        <div v-if="isPromo" style="color: #0e314c; font-size: 10px; margin-left: 10px">per prong</div>

                                    </div>
                                    <h3 style="color: #5457c1">RFE Response</h3>
                                    <h6 style="height: 35px; padding: 5px 10px">Bespoke response to RFE letter</h6>
                                    <div style="color: #949494">Delivery: within 48h</div>

                                    <div class="pricing-footer" style="margin-top: 2rem">
                                        <a href="/checkout-rfe-response" v-on:click="sendEvent('checkout-rfeResponse')" class="btn btn-primary">SELECT</a>
                                    </div>
                                </div>

                                <div class="pricing-table" style="margin-top: 10px; margin-bottom: 0px; padding: 20px 10px">
                                    <div style="min-height: 70px;">Enhance your EB-2 NIW RFE success by targeting the failed USCIS prong with Selfpetition AI</div>
                                    <ul class="select-product-list">
                                        <li><vue-feather type="check"></vue-feather>AI trained on EB2 NIW cases and USCIS data</li>
                                        <li><vue-feather type="check"></vue-feather>Get response drafted for points listed in the RFE letter</li>
                                        <li><vue-feather type="check"></vue-feather>Pay only for prongs that failed USCIS scrutiny</li>
                                        <li><vue-feather type="check"></vue-feather>Choose arguments from AI suggestions</li>
                                        <li><vue-feather type="check"></vue-feather>Unlimited revisions</li>
                                        <li><vue-feather type="check"></vue-feather>Option to order drafting of reference letters</li>
                                        <li><vue-feather type="check"></vue-feather>Full refund if the RFE response is unsuccessful</li>
                                    </ul>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ExternalFooterGreenCard></ExternalFooterGreenCard>
    </div>
</template>


<script>

// 864000 is number of seconds equal to 5 days. Promo updated every 15 days but not shown for the first 5 days


import { mapState, mapActions } from 'vuex'
import ExternalHeaderGreenCard from "./components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "./components/ExternalFooterGreenCard";

export default {
    name: 'SelectProduct',
    data () {
        return {
            discountStart: this.generateClosestDatesArray(),
        }
    },
    components: {
        ExternalHeaderGreenCard,
        ExternalFooterGreenCard
    },
    methods: {
        ...mapActions('account', ['apply']),
        sendEvent(eventType) {
            if (window.gtag) {
            window.gtag('event', eventType)
            }
        },
        generateClosestDatesArray() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const startDate = new Date(year, 0, 0);
        const endDate = new Date(year + 1, 0, 0);

        const dateArray = [];
        let currentDateCandidate = startDate;

        while (currentDateCandidate < endDate) {
            dateArray.push(new Date(currentDateCandidate));
            currentDateCandidate.setDate(currentDateCandidate.getDate() + 15);
        }

        // Find the closest date that is before the current date
        let closestDate = dateArray[0];
        for (const date of dateArray) {
            if (date < currentDate && date > closestDate) {
            closestDate = date;
            }
        }

        return closestDate;
        },
    },
    computed: {
        ...mapState({ account: state => state.account,
        }),
        isPromo() {
        // const tenDaysInMilliseconds = 10 * 24 * 60 * 60 * 1000; // 10 days in milliseconds
        // return tenDaysInMilliseconds > (new Date() - this.discountStart)
          return false;
        },
        countDownStart() {
        const tenDaysInMilliseconds = 10 * 24 * 60 * 60 * 1000
        return tenDaysInMilliseconds - (new Date() - this.discountStart)
        }
    },
}

</script>
