<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <AI></AI>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
    import ExternalHeaderGreenCard from "./components/ExternalHeaderGreenCard";
    import ExternalFooterGreenCard from "./components/ExternalFooterGreenCard";
    import AI from "./components/GreenCardAI";

    export default {
        name: 'GreenCardAIView',
        components: {
            AI,
            ExternalHeaderGreenCard,
            ExternalFooterGreenCard,
        },
        data() {
            return {
              currentUrl: '',
            }
        },
        mounted() {
          this.currentUrl = window.location.pathname;
        },
        watch: {
          '$route'(pathUrl){
            this.currentUrl = pathUrl.path;
          }
        },
        metaInfo() {
            return {
                title: "Simplify your EB2 NIW drafting experience with AI",
                meta: [
                  { vmid: 'description', name: 'description', content:  'Unlock the power of SelfpetitionAI, your trusted companion in self petitioning for EB-2 NIW Advanced Degree category. Answer list of questions about your background and let AI draft your I-140 cover letter and three reference letters with precision and efficiency. Simplify your drafting experience – where innovation meets simplicity.'},
                ]
            }
        }
    }
</script>
