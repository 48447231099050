<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <SuccessStoryAutomotiveEngineer></SuccessStoryAutomotiveEngineer>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import SuccessStoryAutomotiveEngineer from "../blog-components/SuccessStoryAutomotiveEngineer";


export default {
  name: 'SuccessStoryAutomotiveEngineerView',
  components: {
    SuccessStoryAutomotiveEngineer,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: " EB-2 NIW approval for automotive engineer - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "Meet Michael, an accomplished Electronic Engineering Leader in the automotive industry, who successfully obtained approval for his EB2 NIW case in 2023. Michael took matters into his own hands and achieved remarkable success. Michael's impressive background includes a Bachelor of Science in Electronic Engineering from Germany and a Master of Science in Electrical Engineering from the USA. With over 15 years of experience in the automotive industry, he played a pivotal role in introducing electronic innovations to various vehicles, earning recognition from major companies such as Ford, GM, Caterpillar, and Volkswagen."},
      ]
    }
  }
}
</script>
