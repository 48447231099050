<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <span class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </span>
            <span class="article-tags2">
              <a href="#">SUCCESS STORY</a>
            </span>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">EB-2 NIW approval for biotech startup founder</h1>
          </div>
        </div>
      </div>
    </div>

    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">March 2, 2023</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Przemek</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog20-16x9.webp" alt="NIW template entrepreneur">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">Success story: EB-2 NIW</h3>

          <div class="blog-paragraph"><span style="background-color: rgba(68, 206, 111, 0.25)">Tom was a scientist and a biotech startup founder who was passionate about developing new and innovative treatments for rare diseases.</span> He had a <span style="font-weight: 500; font-style: italic">PhD in biochemistry and years of experience working in the industry</span>, but he knew that he needed to bring his ideas to the next level. So, he decided to <span style="font-weight: 500; font-style: italic">start his own company</span> and focused on finding new ways to treat rare genetic disorders that affected children.</div>

          <div class="signupSelfPetition row justify-content-center align-items-center">
            <div class="col-xl-6 col-lg-7">
              <signup-self-petition></signup-self-petition>
            </div>
          </div>

          <div class="blog-paragraph">Tom's company was in its early stages of development, but he was already making a big impact in the industry. His work was gaining <span style="font-weight: 500; font-style: italic">recognition from leading experts</span> in the field. Despite these accomplishments, Tom faced many challenges in growing his company.</div>

          <div class="blog-paragraph">One of the biggest obstacles was obtaining a green card, which would allow him to live and work in the United States permanently. Tom knew that his work was important and that he needed to stay in the country to continue it. He heard about the EB-2 NIW (National Interest Waiver) visa, which could allow him to bypass the traditional green card process if he could demonstrate that his work was in the national interest of the United States.</div>

          <div class="blog-paragraph">For compiling an application Tom leveraged <a href="/home" style="text-decoration: underline">EB-2 NIW Starter Kit</a> for professionals. He gathered all the necessary documents and prepared a strong petition that highlighted the importance of his work and how it benefited the United States. <span style="background-color: rgba(68, 206, 111, 0.25)">He listed all received awards and grants, press and media coverage of his startup work, traction of discussions which he held with investors and the main milestones</span> that the startup achieved so far.</div>

          <div class="blog-paragraph">Check <a href="/home" style="text-decoration: underline">Starter Kit for EB-2 NIW</a>, which includes self-petition Green Card templates for professionals:</div>
          
          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

          <div class="blog-paragraph">After several months of waiting, Tom received the good news that his EB-2 NIW petition had been approved. This meant that he could now live and work in the United States permanently, without having to worry about the typical green card process.</div>

          <div class="blog-paragraph">With his new status, Tom was able to focus fully on his work and his company. He also <span style="font-weight: 500; font-style: italic">secured additional funding and hired more scientists</span>, which allowed him to expand his research and develop new treatments faster. His company became one of the leading biotech startups in the country and his work was recognized by the medical community as some of the most important research in the field.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem; padding-bottom: 0px">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                  <a href="/are-you-eligible" class="btn btn-primary btn-assess">Are you eligible for EB-2 NIW?</a>
              </div>
            </div>
          </div>

          <div class="blog-paragraph" style="padding-bottom: 6rem">Tom's EB-2 NIW approval was a turning point in his career and allowed him to continue his important work and make a real impact in the lives of those affected by <span style="font-weight: 500; font-style: italic">rare genetic disorders</span>. He was grateful for the opportunity and never forgot how the EB-2 NIW visa had helped him reach his goals.</div>

        </div>
      </div>
    </section>

  </div>
</template>
<script>

import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";
import SignupSelfPetition from "../components/SignupSelfPetition.vue";

export default {
  name: 'SuccessStoryEntrepreneur',
  components: {
    PriceOffer,
    CarouselGreenCard,
    SignupSelfPetition
  }
}
</script>
