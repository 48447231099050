<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <EB2NIWPetitionLength></EB2NIWPetitionLength>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import EB2NIWPetitionLength from "../blog-components/EB2NIWPetitionLength";


export default {
  name: 'EB2NIWPetitionLengthView',
  components: {
    EB2NIWPetitionLength,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "Finding the perfect length for EB-2 NIW petition - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "Navigating the complex immigration landscape of the United States requires careful planning and attention to detail. One common question that arises during the petition process is, 'How long should my EB-2 NIW petition be? I have seen others submitting petitions of 100+ pages.' In this article, we will explore the key factors to consider when determining the length of your NIW petition to provide insights into crafting a compelling and effective submission."},
      ]
    }
  }
}
</script>
