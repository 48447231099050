<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <SuccessStorySoftwareEngineerRetail></SuccessStorySoftwareEngineerRetail>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import SuccessStorySoftwareEngineerRetail from "../blog-components/SuccessStorySoftwareEngineerRetail";


export default {
  name: 'SuccessStorySoftwareEngineerRetailView',
  components: {
    SuccessStorySoftwareEngineerRetail,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "EB2 NIW approval for software engineer - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "Meet Rafael, a skilled software engineer from Germany with over 5 years of experience in the retail sector who applied for a green card through EB-2 NIW process. One of the key criteria for the EB-2 NIW is demonstrating the significant impact one's work has on the national interest of the United States. In the world of retail, data-driven decision-making is pivotal for success."},
      ]
    }
  }
}
</script>
