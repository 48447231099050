<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <ProfessionMarketing></ProfessionMarketing>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
    import ExternalHeaderGreenCard from "./components/ExternalHeaderGreenCard";
    import ExternalFooterGreenCard from "./components/ExternalFooterGreenCard";
    import ProfessionMarketing from "./components/ProfessionMarketing";

    export default {
      name: 'ProfessionMarketingView',
      components: {
        ProfessionMarketing,
        ExternalHeaderGreenCard,
        ExternalFooterGreenCard,
      },
      data() {
        return {
          currentUrl: ''
        }
      },
      watch: {
        '$route'(pathUrl){
          this.currentUrl = pathUrl.path;
        }
      },
      mounted() {
        this.currentUrl = window.location.pathname;
      },
      metaInfo() {
        return {
          title: "Marketing - Self Petition USA",
          meta: [
          { vmid: 'description', name: 'description', content:  ""},
          ]
        }
      }
    }
</script>