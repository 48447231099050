<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <div class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </div>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">EB-2 NIW cases that fail - avoid these mistakes! (2)</h1>
          </div>
        </div>
      </div>
    </div>

    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">June 27, 2023</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Tom</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog30-16x9.webp" alt="eb-2 NIW mistakes">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">Lessons learned from failing Prong-1</h3>

          <div class="blog-paragraph"><span style="background-color: rgba(68, 206, 111, 0.25)">In this blog post, we will discuss a <a style="text-decoration: underline" href="https://www.uscis.gov/sites/default/files/err/B5%20-%20Members%20of%20the%20Professions%20holding%20Advanced%20Degrees%20or%20Aliens%20of%20Exceptional%20Ability/Decisions_Issued_in_2023/FEB172023_01B5203.pdf">specific case</a> and dissect the appeal process to understand the <span style="font-weight: 500; font-style: italic">petitioner's errors in meeting Prong-1</span>.</span> Goal is to gain valuable insights into what to avoid in your own petitions. So let's dive in and explore the lessons we can learn from this failed application.</div>

          <div class="blog-paragraph">Before delving into the case, it is essential to familiarize yourself with the general EB-2 requirement and the Matter of Dhanasar framework for the NIW Green Card. The EB-2 category typically requires an <a target="_blank" href="https://www.uscis.gov/working-in-the-united-states/permanent-workers/employment-based-immigration-second-preference-eb-2" style="text-decoration: underline">advanced degree or exceptional ability</a>. Additionally, the <a target="_blank" href="https://www.uscis.gov/working-in-the-united-states/permanent-workers/employment-based-immigration-second-preference-eb-2" style="text-decoration: underline">NIW Green Card has three prongs</a>: demonstrating an endeavor of substantial merit and national importance, being well-positioned to advance the endeavor, and proving that it would be beneficial for the US to waive the job offer requirement.</div>

          <div class="blog-paragraph">In this case, we <span style="background-color: rgba(68, 206, 111, 0.25)">examine the appeal of a civil engineer who aimed to address housing issues in the United States. The petitioner had two US master's degrees, fulfilling the general EB-2 requirement. However, <span style="font-weight: 500; font-style: italic">the appeal focused on evaluating eligibility for the NIW Green Card under the Dhanasar framework</span>.</span></div>

          <h6 style="margin-bottom: 3rem">USCIS source: <a style="text-decoration: underline" href="https://www.uscis.gov/sites/default/files/err/B5%20-%20Members%20of%20the%20Professions%20holding%20Advanced%20Degrees%20or%20Aliens%20of%20Exceptional%20Ability/Decisions_Issued_in_2023/FEB172023_01B5203.pdf">HERE</a></h6>
          
          <h5>Mistake #1: Focusing on self instead of the endeavor</h5>

          <div class="blog-paragraph">One critical mistake made by the petitioner was <span style="font-weight: 500; font-style: italic">prioritizing personal development</span> at the center of the endeavor rather than emphasizing national importance. The petitioner's statement indicated a desire to <span style="font-weight: 500; font-style: italic">gain experience to start their own firm, which undermined the focus on benefiting the United States</span>. The NIW Green Card is about what you can contribute to the country, not what the country can provide for you.</div>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

          <h5>Mistake #2: Insufficient evidence of National Importance</h5>

          <div class="blog-paragraph">The petitioner claimed that their proposed endeavor was of national importance due to the <span style="font-weight: 500; font-style: italic">housing shortage in the US</span>. However, the evidence provided failed to establish the significant potential for employing US workers or generating substantial positive economic effects. The petitioner did not demonstrate how the endeavor's implications would <span style="font-weight: 500; font-style: italic">rise to the level of national importance. Instead he focused on Florida exclusively.</span> It is crucial to provide persuasive detail and evidence to support the claim of national importance in the endeavor.</div>

          <h5>Mistake #3: Inadequate recommendation letters</h5>

          <div class="blog-paragraph">The petitioner submitted recommendation letters as part of their evidence. However, these <span style="font-weight: 500; font-style: italic">letters lacked persuasive detail and did not sufficiently establish the impact of the petitioner's work beyond their current employer</span>. It is essential to ensure these letters are well-crafted, addressing the three prongs of the Dhanasar framework. We <a href="/home" style="text-decoration: underline">recommend drafting your application letter first</a> and then elaborating on the arguments mentioned in the application in the reference letters.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                <router-link class="btn btn-primary" :to="{ name: 'Home', params: { location: 'Templates' }}">EB-2 NIW Templates</router-link>
              </div>
            </div>
          </div>

          <h5>Conclusion</h5>

          <div class="blog-paragraph">Learning from failed cases can provide valuable insights for  seeking an EB-2 or NIW Green Card. Avoid similar pitfalls in your own petition:</div>

          <ul class="features-list">
            <li><vue-feather type="check"></vue-feather>Craft your endeavor statement with a <span style="font-weight: 500; font-style: italic">focus on national importance</span> rather than personal development.</li>
            <li><vue-feather type="check"></vue-feather>Provide detailed evidence to <span style="font-weight: 500; font-style: italic">establish the significant potential for employing US workers and generating positive economic effects</span>. <span style="background-color: rgba(68, 206, 111, 0.25)">USCIS is not so much focused on the geographic impact of your endeavor, even people who work in an area with local or regional impact may qualify for EB-2 NIW. However, keep in mind you shall add evidence of national importance,</span> examples: national expansion in the future, incoming demand from outside of your local area, national implications.</li>
            <li><vue-feather type="check"></vue-feather>Ensure your <a style="text-decoration: underline" href="/prepare-eb2-niw-recommendation-letters">recommendation letters</a> are <span style="font-weight: 500; font-style: italic">well-crafted</span>, <span style="font-weight: 500; font-style: italic">detailed (and not generic)</span>, <span style="font-weight: 500; font-style: italic">persuasive</span>, and <span style="font-weight: 500; font-style: italic">address the specific requirements</span> of the NIW Green Card.</li>
          </ul>
          
          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                  <a href="/eb2niw-mistakes-to-avoid-1" class="btn btn-primary">Continue Reading</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>
<script>
import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";

export default {
  name: 'EB2NIWMistakes',
  components: {
    PriceOffer,
    CarouselGreenCard
  }
}
</script>
