<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <ProfessionChemicalEngineer></ProfessionChemicalEngineer>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
    import ExternalHeaderGreenCard from "./components/ExternalHeaderGreenCard";
    import ExternalFooterGreenCard from "./components/ExternalFooterGreenCard";
    import ProfessionChemicalEngineer from "./components/ProfessionChemicalEngineer";

    export default {
      name: 'ProfessionChemicalEngineerView',
      components: {
        ProfessionChemicalEngineer,
        ExternalHeaderGreenCard,
        ExternalFooterGreenCard,
      },
      data() {
        return {
          currentUrl: ''
        }
      },
      watch: {
        '$route'(pathUrl){
          this.currentUrl = pathUrl.path;
        }
      },
      mounted() {
        this.currentUrl = window.location.pathname;
      },
      metaInfo() {
        return {
          title: "Chemical Engineer - Self Petition USA",
          meta: [
          { vmid: 'description', name: 'description', content:  "In pursuit of the EB-2 NIW, chemical engineers are entrusted with not only showcasing their technical prowess but also highlighting the extensive impact of their work on the nation's well-being. This entails presenting a detailed account of innovations, advancements, or contributions that have positively influenced industries, society, or national security within the realm of chemical engineering."},
          ]
        }
      }
    }
</script>