<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <div class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </div>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">Adjustment of Status vs. Consular Processing</h1>
          </div>
        </div>
      </div>
    </div>
    
    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">January 30, 2023</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Natalie</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog10-16x9.webp" alt="eb2 niw diy kit">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">Which one to choose?</h3>

          <div class="blog-paragraph">Two common ways to obtain a green card are through Adjustment of Status and Consular Processing. While both methods serve the same ultimate purpose, there are some significant differences between them.</div>

          <h5>Adjustment of Status:</h5>

          <div class="blog-paragraph">Adjustment of Status is the <span style="background-color: rgba(68, 206, 111, 0.25)">process by which a foreign national who is already in the United States</span> can apply to adjust their status to that of a lawful permanent resident. To be eligible for Adjustment of Status, the applicant must meet certain criteria, including:</div>

          <ul class="features-list">
            <li><vue-feather type="check"></vue-feather>Being physically present in the United States</li>
            <li><vue-feather type="check"></vue-feather>Having been inspected and admitted or paroled into the United States</li>
            <li><vue-feather type="check"></vue-feather>Having an immigrant visa immediately available</li>
            <li><vue-feather type="check"></vue-feather>Not having violated any immigration laws or regulations</li>
          </ul>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

          <div class="blog-paragraph">The Adjustment of Status process typically involves submitting a <span style="font-weight: 500; font-style: italic">form I-485</span>, along with supporting documentation, to <span style="font-weight: 500; font-style: italic">the United States Citizenship and Immigration Services (USCIS)</span>. After receiving the application, USCIS may request additional information or conduct an interview with the applicant. If the application is approved, the applicant will receive a green card, which grants them permanent residency in the United States.</div>

          <h5>Consular Processing:</h5>
          <div class="blog-paragraph">Consular Processing, on the other hand, is the process by which a <span style="background-color: rgba(68, 206, 111, 0.25)">foreign national applies for a visa at a U.S. consulate or embassy outside of the United States</span>. Consular Processing is typically used by individuals who are not eligible for Adjustment of Status or who prefer to apply for their green card from <span style="font-weight: 500; font-style: italic">outside the United States</span>. To be eligible for Consular Processing, the applicant must have an approved immigrant visa petition and an available immigrant visa.</div>

          <div class="blog-paragraph">The Consular Processing process typically involves <span style="font-weight: 500; font-style: italic">submitting online</span> a <span style="font-weight: 500; font-style: italic">DS-260 form</span>, along with supporting documentation, to <span style="font-weight: 500; font-style: italic">the National Visa Center (NVC)</span>. The NVC will then schedule an interview at a U.S. consulate or embassy abroad. During the interview, the consular officer will review the application and determine whether the applicant is eligible for a visa. If the application is approved, the applicant will receive an immigrant visa, which allows them to travel to the United States and apply for a green card.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                <router-link class="btn btn-primary" :to="{ name: 'Home', params: { location: 'Templates' }}">EB-2 NIW Templates</router-link>
              </div>
            </div>
          </div>

          <h5>What are the key differences?</h5>

          <div class="blog-paragraph">One of the main <span style="background-color: rgba(68, 206, 111, 0.25)">differences between Adjustment of Status and Consular Processing is the location where the application is processed</span>. Adjustment of Status is processed <span style="font-weight: 500; font-style: italic">within the United States</span>, while Consular Processing is processed <span style="font-weight: 500; font-style: italic">outside of the United States</span> at a U.S. consulate or embassy.</div>

          <div class="blog-paragraph">Another significant <span style="background-color: rgba(68, 206, 111, 0.25)">difference is the processing time</span>. Adjustment of Status applications typically take <span style="font-weight: 500; font-style: italic">several months</span> to process, while Consular Processing applications <span style="font-weight: 500; font-style: italic">can take significantly longer</span>, often taking several years to complete.</div>

          <div class="blog-paragraph">Finally, the <span style="background-color: rgba(68, 206, 111, 0.25)"><a href="/home" style="text-decoration: underline">application requirements and documentation</a></span> for Adjustment of Status and Consular Processing may differ. For example, Adjustment of Status requires an <span style="font-weight: 500; font-style: italic">I-485 form</span>, whereas  Consular Process typically involves submitting <span style="font-weight: 500; font-style: italic">DS-260</span>.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                  <a href="/are-you-eligible" class="btn btn-primary btn-assess">Are you eligible for EB-2 NIW?</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>
<script>
import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";

export default {
  name: 'EB2NIWAdjustmentConsular',
  components: {
    PriceOffer,
    CarouselGreenCard
  }
}
</script>
