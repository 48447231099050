<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <EB2NIWSelfPetitionUsa></EB2NIWSelfPetitionUsa>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import EB2NIWSelfPetitionUsa from "../blog-components/EB2NIWSelfPetitionUsa";


export default {
  name: 'EB2NIWSelfPetitionUsaView',
  components: {
    EB2NIWSelfPetitionUsa,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "Empowering almost 60 EB-2 NIW applicants - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "The growth of Self Petition USA, centered around the EB-2 National Interest Waiver, has been a testament to the power of happy customers. We've not only helped almost 60 applicants achieve their dreams but also created a thriving Discord Community where knowledge and experiences are shared, and meaningful connections are made."},
      ]
    }
  }
}
</script>
