<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <section id="are-you-eligible" class="feedback-area ptb-80 bg-f9fafb">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container" style="margin-top: 5rem">
            <div class="section-title" style="height:800px;background-color: white; padding: 1rem; text-align: left">
              <h2 class="titleEligible"  style="margin-bottom: 20px;" >Are you eligible for EB-2 NIW?</h2>

            <iframe frameBorder="0" width="100%" height="100%" src="https://rozm1mvr26a.typeform.com/to/UHWWb9JH"></iframe>
            </div>

          </div>
        </div>
      </div>
    </section>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
    import ExternalHeaderGreenCard from "./components/ExternalHeaderGreenCard";
    import ExternalFooterGreenCard from "./components/ExternalFooterGreenCard";

    export default {
        name: 'TypeformView',
        components: {
            ExternalHeaderGreenCard,
            ExternalFooterGreenCard,
        },
        data() {
            return {
              currentUrl: '',
            }
        },
        mounted() {
          this.currentUrl = window.location.pathname;
        },
        watch: {
          '$route'(pathUrl){
            this.currentUrl = pathUrl.path;
          }
        },
        metaInfo() {
            return {
                title: "EB-2 NIW TEMPLATE: Are you eligible for EB-2 NIW",
                meta: [
                { vmid: 'description', name: 'description', content:  'You can apply for EB-2 NIW without a permanent job offer from U.S. employer. It is very fast process because NIW waives lengthy Labor Certification.'},
                ]
            }
        }
    }
</script>
