<template>

    <section class="works-area ptb-80 bg-f9fafb">

      <b-modal ref="modal-cover-letter" title="Network Engineer" size="xl" hide-footer>
        <img src="../resources/sample-networkEngineer.webp" alt="eb2 niw sample network engineer"/>
      </b-modal>

      <b-modal ref="modal-case-builder" title="Network Engineer" size="xl" hide-footer>
        <img src="../resources/caseBuilder-networkEngineer.webp" alt="eb2 niw sample electrical engineer"/>
      </b-modal>

      <div class="container" style="margin-top: 5rem">
        <div class="row">
          <div class="col-sm-3 col-8 offset-sm-0 offset-2" style="margin-bottom: 4rem">
            <img src="../resources/networkEng.webp" style="border-radius: 50%; border: 2px solid; border-color: #44ce6f; box-shadow: 10px 20px 20px 0px rgba(86, 97, 118, .25)" alt="eb2 niw sample network eng"/>
          </div>

          <div class="col-sm-8 offset-sm-1 col-12 sample-h1">
            <div>
              <h1 style="font-weight: 600;">Network & Electrical Engineer</h1>
              <div class="bar"></div>
            </div>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 30px">
        <div style="margin: 1rem; margin-top: 2rem; max-width: 1000px">In the EB-2 NIW process, professionals specializing in network and electrical engineering must demonstrate their technical expertise and articulate the significant impact of their contributions on critical systems. This entails providing a detailed account of their work in designing and optimizing network infrastructure, developing advanced electrical systems, and enhancing fault tolerance across diverse industries.</div>
          <div style="margin: 1rem; max-width: 1000px">To successfully navigate the EB-2 NIW process in the network and electrical engineering sector, applicants must comprehensively present their accomplishments, emphasizing the pivotal role they play in shaping and advancing technological solutions. Equally crucial is underscoring the broader societal and economic implications of their work, particularly within the context of modern communication networks and electrical systems, where advancements align with national interests such as technological innovation and infrastructure reliability.</div>
          <div style="margin: 1rem; max-width: 1000px">With the inherent alignment of network and electrical engineering expertise with national interests, applicants can leverage their roles as catalysts for technological progress and enhanced efficiency in critical systems. This alignment has been duly recognized and emphasized by policymakers (Biden Administration in January 2022), reflecting the national interest in promoting innovation, ensuring robust communication infrastructure, and addressing the evolving challenges in electrical systems.</div>
          <div style="margin: 1rem; max-width: 1000px">It's noteworthy that, contrary to common belief, research, publications, or citations are not obligatory for EB-2 NIW, and our templates are specifically tailored for professionals in network and electrical engineering without the need for these traditional markers of achievement.</div>

          <div style="margin: 3rem 1rem 1rem; max-width: 200px; border: 2px solid #44ce6f; padding: 12px 12px 4px; border-radius: 5px;">
            <vue-feather type="shield" style="color: #44ce6f"></vue-feather> Read More:
            <ul>
              <li style="margin-top: 5px"><a href="/success-story-network-engineer" target="_blank" style="text-decoration: underline">Success Story 1</a></li>
              <li style="margin-top: 5px"><a href="/success-story-chips-engineer" target="_blank" style="text-decoration: underline">Success Story 2</a></li>
            </ul>
          </div>
        </div>

<!--Network & Electrical Engineer-->
        <div id="profession-sample" style="margin-bottom: 1.5rem">
          <div style="margin: 6rem 0rem">
            <h4 style="text-align: center;">Network & Electrical Engineer</h4>
            <div class="section-title">
              <div class="bar"></div>
            </div>
            
            <div style="text-align: center; margin: 4rem 0 6rem">
              <button v-on:click="navigateToCheckout" class="btn btn-primary">GET THIS TEMPLATE</button>
              <div class="price-offer-comment" style="color: #c4c4c4; margin-top: 50px;">*** Not quite right? Try <a style="color: #c4c4c4; text-decoration: underline" href="/ai">Selfpetition AI</a>  instead ***</div>            
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCoverLetterModal" style="cursor: pointer">
              <img src="../resources/sample-networkEngineer.webp" alt="network engineer i140 sample">
              <div class="works-content">
                <h3 style="color: #fff">Network & Electrical Engineer</h3>
                <h3 style="color: #fff">Cover Letter (10 pages)</h3>
                <p>The cover letter comprises the following segments: meeting the criteria for an Advance Degree, Prong 1, Prong 2, Prong 3, and an appendix containing all supporting evidence. When checking out, you have the option to select up to two professions.</p>
              </div>
            </div>

            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCaseBuilderModal" style="cursor: pointer">
              <img src="../resources/caseBuilder-networkEngineer.webp" alt="electrical engineer green card">
              <div class="works-content">
                <h3 style="color: #fff">Network & Electrical Engineer</h3>                    
                <h3 style="color: #fff">EB-2 NIW Case Builder (18 pages)</h3>
                <p>You might be wondering how to identify relevant NIW initiatives and supporting arguments. This is where the NIW Case Builder Pdf comes in – it's designed to inspire you with alternative points to emphasize in your cover letter as you modify our template.</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div style="margin: 5rem 0rem">
            <h4 style="text-align: center;">EB2 NIW Starter Kit</h4>
            <h6 style="text-align: center;">List of Documents</h6>
            <div class="section-title">
              <div class="bar"></div>
            </div>
          </div>

          <div class="section-title faq" style="margin-bottom: 1.5rem">
            <DocumentsList></DocumentsList>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 1.5rem">
          <div class="row">
            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Most suitable for</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">Network Engineer</li>
                <li class="profession-page-list">Electrical Engineer</li>
                <li class="profession-page-list">Systems Engineer</li>
                <li class="profession-page-list">Telecommunications Engineer</li>
                <li class="profession-page-list">Network Security Engineer</li>
                <li class="profession-page-list">Hardware Engineer</li>
                <li class="profession-page-list">Wireless Communication Engineer</li>
                <li class="profession-page-list">Control Systems Engineer</li>
                <li class="profession-page-list">Power Systems Engineer</li>
                <li class="profession-page-list">Electronics Design Engineer</li>
                <li class="profession-page-list">Network Administrator</li>
                <li class="profession-page-list">Embedded Systems Engineer</li>
                <li class="profession-page-list">Instrumentation Engineer</li>
                <li class="profession-page-list">Automation Engineer</li>
                <li class="profession-page-list">Electrical Design Engineer</li>
                <li class="profession-page-list">Data Center Engineer</li>
                <li class="profession-page-list">IT Infrastructure Engineer</li>
                <li class="profession-page-list">Network Architect</li>
                <li class="profession-page-list">FPGA Engineer</li>
                <li class="profession-page-list">PLC Engineer</li>
                <li class="profession-page-list">Signal Processing Engineer</li>
                <li class="profession-page-list">Cloud Solutions Engineer</li>
                <li class="profession-page-list">Test and Validation Engineer</li>
                <li class="profession-page-list">Robotics Engineer</li>
                <li class="profession-page-list">Systems Integration Engineer</li>
                <li class="profession-page-list">Electric Power Engineer</li>
                <li class="profession-page-list">IT Support Engineer</li>
                <li class="profession-page-list">Circuit Design Engineer</li>
                <li class="profession-page-list">... and other similar</li>
              </ul>
            </div>

            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Topics</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">Telecommunication services</li>
                <li class="profession-page-list">5G technology</li>
                <li class="profession-page-list">Network infrastructure</li>
                <li class="profession-page-list">Wireless technologies</li>
                <li class="profession-page-list">Next-generation networks</li>
                <li class="profession-page-list">Network optimization</li>
                <li class="profession-page-list">Network performance</li>
                <li class="profession-page-list">Network architecture</li>
                <li class="profession-page-list">5G deployment</li>
                <li class="profession-page-list">Networking technology</li>
                <li class="profession-page-list">Routing and switching</li>
                <li class="profession-page-list">Security technologies</li>
                <li class="profession-page-list">Cloud computing</li>
                <li class="profession-page-list">Software-defined networking (SDN)</li>
                <li class="profession-page-list">Job creation</li>
                <li class="profession-page-list">Commercialization efforts</li>
                <li class="profession-page-list">Federal Communications Commission (FCC)</li>
                <li class="profession-page-list">Economic growth</li>
                <li class="profession-page-list">Technological advancement</li>
                <li class="profession-page-list">Critical infrastructure</li>
                <li class="profession-page-list">Digital transformation</li>
                <li class="profession-page-list">Public-private partnerships</li>
                <li class="profession-page-list">Network virtualization</li>
                <li class="profession-page-list">Wireless protocols</li>
                <li class="profession-page-list">Network security</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="shape2 rotateme" style="left: 90%"><img src="../resources/shape2.svg" alt="EB2 niw diy"></div>
      <div class="shape4"><img src="../resources/shape4.svg" alt="EB-2 niw network engineer"></div>
      <div class="shape7"><img src="../resources/shape4.svg" alt="EB-2 niw electrical engineer"></div>
    </section>

</template>

<script>

import { mapActions } from 'vuex';
import DocumentsList from "./DocumentsList.vue";

    export default {
      name: 'ProfessionNetworkElectricalEng',
      components: {
        DocumentsList,
      },
      data() {
        return {
          user: {
            profession1: 'networkElectricalEngineer',
          }
        }
      },
      methods: {
         ...mapActions('account', ['apply']),
        navigateToCheckout() {
          //this.apply('Network Electrical Sample');
          // Handle navigation to checkout here
          this.$router.push({
            path: '/checkout-starter-kit',
            query: {
              profession: this.user.profession1
            }
          });
        },
        openCoverLetterModal() {
          setTimeout(() => {
            this.$refs['modal-cover-letter'].show();
          }, 100);
        },
        openCaseBuilderModal() {
          setTimeout(() => {
            this.$refs['modal-case-builder'].show();
          }, 100);
        },
      }
    }
</script>

