<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <div class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </div>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">EB-2 NIW independent recommendation letters</h1>
          </div>
        </div>
      </div>
    </div>
    
    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">April 21, 2023</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Tom</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog25-16x9.webp" alt="eb2 niw diy kit">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">Who are and how to find independent recommenders?</h3>

          <div class="blog-paragraph">In this article, we will discuss who they are, why you need them for your EB-2 NIW petition, and address some of the frequently asked questions.</div>
          <div class="blog-paragraph">There are two types of recommenders, objective and subjective:</div>
          
          <ul class="features-list">
            <li><vue-feather type="check"></vue-feather><span style="background-color: rgba(68, 206, 111, 0.25)"><span style="font-weight: 500; font-style: italic">Objective recommenders</span>, also known as independent recommenders, independent advisory opinions, or independent expert opinions. These are experts who do not know you personally but can vouch for your work. They may have met you or talked with you, but they have not worked closely with you or been in the same organization. Although it may seem counterintuitive, independent recommenders are the most important type of recommender for the EB-2 NIW petition.</span></li>
            <li><vue-feather type="check"></vue-feather>In contrast, <span style="font-weight: 500; font-style: italic">subjective recommenders</span> are those whom you have worked closely with, such as bosses or colleagues, and can attest to your qualifications and achievements. While it is useful to have some subjective recommendation letters in your petition, they are not the most important type.</li>
          </ul>

          <div class="blog-paragraph">In this article we will focus exclusively on objective recommenders as USCIS might send you an RFE if they are not part of your application. Independent recommendation letters are crucial for your EB-2 NIW petition, and we will cover why and how to obtain them.</div>
          
          <h5>Who are independent recommenders?</h5>
          <div class="blog-paragraph">They are a well-known and reputable organization or persons with numerous contributions to their field. These independent recommendations will add value to your profile.</div>
          
          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                <router-link class="btn btn-primary" :to="{ name: 'Home', params: { location: 'Templates' }}">EB-2 NIW Templates</router-link>
              </div>
            </div>
          </div>

          <h5>How to find independent recommenders?</h5>
          <div class="blog-paragraph">To find independent recommenders, you can either approach someone who is completely unrelated. In this case, find information from their organization's website, LinkedIn profile, or published papers to contact them. However, this has a low success rate, as they may not have any references about you.</div>
          <div class="blog-paragraph"><span style="background-color: rgba(68, 206, 111, 0.25)">In the second scenario, <span style="font-weight: 500; font-style: italic">you might know the person, but not in a professional capacity of working on a daily basis</span>. It might be someone you have previously met (event, conference, association, client), or you might leverage the network of someone you know (your manager, advisor, previous professor, association, mentor). Ask them if they can name-drop yourself to their colleagues to obtain a letter. This increases your chances of success since they have a previous connection with you.</span></div>
          
          <h5>Who writes recommendation letters?</h5>
          <div class="blog-paragraph">The simple answer is <span style="font-weight: 500; font-style: italic">YOU</span>. As you know your profile the best, you'll be able to highlight the aspects you want to emphasize in your petition. Therefore, you should write these letters intentionally, and <span style="font-weight: 500; font-style: italic">use short excerpts from them in your petition letter</span>.</div>
          <div class="blog-paragraph">Providing your recommenders with a draft will also save them a lot of time. Writing a recommendation letter can be very time-consuming, especially if you're asking a senior executive or a highly reputable person to write the whole thing. Therefore, <span style="font-weight: 500; font-style: italic">by writing the letter yourself, you'll be able to control the content.</span></div>
          <div class="blog-paragraph">Check our <a href="/home" style="text-decoration: underline">Starter Kit for EB-2 NIW</a>, which includes a sample of <a href="/home" style="text-decoration: underline">three independent recommendation letters</a> from Author's successful application <span style="font-weight: 500; font-style: italic">PLUS</span> a detailed <a href="/home" style="text-decoration: underline">instruction how you can request your own:</a></div>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

          <h5>What to include in a recommendation letter?</h5>
          <div class="blog-paragraph">The letters should be <span style="font-weight: 500; font-style: italic">signed in ink or electronically</span> using DocuSign or similar. Don't forget about a <span style="font-weight: 500; font-style: italic">date</span>! It is always beneficial to attach recommender's <span style="font-weight: 500; font-style: italic">CV/resume or a short bio</span>. If the letter is in a language other than English, provide a <span style="font-weight: 500; font-style: italic">translation.</span></div>
          <div class="blog-paragraph">It is nice to have the letter written on the recommender's <span style="font-weight: 500; font-style: italic">official letterhead</span>, but if you can't accomplish that, come up with an alternative way to make it look reliable: add <span style="font-weight: 500; font-style: italic">LinkedIn profile, contact number or email, link to a website</span> where the person is mentioned.</div>
          <div class="blog-paragraph">The original letter does not need to be mailed, and an electronic version is sufficient. Letters from non-US countries are accepted and that letters should be around two to three pages long. <span style="background-color: rgba(68, 206, 111, 0.25)">There is no official guideline for how many letters to have. Authors of this blog succeeded with only three recommendation letters!</span></div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                  <a href="/prepare-eb2-niw-recommendation-letters" class="btn btn-primary">Continue Reading</a>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>

  </div>
</template>
<script>
import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";

export default {
  name: 'EB2NIWIndependentRecommendatioLetters',
  components: {
    PriceOffer,
    CarouselGreenCard
  }
}
</script>
