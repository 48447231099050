<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <div class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </div>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">EB-2 NIW green card timeline in 2023</h1>
          </div>
        </div>
      </div>
    </div>
    
    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">April 4, 2023</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Tom</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog23-16x9.webp" alt="self petition DIY">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">How long does EB-2 NIW take?</h3>

          <div class="blog-paragraph">The employment-based second preference (EB2) National Interest Waiver (NIW) green card is a popular option for foreign nationals who possess exceptional abilities or advanced degrees and want to pursue permanent residency in the United States. In this article, we will discuss the estimated timeline for the <span style="font-weight: 500; font-style: italic">EB2 NIW green card petition in 2023</span>.</div>

          <div class="blog-paragraph">The EB2 NIW green card process involves several steps, including filing the <span style="font-weight: 500; font-style: italic">I-140 form</span> and the <span style="font-weight: 500; font-style: italic">I-485 form</span>, and attending a <span style="font-weight: 500; font-style: italic">biometrics appointment</span> and an <span style="font-weight: 500; font-style: italic">interview</span>. The processing time for each of these steps can vary, and the overall timeline can be affected by USCIS's workload and backlog.</div>

          <div class="blog-paragraph">Let's break down the estimated timeline for each step:</div>

          <ul class="features-list">
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Filing the I-140 form:</span> The first step in the EB2 NIW green card petition process is to file the I-140 form, which is a petition for alien workers. <span style="background-color: rgba(68, 206, 111, 0.25)">The processing time for the I-140 form can take anywhere from 6 to 8 months.</span> However, premium processing can expedite the process to 15 calendar days for an additional fee.</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Filing the I-485 form:</span> The next step in the EB2 NIW green card petition process is to file the I-485 form, which is an application to adjust status to permanent resident. <span style="background-color: rgba(68, 206, 111, 0.25)">The processing time for the I-485 form can take anywhere from 8 to 14 months.</span></li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Biometrics appointment:</span> After filing the I-485 form, USCIS will schedule a biometrics appointment for the applicant to provide their fingerprints, photograph, and signature. <span style="background-color: rgba(68, 206, 111, 0.25)">The processing time for the biometrics appointment is typically 2 to 4 weeks.</span></li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Interview:</span> Finally, USCIS may schedule an interview for the applicant. <span style="background-color: rgba(68, 206, 111, 0.25)">The interview can take anywhere from 3 to 6 months after filing the I-485 form.</span></li>
          </ul>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

          <div class="blog-paragraph">Overall, the <span style="background-color: rgba(68, 206, 111, 0.25)">estimated timeline for the EB2 NIW green card petition in 2023 is between 18 to 28 months</span>. However, it's important to note that USCIS processing times can change, and delays can occur due to various reasons such as security checks or administrative errors.</div>

          <div class="blog-paragraph"><span style="background-color: rgba(68, 206, 111, 0.25)">Check current processing time on the official USCIS website: <a target="_blank" href="https://egov.uscis.gov/processing-times/" style="text-decoration: underline">www.egov.uscis.gov/processing-times</a></span></div>

          <ul class="features-list">
            <li><vue-feather type="check"></vue-feather>Select "I-140"</li>
            <li><vue-feather type="check"></vue-feather>In form category select: "Advanced degree or exceptional ability requesting a National Interest Waiver"</li>
            <li><vue-feather type="check"></vue-feather>In field office select: "Texas" or "Nebraska"</li>
          </ul>

          <div class="blog-paragraph">The official USCIS note states: <span style="font-weight: 500; font-style: italic">The processing time displayed on the USCIS website is the amount of time it took us to complete 80% of adjudicated cases over the last six months. Processing time is defined as the number of days (or months) that have elapsed between the date USCIS received an application, petition, or request and the date USCIS completed the application, petition, or request (that is, approved or denied it) in a given six-month period."</span></div>

          <div class="blog-paragraph">So keep in mind it is only an estimate published with a 6 months delay!</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                <router-link class="btn btn-primary" :to="{ name: 'Home', params: { location: 'Templates' }}">EB-2 NIW Templates</router-link>
              </div>
            </div>
          </div>

          <div class="blog-paragraph">In addition, USCIS has faced a significant backlog due to the COVID-19 pandemic, which has caused delays in the processing of immigration applications. <span style="background-color: rgba(68, 206, 111, 0.25)">However, the situation is improving, and USCIS has announced plans to expedite processing times by hiring additional staff and increasing resources.</span></div>

          <div class="blog-paragraph" style="padding-bottom: 6rem">In conclusion, the estimated timeline for the EB2 NIW green card petition in 2023 is subject to change due to USCIS's workload and backlog. However, by preparing well and staying up-to-date with USCIS's processing times, applicants can increase their chances of a successful outcome.</div>
          
        </div>
      </div>
    </section>

  </div>
</template>
<script>
import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";

export default {
  name: 'EB2NIWTimeline',
  components: {
    PriceOffer,
    CarouselGreenCard
  }
}
</script>
