<template>

    <section class="works-area ptb-80 bg-f9fafb">

      <b-modal ref="modal-cover-letter" title="Marketing Professional" size="xl" hide-footer>
        <img src="../resources/sample-marketing.webp" alt="eb2 niw sample marketing"/>
      </b-modal>

      <b-modal ref="modal-case-builder" title="Marketing Professional" size="xl" hide-footer>
        <img src="../resources/caseBuilder-marketing.webp" alt="eb2 niw sample marketing"/>
      </b-modal>

      <div class="container" style="margin-top: 5rem">
        <div class="row">
          <div class="col-sm-3 col-8 offset-sm-0 offset-2" style="margin-bottom: 4rem">
            <img src="../resources/marketing.webp" style="border-radius: 50%; border: 2px solid; border-color: #44ce6f; box-shadow: 10px 20px 20px 0px rgba(86, 97, 118, .25)" alt="eb2 niw sample marketing"/>
          </div>

          <div class="col-sm-8 offset-sm-1 col-12 sample-h1">
            <div>
              <h1 style="font-weight: 600;">Marketing Professional</h1>
              <div class="bar"></div>
            </div>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 30px">
          <div style="margin: 1rem; margin-top: 2rem; max-width: 1000px">In the dynamic realm of marketing, professionals seeking to navigate the EB-2 NIW process encounter a unique set of challenges and opportunities. For those specializing in marketing strategies, branding, and market research, the path to a successful petition involves not only showcasing their strategic acumen but also articulating the profound impact of their contributions on the market landscape.</div>
          <div style="margin: 1rem; max-width: 1000px">The EB-2 NIW process for marketing professionals requires a meticulous presentation of achievements, emphasizing the strategic role played in shaping and advancing marketing solutions. This involves delving into the details of successful campaigns, brand positioning endeavors, and innovative market research methodologies that have significantly influenced consumer behavior and market trends.</div>
          <div style="margin: 1rem; max-width: 1000px">Successfully navigating the EB-2 NIW process in the marketing sector necessitates a comprehensive approach, wherein applicants highlight their instrumental role in driving market share, enhancing brand visibility, and contributing to the economic landscape. It is equally crucial to underscore the broader societal and economic implications of their work within the context of modern marketing dynamics, where advancements align with national interests such as economic growth, market competitiveness, and strategic positioning on the global stage.</div>
          <div style="margin: 1rem; max-width: 1000px">With the inherent alignment of marketing expertise with national interests, applicants can position themselves as catalysts for economic growth and enhanced market efficiency. This alignment has been duly recognized and emphasized by policymakers, reflecting the national interest in promoting innovation, ensuring robust market dynamics, and addressing the evolving challenges in the marketing landscape.</div>
          <div style="margin: 1rem; max-width: 1000px">It's noteworthy that, contrary to common belief, research, publications, or citations are not obligatory for EB-2 NIW, and our templates are specifically tailored for marketing professionals without the need for these traditional markers of achievement.</div>

          <div style="margin: 3rem 1rem 1rem; max-width: 200px; border: 2px solid #44ce6f; padding: 12px 12px 4px; border-radius: 5px;">
            <vue-feather type="shield" style="color: #44ce6f"></vue-feather> Read More:
            <ul>
              <li style="margin-top: 5px"><a href="/success-story-marketing-manager" target="_blank" style="text-decoration: underline">Success Story</a></li>
            </ul>
          </div>
        </div>

<!--Marketing-->
        <div id="profession-sample" style="margin-bottom: 1.5rem">
          <div style="margin: 6rem 0rem">
            <h4 style="text-align: center;">Marketing Professional</h4>
            <div class="section-title">
              <div class="bar"></div>
            </div>
            
            <div style="text-align: center; margin: 4rem 0 6rem">
              <button v-on:click="navigateToCheckout" class="btn btn-primary">GET THIS TEMPLATE</button>
              <div class="price-offer-comment" style="color: #c4c4c4; margin-top: 50px;">*** Not quite right? Try <a style="color: #c4c4c4; text-decoration: underline" href="/ai">Selfpetition AI</a>  instead ***</div>            
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCoverLetterModal" style="cursor: pointer">
              <img src="../resources/sample-marketing.webp" alt="marketing i140 sample">
              <div class="works-content">
                <h3 style="color: #fff">Marketing Professional</h3>
                <h3 style="color: #fff">Cover Letter (10 pages)</h3>
                <p>The cover letter comprises the following segments: meeting the criteria for an Advance Degree, Prong 1, Prong 2, Prong 3, and an appendix containing all supporting evidence. When checking out, you have the option to select up to two professions.</p>
              </div>
            </div>

            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCaseBuilderModal" style="cursor: pointer">
              <img src="../resources/caseBuilder-marketing.webp" alt="marketing green card">
              <div class="works-content">
                <h3 style="color: #fff">Marketing Professional</h3>                    
                <h3 style="color: #fff">EB-2 NIW Case Builder (17 pages)</h3>
                <p>You might be wondering how to identify relevant NIW initiatives and supporting arguments. This is where the NIW Case Builder Pdf comes in – it's designed to inspire you with alternative points to emphasize in your cover letter as you modify our template.</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div style="margin: 5rem 0rem">
            <h4 style="text-align: center;">EB2 NIW Starter Kit</h4>
            <h6 style="text-align: center;">List of Documents</h6>
            <div class="section-title">
              <div class="bar"></div>
            </div>
          </div>

          <div class="section-title faq" style="margin-bottom: 1.5rem">
            <DocumentsList></DocumentsList>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 1.5rem">
          <div class="row">
            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Most suitable for</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">Marketing Manager</li>
                <li class="profession-page-list">Digital Marketing Specialist</li>
                <li class="profession-page-list">Social Media Manager</li>
                <li class="profession-page-list">Content Marketing Manager</li>
                <li class="profession-page-list">Brand Manager</li>
                <li class="profession-page-list">Product Marketing Manager</li>
                <li class="profession-page-list">Public Relations Specialist</li>
                <li class="profession-page-list">Marketing Analyst</li>
                <li class="profession-page-list">SEO Specialist</li>
                <li class="profession-page-list">Email Marketing Specialist</li>
                <li class="profession-page-list">Event Coordinator</li>
                <li class="profession-page-list">Graphic Designer</li>
                <li class="profession-page-list">Market Research Analyst</li>
                <li class="profession-page-list">Advertising Manager</li>
                <li class="profession-page-list">Media Planner</li>
                <li class="profession-page-list">CRM Manager</li>
                <li class="profession-page-list">E-commerce Manager</li>
                <li class="profession-page-list">Influencer Marketing Manager</li>
                <li class="profession-page-list">Public Relations Manager</li>
                <li class="profession-page-list">Creative Director</li>
                <li class="profession-page-list">Communications Specialist</li>
                <li class="profession-page-list">Demand Generation Manager</li>
                <li class="profession-page-list">Community Manager</li>
                <li class="profession-page-list">Marketing Coordinator</li>
                <li class="profession-page-list">Trade Show Coordinator</li>
                <li class="profession-page-list">... and other similar</li>
              </ul>
            </div>

            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Topics</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">E-commerce marketing</li>
                <li class="profession-page-list">Small business marketing</li>
                <li class="profession-page-list">Entrepreneurial marketing</li>
                <li class="profession-page-list">Marketing automation</li>
                <li class="profession-page-list">Customized marketing strategies</li>
                <li class="profession-page-list">Digital marketing solutions</li>
                <li class="profession-page-list">Website design and development</li>
                <li class="profession-page-list">Social media marketing</li>
                <li class="profession-page-list">Search Engine Optimization (SEO)</li>
                <li class="profession-page-list">Email marketing</li>
                <li class="profession-page-list">Market research</li>
                <li class="profession-page-list">Branding</li>
                <li class="profession-page-list">Strategic marketing</li>
                <li class="profession-page-list">Online presence optimization</li>
                <li class="profession-page-list">Market trends analysis</li>
                <li class="profession-page-list">Customer behavior analysis</li>
                <li class="profession-page-list">Revenue growth strategies</li>
                <li class="profession-page-list">Job creation</li>
                <li class="profession-page-list">Innovation in e-commerce</li>
                <li class="profession-page-list">Marketing ROI optimization</li>
                <li class="profession-page-list">Market competitiveness</li>
                <li class="profession-page-list">Business expansion</li>
                <li class="profession-page-list">Technological progress</li>
                <li class="profession-page-list">Small business and entrepreneurs</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="shape2 rotateme" style="left: 90%"><img src="../resources/shape2.svg" alt="EB2 niw diy"></div>
      <div class="shape4"><img src="../resources/shape4.svg" alt="EB-2 niw marketing"></div>
      <div class="shape7"><img src="../resources/shape4.svg" alt="EB-2 niw branding"></div>
    </section>

</template>

<script>

import { mapActions } from 'vuex';
import DocumentsList from "./DocumentsList.vue";

    export default {
      name: 'ProfessionMarketing',
      components: {
        DocumentsList,
      },
      data() {
        return {
          user: {
            profession1: 'marketing',
          }
        }
      },
      methods: {
        ...mapActions('account', ['apply']),
        navigateToCheckout() {
          //this.apply('Marketing Sample');
          // Handle navigation to checkout here
          this.$router.push({
            path: '/checkout-starter-kit',
            query: {
              profession: this.user.profession1
            }
          });
        },
        openCoverLetterModal() {
          setTimeout(() => {
            this.$refs['modal-cover-letter'].show();
          }, 100);
        },
        openCaseBuilderModal() {
          setTimeout(() => {
            this.$refs['modal-case-builder'].show();
          }, 100);
        }
      }
    }
</script>

