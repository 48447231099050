<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <EB2NIWCost></EB2NIWCost>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import EB2NIWCost from "../blog-components/EB2NIWCost";


export default {
  name: 'EB2NIWCostView',
  components: {
    EB2NIWCost,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "What is the cost of EB2 NIW? - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "Some of the expenses associated with the EB-2 NIW process include filing fees. The USCIS charges a fee for filing the Form I-140 and Form I-485."},
      ]
    }
  }
}
</script>
