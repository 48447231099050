<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div style="margin-top: 8rem">
      <SelfpetitionAIGreenCard></SelfpetitionAIGreenCard>
      <ContactGreenCard></ContactGreenCard>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
  import ExternalHeaderGreenCard from "./components/ExternalHeaderGreenCard";
  import ExternalFooterGreenCard from "./components/ExternalFooterGreenCard";
  import SelfpetitionAIGreenCard from './components/SelfpetitionAIGreenCard';
  import ContactGreenCard from './components/ContactGreenCard';


  export default {
    name: 'HomeGreenCardSelfpetitionAI',
    components: {
      ExternalHeaderGreenCard,
      ExternalFooterGreenCard,
      SelfpetitionAIGreenCard,
      ContactGreenCard,
    },
    data() {
      return {
        currentUrl: ''
      }
    },

    watch: {
      '$route'(pathUrl){
        this.currentUrl = pathUrl.path;
      }
    },

    mounted() {
      this.currentUrl = window.location.pathname;
    },
    metaInfo() {
      return {
        title: "EB2 NIW with artificial intelligence",
        meta: [
          { vmid: 'description', name: 'description', content: "Embark on your journey to permanent residency with the EB2-NIW Selfpetition AI. Leveraging insights from previous cases, this tool is crafted to navigate you through the complexities of the self-petition process. Discover your essential roadmap for a successful self-petition experience."},
        ]
      }
    }
  }
</script>
