<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <EB2NIWBenefits></EB2NIWBenefits>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import EB2NIWBenefits from "../blog-components/EB2NIWBenefits";


export default {
  name: 'EB2NIWBenefitsView',
  components: {
    EB2NIWBenefits,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "What are the benefits of EB2 NIW? - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "This means that the process is faster and more straightforward than other types of immigration visas, as there is no requirement to first find a job offer from a US employer."},
      ]
    }
  }
}
</script>
