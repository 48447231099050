<template>
  <div>
    <ExternalHeaderGreenCard></ExternalHeaderGreenCard>
    <div>
      <div style="padding-top: 5rem; margin-top: 2rem; margin-bottom: 0rem;" >
        <div class="d-table">
          <div class="d-table-cell">
            <div class="container">
              <div style="margin-top: 14px">
                <h2 class="title-mobile-center">Selfpetition AI</h2>
                <div class="mobile-center" style="display: flex;">
                  <h5 style="color: #5457c1; margin-bottom: 0px; margin: auto 0">&#129302; Customize order</h5>
                </div>
              </div>

              <div  style="margin-top: 2rem; border: 2px solid #5457c1" class="alert alert-light" role="alert">
                Allow up to 48h to receive the first draft. Selfpetition AI package includes three reference letters, you can add more as needed
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="d-table" style="background-color: #f9fafb">
        <div class="d-table-cell">
          <div class="container">

            <div class="row">

              <div class="col-lg-8" style="margin-top: 20px;">
                <div class="pricing-table" style="padding-top: 10px; padding-bottom: 10px">
                  <h5 style="margin-bottom: 0">Review and approve</h5>
                  <p style="margin-bottom: 25px; font-size: 12px">Selfpetition AI package</p>

                  <div style="text-align: left; padding-left: 20px; padding-right: 20px">
                    <div style="font-size: 15px; margin-top: 5px; display: flex; justify-content: space-between;">
                      <span style="font-weight: 600">
                        Selfpetition AI package: <span style="font-weight: 400;">cover letter and three reference letters included</span>
                      </span>
                      <span style="font-weight: 500">
                        $1,000
                      </span>
                    </div>
                    <div class="form-check" style="margin-top: 20px">
                      <input class="form-check-input profession-checkbox" type="checkbox" name="disclaimer1" id="disclaimer1" value="disclaimer1" v-model="userDisclaimers.disclaimer1">
                      <label class="form-check-label" style="display: flex; justify-content: space-between; width: 100%;" for="disclaimer1">
                        <div style="flex-grow: 1;">
                          <span style="font-weight: 400;">The greater the level of detail you provide in the <a href="/aiform2" target="_blank" style="font-weight: 500; text-decoration: underline">form</a> (available to you after placing the order), the more comprehensive your petition can become, reducing the need for numerous iterations with respect to the cover letter and reference letters.</span>
                        </div>
                      </label>
                    </div>
                    <div class="form-check" style="margin-top: 5px">
                      <input class="form-check-input profession-checkbox" type="checkbox" name="disclaimer2" id="disclaimer2" value="disclaimer2" v-model="userDisclaimers.disclaimer2">
                      <label class="form-check-label" style="display: flex; justify-content: space-between; width: 100%;" for="disclaimer2">
                        <div style="flex-grow: 1;">
                          <span style="font-weight: 400;">An AI-generated draft for an EB-2 NIW cover letter and reference letters should not be considered a replacement for legal advice. We are not legal professionals and do not offer legal guidance. Selfpetition AI serves as a tool for drafting the petition, suggesting well-organized and logical arguments. However, you bear the responsibility for deciding on the content of your application. This is due to our limited knowledge of your background, legal status, and inability to verify the accuracy of your inputs.</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  
                  <div :class="{ nonvisible: !submitted || ((userDisclaimers.disclaimer1 !== false) && (userDisclaimers.disclaimer2 !== false)) }" style="padding-bottom: 1px;">
                    <div style="padding-left:20px; padding-right:20px;">
                      <div class="alert alert-warning" role="alert" style="margin-top: 10px; margin-bottom: 0">
                        <div style="font-weight: 500">Review and approve both disclaimers</div>
                      </div>
                    </div>
                  </div>
                </div>

<!--                <div class="container">-->
                <div class="row">
                  <div class=" col-md-6">
                    <div class="pricing-table" style="margin-top:20px; padding: 10px 20px">
                      <h5 style="margin-bottom: 0">Select add-ons</h5>
                      <p style="margin-bottom: 15px; font-size: 12px">Enrich your Selfpetition AI package</p>

                      <div class="row" style="text-align: left; margin: 0px;display: block">

                        <div class="form-group" style="padding-left: 0px; padding-right: 0px">
                          <div style="display: flex;">
                            <select class="form-control" id="referenceLetter" style="width: 40px; height: 28px; padding: 3px; border-radius: 5px; border-color: rgb(118, 118, 118);" v-model="userAddons.referenceLettersNumber">
                              <option selected>0</option>
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                            </select>
                            <label for="referenceLetter" style="width: 100%; margin: 2px 0px 0 6px">Reference letter(s)*<div style="display: inline; float: right"><span style="font-size: 10px">each </span>$85</div></label>
                          </div>
                          <div style="margin-left: 5px; margin-top: 5px; color: #818182">* In addition to three reference letters included in the package</div>
                        </div>

                        <div class="form-group" style="padding-left: 2px; padding-right: 0px; margin-bottom: 10px; width: 100%">
                          <div style="display: flex;">
                          <div class="form-check" style="width: 100%">
                            <input class="form-check-input addons-checkbox" type="checkbox" name="addOn" id="consultation" value="consultation" v-model="userAddons.consultation">
                            <label class="form-check-label"  style="width: 100%;"  for="consultation">30min Consultation <div style="display: inline; float: right">$80</div></label>
                          </div>
                        </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="pricing-table " style="margin-top:20px; padding-top: 10px; padding-bottom: 20px;">
                      <h5 style="margin-bottom: 0">E-mail to send AI output</h5>
                      <p style="margin-bottom: 25px; font-size: 12px">Where you want to receive Selfpetition AI draft</p>

                      <div class="row form-group" style="margin-left: 20px; margin-right: 20px; margin-bottom: 0">
                          <input type="email" class="form-control" id="clientEmail" aria-describedby="clientEmail" placeholder="E-mail to receive Selfpetition AI draft" style="border-radius: 5px; border-color: rgb(118, 118, 118);" v-model="userData.email" v-on:input="clearEmailError()">

                        <div class="col-12" style="padding: 0">
                          <div :class="{ nonvisible: isEmail || (!isEmail && !submitted) }" style="padding-bottom: 1px;">
                            <div style="padding-left:20px; padding-right:20px; padding: 0">
                              <div class="alert alert-warning" role="alert" style="margin-top: 10px; margin-bottom: 0">
                                <div style="font-weight: 500">Enter a valid email</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
<!--                </div>-->
              </div>

              <div class="col-lg-4" style="margin-top: 20px;">
                <div v-if="!showWaitingGif" class="pricing-table" style="padding-top: 10px; padding-left: 10px;padding-right: 10px; padding-bottom: 1px">
                  <h5 style="margin-bottom: 10px">Order summary</h5>

                  <div style="text-align: left; font-size: 12px; padding: 10px; border: #0f054b; border:1px; border-style:solid">
                    <div style="margin-bottom: 2px">Selfpetition AI<span style="float:right">1,000$</span></div>
                    <div v-if="orderItems.has('ReferenceLetter1' || 'ReferenceLetter2' || 'ReferenceLetter3')" style="margin-bottom: 2px">Reference letter(s) <span style="float:right">{{userAddons.referenceLettersNumber}} x 85$</span></div>
                    <div v-if="orderItems.has('Consultation')" style="margin-bottom: 2px">Consultation (30min)<span style="float:right">80$</span></div>

                    <h6 style="margin-top: 10px">Total</h6>
                    <div v-if="!promoApplied">
                      <hr style=" border: none;border-top: 2px dotted;height: 1px;width: 100%;">
                      <div>Total order: <span style="float:right">{{ (totalOrder.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}$</span></div>
                      <hr style=" border: none;border-top: 2px dotted;height: 1px;width: 100%;">
                    </div>

                    <div v-if="promoApplied">
                      <hr style=" border: none;border-top: 2px dotted;height: 1px;width: 100%;">
                      <div>Subtotal: <span style="float:right">{{ (totalOrder.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}$</span></div>
                      <div>Promo* ({{parseInt(discountValue * 100)}}% x 1,000): <span style="float:right; color: #ff301f">-{{promoDiscount}}$</span></div>
                      <hr style=" border: none;border-top: 2px dotted;height: 1px;width: 100%;">
                      <div>Total order: <span style="float:right">{{ ((totalOrder - promoDiscount).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}$</span></div>
                      <hr style=" border: none;border-top: 2px dotted;height: 1px;width: 100%;">
                      <div style="color: #a9a9a9">*Multiple discounts cannot be combined</div>
                    </div>

                    <form class="form-inline" v-if="!promoApplied"  @submit.prevent="applyPromoCode(promoCode)">
                      <input type="text" class="form-control"  id="inputPassword2"   v-model="promoCode" placeholder="Promo code"  style="margin-top: 10px;margin-right: 10px;border-radius: 5px; border-color: rgb(118, 118, 118);">
                      <button type="submit" style="height: 45px; font-size: 13px; margin-top: 10px; padding: 5px 25px;" class="btn-success button-order">Apply</button>
                    </form>
                      <p v-if="codeWarning" style="color: #db3040; margin-top: 10px;">Invalid promo code</p>

                  </div>
                  
                  <form @submit.prevent="submit()">
                    <button type="submit" style="background-color: #ff4e00; height: 40px; font-size: 13px; margin-top: 30px; padding: 5px; margin-bottom: 10px; width: 100%;" class="btn-success button-order">Go to payment</button>
                    <div v-if="submitted && ((userDisclaimers.disclaimer1 === false) || (userDisclaimers.disclaimer2 === false) || !isEmail)" class="alert alert-warning" role="alert" style="margin-top: 4px;">
                      <div style="font-weight: 500">Oops... check your selection</div>
                    </div>
                  </form>
                </div>

                <div v-if="showWaitingGif" style="padding: 3rem 0rem; display: flex; flex-direction: column; align-items: center; justify-content: center;">
                  <img src="./resources/musical.gif" style="width: 15rem">
                  <div  class="alert alert-primary text-center" role="alert">
                    <div style="font-weight: 600; font-size: 1.3em">DO NOT REFRESH</div>
                    <div style="margin-top: 8px">Directing you to payment</div>
                  </div>
                </div>
              
                <div class="pricing-table" style="margin-top:20px; padding: 10px 10px 20px;">
                  <h5 style="margin-bottom: 15px">Payment methods</h5>
                  <div class="Box-root Flex-flex Flex-direction--column Flex-justifyContent--flexStart Flex-wrap--nowrap">
                    <div class="Box-root Box-hideIfEmpty Margin-top--12 Margin-left--12" style="pointer-events: auto">
                      <div class="Box-root" style="pointer-events: none;">
                        <div style="display: flex" class="Box-root Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--wrap" >
                          <div class="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8" style="pointer-events: auto;"><div class="Box-root" style="pointer-events: none;"><div class="Box-root Flex-flex Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap" style="display: flex;margin-right: 10px;margin-top: 10px"><div class="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4" style="pointer-events: auto;"><div class="SVGInline SVGInline--cleaned SVG BrandIcon BrandIcon--size--20 Box-root Flex-flex"><svg class="SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg BrandIcon--size--20-svg" height="20" width="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#D8DEE4" d="M0 0h32v32H0z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M6 10.375C6 9.339 6.84 8.5 7.875 8.5h16.25C25.16 8.5 26 9.34 26 10.375v11.25c0 1.035-.84 1.875-1.875 1.875H7.875A1.875 1.875 0 0 1 6 21.625v-11.25Zm1.875 0h16.25v1.875H7.875v-1.875Zm16.25 3.75v7.5H7.875v-7.5h16.25Z" fill="#474E5A"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M14.75 18.813c0-.518.42-.938.938-.938h5.624a.937.937 0 1 1 0 1.875h-5.625a.937.937 0 0 1-.937-.938Z" fill="#474E5A"></path></svg></div></div><div class="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4" style="pointer-events: auto;"><span class="Text-color--default Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline"><span style="margin-left: 5px">Card</span></span></div></div></div></div>
                          <div class="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8" style="pointer-events: auto;"><div class="Box-root" style="pointer-events: none;"><div class="Box-root Flex-flex Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap" style="display: flex;margin-right: 10px;margin-top: 10px"><div class="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4" style="pointer-events: auto;"><div class="SVGInline SVGInline--cleaned SVG BrandIcon BrandIcon--size--20 Box-root Flex-flex"><svg class="SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg BrandIcon--size--20-svg" height="20" width="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#000" d="M0 0h32v32H0z"></path><path d="M19.308 7.63c-.8.95-2.082 1.698-3.367 1.592-.16-1.285.469-2.65 1.204-3.497.8-.975 2.204-1.672 3.341-1.725.132 1.338-.387 2.653-1.178 3.63Zm1.166 1.847c-1.86-.106-3.448 1.057-4.333 1.057-.894 0-2.244-1.003-3.714-.976-1.91.026-3.686 1.11-4.661 2.838-2.004 3.456-.522 8.57 1.416 11.383.947 1.393 2.082 2.921 3.58 2.865 1.418-.05 1.976-.922 3.689-.922 1.722 0 2.216.922 3.714.897 1.55-.025 2.526-1.393 3.473-2.787 1.082-1.578 1.523-3.119 1.55-3.2-.025-.025-2.991-1.165-3.02-4.593-.025-2.865 2.339-4.23 2.445-4.312-1.335-1.981-3.417-2.196-4.14-2.25Z" fill="#fff"></path></svg></div></div><div class="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4" style="pointer-events: auto;"><span class="Text-color--default Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline"><span style="margin-left: 5px">Apple Pay</span></span></div></div></div></div>
                          <div class="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8" style="pointer-events: auto;"><div class="Box-root" style="pointer-events: none;"><div class="Box-root Flex-flex Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap" style="display: flex;margin-right: 10px;margin-top: 10px"><div class="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4" style="pointer-events: auto;"><div class="SVGInline SVGInline--cleaned SVG BrandIcon BrandIcon--size--20 Box-root Flex-flex"><svg class="SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg BrandIcon--size--20-svg" height="20" width="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#F5F6F8" d="M0 0h32v32H0z"></path><path d="M27.765 13.823h-11.53v4.628h6.611a5.444 5.444 0 0 1-.84 2.068 5.551 5.551 0 0 1-1.607 1.572v3.011h3.934a11.74 11.74 0 0 0 2.788-4.044c.944-2.285 1.055-4.815.644-7.235Z" fill="#4285F4"></path><path d="M16.235 28c3.295 0 6.085-1.055 8.098-2.898l-3.934-3.01a7.42 7.42 0 0 1-4.164 1.145 7.409 7.409 0 0 1-4.238-1.39 7.198 7.198 0 0 1-2.625-3.553H5.3v3.078a12.104 12.104 0 0 0 4.511 4.837A12.405 12.405 0 0 0 16.235 28Z" fill="#34A853"></path><path d="M9.372 18.294a7.069 7.069 0 0 1 0-4.606v-3.1H5.3A11.847 11.847 0 0 0 4 15.98c0 1.872.445 3.719 1.3 5.392l4.072-3.078Z" fill="#FBBC04"></path><path d="M16.235 8.745a6.7 6.7 0 0 1 4.69 1.797l3.5-3.438a12.304 12.304 0 0 0-4.874-2.65 12.454 12.454 0 0 0-5.565-.252 12.352 12.352 0 0 0-5.102 2.195 12.077 12.077 0 0 0-3.584 4.19l4.072 3.1a7.198 7.198 0 0 1 2.625-3.551 7.409 7.409 0 0 1 4.238-1.391Z" fill="#EA4335"></path></svg></div></div><div class="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4" style="pointer-events: auto;"><span class="Text-color--default Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline"><span style="margin-left: 5px">Google Pay</span></span></div></div></div></div>
                          <div class="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8" style="pointer-events: auto;"><div class="Box-root" style="pointer-events: none;"><div class="Box-root Flex-flex Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap" style="display: flex;margin-right: 10px;margin-top: 10px"><div class="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4" style="pointer-events: auto;"><div class="SVGInline SVGInline--cleaned SVG BrandIcon BrandIcon--size--20 Box-root Flex-flex"><svg class="SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg BrandIcon--size--20-svg" height="20" width="20" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h32v32H0z" fill="#1c9fe5"></path><path d="M23.104 18.98a142.494 142.494 0 0 0 11.052 3.848c2.044.85 0 5.668-2.159 4.674-2.444-1.066-7.359-3.245-11.097-5.108C18.822 24.842 15.556 28 10.907 28 6.775 28 4 25.568 4 21.943c0-3.053 2.11-6.137 6.82-6.137 2.697 0 5.47.766 8.785 1.922a25.007 25.007 0 0 0 1.529-3.838l-11.981-.006v-1.848l6.162.015V9.63H7.808V7.81l7.507.006V5.115c0-.708.38-1.115 1.042-1.115h3.14v3.827l7.442.005v1.805h-7.44v2.431l6.088.016s-.754 3.904-2.483 6.897zM5.691 21.79v-.004c0 1.736 1.351 3.489 4.64 3.489 2.54 0 5.028-1.52 7.408-4.522-3.181-1.592-4.886-2.357-7.348-2.357-2.394 0-4.7 1.164-4.7 3.394z" fill="#fff" fill-rule="nonzero"></path></g></svg></div></div><div class="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4" style="pointer-events: auto;"><span class="Text-color--default Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline"><span style="margin-left: 5px">Alipay</span></span></div></div></div></div>
                          <div class="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8" style="pointer-events: auto;"><div class="Box-root" style="pointer-events: none;"><div class="Box-root Flex-flex Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap" style="display: flex;margin-right: 10px;margin-top: 10px"><div class="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4" style="pointer-events: auto;"><div class="SVGInline SVGInline--cleaned SVG BrandIcon BrandIcon--size--20 Box-root Flex-flex"><svg class="SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg BrandIcon--size--20-svg" height="20" width="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#00D64F" d="M0 0h32v32H0z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M16.892 10.264c1.782 0 3.49.693 4.607 1.643.282.24.705.24.97-.018l1.328-1.293a.646.646 0 0 0-.032-.968 10.625 10.625 0 0 0-3.56-1.92l.416-1.903C20.71 5.39 20.38 5 19.94 5h-2.567a.69.69 0 0 0-.68.528l-.376 1.693c-3.412.163-6.303 1.796-6.303 5.147 0 2.9 2.392 4.144 4.917 5.004 2.393.86 3.655 1.18 3.655 2.391 0 1.243-1.262 1.976-3.123 1.976-1.697 0-3.475-.537-4.854-1.841a.713.713 0 0 0-.974-.002l-1.427 1.35a.652.652 0 0 0 .004.96c1.113 1.036 2.522 1.786 4.13 2.206l-.391 1.763c-.092.413.234.803.676.806l2.57.019a.689.689 0 0 0 .686-.53l.371-1.695c4.085-.242 6.586-2.372 6.586-5.49 0-2.868-2.492-4.08-5.516-5.068-1.728-.606-3.224-1.02-3.224-2.263 0-1.21 1.397-1.69 2.792-1.69Z" fill="#fff"></path></svg></div></div><div class="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4" style="pointer-events: auto;"><span class="Text-color--default Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline"><span style="margin-left: 5px">Cash App Pay</span></span></div></div></div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <ExternalFooterGreenCard></ExternalFooterGreenCard>
    </div>
  </div>
</template>

<script>
import ExternalHeaderGreenCard from "./components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "./components/ExternalFooterGreenCard";
import {mapActions} from "vuex";



  export default {
    name: 'CheckoutSelfpetitionAi',
    data () {
      return {
        codes : ['YQ1SL7', 'MTACRL', 'YMBNCY', 'WRFTIN', 'LBIRYU', 'ZKERVH', 'PT2R5G', 'PFTEY1'],
        opti : ['RR28SU'],
        codeWarning: false,
        Order: {
          SelfpetitionAi: 1000,
          ReferenceLetter1: 85,
          ReferenceLetter2: 85,
          ReferenceLetter3: 85,
          Consultation: 80
        },
        discountStart: this.generateClosestDatesArray(),
        totalPrice: 0,
        showWaitingGif: false,
        promoCode: '',
        promoApplied: false,
        submitted: false,
        isEmail: false,
        userDisclaimers: {
          disclaimer1: false,
          disclaimer2: false,
        },
        userAddons: {
          referenceLettersNumber: 0,
          consultation: false,
        },
        userData: {
          email: ''
        },
        paymentLinkMap: this.initPaymentLinkMap()
      }
    },
    components: {
        ExternalHeaderGreenCard,
        ExternalFooterGreenCard
    },
    mounted() {
      this.sendEvent('render-checkout-selfpetition-ai');

      if(this.isPromo()) {
        const randomIndex = Math.floor(Math.random() * (this.codes.length));
        const randomEntry = this.codes[randomIndex];
        //console.log(randomIndex)
        //console.log(randomEntry)
        this.applyPromoCode(randomEntry)
      }
    },
    computed: {
      discountValue() {
        if(this.codes.includes(this.promoCode)){
          return 0.35;
        } else if (this.opti.includes(this.promoCode)) {
          return 0.14;
        } else return 0.0;
      },
      checkedCountDisclaimers() {
        return Object.entries(this.userDisclaimers)
            .filter(([key, value]) => value === true)
            .length
        },
      orderItems() {
        let colorSet = new Set();
        colorSet.add('SelfpetitionAi')
        if (this.userAddons.referenceLettersNumber > 0) {
          for (let i=1; i <= this.userAddons.referenceLettersNumber; i++) {
            colorSet.add(`ReferenceLetter${i}`)
          }
        }
        if (this.userAddons.consultation) {
          colorSet.add('Consultation')
        }
        console.log(colorSet)
        return colorSet;
      },
      totalOrder() {
        return Array.from(this.orderItems).reduce((sum, item) => {
          // Check if the item exists in the Order constant
          if (item in this.Order) {
            sum += this.Order[item];
          }
          return sum;
        }, 0);
      },
      promoDiscount() {
        const discount  = this.Order.SelfpetitionAi * this.discountValue;
        return parseFloat(discount).toFixed(2);
      }
    },
    methods: {
      ...mapActions('account', ['apply']),
        sendEvent(eventType) {
          if (window.gtag) {
            window.gtag('event', eventType); // google analytics
          }
        },
        sendConversion() {
          if (window.gtag) {
            window.gtag('event', 'conversion', {
              'send_to': 'AW-10969381549/AfSLCNWAnbwZEK31ze4o',
              'transaction_id': ''
            });
          }
        },
        handleDisclaimersClick() {
          if (this.userDisclaimers.disclaimer1) {
            this.userDisclaimers.disclaimer1 = 'The greater the level of detail you provide in the form (available to you after placing the order), the more comprehensive your petition can become, reducing the need for numerous iterations with respect to the cover letter and reference letters.';
          }
          if (this.userDisclaimers.disclaimer2) {
            this.userDisclaimers.disclaimer2 = '>An AI-generated draft for an EB-2 NIW cover letter and reference letters should not be considered a replacement for legal advice. We are not legal professionals and do not offer legal guidance. Selfpetition AI serves as a tool for drafting the petition, suggesting well-organized and logical arguments. However, you bear the responsibility for deciding on the content of your application. This is due to our limited knowledge of your background, legal status, and inability to verify the accuracy of your inputs.';
          }
        },
        generateClosestDatesArray() {
          const currentDate = new Date();
          const year = currentDate.getFullYear();
          const startDate = new Date(year, 0, 0);
          const endDate = new Date(year + 1, 0, 0);

          const dateArray = [];
          let currentDateCandidate = startDate;

          while (currentDateCandidate < endDate) {
            dateArray.push(new Date(currentDateCandidate));
            currentDateCandidate.setDate(currentDateCandidate.getDate() + 15);
          }

          // Find the closest date that is before the current date
          let closestDate = dateArray[0];
          for (const date of dateArray) {
            if (date < currentDate && date > closestDate) {
              closestDate = date;
            }
          }

          return closestDate;
        },
        isPromo() {
          // const tenDaysInMilliseconds = 10 * 24 * 60 * 60 * 1000; // 10 days in milliseconds
          // //console.log(tenDaysInMilliseconds > (new Date() - this.discountStart))
          // return tenDaysInMilliseconds > (new Date() - this.discountStart)
          return false;
        },
        applyPromoCode(code) {
          if(this.codes.includes(code) || this.opti.includes(code)) {
            this.codeWarning = false;
            this.promoApplied = true;
            this.promoCode = code;
          } else {
            this.codeWarning = true;
            this.promoCode = ''
          }
        },
        validateEmail () {
          this.isEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.userData.email);
        },
        clearEmailError () {
          this.isEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.userData.email);
        },
        initPaymentLinkMap () {
          const myMap = new Map();
          const ai =  ['SelfpetitionAi'].join(',');
          const aireferenceLetter1 =  ['SelfpetitionAi', 'ReferenceLetter1'].sort().join(',');
          const aireferenceLetter1referenceLetter2 =  ['SelfpetitionAi', 'ReferenceLetter1', 'ReferenceLetter2'].sort().join(',');
          const aireferenceLetter1referenceLetter2referenceLetter3 =  ['SelfpetitionAi', 'ReferenceLetter1', 'ReferenceLetter2', 'ReferenceLetter3'].sort().join(',');
          const aiconsultation =  ['SelfpetitionAi', 'Consultation'].sort().join(',');
          const aireferenceLetter1consultation =  ['SelfpetitionAi', 'ReferenceLetter1', 'Consultation'].sort().join(',');
          const aireferenceLetter1referenceLetter2consultation =  ['SelfpetitionAi', 'ReferenceLetter1', 'ReferenceLetter2', 'Consultation'].sort().join(',');
          const aireferenceLetter1referenceLetter2referenceLetter3consultation =  ['SelfpetitionAi', 'ReferenceLetter1', 'ReferenceLetter2', 'ReferenceLetter3', 'Consultation'].sort().join(',');

          myMap.set(ai, 'https://pay.selfpetitionusa.com/b/9AQ5nU7RRd6Z3YIeXe');
          myMap.set(aireferenceLetter1, 'https://pay.selfpetitionusa.com/b/7sIbMigongjb9j29CV');
          myMap.set(aireferenceLetter1referenceLetter2, 'https://pay.selfpetitionusa.com/b/9AQ2bI9ZZ9UNeDm02m');
          myMap.set(aireferenceLetter1referenceLetter2referenceLetter3, 'https://pay.selfpetitionusa.com/b/fZe17E0pp1ohdziaH1');
          myMap.set(aiconsultation, 'https://pay.selfpetitionusa.com/b/6oEaIe6NNd6Zan6bL6');
          myMap.set(aireferenceLetter1consultation, 'https://pay.selfpetitionusa.com/b/14k5nU4FF6IBgLu5mJ');
          myMap.set(aireferenceLetter1referenceLetter2consultation, 'https://pay.selfpetitionusa.com/b/9AQaIe9ZZd6ZbrabL8');
          myMap.set(aireferenceLetter1referenceLetter2referenceLetter3consultation, 'https://pay.selfpetitionusa.com/b/fZecQmdcb4AteDm6qP');
          
          return myMap;
        },
        submit() {
          //console.log(Array.from(this.orderItems).sort().join(','));
          //console.log(this.paymentLinkMap);
          this.submitted = true;
          this.handleDisclaimersClick();
          this.validateEmail();

          if (
              (this.userDisclaimers.disclaimer1 !== false) && (this.userDisclaimers.disclaimer2 !== false) && this.isEmail
          ) {
            this.sendConversion();
            this.showWaitingGif = true;
            this.apply({
              userData: this.userData,
              userDisclaimers: this.userDisclaimers,
              userAddons: this.userAddons,
              });
            var query = ''
            if(this.promoApplied)
                query = '?prefilled_email=' + this.userData.email + '&prefilled_promo_code=' + this.promoCode;
            
            setTimeout(() => {
              window.location.href = this.paymentLinkMap.get(Array.from(this.orderItems).sort().join(',')) + query
            }, 1000);
          }
        }
      }
    }
</script>
