<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
        <div class="d-table"><div class="d-table-cell">
          <div class="article-tags">
            <a href="#">EB-2 NIW BLOG</a>
          </div>
          <h1 style="font-weight: 600; margin-top: 30px; text-align: left">Prepare EB-2 NIW recommendation letters</h1>
        </div>
        </div>
      </div>
    </div>

    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">February 14, 2023</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Tom</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog15-16x9.webp" alt="green card self-service">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">Recommendation letters</h3>

          <div class="blog-paragraph">Letters of recommendation are a critical component of the EB-2 National Interest Waiver (NIW) immigration petition. <span style="background-color: rgba(68, 206, 111, 0.25)">These letters provide insight into the applicant's accomplishments, skills, and experience and help to support the claim that the applicant's work is of exceptional merit and national importance</span>. In this article, we will provide you with some <span style="font-weight: 500; font-style: italic">tips</span> to help prepare the most effective letters of recommendation for your EB-2 NIW petition.</div>

          <div class="blog-paragraph"><span style="font-weight: 500; font-style: italic">Choose your recommenders carefully:</span> Recommendation letters should be written by individuals who are knowledgeable about your work and qualifications. Ideally, your recommenders should be experts in your field and hold advanced degrees or professional licenses. Consider asking colleagues, supervisors, or university professors who can provide a detailed and insightful evaluation of your work.</div>

          <div class="blog-paragraph"><span style="font-weight: 500; font-style: italic">Provide context:</span> Before you ask for a recommendation letter, provide your recommender with background information that the letter is part of your Green Card application. You should also make sure that recommendation letters directly support arguments used in the main application. </div>

          <div class="blog-paragraph">How to achieve that? Check <a href="/home" style="text-decoration: underline">EB-2 NIW Starter Kit</a>. You will find there a step-by-step guide for requesting recommendation letters, sample emails how to reach out to recommenders and three sample letters:</div>
          
          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem;">
            <div class="col-xl-7 col-lg-9;">
              <div class="row justify-content-center">
                <router-link class="btn btn-primary" :to="{ name: 'Home', params: { location: 'Templates' }}">EB-2 NIW Templates</router-link>
              </div>
            </div>
          </div>

          <div class="blog-paragraph"><span style="font-weight: 500; font-style: italic">Give adequate notice:</span> Give your recommenders plenty of notice before asking for a recommendation letter. This will allow them enough time to deliver on your request and make any required adjustments.</div>

          <div class="blog-paragraph">Consider carefully whether it would be better to <span style="background-color: rgba(68, 206, 111, 0.25)">provide a sample letter or a list of key points that should be covered. As authors of this blog, we have tested various ways with our own application submitted to USCIS</span>. If you want to follow our approach, check <a href="/home" style="text-decoration: underline">EB-2 NIW Starter Kit</a>:</div>
          
          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

          <div class="blog-paragraph"><span style="font-weight: 500; font-style: italic">Review the letter before submission:</span> Once you have received your recommendation letters, take the time to review them carefully before submitting them to USCIS. Make sure the letters accurately reflect your achievements and that they support your EB-2 NIW petition.</div>

          <div class="blog-paragraph">In conclusion, a well-prepared letter of recommendation can significantly enhance your chances of success in the EB-2 NIW immigration process. By following these tips, you can ensure that your letters are <span style="font-weight: 500; font-style: italic">effective and persuasive</span>, and that they provide a strong foundation for your petition.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                  <a href="/independent-recommendation-letters" class="btn btn-primary">Continue Reading</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>
<script>

import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";

export default {
  name: 'EB2NIWLettersOfRecommendation',
  components: {
    PriceOffer,
    CarouselGreenCard
  }
}
</script>
