<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <SuccessStoryNetworkEngineer></SuccessStoryNetworkEngineer>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import SuccessStoryNetworkEngineer from "../blog-components/SuccessStoryNetworkEngineer";


export default {
  name: 'SuccessStoryNetworkEngineerView',
  components: {
    SuccessStoryNetworkEngineer,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "EB2 NIW approval for network engineer - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "This success story revolves around Juan, a 5G network engineer, whose dedication and contributions have not only propelled his career but have also fostered innovation, driven economic growth, and strengthened the United States' position in the global telecommunications market. Juan has showcased the power of next-generation wireless technologies and their impact on connectivity, digital transformation, and national security."},
      ]
    }
  }
}
</script>
