<template>
    <div>

		<div class="page-title-area section-title" style="margin-bottom: 0px; padding-bottom: 10px">
			<div class="d-table"><div class="d-table-cell"><div class="container"><h1 class="page-title" >EB-2 NIW Blog</h1></div></div></div>
			<div class="bar"></div>
		<!-- End Page Title -->
			<div style="top:20px" class="shape1"><img src="../resources/shape1.png" alt="national interest waiver templates"></div>
			<div class="shape8 rotateme"><img src="../resources/shape2.svg" alt="niw templates"></div>
			<div class="shape7"><img src="../resources/shape4.svg" alt="eb2 niw product"></div>
		</div>

		<!-- Start Blog Area -->
		<section class="blog-area ptb-80">
			<div class="container">
				<div class="row">


					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/our-track-record">
									<img height="" src="../resources/blog/blog39-1x1.webp" alt="SelfPetitionUSA">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> October 25, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/our-track-record">Empowering EB-2 NIW applicants</a></h4>

								<span>Author: <a href="#">Przemek</a></span>

								<p>If you're considering the EB-2 NIW, you're not alone; many applicants have found success in this journey, and it's a decision that can profoundly impact your life and career. In this article, we'll explore why we set up Self Petition USA and how it can be a transformative...</p>

								<a href="/our-track-record" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/i485">
									<img height="" src="../resources/blog/blog38-1x1.webp" alt="i485 application form">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> October 12, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/i485">Understanding I-485 application</a></h4>

								<span>Author: <a href="#">Przemek</a></span>

								<p>The path to becoming a permanent resident in the United States can be a complex and often daunting journey. Among the various forms and applications, the I-485, or the Application to Register Permanent Residence or Adjustment of Status, stands out...</p>

								<a href="/i485" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/success-story-software-engineer-2">
									<img height="" src="../resources/blog/blog37-1x1.webp" alt="eb2 niw software engineer">
								</a>

								<div class="story">
									<vue-feather type="shield"></vue-feather> Success story
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/success-story-software-engineer-2">EB-2 NIW approval for software engineer</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>Meet Rafael, a skilled software engineer from Germany with over 5 years of experience in the retail sector who applied for a green card through EB-2 NIW process. One of the key criteria for the EB-2 NIW is demonstrating... </p>

								<a href="/success-story-software-engineer-2" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/success-story-data-scientist">
									<img height="" src="../resources/blog/blog36-1x1.webp" alt="eb2 niw data science">
								</a>

								<div class="story">
									<vue-feather type="shield"></vue-feather> Success story
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/success-story-data-scientist">EB-2 NIW approval for data scientist</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>Alex's background was in data science, holding a bachelor's degree in computer science and having previously worked for a well-known internet company. Despite this, he recently graduated with a Master of Business Administration (MBA) in the U.S.</p>

								<a href="/success-story-data-scientist" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/success-story-venture-capitalist">
									<img height="" src="../resources/blog/blog35-1x1.webp" alt="eb2 niw vc">
								</a>

								<div class="story">
									<vue-feather type="shield"></vue-feather> Success story
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/success-story-venture-capitalist">EB-2 NIW approval for Head of Investments</a></h4>

								<span>Author: <a href="#">Tom</a></span>

								<p>In this success story, we will showcase the journey of Sarah, a professional who held the prestigious position of Head of Investments & Portfolio Capital at a prominent Venture Capital. Sarah successfully secured an EB-2 NIW... </p>

								<a href="/success-story-venture-capitalist" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/new-eta9089">
									<img height="" src="../resources/blog/blog34-1x1.webp" alt="eb2 niw eta-9089">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> August 22, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/new-eta9089">Use new ETA-9089 form for EB-2 NIW</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>In this article, we'll delve into the intricacies of Form ETA-9089, an essential document for those seeking a National Interest Waiver (NIW) Green Card. We will provide you with valuable insights into the updated version of this form, recent changes, and which version to use.</p>

								<a href="/new-eta9089" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/eta9089-vs-eta750">
									<img height="" src="../resources/blog/blog33-1x1.webp" alt="eb2 niw eta9089 vs eta750">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> August 17, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/eta9089-vs-eta750">ETA-9089 or ETA-750 forms for EB2 NIW?</a></h4>

								<span>Author: <a href="#">Przemek</a></span>

								<p>Are you on the path to obtaining an EB-2 National Interest Waiver (NIW) and confused about whether to include Labor Certification forms in your application package? In this article, we'll clarify the requirements and discuss which...</p>

								<a href="/eta9089-vs-eta750" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/success-story-science-teacher">
									<img height="" src="../resources/blog/blog32-1x1.webp" alt="eb2 niw science teacher">
								</a>

								<div class="story">
									<vue-feather type="shield"></vue-feather> Success story
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/success-story-science-teacher">EB-2 NIW approval for science teacher</a></h4>

								<span>Author: <a href="#">Przemek</a></span>

								<p>This success story centers around Alice, a visionary high school teacher, whose unwavering commitment and remarkable contributions have not only elevated her career but have also ignited a spark for STEM education, driven by a focus on empowering...</p>

								<a href="/success-story-science-teacher" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/success-story-finance-expert">
									<img height="" src="../resources/blog/blog31-1x1.webp" alt="eb2 niw finance expert">
								</a>

								<div class="story">
									<vue-feather type="shield"></vue-feather> Success story
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/success-story-finance-expert">EB-2 NIW approval for finance expert</a></h4>

								<span>Author: <a href="#">Tom</a></span>

								<p>Today, we have the privilege of sharing the inspiring story of George, a finance expert. George recently received approval for his EB-2 NIW (National Interest Waiver) visa, an achievement that has opened up exciting opportunities for him in the United States.</p>

								<a href="/success-story-finance-expert" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					
					<div class="col-lg-12 col-md-12">
						<div class="pagination-area">
							<nav aria-label="Page navigation">
								<ul class="pagination justify-content-center">
									<li class="page-item"><a class="page-link" href="/blog/1">Prev</a></li>
									<li class="page-item "><a class="page-link" href="/blog/1">1</a></li>
									<li class="page-item active"><a class="page-link" href="#">2</a></li>
									<li class="page-item"><a class="page-link" href="/blog/3">3</a></li>
									<li class="page-item"><a class="page-link" href="/blog/4">4</a></li>
									<li class="page-item"><a class="page-link" href="/blog/5">5</a></li>
									<li class="page-item"><a class="page-link" href="/blog/6">6</a></li>
									<li class="page-item"><a class="page-link" href="/blog/3">Next</a></li>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Blog Area -->
    </div>
</template>
<script>
export default {
    name: 'BlogGrid2'
}
</script>
