<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <div class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </div>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">EB-2 NIW approval rates for STEM</h1>
          </div>
        </div>
      </div>
    </div>
    
    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">April 20, 2024</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Przemek</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog45-16x9.webp" alt="eb2 niw stem">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">STEM vs. Non-STEM approval rates in EB2 NIW</h3>

            <div class="blog-paragraph">Approval rates for EB2 NIW petitions have always been a topic of interest among those navigating the complex landscape of U.S. immigration. While quarterly reports from USCIS provide valuable insights, a recent report delves deeper into the nuances of approval rates, particularly in the context of <span style="font-weight: 500; font-style: italic">STEM (Science, Technology, Engineering, and Mathematics)</span> versus <span style="font-weight: 500; font-style: italic">non-STEM</span> fields. You can access the original report <a target="_blank" href="https://www.uscis.gov/sites/default/files/document/reports/stem_related_petition_trends_eb2_and_o1a_categories_factsheet_fy23.pdf" style="text-decoration: underline">HERE</a>.</div>
            <div class="blog-paragraph">In this article, we'll dissect the key findings of recent report and explore what they mean for prospective petitioners.</div>

          <h5>STEM vs. Non-STEM: a closer look</h5>

            <div class="blog-paragraph"><span style="background-color: rgba(68, 206, 111, 0.25)">The USCIS report sheds light on the approval rates within EB2 NIW, categorized by <span style="font-weight: 500; font-style: italic">STEM</span> and <span style="font-weight: 500; font-style: italic">non-STEM</span> fields. Stemming from the acronym for Science, Technology, Engineering, and Mathematics, STEM fields have traditionally enjoyed <span style="font-weight: 500; font-style: italic">higher approval rates</span> compared to non-STEM fields.</span> The report confirms this trend, revealing a consistent gap in approval rates between the two categories.</div>
            <div class="blog-paragraph">In 2023, for instance, STEM-related endeavors boasted a significantly higher approval rate compared to their non-STEM counterparts, with a <span style="background-color: rgba(68, 206, 111, 0.25)">notable <span style="font-weight: 500; font-style: italic">20-point difference</span> on average</span>. This trend underscores the importance of understanding the landscape within one's field of expertise when considering an EB2 NIW petition.</div>

            <PriceOffer style="margin-bottom: 4rem"></PriceOffer>

            <div class="blog-paragraph"><span style="font-weight: 500; font-style: italic">Key findings when looking at All jobs:</span></div>
            <ul class="features-list">
              <li><vue-feather type="check"></vue-feather>Total receipts of Form I-140, with and without NIW (combined), increased by 20% from 70,600 to 84,470 from FY 2021 to FY 2022. They then rose by a further 10% from FY 2022 to FY 2023.</li>
              <li><vue-feather type="check"></vue-feather>Approvals with and without NIW (combined) experienced a significant increase of 60% from 57,810 in FY 2021 to 92,280 in FY 2022 but declined by approximately 12% to 81,380 in FY 2023.</li>
              <li><vue-feather type="check"></vue-feather>The approval rate with and without NIW (combined) remained consistently high, at 90% or above, from FY 2018 to FY 2023.</li>
              <li><vue-feather type="check"></vue-feather>Despite non-NIW petitions (i.e., with labor certification sponsored by employers) remaining the majority of I-140 receipts from FY 2021 to FY 2022, the percentage of receipts with NIWs compared to those without continued to rise, from 21% to 26%. This trend further intensified from 26% in FY 2022 to 43% in FY 2023. That’s where the number of petitions with NIWs nearly doubled, increasing from 21,990 in FY 2022 to 39,810 in FY 2023, while the number of petitions without NIWs decreased from 62,490 to 53,200 over the same period.</li>
            </ul>
            <div class="row" style="margin-bottom: 3rem">
              <div class="blog-paragraph" style="padding-bottom: 5px; padding-left: 15px"><span style="font-weight: 500;">EB-2 Category Form I-140 Total Receipts, Completions, and Approvals: All Job Types</span> (Source: <a target="_blank" href="https://www.uscis.gov/sites/default/files/document/reports/stem_related_petition_trends_eb2_and_o1a_categories_factsheet_fy23.pdf" style="text-decoration: underline">USCIS</a>)</div>
              <img class="col-12 col-sm-10" src="../resources/All-jobs.webp" alt="USCIS approval rates">
            </div>

            <div class="blog-paragraph"><span style="font-weight: 500; font-style: italic">Key findings when looking at STEM jobs:</span></div>
            <ul class="features-list">
              <li><vue-feather type="check"></vue-feather>Receipts in STEM job categories decreased by nearly 13%, from 61,790 in FY 2022 to 53,960 in FY 2023 (with and without NIW). <span style="background-color: rgba(68, 206, 111, 0.25)">This was a result of a harsh, 15k decrease of non-NIW petitions (i.e., labor certification sponsored by employers), which was only partially offset by 7.4k increase of NIW petitions.</span></li>
              <li><vue-feather type="check"></vue-feather>Approval rates of non-NIW petitions remain consistently strong, and above 98% across 2018-2023.</li>
              <li><vue-feather type="check"></vue-feather>Approval rates of NIW petitions have been within 91%-96% in years 2018-2023, however then number of STEM NIW petitions was relatively small, 6-8k. Then significantly <span style="background-color: rgba(68, 206, 111, 0.25)">increased to 14.6k in 2022 (with approval rate of 95%) and to 23.4k in 2023 (with approval rate of 91%).</span></li>
            </ul>
            <div class="row" style="margin-bottom: 3rem;">
              <div class="blog-paragraph" style="padding-bottom: 5px; padding-left: 15px"><span style="font-weight: 500;">EB-2 Category Form I-140 Total Receipts, Approvals, and Denials: STEM Job Types</span> (Source: <a target="_blank" href="https://www.uscis.gov/sites/default/files/document/reports/stem_related_petition_trends_eb2_and_o1a_categories_factsheet_fy23.pdf" style="text-decoration: underline">USCIS</a>)</div>
              <img class="col-12 col-sm-10" src="../resources/Stem-jobs.webp" alt="stem eb2 niw approval rates">
            </div>

            <div class="blog-paragraph"><span style="font-weight: 500; font-style: italic">Key findings when looking at non-STEM jobs:</span></div>
            <ul class="features-list">
              <li><vue-feather type="check"></vue-feather>Receipts in non-STEM job categories increased by 28% from FY 2022 to FY 2023 (with and without NIW).<span style="background-color: rgba(68, 206, 111, 0.25)">This was a result of a significant, 5.8k increase of NIW petitions, while non-NIW petitions (i.e., labor certification sponsored by employers) decreased only slightly by 0.8k.</span></li>
              <li><vue-feather type="check"></vue-feather>Approval rates of non-NIW petitions remain consistently strong, and above 98% across 2018-2023.</li>
              <li><vue-feather type="check"></vue-feather><span style="background-color: rgba(68, 206, 111, 0.25)">Approval rates of NIW petitions have been in 66-71% range in the worse years (2020, 2021, 2023) and 77-81% range in the better years (2018, 2019, 2022).</span> However, number of non-STEM NIW petitions was pretty small initially (only 1-2k), and only increased to 4.4k in 2022 and to 11.4k in 2023.</li>
            </ul>
            <div class="row" style="margin-bottom: 3rem;">
              <div class="blog-paragraph" style="padding-bottom: 5px; padding-left: 15px;"><span style="font-weight: 500;">EB-2 Category Form I-140 Total Receipts, Approvals, and Denials: Non-STEM Job Types</span> (Source: <a target="_blank" href="https://www.uscis.gov/sites/default/files/document/reports/stem_related_petition_trends_eb2_and_o1a_categories_factsheet_fy23.pdf" style="text-decoration: underline">USCIS</a>)</div>
              <img class="col-12 col-sm-10" src="../resources/Non-stem-jobs.webp" alt="non-stem eb2 niw approval rates">
            </div>

          <h5>Navigating the complexities</h5>
            <div class="blog-paragraph">While data insights provide valuable guidance, it's crucial to remember that success in EB2 NIW petitions isn't solely determined by whether one's field falls under STEM or non-STEM. Individual factors such as the <span style="font-weight: 500; font-style: italic">quality of the petition package</span>, the <span style="font-weight: 500; font-style: italic">petitioner's profile</span>, and the <span style="font-weight: 500; font-style: italic">skillful presentation</span> of the case play significant roles.</div>
            <div class="blog-paragraph">Moreover, the subjective nature of the evaluation process means that outcomes can vary based on the <span style="font-weight: 500; font-style: italic">reviewing officer</span>. As such, petitioners must focus on crafting compelling narratives and presenting evidence that effectively demonstrates the national interest served by their work.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                <router-link class="btn btn-primary" :to="{ name: 'Home', params: { location: 'Templates' }}">EB-2 NIW Templates</router-link>
              </div>
            </div>
          </div>

          <h5>Conclusion: beyond statistics</h5>
            <div class="blog-paragraph">While statistics offer valuable insights into approval trends, they should be viewed as part of a broader context. Ultimately, the success of an EB2 NIW petition hinges on a combination of factors, including the petitioner's profile, the strength of the proposal, and the clarity of presentation.</div>
            <div class="blog-paragraph"><span style="background-color: rgba(68, 206, 111, 0.25)">If you're considering petitioning, take a look at our two options: the <router-link :to="{ name: 'Home', params: { location: 'Templates' }}" style="text-decoration: underline">Starter Kit Templates</router-link> and <a href="/ai" style="text-decoration: underline">SelfPetitionAI</a>. The Starter Kit Templates provide <span style="font-weight: 500; font-style: italic">standardized EB-2 NIW templates</span> for various professions, but you'll need to customize them to fit your background.</span> Meanwhile, SelfPetitionAI, our newest service, <span style="font-weight: 500; font-style: italic">crafts personalized cover letters and reference letters</span> tailored specifically to your background. Whether you're a software engineer, project manager, entrepreneur, or in any other profession, you can find something that suits your needs.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                  <a href="/are-you-eligible" class="btn btn-primary btn-assess">Are you eligible for EB-2 NIW?</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>
<script>
import PriceOffer from "../components/PriceOffer.vue";

export default {
  name: 'EB2NIWStemApproval',
  components: {
    PriceOffer,
  }
}
</script>
