<template>
    <div>

		<div class="page-title-area section-title" style="margin-bottom: 0px; padding-bottom: 10px">
			<div class="d-table"><div class="d-table-cell"><div class="container"><h1 class="page-title" >EB-2 NIW Blog</h1></div></div></div>
			<div class="bar"></div>
		<!-- End Page Title -->
			<div style="top:20px" class="shape1"><img src="../resources/shape1.png" alt="national interest waiver templates"></div>
			<div class="shape8 rotateme"><img src="../resources/shape2.svg" alt="niw templates"></div>
			<div class="shape7"><img src="../resources/shape4.svg" alt="eb2 niw software engineer"></div>
		</div>

		<!-- Start Blog Area -->
		<section class="blog-area ptb-80">
			<div class="container">
				<div class="row">

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/success-story-dentist">
									<img src="../resources/blog/blog21-1x1.webp" alt="eb2 niw dentist">
								</a>

								<div class="story">
									<vue-feather type="shield"></vue-feather> Success story
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/success-story-dentist">EB-2 NIW approval for healthcare professional</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>The following is Kayla's success story of an EB-2 National Interest Waiver (NIW) approval for a dental healthcare professional. Kayla is a highly skilled and experienced dental healthcare professional from overseas.</p>

								<a href="/success-story-dentist" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/success-story-entrepreneur">
									<img src="../resources/blog/blog20-1x1.webp" alt="eb2 niw entrepreneur">
								</a>

								<div class="story">
									<vue-feather type="shield"></vue-feather> Success story
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/success-story-entrepreneur">EB-2 NIW approval for biotech startup founder</a></h4>

								<span>Author: <a href="#">Przemek</a></span>

								<p>Tom was a scientist and a biotech startup founder who was passionate about developing new and innovative treatments for rare diseases. He had a PhD in biochemistry and years of experience working in the industry.</p>

								<a href="/success-story-entrepreneur" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/success-story-human-resources-consultant">
									<img src="../resources/blog/blog19-1x1.webp" alt="eb2 niw recruiter">
								</a>

								<div class="story">
									<vue-feather type="shield"></vue-feather> Success story
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/success-story-human-resources-consultant">EB-2 NIW approval for HR consultant</a></h4>

								<span>Author: <a href="#">Przemek</a></span>

								<p>Helen, is a consultant who has worked in the field of Human Resources for over 10 years with a lot of experience in helping companies expand their workforce in a sustainable way. However, she faced a challenge when she decided to immigrate.</p>

								<a href="/success-story-human-resources-consultant" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/success-story-marketing-manager">
									<img src="../resources/blog/blog18-1x1.webp" alt="eb2 niw marketing">
								</a>

								<div class="story">
									<vue-feather type="shield"></vue-feather> Success story
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/success-story-marketing-manager">EB-2 NIW approval for marketing manager</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>Anna, a brand marketing manager with extensive experience in developing and implementing marketing strategies for multiple multinational companies applied for EB-2 NIW (National Interest Waiver) immigration status.</p>

								<a href="/success-story-marketing-manager" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/success-story-petroleum-engineer">
									<img src="../resources/blog/blog17-1x1.webp" alt="eb2 niw petroleum engineer">
								</a>

								<div class="story">
									<vue-feather type="shield"></vue-feather> Success story
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/success-story-petroleum-engineer">EB-2 NIW approval for petroleum engineer</a></h4>

								<span>Author: <a href="#">Tom</a></span>

								<p>Maxwell is a Petroleum Engineering consultant who received approval for an EB-2 National Interest Waiver (NIW) without any research experience. Despite the common perception that research is necessary for NIW approval...</p>

								<a href="/success-story-petroleum-engineer" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/success-story-software-engineer">
									<img src="../resources/blog/blog16-1x1.webp" alt="eb2 niw software engineer">
								</a>

								<div class="story">
									<vue-feather type="shield"></vue-feather> Success story
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/success-story-software-engineer">EB-2 NIW approval for software engineer</a></h4>

								<span>Author: <a href="#">Tom</a></span>

								<p>Meet John, a talented machine learning software engineer from Germany with over 7 years of experience in the field. Despite his vast knowledge and expertise, he faced several roadblocks in obtaining a Green Card.</p>

								<a href="/success-story-software-engineer" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/prepare-eb2-niw-recommendation-letters">
									<img src="../resources/blog/blog15-1x1.webp" alt="eb2-niw do it yourself package">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> February 14, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/prepare-eb2-niw-recommendation-letters">Prepare EB-2 NIW recommendation letters</a></h4>

								<span>Author: <a href="#">Tom</a></span>

								<p>Letters of recommendation are a critical component of the EB-2 National Interest Waiver (NIW) immigration petition. These letters provide insight into the applicant's accomplishments, skills, and experience.</p>
								
								<a href="/prepare-eb2-niw-recommendation-letters" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/what-if-my-eb2-niw-is-denied">
									<img src="../resources/blog/blog14-1x1.webp" alt="niw petition letter sample">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> February 13, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/what-if-my-eb2-niw-is-denied">What if my EB-2 NIW is denied?</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>Like any other immigration application, there is always a risk of denial. In this article, we will discuss the possible outcomes if your EB-2 NIW is denied and what you can do to increase your chances of success in your future immigration efforts.</p>

								<a href="/what-if-my-eb2-niw-is-denied" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/my-options-to-apply-for-eb2-visa-niw-vs-perm">
									<img src="../resources/blog/blog13-1x1.webp" alt="eb2 niw cover letter sample">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> February 6, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/my-options-to-apply-for-eb2-visa-niw-vs-perm">My options for EB-2 visa: NIW vs. PERM</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>National Interest Waiver (NIW) and Permanent Employment Certification (PERM) are two different processes for foreign nationals seeking permanent residency in the United States. Both offer pathways to a Green Card.</p>

								<a href="/my-options-to-apply-for-eb2-visa-niw-vs-perm" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					
					<div class="col-lg-12 col-md-12">
						<div class="pagination-area">
							<nav aria-label="Page navigation">
								<ul class="pagination justify-content-center">
									<li class="page-item"><a class="page-link" href="/blog/3">Prev</a></li>
									<li class="page-item "><a class="page-link" href="/blog/1">1</a></li>
									<li class="page-item"><a class="page-link" href="/blog/2">2</a></li>
									<li class="page-item"><a class="page-link" href="/blog/3">3</a></li>
									<li class="page-item active"><a class="page-link" href="#">4</a></li>
									<li class="page-item"><a class="page-link" href="/blog/5">5</a></li>
									<li class="page-item"><a class="page-link" href="/blog/6">6</a></li>
									<li class="page-item"><a class="page-link" href="/blog/5">Next</a></li>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Blog Area -->
    </div>
</template>
<script>
export default {
    name: 'BlogGrid3'
}
</script>
