var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('section',{staticClass:"blog-details-area"},[_c('div',{staticClass:"article-content"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('vue-feather',{attrs:{"type":"clock"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("June 14, 2023")])],1),_c('li',[_c('vue-feather',{attrs:{"type":"user"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("Natalie")])],1)])])])]),_vm._m(1),_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"article-content"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Success story: EB-2 NIW")]),_vm._m(2),_c('div',{staticClass:"signupSelfPetition row justify-content-center align-items-center"},[_c('div',{staticClass:"col-xl-6 col-lg-7"},[_c('signup-self-petition')],1)]),_vm._m(3),_vm._m(4),_vm._m(5),_c('PriceOffer',{staticStyle:{"margin-bottom":"4rem"}}),_c('CarouselGreenCard',{staticStyle:{"margin-bottom":"4rem"}}),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area2"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('span',{staticClass:"article-tags"},[_c('a',{attrs:{"href":"#"}},[_vm._v("EB-2 NIW BLOG")])]),_c('span',{staticClass:"article-tags2"},[_c('a',{attrs:{"href":"#"}},[_vm._v("SUCCESS STORY")])]),_c('h1',{staticStyle:{"font-weight":"600","margin-top":"30px","text-align":"left"}},[_vm._v("EB-2 NIW approval for 5G network engineer")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../resources/blog/blog29-16x9.webp"),"alt":"NIW template network engineer"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("In the ever-evolving landscape of "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("telecommunications")]),_vm._v(", individuals with a passion for innovation and expertise in cutting-edge technologies play a vital role in shaping the future. "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("This success story revolves around Juan, a "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("5G network engineer")]),_vm._v(", whose dedication and contributions have not only propelled his career but have also "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("fostered innovation")]),_vm._v(", "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("driven economic growth")]),_vm._v(", and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("strengthened the United States' position in the global telecommunications market.")])]),_vm._v(" Juan has showcased the power of next-generation wireless technologies and their impact on connectivity, digital transformation, and national security.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("The significance of 5G technology cannot be overstated. "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("Recognizing its potential, the U.S. government has prioritized the deployment and development of 5G networks due to their critical role in various sectors such as "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("telecommunications")]),_vm._v(", "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("transportation")]),_vm._v(", "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("energy")]),_vm._v(", "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("healthcare")]),_vm._v(", and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("defense")]),_vm._v(".")]),_vm._v(" In alignment with the national strategy, Juan's initiative focuses on "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("designing and optimizing advanced 5G network infrastructure")]),_vm._v(" to enhance connectivity and improve telecommunications services.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("His endeavor directly contributes to the nation's goal of advancing telecommunication technology, supporting digital transformation, and maintaining a competitive edge in the global telecommunications market. As he contributes to the development of advanced 5G network infrastructure, Juan not only "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("strengthens the nation's telecommunications capabilities but also enables digital services, propels technological advancement, and drives economic growth.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Juan filed I-140 petition for EB-2 NIW leveraging "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("Self Petition Starter Kit PRO - network engineering")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Juan has made significant contributions to network performance, reliability, and user experience. His recent "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("promotion to a senior position")]),_vm._v(" is a testament to his expertise and the impact he has had on the organization. By continually pushing the boundaries of 5G network infrastructure design and optimization, Juan's work has resulted in "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("enhanced connectivity, improved telecommunications services, and increased competitive advantage for his employer.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("Juan's had "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("no research findings")]),_vm._v(" in conventional academic outlets, as he had not produced any papers in such venues.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("The success story of Juan, demonstrates the pivotal role played by individuals in driving innovation and economic growth within the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("telecommunications")]),_vm._v(" industry.")]),_vm._v(" His expertise and contributions in designing and optimizing advanced "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("5G network infrastructure align perfectly with the United States' national interest in advancing telecommunication technology and maintaining a competitive edge")]),_vm._v(" in the global market. By fostering connectivity, enabling digital services, and strengthening the nation's telecommunications capabilities, Juan exemplifies the transformative power of "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("next-generation wireless technologies")]),_vm._v(" and their significant impact on society and the economy.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('a',{staticClass:"btn btn-primary",staticStyle:{"margin-top":"3rem","margin-bottom":"2rem"},attrs:{"href":"/network-electrical-engineer-sample"}},[_vm._v("NETWORK ENGINEER TEMPLATE")])])])])
}]

export { render, staticRenderFns }