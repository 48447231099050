<template>

    <section class="services-area ptb-80" style="background: white; padding-bottom: 3rem; padding-top: 2rem">
        <section />
        <div class="container">

            <div class="section-title">
                <h2>Request for Evidence &#127919;</h2>
                <div class="bar"></div>
                <div class="paragraph">
                    <p style="margin-bottom: 10px">Selfpetition AI also handles RFEs.</p>
                    <p>If the RFE from USCIS points out a lack of evidence, there's not much Selfpetition AI can do. However, if you received Request For Evidence because your cover letter or reference letters were poorly drafted or you seek alternative arguments not used in the original submission, then we can help.</p>
                </div>
                <h2 style="text-align: center; color: #6084a4; margin-top: 20px; margin-bottom: 0px">&#8681;</h2>
            </div>

            <SignupRfe></SignupRfe>

        </div>
    </section>
 
</template>

<script>
 import SignupRfe from "./SignupRfe.vue";

    export default {
        name: 'RfeGreenCard',
        components: {
            SignupRfe,
        }
    }
</script>
