<template>

    <section class="works-area" style="background: white; margin-top: 3rem">

        <b-modal ref="modal-zoom" :modal-class="modalZoom" :return-focus="this.$parent" size="lg" hide-footer>
            <template #modal-title>
               {{modalProperties[index].title}}
            </template>
            <div style="text-align: center;">
                <img :src="getImgUrl()" alt="eb-2 niw template" />
            </div>
        </b-modal>

        <div class="works-slides" style="margin-top: 3rem">
            <carousel
                :autoplay = "true"
                :loop = "true"
                :autoplaySpeed = "true"
                :autoplayTimeout = "5000"
                :perPageCustom = "[[0, 1],[768, 2],[1200, 3], [1500, 4]]"
            >
                <slide>
                    <h6 style="text-align: center; margin-bottom: 40px">Exclusive Discord Community</h6>

                    <a @click="index = 0; $refs['modal-zoom'].show()" style="cursor: pointer;">
                        <div class="single-works carousel-green-hover">
                            <img src="../resources/0-discord.webp" alt="how to self-petition for a green card">
                            <a v-if="windowWidth > 767" class="icon"><vue-feather type="zoom-in"></vue-feather></a>

                            <div class="works-content">
                                <h3 style="color: #fff">Exclusive Lifetime Access to Discord</h3>
                                <p>Explore additional materials and ask questions about the process</p>
                            </div>
                        </div>
                    </a>
                </slide>

                <slide>
                    <h6 style="text-align: center; margin-bottom: 40px">What Starter Kit Includes</h6>

                    <a @click="index = 1; $refs['modal-zoom'].show()" style="cursor: pointer;">
                        <div class="single-works carousel-green-hover">
                            <img src="../resources/1-instructions.webp" alt="national interest waiver template">
                            <a v-if="windowWidth > 767" class="icon"><vue-feather type="zoom-in"></vue-feather></a>

                            <div class="works-content">
                                <h3 style="color: #fff">Document #1</h3>
                                <p>Starter Kit structure, importance of repetitions and choosing the right language</p>
                            </div>
                        </div>
                    </a>
                </slide>

                <slide>
                    <h6 style="text-align: center; margin-bottom: 40px">Sample I-140 Cover Letter for Your Profession</h6>

                    <a @click="index = 2; $refs['modal-zoom'].show()" style="cursor: pointer;">
                        <div class="single-works carousel-green-hover">
                            <img src="../resources/2-profession-application.webp" alt="niw template">
                            <a v-if="windowWidth > 767" class="icon"><vue-feather type="zoom-in"></vue-feather></a>

                            <div class="works-content">
                                <h3 style="color: #fff">Document #2</h3>
                                <p>Find list of professions in the section: <a href="/#starterKitProfessions" @click="closeBannerModal" style="color: #fff; text-decoration: underline">AVAILABLE TEMPLATES</a></p>
                            </div>
                        </div>
                    </a>
                </slide>

                <slide>
                    <h6 style="text-align: center; margin-bottom: 40px">NIW Case Builder for Your Profession</h6>

                    <a @click="index = 3; $refs['modal-zoom'].show()" style="cursor: pointer;">
                        <div class="single-works carousel-green-hover">
                            <img src="../resources/3-niw-builder.webp" alt="how to self-petition for a green card">
                            <a v-if="windowWidth > 767" class="icon"><vue-feather type="zoom-in"></vue-feather></a>

                            <div class="works-content">
                                <h3 style="color: #fff">Document #3</h3>
                                <p>For profession of your choice, to help you identify relevant NIW initiatives and arguments</p>
                            </div>
                        </div>
                    </a>
                </slide>

                <slide>
                    <h6 style="text-align: center; margin-bottom: 40px">Our Original I-140 Cover Letter (Banking)</h6>

                    <a @click="index = 4; $refs['modal-zoom'].show()" style="cursor: pointer;">
                        <div class="single-works carousel-green-hover">
                            <img src="../resources/4-original-application.webp" alt="niw template">
                            <a v-if="windowWidth > 767" class="icon"><vue-feather type="zoom-in"></vue-feather></a>

                            <div class="works-content">
                                <h3 style="color: #fff">Document #4</h3>
                                <p>Important also for non-banking professions: from our approved NON-RFE petition</p>
                            </div>
                        </div>
                    </a>
                </slide>
                
                <slide>
                    <h6 style="text-align: center; margin-bottom: 40px">Our Three Original Reference Letters</h6>

                    <a @click="index = 5; $refs['modal-zoom'].show()" style="cursor: pointer;">
                        <div class="single-works carousel-green-hover">
                            <img src="../resources/5-reference-letter.webp" alt="how to self-petition for a green card">
                            <a v-if="windowWidth > 767" class="icon"><vue-feather type="zoom-in"></vue-feather></a>

                            <div class="works-content">
                                <h3 style="color: #fff">Documents #5A, #5B, #5C</h3>
                                <p>Important also for non-banking professions: from our approved NON-RFE petition</p>
                            </div>
                        </div>
                    </a>
                </slide>

                <slide>
                    <h6 style="text-align: center; margin-bottom: 40px">Editable Original Reference Letters</h6>

                    <a @click="index = 6; $refs['modal-zoom'].show()" style="cursor: pointer;">
                        <div class="single-works carousel-green-hover">
                            <img src="../resources/6-reference-highlighted.webp" alt="national interest waiver referral letter">
                            <a v-if="windowWidth > 767" class="icon"><vue-feather type="zoom-in"></vue-feather></a>

                            <div class="works-content">
                                <h3 style="color: #fff">Documents #6A, #6B, #6C</h3>
                                <p>Word format of our three original reference letters that can be edited</p>
                            </div>
                        </div>
                    </a>
                </slide>

                <slide>
                    <h6 style="text-align: center; margin-bottom: 40px">Guide to Request Reference Letters</h6>

                    <a @click="index = 7; $refs['modal-zoom'].show()" style="cursor: pointer;">
                        <div class="single-works carousel-green-hover">
                            <img src="../resources/7-request-letters.webp" alt="national interest waiver referral letter">
                            <a v-if="windowWidth > 767" class="icon"><vue-feather type="zoom-in"></vue-feather></a>

                            <div class="works-content">
                                <h3 style="color: #fff">Document #7</h3>
                                <p>Detailed steps we took to acquire reference letters and recommended strategies</p>
                            </div>
                        </div>
                    </a>
                </slide>

                <slide>
                    <h6 style="text-align: center; margin-bottom: 40px">Guide to Understand Visa Bulletin</h6>

                    <a @click="index = 8; $refs['modal-zoom'].show()" style="cursor: pointer;">
                        <div class="single-works carousel-green-hover">
                            <img src="../resources/8-visa-bulletin.webp" alt="niw template">
                            <a v-if="windowWidth > 767" class="icon"><vue-feather type="zoom-in"></vue-feather></a>

                            <div class="works-content">
                                <h3 style="color: #fff">Document #8</h3>
                                <p>Find out if your date is current, ie. there is no backlog and no wait time for a green card</p>
                            </div>
                        </div>
                    </a>
                </slide>

                <slide>
                    <h6 style="text-align: center; margin-bottom: 40px">Adjustment of Status Guide</h6>

                    <a @click="index = 9; $refs['modal-zoom'].show()" style="cursor: pointer;">
                        <div class="single-works carousel-green-hover">
                            <img src="../resources/9-aos-guide.webp" alt="adjustment of status">
                            <a v-if="windowWidth > 767" class="icon"><vue-feather type="zoom-in"></vue-feather></a>

                            <div class="works-content">
                                <h3 style="color: #fff">Document #9</h3>
                                <p>Adjustment of Status explained, along with list of required forms and documents (10 pages)</p>
                            </div>
                        </div>
                    </a>
                </slide>

                <slide>
                    <h6 style="text-align: center; margin-bottom: 40px">AOS Sample: I-485, I-765 & I-131</h6>

                    <a @click="index = 10; $refs['modal-zoom'].show()" style="cursor: pointer;">
                        <div class="single-works carousel-green-hover">
                            <img src="../resources/10-aos-example.webp" alt="adjustment of status example">
                            <a v-if="windowWidth > 767" class="icon"><vue-feather type="zoom-in"></vue-feather></a>

                            <div class="works-content">
                                <h3 style="color: #fff">Document #10</h3>
                                <p>Forms I-485, I-765, I-131 and Adjustment of Status cover letter in editable Word format</p>
                            </div>
                        </div>
                    </a>
                </slide>

                <slide>
                    <h6 style="text-align: center; margin-bottom: 40px">ETA-9089 & ETA-750 Forms Sample</h6>

                    <a @click="index = 11; $refs['modal-zoom'].show()" style="cursor: pointer;">
                        <div class="single-works carousel-green-hover">
                            <img src="../resources/11-eta750.webp" alt="eta-750 eta-9089">
                            <a v-if="windowWidth > 767" class="icon"><vue-feather type="zoom-in"></vue-feather></a>

                            <div class="works-content">
                                <h3 style="color: #fff">Document #11</h3>
                                <p>Select sample form to add to your package</p>
                            </div>
                        </div>
                    </a>
                </slide>

            </carousel>
        </div>
        <div class="shape4"><img src="../resources/shape4.svg" alt="self petition green card"></div>
    </section>

</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
    name: 'CarouselGreenCard',
    components: { Carousel, Slide },
    data() {
        return {
            modalZoom: ['modal-zoom'],
            windowWidth: window.innerWidth,
            index: 0,
            modalProperties: [
                {
                    title : "Exclusive Discord Community",
                    img: "./0-discord.webp"
                },
                {
                   title : "What Starter Kit Includes",
                   img: "./1-instructions.webp"
                },
                {
                    title : "Sample I-140 Cover Letter for Your Profession",
                    img: "./2-profession-application.webp"
                },
                {
                    title : "NIW Case Builder for Your Profession",
                    img: "./3-niw-builder.webp"
                },
                {
                    title : "Our Original I-140 Cover Letter (Banking)",
                    img: "./4-original-application.webp"
                },
                {
                    title : "Our Three Original Reference Letters",
                    img: "./5-reference-letter.webp"
                },
                {
                    title : "Editable Original Reference Letters",
                    img: "./6-reference-highlighted.webp"
                },
                {
                    title : "Guide to Request Reference Letters",
                    img: "./7-request-letters.webp"
                },
                {
                    title : "Guide to Understand Visa Bulletin",
                    img: "./8-visa-bulletin.webp"
                },
                                {
                    title : "Adjustment of Status Guide",
                    img: "./9-aos-guide.webp"
                },
                {
                    title : "Adjustment of Status Sample: I-485, I-765 & I-131",
                    img: "./10-aos-example.webp"
                },
                {
                    title : "ETA-9089 & ETA-750 Forms Sample",
                    img: "./11-eta750.webp"
                }
            ]
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
      var resizeEvent = window.document.createEvent('UIEvents');
      resizeEvent.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(resizeEvent);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth
        },
        getImgUrl() {
            var images = require.context('../resources/', false, /\.webp$/)
            return images(this.modalProperties[this.index].img)
        },
        closeBannerModal() {
        // Emit a custom event to notify the parent component (BannerGreenCard)
        this.$emit('close-banner-modal');
        }
    }
}
</script>
