<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <BlogGrid2></BlogGrid2>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import BlogGrid2 from "../blog-components/BlogGrid2";


export default {
  name: 'BlogGridView2',
  components: {
    BlogGrid2,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "EB2NIW Blog: checklists and tips",
      meta: [
        { vmid: 'description', name: 'description', content:  'What are the benefits of EB-2 NIW (vs. PERM), how can I self petition and what can I do if there is a backlog?'},
      ]
    }
  }
}
</script>
