<template>

    <div class="pricing-table col-xl-6 col-md-8 col-12 offset-xl-3 offset-md-2 offset-0" style="padding-top: 3rem; padding-left: 0px; padding-right: 0px">

        <div v-if="isPromo" style="margin-top: 0.4rem; margin-bottom: 2rem; font-size: 14px; color: #0e314c">
            <img src="../resources/discount.webp" style="height: 90px; position: absolute; right: 10px; top: 10px;">
            <span>Valid for: </span>
            <span class="countdown-mobile">
                <countdown :time="countDownStart" :interval="1000" v-slot="{ days, hours, minutes, seconds }">

                  <span style="font-weight: 600" v-if="days > 0">{{ days }}</span><span v-if="days > 0"> day</span><span v-if="days > 1">s</span>
                  <span style="font-weight: 600"> {{ hours }}</span>h :
                  <span style="font-weight: 600">{{ minutes }}</span>m :
                  <span style="font-weight: 600">{{ seconds }}</span>s
                </countdown>
            </span>
        </div>

        <div class="row justify-content-center" style="padding: 0rem 20px 1rem">
            <div class="col-4" style="padding-left: 0px; padding-right: 0px">
                <a href="/checkout-starter-kit">
                    <div class="priceOffer-box">
                        <div class="price" style="margin-bottom: 0px; display: flex; align-items: center; justify-content: center">
                            <span v-if="isPromo" style="color: #0e314c; line-height: 75%; padding-top: 10px">
                                <sup>$</sup>234
                                <br>
                                <sup style="color: #b30000; font-size: 13px; margin-left: 3px;">$</sup><span style="color: #b30000; font-size: 16px; text-decoration: line-through">360</span>
                            </span>
                            <span v-if="!isPromo" style="color: #0e314c"><sup>$</sup>360</span>
                        </div>
                        <h3 style="color: #5457c1; margin-top: 30px; margin-bottom: 8px">Starter Kit</h3>
                        <div class="priceOfferDesc" style="margin-bottom: 12px; font-size: 12px">Profession-specific standard template</div>
                        <h3 style="margin: 0; font-size: 20px; height: 25px">&#9997;&#127996;</h3>
                    </div>
                </a>
            </div>

            <div class="col-4" style="padding-left: 0px; padding-right: 0px">
                <a href="/checkout-selfpetition-ai">
                    <div class="priceOffer-box">
                        <div class="price" style="margin-bottom: 0px; display: flex; align-items: center; justify-content: center">
                            <span v-if="isPromo" style="color: #0e314c; line-height: 75%; padding-top: 10px">
                                <sup>$</sup>650
                                <br>
                                <sup style="color: #b30000; font-size: 13px; margin-left: 3px;">$</sup><span style="color: #b30000; font-size: 16px; text-decoration: line-through">1,000</span>
                            </span>
                            <span v-if="!isPromo" style="color: #0e314c"><sup>$</sup>1,000</span>
                        </div>
                        <h3 style="color: #5457c1; margin-top: 30px; margin-bottom: 8px">Selfpetition AI</h3>
                        <div class="priceOfferDesc" style="margin-bottom: 12px; font-size: 12px">Custom petition reflecting your case</div>
                        <h3 style="margin: 0; font-size: 20px; height: 25px">&#129302;</h3>
                    </div>
                </a>
            </div>

            <div class="col-4" style="padding-left: 0px; padding-right: 0px">
                <a href="/checkout-rfe-response">
                    <div class="priceOffer-box">
                        <div class="price" style="margin-bottom: 0px; display: flex; align-items: center; justify-content: center">
                            <span v-if="isPromo" style="color: #0e314c; line-height: 75%; padding-top: 10px">
                                <sup>$</sup>325
                                <br>
                                <sup style="color: #b30000; font-size: 13px; margin-left: 3px;">$</sup><span style="color: #b30000; font-size: 16px; text-decoration: line-through">500</span>
                            </span>
                            <span v-if="!isPromo" style="color: #0e314c"><sup>$</sup>500</span>
                            <div v-if="!isPromo" style="color: #0e314c; font-size: 10px; position: absolute; margin-top: 40px; margin-left: 10px">per prong</div>
                            <div v-if="isPromo" style="color: #0e314c; font-size: 10px; position: absolute; margin-top: 75px; margin-left: 10px">per prong</div>
                        </div>
                        <h3 style="color: #5457c1; margin-top: 30px; margin-bottom: 8px">RFE Response</h3>
                        <div class="priceOfferDesc" style="margin-bottom: 12px; font-size: 12px;">Bespoke response to RFE letter</div>
                        <h3 style="margin: 0; font-size: 23px; height: 25px">&#127919;</h3>
                    </div>
                </a>
            </div>
        </div>

        <div class="row banner-action-btns" style="text-align: center; margin-top: 2rem">
            <a v-on:click="sendEvent('offer-price')" class="btn btn-primary" href="/select-product" alt="buy niw petition letter template">Compare products</a>
        </div>

    </div>

</template>


<script>

// 864000 is number of seconds equal to 5 days. Promo updated every 15 days but not shown for the first 5 days


    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'PriceOffer',
        data () {
            return {
              discountStart: this.generateClosestDatesArray(),
            }
        },
        methods: {
          ...mapActions('account', ['apply']),
          sendEvent(eventType) {
              if (window.gtag) {
                window.gtag('event', eventType)
              }
          },
          generateClosestDatesArray() {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const startDate = new Date(year, 0, 0);
            const endDate = new Date(year + 1, 0, 0);

            const dateArray = [];
            let currentDateCandidate = startDate;

            while (currentDateCandidate < endDate) {
              dateArray.push(new Date(currentDateCandidate));
              currentDateCandidate.setDate(currentDateCandidate.getDate() + 15);
            }

            // Find the closest date that is before the current date
            let closestDate = dateArray[0];
            for (const date of dateArray) {
              if (date < currentDate && date > closestDate) {
                closestDate = date;
              }
            }

            return closestDate;
          },
        },
        computed: {
          ...mapState({ account: state => state.account,
          }),
          isPromo() {
            // const tenDaysInMilliseconds = 10 * 24 * 60 * 60 * 1000; // 10 days in milliseconds
            // return tenDaysInMilliseconds > (new Date() - this.discountStart)
            return false;
          },
          countDownStart() {
            const tenDaysInMilliseconds = 10 * 24 * 60 * 60 * 1000
            return tenDaysInMilliseconds - (new Date() - this.discountStart)
          }
        },
    }

</script>
