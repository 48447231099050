var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('section',{staticClass:"blog-details-area"},[_c('div',{staticClass:"article-content"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('vue-feather',{attrs:{"type":"clock"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("July 26, 2023")])],1),_c('li',[_c('vue-feather',{attrs:{"type":"user"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("Przemek")])],1)])])])]),_vm._m(1),_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"article-content"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Success story: EB-2 NIW")]),_vm._m(2),_c('div',{staticClass:"signupSelfPetition row justify-content-center align-items-center"},[_c('div',{staticClass:"col-xl-6 col-lg-7"},[_c('signup-self-petition')],1)]),_vm._m(3),_vm._m(4),_vm._m(5),_c('PriceOffer',{staticStyle:{"margin-bottom":"4rem"}}),_c('CarouselGreenCard',{staticStyle:{"margin-bottom":"4rem"}}),_vm._m(6),_vm._m(7),_vm._m(8)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area2"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('span',{staticClass:"article-tags"},[_c('a',{attrs:{"href":"#"}},[_vm._v("EB-2 NIW BLOG")])]),_c('span',{staticClass:"article-tags2"},[_c('a',{attrs:{"href":"#"}},[_vm._v("SUCCESS STORY")])]),_c('h1',{staticStyle:{"font-weight":"600","margin-top":"30px","text-align":"left"}},[_vm._v("EB-2 NIW approval for science teacher")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../resources/blog/blog32-16x9.webp"),"alt":"NIW template science teacher"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("This success story centers around Alice, "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("a visionary "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("high school teacher")]),_vm._v(", whose unwavering commitment and remarkable contributions have not only elevated her career but have also ignited a spark for "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("STEM education")]),_vm._v(", driven by a focus on empowering "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("underrepresented groups, particularly the Latino community")])]),_vm._v(". In the ever-evolving landscape of education, individuals with a passion for fostering innovation and expertise in "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("STEM (Science, Technology, Engineering, Math)")]),_vm._v(" play a vital role in shaping the future. Alice's journey showcases the transformative power of education and its significant impact on students' lives and the nation's future.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Alice, a "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("high school STEM tacher")]),_vm._v(", has become a beacon of inspiration. Her innovative "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("teaching methods")]),_vm._v(" and dedication to nurturing a strong foundation in Science, Technology, Engineering, and Math among her students have set her apart as an "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("exceptional educator")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("In her "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("EB-2 NIW application she used "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("quotes from Department of Education")]),_vm._v(" and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Senate")]),_vm._v(" to prove that promoting STEM within an unrepresented group of students is in U.S. national interest.")]),_vm._v(" Beyond the conventional classroom setting, Alice actively engages her students in "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("after-school club activities")]),_vm._v(" and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("science competitions")]),_vm._v(", empowering them to explore their potential in these critical fields. Alice's impact extends beyond her local community. Her passion has led her to become a sought-after "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("speaker at various teacher, education, and STEM conferences")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Alice used the "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("EB-2 NIW Starter Kit")]),_vm._v(" to draft her application and reference letters. She applied as a STEM teacher "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("without any citations or quatations")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Recognizing the "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("national importance of Alice's dedication to promoting diversity and inclusivity in STEM education, and unwavering commitment to empowering underrepresented groups, USCIS approved her EB-2 NIW i-140 petition.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("The significance of Alice's EB-2 NIW petition lies in her "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("contributions to STEM education")]),_vm._v(" and her commitment to fostering "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("inclusivity within the Latino community")]),_vm._v(". Her passion for STEM education and dedication to promoting inclusivity serve as a guiding light, where education is a transformative force that can break barriers, empower young minds, and drive progress.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('a',{staticClass:"btn btn-primary",staticStyle:{"margin-top":"3rem","margin-bottom":"2rem"},attrs:{"href":"/science-teacher-sample"}},[_vm._v("SCIENCE TEACHER TEMPLATE")])])])])
}]

export { render, staticRenderFns }