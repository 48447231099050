<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <EB2NIWDenied></EB2NIWDenied>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import EB2NIWDenied from "../blog-components/EB2NIWDenied";


export default {
  name: 'EB2NIWDeniedView',
  components: {
    EB2NIWDenied,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "What if my EB2 NIW is denied? - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "Like any other immigration application, there is always a risk of denial. In this article, we will discuss the possible outcomes if your EB-2 NIW is denied and what you can do."},
      ]
    }
  }
}
</script>
