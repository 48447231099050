<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <div class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </div>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">Use new ETA-9089 form for EB-2 NIW</h1>
          </div>
        </div>
      </div>
    </div>
    
    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">August 22, 2023</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Natalie</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog34-16x9.webp" alt="eb2 niw diy kit">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">Understanding form ETA-9089</h3>

          <div class="blog-paragraph">In this article, we'll delve into the intricacies of <span style="font-weight: 500; font-style: italic">Form ETA-9089</span>, an essential document for those seeking a <a href="/home" style="text-decoration: underline">National Interest Waiver (NIW) Green Card</a>. We will provide you with valuable insights into the updated version of this form, recent changes, and which version you should use. Let's get started!</div>

          <h5>The purpose of form ETA-9089</h5>
          <div class="blog-paragraph">Form ETA-9089, commonly referred to as the <span style="font-weight: 500; font-style: italic">labor certification form</span>, plays a crucial role in the NIW Green Card application process. <span style="background-color: rgba(68, 206, 111, 0.25)">Despite the "waiver of labor certification" aspect of the NIW category, you must still submit this form.</span> Failing to do so may result in a Request for Evidence (RFE) or rejection of your application. It might seem counterintuitive, but <span style="background-color: rgba(68, 206, 111, 0.25)">this form is directed to the <span style="font-weight: 500; font-style: italic">United States Citizenship and Immigration Services (USCIS)</span>, not the Department of Labor.</span></div>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

          <h5>Overview of the NIW process</h5>
          <div class="blog-paragraph">Before diving into the specifics of the new version of Form ETA-9089, let's briefly revisit the NIW Green Card process. Unlike traditional green card categories that involve a lengthy labor certification process, <span style="font-weight: 500; font-style: italic">NIW applicants can bypass these steps</span>. Instead, they can start directly with an <a href="/home" style="text-decoration: underline">I-140 petition</a>. If applying from abroad, the process concludes with consular processing, while applicants in the U.S. file for an adjustment of status using Form I-485.</div>

          <h5>The new version of form ETA-9089</h5>
          <div class="blog-paragraph"><span style="background-color: rgba(68, 206, 111, 0.25)">The new version of Form ETA-9089 was released by the Department of State, featuring a significant overhaul. It's important to note that the USCIS <span style="font-weight: 500; font-style: italic">has not yet provided official guidance</span> on which version to use.</span> Therefore, confusion abounds in determining whether to use the older, expired version or the new one. The key difference is that the new form <span style="font-weight: 500; font-style: italic">includes various appendices, increasing its complexity</span>.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                <router-link class="btn btn-primary" :to="{ name: 'Home', params: { location: 'Templates' }}">EB-2 NIW Templates</router-link>
              </div>
            </div>
          </div>

          <h5>Which version should you use?</h5>
          <div class="blog-paragraph"><span style="background-color: rgba(68, 206, 111, 0.25)">As a rule of thumb, it's wise to rely on <span style="font-weight: 500; font-style: italic">official USCIS guidance</span>. Unfortunately, the USCIS website has not been updated to reflect the new version of Form ETA-9089, and it still <span style="font-weight: 500; font-style: italic">links to the older, expired form</span>.</span> Until USCIS issues clear instructions and updates its policy manual, many applicants keep using the older version of the form.</div>
          <div class="blog-paragraph">While awaiting official guidance, we can anticipate the sections that will likely need completion on the new Form ETA-9089. Expect to fill out the <span style="font-weight: 500; font-style: italic">main form, particularly Part C</span>, where agent information is provided. You may also need to select and complete <span style="font-weight: 500; font-style: italic">Appendix A</span>, which is relevant to NIW applicants and resembles the previous version's educational and job history details. Additionally, <span style="font-weight: 500; font-style: italic">Appendix E</span>, "Final Determination," will be essential for NIW applicants, as it requires your signature.</div>

          <div class="blog-paragraph">Navigating the world of immigration can be challenging, especially when faced with updates to essential forms like Form ETA-9089. However, by <a href="/home" style="text-decoration: underline">staying informed</a> and following official USCIS guidance, you can ensure a smoother NIW Green Card application process.</div>
          <div class="blog-paragraph"><span style="font-weight: 500; font-style: italic">Please be aware that forms and regulations can change over time</span>. The information provided above is <span style="font-weight: 500; font-style: italic">not intended as legal advice</span> but rather represents the author's personal anticipation of potential future developments. Therefore, it is essential to double-check the information in this content and to ensure its accuracy at the time you are reading it.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                  <a href="/eta9089-vs-eta750" class="btn btn-primary">Continue Reading</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>
<script>
import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";

export default {
  name: 'EB2NIWEta9089',
  components: {
    PriceOffer,
    CarouselGreenCard
  }
}
</script>
