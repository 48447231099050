<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <SuccessStoryScienceTeacher></SuccessStoryScienceTeacher>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import SuccessStoryScienceTeacher from "../blog-components/SuccessStoryScienceTeacher";


export default {
  name: 'SuccessStoryScienceTeacherView',
  components: {
    SuccessStoryScienceTeacher,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "EB2 NIW approval for science teacher - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "This success story centers around Alice, a visionary high school teacher, whose unwavering commitment and remarkable contributions have not only elevated her career but have also ignited a spark for STEM education, driven by a focus on empowering underrepresented groups, particularly the Latino community."},
      ]
    }
  }
}
</script>
