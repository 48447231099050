<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <EB2NIWEvidence></EB2NIWEvidence>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import EB2NIWEvidence from "../blog-components/EB2NIWEvidence";


export default {
  name: 'EB2NIWEvidenceView',
  components: {
    EB2NIWEvidence,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "What evidence can you use for EB2 NIW? - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "There are many types of evidence that can be used to support EB2 NIW application. Below you will find some examples. This list is not exhaustive and depends on your profession and type of arguments that you want to use in the main application letter."},
      ]
    }
  }
}
</script>
