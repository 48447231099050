<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <EB2NIWI485></EB2NIWI485>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import EB2NIWI485 from "../blog-components/EB2NIWI485";


export default {
  name: 'EB2NIWI485View',
  components: {
    EB2NIWI485,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "Understanding I-485 application - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "Our business grows organically, all thanks to happy customers who spread the word about their successes. Up until the middle of 2023, we've been able to help out almost 60 applicants applying for the EB-2 NIW. And guess what? Almost half of them have hopped on board our Discord Community."},
      ]
    }
  }
}
</script>
