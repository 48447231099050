<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <SuccessStoryRfeDataAnalyst></SuccessStoryRfeDataAnalyst>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import SuccessStoryRfeDataAnalyst from "../blog-components/SuccessStoryRfeDataAnalyst";


export default {
  name: 'SuccessStoryRfeDataAnalystView',
  components: {
    SuccessStoryRfeDataAnalyst,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "Successful RFE Response for data analyst - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "Aria, a data analyst with extensive expertise in the payment processing industry, initially applied for an EB-2 NIW on her own. During the application process, she encountered a Request for Evidence (RFE) that questioned her qualifications, requiring additional evidence to demonstrate how her unique skill set significantly advances her field and provides substantial benefits at a national level."},
      ]
    }
  }
}
</script>
