<template>
    <!-- Start Navbar Area -->
    <header id="header" :class="['headroom', {'is-sticky': isSticky}]">
        <div class="startp-nav">
<!--            <div class="container">-->
                <nav class="navbar navbar-expand-md navbar-light">
                    <a href="/" class="navbar-brand" v-scroll-to="'#home'">
                        <img width="150px" src="./../resources/selfpetitionusa-logo.svg" alt="Self Petition USA"/>
                    </a>

                    <b-navbar-toggle target="navbarSupportedContent"></b-navbar-toggle>


                    <b-collapse class="collapse navbar-collapse mean-menu" id="navbarSupportedContent" is-nav>
                        <ul class="navbar-nav nav ml-auto">

                            <li class="nav-item">
                                <a href="/" class="nav-link" :class="{ 'active-menu': isActive('home') }" v-scroll-to="'#home'" style="display: flex; align-items: flex-end; height: 20px;">Home</a>
                            </li>
                            <li class="nav-item">
                                <a href="/" class="nav-link" :class="{ 'active-menu': isActive('starterKit') }" v-scroll-to="'#starterKit'" style="display: flex; align-items: flex-end; height: 20px;">Starter Kit &#9997;&#127996;</a>
                            </li>

                            <li class="nav-item">
                                <a href="/" class="nav-link" :class="{ 'active-menu': isActive('selfpetitionAI') }" v-scroll-to="'#selfpetitionAI'" style="display: flex; align-items: flex-end; height: 20px;">Selfpetition AI &#129302;</a>
                            </li>

                            <li class="nav-item">
                                <a href="/" class="nav-link" :class="{ 'active-menu': isActive('rfes') }" v-scroll-to="'#rfes'" style="display: flex; align-items: flex-end; height: 20px;">RFEs <span style="font-size: 18px; margin-left: 2px">&#127919;</span></a>
                            </li>

                            <li class="nav-item">
                                <a href="/" class="nav-link" :class="{ 'active-menu': isActive('reviews') }" v-scroll-to="'#reviews'" style="display: flex; align-items: flex-end; height: 20px;">Reviews</a>
                            </li>
                            <li class="nav-item">
                                <a href="/prices" class="nav-link" style="display: flex; align-items: flex-end; height: 20px;">Pricing</a>
                            </li>
                            <li class="nav-item">
                                <a href="/contact" class="nav-link" style="display: flex; align-items: flex-end; height: 20px;">Contact</a>
                            </li>

                            <li class="nav-item">
                                <a href="/faq" class="nav-link" style="display: flex; align-items: flex-end; height: 20px;">FAQ</a>
                            </li>
                            <li class="nav-item">
                                <a href="/blog/1" class="nav-link" style="display: flex; align-items: flex-end; height: 20px;">Blog</a>
                            </li>
                        </ul>
                    </b-collapse>

                    <div class="others-option"></div>
                </nav>

                <div id="aiBarContainer" class="ai-bar-container">
                    <div class="ai-bar">
                        <img width="40px" src="./../resources/newIcon.webp" alt="Self-Petition USA"/>Draft EB2 NIW with AI <a class="btn-ai" href="/ai">Learn more -></a>
                    </div>
                </div>

            </div>
<!--        </div>-->
    </header>
    <!-- End Navbar Area -->
</template>

<script>

import { mapActions } from 'vuex';

export default {
    name: 'HeaderGreenCard',
    data(){
        return {
            isSticky: false,
            aiBarVisible: false, // This is the reactive data property
            activeSection: '',
        }
    },
    mounted() {
        let navbarToggler = document.querySelector(".navbar-toggler");
        let navItems = document.querySelectorAll(".nav-link");

        navbarToggler.addEventListener("click", () => {
        this.toggleAiBar();
        });

        const removeHiddenHandler = () => {
        this.toggleAiBar();
        };

        navItems.forEach(navItem => {
            navItem.addEventListener("click", removeHiddenHandler, { once: true });
        });

        const that = this;
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY;
            that.isSticky = scrollPos >= 100;

            // Determine the active section based on scroll position
            const sections = ['home', 'starterKit', 'selfpetitionAI', 'rfes', 'reviews'];
            const offsets = sections.map(id => ({
                id,
                offset: document.getElementById(id)?.offsetTop || 0
            }));

            //console.log(offsets);

            let active = '';
            for (let i = offsets.length - 1; i >= 0; i--) {
                if (scrollPos >= offsets[i].offset - 100) { // 100px buffer to switch before reaching the section
                    active = offsets[i].id;
                    break;
                }
            }
            this.activeSection = active;
            //console.log(that.activeSection);
        })
    },
    watch: {
        aiBarVisible(newVal) {
            let aiBarContainer = document.getElementById("aiBarContainer");
            if (newVal) {
                // Logic to execute when aiBar is visible
                aiBarContainer.classList.toggle("hidden");
            } else {
                // Logic to execute when aiBar is hidden
                aiBarContainer.classList.remove("hidden");
            }
        },
    },
    methods: {
        ...mapActions('account', ['apply']),
        sendEvent(eventType) {
          if (window.gtag) {
            window.gtag('event', eventType); // google analytics
          }
        },
        toggleAiBar() {
            this.aiBarVisible = !this.aiBarVisible;
        },
        isActive(sectionId) {
            return this.activeSection === sectionId;
        },
    },
}
</script>
