var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('section',{staticClass:"blog-details-area"},[_c('div',{staticClass:"article-content"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('vue-feather',{attrs:{"type":"clock"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("April 11, 2023")])],1),_c('li',[_c('vue-feather',{attrs:{"type":"user"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("Przemek")])],1)])])])]),_vm._m(1),_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"article-content"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Failing Advanced Degree or Exceptional Ability requirement")]),_c('div',{staticClass:"blog-paragraph"},[_vm._v("Understanding why some cases fail can help applicants avoid making similar mistakes. In this article, we will look at individual EB-2 NIW cases and discuss the lessons we can learn from the most common mistakes applicants make.")]),_c('h5',[_vm._v("Mistake #1: Not meeting basic requirement for Advanced Degree")]),_vm._m(2),_vm._m(3),_vm._m(4),_c('PriceOffer',{staticStyle:{"margin-bottom":"4rem"}}),_c('CarouselGreenCard',{staticStyle:{"margin-bottom":"4rem"}}),_c('h5',[_vm._v("Mistake #2: Not meeting basic requirement for Exceptional Ability")]),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{ name: 'Home', params: { location: 'Templates' }}}},[_vm._v("EB-2 NIW Templates")])],1)])]),_vm._m(9),_vm._m(10),_vm._m(11)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area2"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('div',{staticClass:"article-tags"},[_c('a',{attrs:{"href":"#"}},[_vm._v("EB-2 NIW BLOG")])]),_c('h1',{staticStyle:{"font-weight":"600","margin-top":"30px","text-align":"left"}},[_vm._v("EB-2 NIW cases that fail - avoid these mistakes! (1)")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../resources/blog/blog24-16x9.webp"),"alt":"eb-2 NIW advanced degree DIY"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',{staticStyle:{"margin-bottom":"2rem"}},[_vm._v("USCIS source: "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"https://www.uscis.gov/sites/default/files/err/B5%20-%20Members%20of%20the%20Professions%20holding%20Advanced%20Degrees%20or%20Aliens%20of%20Exceptional%20Ability/Decisions_Issued_in_2023/MAR012023_01B5203.pdf"}},[_vm._v("HERE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("The first case involves a petitioner who is seeking an "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("EB-2 classification as a professional with an advanced degree")]),_vm._v(". The petitioner is a police investigator who holds a bachelor's degree in Accounting. Unfortunately, the USCIS concluded that the petitioner did not qualify for the EB-2 classification with an advanced degree, nor did they provide evidence regarding exceptional ability.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("The lesson we can learn from this case is the importance of providing evidence that meets the requirements for the EB-2 classification. In particular, the USCIS requires evidence of either an advanced degree or exceptional ability. If you're going to apply under the advanced degree category, "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("make sure your degree is related to the field in which you are seeking employment, so your proposed endeavor. In case of doubts, make sure you explain the relationship in your application letter.")]),_vm._v(" Otherwise, your application may be denied.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',{staticStyle:{"margin-bottom":"2rem"}},[_vm._v("USCIS source: "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"https://www.uscis.gov/sites/default/files/err/B5%20-%20Members%20of%20the%20Professions%20holding%20Advanced%20Degrees%20or%20Aliens%20of%20Exceptional%20Ability/Decisions_Issued_in_2023/FEB282023_01B5203.pdf"}},[_vm._v("HERE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("The case in question involves an aviation pilot from Germany who applied for a "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("second preference immigrant classification as an individual of exceptional ability (EB-2 NIW)")]),_vm._v(". The petitioner's initial application was denied because they had not established eligibility as an individual of exceptional ability. The petitioner met three required criteria but "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("did not demonstrate how their professional achievements set them apart from other airline pilots.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("The case highlights an essential lesson to understand when applying for an EB-2 visa. While we must meet at least three criteria listed in the policy manual (see the full list "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"target":"_blank","href":"https://www.uscis.gov/working-in-the-united-states/permanent-workers/employment-based-immigration-second-preference-eb-2"}},[_vm._v("HERE")]),_vm._v("), that does not guarantee qualification under the exceptional ability rule.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("The USCIS evaluates each application in a "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("two-step process")]),_vm._v(". The first step is to determine if the applicant meets at least three of the six criteria. If they do, the second step is to evaluate the applicant's overall profile and determine if they possess a degree of expertise significantly above that ordinarily encountered in their field.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("The petitioner in their RFE tried to add more criteria to their list, hoping that they would approve the case because they met many of the criteria. "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("However, the USCIS requires applicants to demonstrate that they are above average and possess a level of expertise significantly above other individuals in their field. It is not enough to have a long list of accomplishments;")]),_vm._v(" applicants must show that they are among the best in their field and possess skills that are unique and irreplaceable.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("One way to demonstrate exceptional ability is through salary. Applicants can provide evidence of their high salary, which indicates that they are valued and in-demand professionals. However, this alone is not enough to qualify for an EB-2 visa. "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("Remember to provide a comparable average salary relevant to your role, as a benchmark - to show you are significantly above the average profile in your profession.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('a',{staticClass:"btn btn-primary",attrs:{"href":"/eb2niw-mistakes-to-avoid-2"}},[_vm._v("Continue Reading")])])])])
}]

export { render, staticRenderFns }