<template>
    <div>
        <ExternalHeaderGreenCard></ExternalHeaderGreenCard>
        <div>
          <div style="padding-top: 5rem; margin-top: 2rem; margin-bottom: 0rem;" >
            <div class="d-table">
              <div class="d-table-cell">
                <div class="container">
                  <div style="margin-top: 14px">
                    <h2 class="title-mobile-center">Starter Kit</h2>
                    <div class="mobile-center" style="display: flex;">
                      <h5 style="color: #5457c1; margin-bottom: 0px; margin: auto 0">&#9997;&#127996; Customize order</h5>
                    </div>
                  </div>

                  <div  style="margin-top: 2rem; border: 2px solid #5457c1" class="alert alert-light" role="alert">
                    Check <a href="/ai" style="font-weight: 600; text-decoration: underline;">Selfpetition AI</a> or <a href="/contact" style="font-weight: 600; text-decoration: underline;">contact us</a> if your profession is not listed
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="d-table" style="background-color: #f9fafb">
            <div class="d-table-cell">
              <div class="container">

                <div  class="row" style="margin-bottom: 10px;">

                  <div class="col-lg-4" style="margin-top: 20px">
                    <div class="pricing-table" style="padding-top: 10px;padding-bottom: 0px; margin-bottom: 10px">
                      <h5 style="margin-bottom: 0">Select profession</h5>
                      <p style="margin-bottom: 25px; font-size: 12px">One included in price, add <span style="text-decoration: underline">one</span> more for $50</p>

                      <div  style="text-align: left; padding-left: 20px;">
                        <div class="form-check" style="margin-top: 5px">
                          <input class="form-check-input profession-checkbox" type="checkbox" name="profession" id="bankingFinance" value="bankingFinance" v-model="userProfession.bankingFinance" :disabled="checkedCountProfession >= 2 && !userProfession.bankingFinance">
                          <label class="form-check-label" for="bankingFinance">Banking & Finance</label>
                        </div>
                        <div class="form-check" style="margin-top: 5px">
                          <input class="form-check-input profession-checkbox" type="checkbox" name="profession" id="softwareEngineer" value="softwareEngineer" v-model="userProfession.softwareEngineer" :disabled="checkedCountProfession >= 2 && !userProfession.softwareEngineer">
                          <label class="form-check-label" for="softwareEngineer">Software Engineer</label>
                        </div>
                        <div class="form-check" style="margin-top: 5px">
                          <input class="form-check-input profession-checkbox" type="checkbox" name="profession" id="it" value="it" v-model="userProfession.it" :disabled="checkedCountProfession >= 2 && !userProfession.it">
                          <label class="form-check-label" for="it">IT</label>
                        </div>
                        <div class="form-check" style="margin-top: 5px">
                          <input class="form-check-input profession-checkbox" type="checkbox" name="profession" id="dataScientist" value="dataScientist" v-model="userProfession.dataScientist" :disabled="checkedCountProfession >= 2 && !userProfession.dataScientist">
                          <label class="form-check-label" for="dataScientist">Data Scientist</label>
                        </div>
                        <div class="form-check" style="margin-top: 5px">
                          <input class="form-check-input profession-checkbox" type="checkbox" name="profession" id="dataAnalyst" value="dataAnalyst" v-model="userProfession.dataAnalyst" :disabled="checkedCountProfession >= 2 && !userProfession.dataAnalyst">
                          <label class="form-check-label" for="dataAnalyst">Data Analyst</label>
                        </div>
                        <div class="form-check" style="margin-top: 5px">
                          <input class="form-check-input profession-checkbox" type="checkbox" name="profession" id="productProjectManager" value="productProjectManager" v-model="userProfession.productProjectManager" :disabled="checkedCountProfession >= 2 && !userProfession.productProjectManager">
                          <label class="form-check-label" for="productProjectManager">Product & Project Manager</label>
                        </div>
                        <div class="form-check" style="margin-top: 5px">
                          <input class="form-check-input profession-checkbox" type="checkbox" name="profession" id="networkElectricalEngineer" value="networkElectricalEngineer" v-model="userProfession.networkElectricalEngineer" :disabled="checkedCountProfession >= 2 && !userProfession.networkElectricalEngineer">
                          <label class="form-check-label" for="networkElectricalEngineer">Network & Electrical Engineer</label>
                        </div>
                        <div class="form-check" style="margin-top: 5px">
                          <input class="form-check-input profession-checkbox" type="checkbox" name="profession" id="electromechanicalEngineer" value="electromechanicalEngineer" v-model="userProfession.electromechanicalEngineer" :disabled="checkedCountProfession >= 2 && !userProfession.electromechanicalEngineer">
                          <label class="form-check-label" for="electromechanicalEngineer">Electro-mechanical Engineer</label>
                        </div>
                        <div class="form-check" style="margin-top: 5px">
                          <input class="form-check-input profession-checkbox" type="checkbox" name="profession" id="chemicalEngineer" value="chemicalEngineer" v-model="userProfession.chemicalEngineer" :disabled="checkedCountProfession >= 2 && !userProfession.chemicalEngineer">
                          <label class="form-check-label" for="chemicalEngineer">Chemical Engineer</label>
                        </div>
                        <div class="form-check" style="margin-top: 5px">
                          <input class="form-check-input profession-checkbox" type="checkbox" name="profession" id="marketing" value="marketing" v-model="userProfession.marketing" :disabled="checkedCountProfession >= 2 && !userProfession.marketing">
                          <label class="form-check-label" for="marketing">Marketing Professional</label>
                        </div>
                        <div class="form-check" style="margin-top: 5px">
                          <input class="form-check-input profession-checkbox" type="checkbox" name="profession" id="hrRecruiter" value="hrRecruiter" v-model="userProfession.hrRecruiter" :disabled="checkedCountProfession >= 2 && !userProfession.hrRecruiter">
                          <label class="form-check-label" for="hrRecruiter">HR & Recruiter</label>
                        </div>
                        <div class="form-check" style="margin-top: 5px">
                          <input class="form-check-input profession-checkbox" type="checkbox" name="profession" id="scienceTeacher" value="scienceTeacher" v-model="userProfession.scienceTeacher" :disabled="checkedCountProfession >= 2 && !userProfession.scienceTeacher">
                          <label class="form-check-label" for="scienceTeacher">Science Teacher</label>
                        </div>
                        <div class="form-check" style="margin-top: 5px">
                          <input class="form-check-input profession-checkbox" type="checkbox" name="profession" id="physicianDentist" value="physicianDentist" v-model="userProfession.physicianDentist" :disabled="checkedCountProfession >= 2 && !userProfession.physicianDentist">
                          <label class="form-check-label" for="physicianDentist">Physician & Dentist</label>
                        </div>
                        <div class="form-check" style="margin-top: 5px">
                          <input class="form-check-input profession-checkbox" type="checkbox" name="profession" id="pilot" value="pilot" v-model="userProfession.pilot" :disabled="checkedCountProfession >= 2 && !userProfession.pilot">
                          <label class="form-check-label" for="pilot">Pilot</label>
                        </div>
                        <div class="form-check" style="margin-top: 5px">
                          <input class="form-check-input profession-checkbox" type="checkbox" name="profession" id="aeronauticalEngineer" value="aeronauticalEngineer" v-model="userProfession.aeronauticalEngineer" :disabled="checkedCountProfession >= 2 && !userProfession.aeronauticalEngineer">
                          <label class="form-check-label" for="aeronauticalEngineer">Pilot & Aeronautical Engineer</label>
                        </div>
                        <div class="form-check" style="margin-top: 5px">
                          <input class="form-check-input profession-checkbox" type="checkbox" name="profession" id="techEntrepreneur" value="techEntrepreneur" v-model="userProfession.techEntrepreneur" :disabled="checkedCountProfession >= 2 && !userProfession.techEntrepreneur">
                          <label class="form-check-label" for="techEntrepreneur">Tech Entrepreneur</label>
                        </div>
                        <div class="form-check" style="margin-top: 5px">
                          <input class="form-check-input profession-checkbox" type="checkbox" name="profession" id="businessOwner" value="businessOwner" v-model="userProfession.businessOwner" :disabled="checkedCountProfession >= 2 && !userProfession.businessOwner">
                          <label class="form-check-label" for="businessOwner">Brick-and-mortar Business Owner</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input profession-checkbox" type="checkbox" name="profession" id="general" value="general" v-model="userProfession.general" :disabled="checkedCountProfession >= 2 && !userProfession.general">
                          <label class="form-check-label" for="general" style="margin-right: 3px">If not listed above:</label>
                          <input type="text" id="userProfessionInput" v-model="userProfession.userInput" :disabled="checkedCountProfession >= 2 && !userProfession.general" />
                        </div>
                      </div>
                      
                      <div :class="{ nonvisible: checkedCountProfession == 1 }" style="padding-bottom: 1px;">
                        <div style="padding-left:20px; padding-right:20px;">
                          <div v-if="checkedCountProfession == 2 || checkedCountProfession == 1" class="alert alert-warning" role="alert" style="margin-top: 10px;">
                            <div style="font-weight: 500">Maximum amount reached</div>
                          </div>
                          <div v-if="checkedCountProfession == 0" class="alert alert-warning" role="alert" style="margin-top: 10px;">
                            <div style="font-weight: 500">Select a profession above</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-8"  style="margin-top: 20px;">
                    <div  class="row" style="height: 100%">
                      <div class="col-lg-6">
                        <div class="pricing-table" style="padding-top: 10px;padding-bottom: 0px">
                          <h5 style="margin-bottom: 0px">Select employment type</h5>
                          <p style="margin-bottom: 25px; font-size: 12px">One included in price, add <span style="text-decoration: underline">one</span> more for $25</p>

                          <div  style="text-align: left; padding-left: 20px;">
                            <div class="form-check" style="margin-top: 5px">
                              <input class="form-check-input employment-checkbox" type="checkbox" name="employment" id="employee" value="employee" v-model="userEmployment.employee" :disabled="checkedCountEmployment >= 2 && !userEmployment.employee">
                              <label class="form-check-label" for="employee">Employee</label>
                            </div>
                            <div class="form-check" style="margin-top: 5px">
                              <input class="form-check-input employment-checkbox" type="checkbox" name="employment" id="entrepreneur" value="entrepreneur" v-model="userEmployment.entrepreneur" :disabled="checkedCountEmployment >= 2 && !userEmployment.entrepreneur">
                              <label class="form-check-label" for="entrepreneur">Entrepreneur or Business Owner</label>
                            </div>
                            <div class="form-check" style="margin-top: 5px">
                              <input class="form-check-input employment-checkbox" type="checkbox" name="employment" id="consultant" value="consultant" v-model="userEmployment.consultant" :disabled="checkedCountEmployment >= 2 && !userEmployment.consultant">
                              <label class="form-check-label" for="consultant">Consultant</label>
                            </div>
                          </div>

                          <div :class="{ nonvisible: checkedCountEmployment == 1 }" style="padding-bottom: 1px;">
                            <div style="padding-left:20px; padding-right:20px;">
                              <div v-if="checkedCountEmployment == 2 || checkedCountEmployment == 1" class="alert alert-warning" role="alert" style="margin-top: 10px;">
                                <div style="font-weight: 500">Maximum amount reached</div>
                              </div>
                              <div v-if="checkedCountEmployment == 0" class="alert alert-warning" role="alert" style="margin-top: 10px;">
                                <div style="font-weight: 500">Select an employment type above</div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="pricing-table" style="margin-top:20px; padding-top: 10px;padding-bottom: 10px">
                          <h5 style="margin-bottom: 0">Select add-ons</h5>
                          <p style="margin-bottom: 25px; font-size: 12px">Enrich your Starter Kit</p>
                          
                          <div  style="text-align: left; padding-left: 20px;padding-right: 20px;">
                            <div class="form-check" style="margin-top: 5px; width: 100%">
                              <input class="form-check-input addons-checkbox" type="checkbox" name="addOn" id="aos" value="aos" v-model="userAddons.aos">
                              <label class="form-check-label"  style="display: flex;flex-direction: row; justify-content: space-between;" for="aos">Adjustment of Status <div>$59</div></label>
                            </div>
                            <div class="form-check" style="margin-top: 5px">
                              <input class="form-check-input addons-checkbox" type="checkbox" name="addOn" id="forms9089750" value="forms9089750" v-model="userAddons.forms9089750">
                              <label class="form-check-label" style="display: flex;flex-direction: row; justify-content: space-between;" for="forms9089750">Forms 9089 and 750 <div>$39</div></label>
                            </div>
                            <div class="form-check" style="margin-top: 5px">
                              <input class="form-check-input addons-checkbox" type="checkbox" name="addOn" id="consultation" value="consultation" v-model="userAddons.consultation">
                              <label class="form-check-label"  style="display: flex;flex-direction: row; justify-content: space-between;" for="consultation">30min Consultation <div >$80</div></label>
                            </div>
                          </div>
                        </div>

                        <div class="pricing-table" style="margin-top:20px; padding-top: 10px; padding-bottom: 0px">
                          <h5 style="margin-bottom: 0">E-mail to send Starter Kit</h5>
                          <p style="margin-bottom: 25px; font-size: 12px">Where you want to receive documents</p>

                          <div class="form-group" style="margin-left: 20px; margin-right: 20px">
                            <input type="email" class="form-control" id="clientEmail" aria-describedby="clientEmail" placeholder="E-mail to receive Starter Kit" style="border-radius: 5px; border-color: rgb(118, 118, 118);" v-model="userData.email" v-on:input="clearEmailError()">
                            <div :class="{ nonvisible: isEmail || (!isEmail && !submitted) }" style="padding-bottom: 1px;">
                              <div style="padding-left:20px; padding-right:20px;">
                                <div class="alert alert-warning" role="alert" style="margin-top: 14px;">
                                  <div style="font-weight: 500">Enter a valid email</div>
                                </div>
                              </div>
                            </div>
                          </div>  
                        </div>  
                      </div>

                      <div class="col-lg-6">
                        <div v-if="!showWaitingGif" class="pricing-table" style="padding-top: 10px;padding-left: 10px;padding-right: 10px; padding-bottom: 1px">
                          <h5 style="margin-bottom: 10px">Order summary</h5>

                          <div style="text-align: left; font-size: 12px; padding: 10px; border: #0f054b; border:1px; border-style:solid">
                            <div style="margin-bottom: 2px">Starter Kit<span style="float:right">360$</span></div>
                            <div v-if="orderItems.has('SecondProfession')" style="margin-bottom: 2px">Second Profession (Cover letter + NIW Builder) <span style="float:right">50$</span></div>
                            <div v-if="orderItems.has('SecondEmploymentType')" style="margin-bottom: 2px">Second Employment (NIW Builder)  <span style="float:right">25$</span></div>
                            <div v-if="orderItems.has('AOS')" style="margin-bottom: 2px">Adjustment of Status <span style="float:right">59$</span></div>
                            <div v-if="orderItems.has('Forms')" style="margin-bottom: 2px">Forms ETA-9089 & ETA-750 <span style="float:right">39$</span></div>
                            <div v-if="orderItems.has('Consultation')" style="margin-bottom: 2px">Consultation (30min)<span style="float:right">80$</span></div>

                            <h6 style="margin-top: 10px">Total</h6>
                            <div v-if="!promoApplied">
                              <hr style=" border: none;border-top: 2px dotted;height: 1px;width: 100%;">
                              <div>Total order: <span style="float:right">{{ totalOrder.toFixed(2) }}$</span></div>
                              <hr style=" border: none;border-top: 2px dotted;height: 1px;width: 100%;">
                            </div>

                            <div v-if="promoApplied">
                              <hr style=" border: none;border-top: 2px dotted;height: 1px;width: 100%;">
                              <div>Subtotal: <span style="float:right">{{ totalOrder.toFixed(2) }}$</span></div>
                              <div>Promo* ({{parseInt(discountValue * 100)}}% x ${{Order.StarterKit}} Starter Kit): <span style="float:right; color: #ff301f">-{{promoDiscount}}$</span></div>
                              <hr style=" border: none;border-top: 2px dotted;height: 1px;width: 100%;">
                              <div>Total order: <span style="float:right">{{ (totalOrder - promoDiscount).toFixed(2) }}$</span></div>
                              <hr style=" border: none;border-top: 2px dotted;height: 1px;width: 100%;">
                              <div style="color: #a9a9a9">*Multiple discounts cannot be combined</div>
                            </div>

                            <form class="form-inline" v-if="!promoApplied"  @submit.prevent="applyPromoCode(promoCode)">
                              <input type="text" class="form-control"  id="inputPassword2"   v-model="promoCode" placeholder="Promo code"  style="margin-top: 10px;margin-right: 10px;border-radius: 5px; border-color: rgb(118, 118, 118);">
                              <button type="submit" style="height: 45px; font-size: 13px; margin-top: 10px; padding: 5px 25px;" class="btn-success button-order">Apply</button>
                            </form>
                              <p v-if="codeWarning" style="color: #db3040; margin-top: 10px;">Invalid promo code</p>

                          </div>
                          
                          <form  @submit.prevent="submit()">
                            <button type="submit" style="background-color: #ff4e00; height: 40px; font-size: 13px; margin-top: 30px; padding: 5px; margin-bottom: 10px; width: 100%;" class="btn-success button-order">Go to payment</button>
                            <div v-if="submitted && (checkedCountProfession == 0 || checkedCountEmployment == 0 || !isEmail)" class="alert alert-warning" role="alert" style="margin-top: 4px;">
                              <div style="font-weight: 500">Oops... check your selection</div>
                            </div>
                          </form>
                        </div>

                        <div v-if="showWaitingGif" style="padding: 3rem 0rem; display: flex; flex-direction: column; align-items: center; justify-content: center;">
                          <img src="./resources/musical.gif" style="width: 15rem">
                          <div  class="alert alert-primary text-center" role="alert">
                            <div style="font-weight: 600; font-size: 1.3em">DO NOT REFRESH</div>
                            <div style="margin-top: 8px">Directing you to payment</div>
                          </div>
                        </div>
                      
                        <div class="pricing-table" style="margin-top:20px; padding: 10px 10px 20px;">
                          <h5 style="margin-bottom: 15px">Payment methods</h5>
                          <div class="Box-root Flex-flex Flex-direction--column Flex-justifyContent--flexStart Flex-wrap--nowrap">
                            <div class="Box-root Box-hideIfEmpty Margin-top--12 Margin-left--12" style="pointer-events: auto">
                              <div class="Box-root" style="pointer-events: none;">
                                <div style="display: flex" class="Box-root Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--wrap" >
                                  <div class="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8" style="pointer-events: auto;"><div class="Box-root" style="pointer-events: none;"><div class="Box-root Flex-flex Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap" style="display: flex;margin-right: 10px;margin-top: 10px"><div class="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4" style="pointer-events: auto;"><div class="SVGInline SVGInline--cleaned SVG BrandIcon BrandIcon--size--20 Box-root Flex-flex"><svg class="SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg BrandIcon--size--20-svg" height="20" width="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#D8DEE4" d="M0 0h32v32H0z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M6 10.375C6 9.339 6.84 8.5 7.875 8.5h16.25C25.16 8.5 26 9.34 26 10.375v11.25c0 1.035-.84 1.875-1.875 1.875H7.875A1.875 1.875 0 0 1 6 21.625v-11.25Zm1.875 0h16.25v1.875H7.875v-1.875Zm16.25 3.75v7.5H7.875v-7.5h16.25Z" fill="#474E5A"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M14.75 18.813c0-.518.42-.938.938-.938h5.624a.937.937 0 1 1 0 1.875h-5.625a.937.937 0 0 1-.937-.938Z" fill="#474E5A"></path></svg></div></div><div class="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4" style="pointer-events: auto;"><span class="Text-color--default Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline"><span style="margin-left: 5px">Card</span></span></div></div></div></div>
                                  <div class="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8" style="pointer-events: auto;"><div class="Box-root" style="pointer-events: none;"><div class="Box-root Flex-flex Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap" style="display: flex;margin-right: 10px;margin-top: 10px"><div class="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4" style="pointer-events: auto;"><div class="SVGInline SVGInline--cleaned SVG BrandIcon BrandIcon--size--20 Box-root Flex-flex"><svg class="SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg BrandIcon--size--20-svg" height="20" width="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#000" d="M0 0h32v32H0z"></path><path d="M19.308 7.63c-.8.95-2.082 1.698-3.367 1.592-.16-1.285.469-2.65 1.204-3.497.8-.975 2.204-1.672 3.341-1.725.132 1.338-.387 2.653-1.178 3.63Zm1.166 1.847c-1.86-.106-3.448 1.057-4.333 1.057-.894 0-2.244-1.003-3.714-.976-1.91.026-3.686 1.11-4.661 2.838-2.004 3.456-.522 8.57 1.416 11.383.947 1.393 2.082 2.921 3.58 2.865 1.418-.05 1.976-.922 3.689-.922 1.722 0 2.216.922 3.714.897 1.55-.025 2.526-1.393 3.473-2.787 1.082-1.578 1.523-3.119 1.55-3.2-.025-.025-2.991-1.165-3.02-4.593-.025-2.865 2.339-4.23 2.445-4.312-1.335-1.981-3.417-2.196-4.14-2.25Z" fill="#fff"></path></svg></div></div><div class="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4" style="pointer-events: auto;"><span class="Text-color--default Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline"><span style="margin-left: 5px">Apple Pay</span></span></div></div></div></div>
                                  <div class="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8" style="pointer-events: auto;"><div class="Box-root" style="pointer-events: none;"><div class="Box-root Flex-flex Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap" style="display: flex;margin-right: 10px;margin-top: 10px"><div class="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4" style="pointer-events: auto;"><div class="SVGInline SVGInline--cleaned SVG BrandIcon BrandIcon--size--20 Box-root Flex-flex"><svg class="SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg BrandIcon--size--20-svg" height="20" width="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#F5F6F8" d="M0 0h32v32H0z"></path><path d="M27.765 13.823h-11.53v4.628h6.611a5.444 5.444 0 0 1-.84 2.068 5.551 5.551 0 0 1-1.607 1.572v3.011h3.934a11.74 11.74 0 0 0 2.788-4.044c.944-2.285 1.055-4.815.644-7.235Z" fill="#4285F4"></path><path d="M16.235 28c3.295 0 6.085-1.055 8.098-2.898l-3.934-3.01a7.42 7.42 0 0 1-4.164 1.145 7.409 7.409 0 0 1-4.238-1.39 7.198 7.198 0 0 1-2.625-3.553H5.3v3.078a12.104 12.104 0 0 0 4.511 4.837A12.405 12.405 0 0 0 16.235 28Z" fill="#34A853"></path><path d="M9.372 18.294a7.069 7.069 0 0 1 0-4.606v-3.1H5.3A11.847 11.847 0 0 0 4 15.98c0 1.872.445 3.719 1.3 5.392l4.072-3.078Z" fill="#FBBC04"></path><path d="M16.235 8.745a6.7 6.7 0 0 1 4.69 1.797l3.5-3.438a12.304 12.304 0 0 0-4.874-2.65 12.454 12.454 0 0 0-5.565-.252 12.352 12.352 0 0 0-5.102 2.195 12.077 12.077 0 0 0-3.584 4.19l4.072 3.1a7.198 7.198 0 0 1 2.625-3.551 7.409 7.409 0 0 1 4.238-1.391Z" fill="#EA4335"></path></svg></div></div><div class="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4" style="pointer-events: auto;"><span class="Text-color--default Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline"><span style="margin-left: 5px">Google Pay</span></span></div></div></div></div>
                                  <div class="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8" style="pointer-events: auto;"><div class="Box-root" style="pointer-events: none;"><div class="Box-root Flex-flex Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap" style="display: flex;margin-right: 10px;margin-top: 10px"><div class="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4" style="pointer-events: auto;"><div class="SVGInline SVGInline--cleaned SVG BrandIcon BrandIcon--size--20 Box-root Flex-flex"><svg class="SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg BrandIcon--size--20-svg" height="20" width="20" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h32v32H0z" fill="#1c9fe5"></path><path d="M23.104 18.98a142.494 142.494 0 0 0 11.052 3.848c2.044.85 0 5.668-2.159 4.674-2.444-1.066-7.359-3.245-11.097-5.108C18.822 24.842 15.556 28 10.907 28 6.775 28 4 25.568 4 21.943c0-3.053 2.11-6.137 6.82-6.137 2.697 0 5.47.766 8.785 1.922a25.007 25.007 0 0 0 1.529-3.838l-11.981-.006v-1.848l6.162.015V9.63H7.808V7.81l7.507.006V5.115c0-.708.38-1.115 1.042-1.115h3.14v3.827l7.442.005v1.805h-7.44v2.431l6.088.016s-.754 3.904-2.483 6.897zM5.691 21.79v-.004c0 1.736 1.351 3.489 4.64 3.489 2.54 0 5.028-1.52 7.408-4.522-3.181-1.592-4.886-2.357-7.348-2.357-2.394 0-4.7 1.164-4.7 3.394z" fill="#fff" fill-rule="nonzero"></path></g></svg></div></div><div class="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4" style="pointer-events: auto;"><span class="Text-color--default Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline"><span style="margin-left: 5px">Alipay</span></span></div></div></div></div>
                                  <div class="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8" style="pointer-events: auto;"><div class="Box-root" style="pointer-events: none;"><div class="Box-root Flex-flex Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap" style="display: flex;margin-right: 10px;margin-top: 10px"><div class="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4" style="pointer-events: auto;"><div class="SVGInline SVGInline--cleaned SVG BrandIcon BrandIcon--size--20 Box-root Flex-flex"><svg class="SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg BrandIcon--size--20-svg" height="20" width="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#00D64F" d="M0 0h32v32H0z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M16.892 10.264c1.782 0 3.49.693 4.607 1.643.282.24.705.24.97-.018l1.328-1.293a.646.646 0 0 0-.032-.968 10.625 10.625 0 0 0-3.56-1.92l.416-1.903C20.71 5.39 20.38 5 19.94 5h-2.567a.69.69 0 0 0-.68.528l-.376 1.693c-3.412.163-6.303 1.796-6.303 5.147 0 2.9 2.392 4.144 4.917 5.004 2.393.86 3.655 1.18 3.655 2.391 0 1.243-1.262 1.976-3.123 1.976-1.697 0-3.475-.537-4.854-1.841a.713.713 0 0 0-.974-.002l-1.427 1.35a.652.652 0 0 0 .004.96c1.113 1.036 2.522 1.786 4.13 2.206l-.391 1.763c-.092.413.234.803.676.806l2.57.019a.689.689 0 0 0 .686-.53l.371-1.695c4.085-.242 6.586-2.372 6.586-5.49 0-2.868-2.492-4.08-5.516-5.068-1.728-.606-3.224-1.02-3.224-2.263 0-1.21 1.397-1.69 2.792-1.69Z" fill="#fff"></path></svg></div></div><div class="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4" style="pointer-events: auto;"><span class="Text-color--default Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline"><span style="margin-left: 5px">Cash App Pay</span></span></div></div></div></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
        <ExternalFooterGreenCard></ExternalFooterGreenCard>
      </div>

    </div>
</template>

<script>
import ExternalHeaderGreenCard from "./components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "./components/ExternalFooterGreenCard";
import {mapActions} from "vuex";



  export default {
    name: 'CheckoutStarterKit',
    data () {
      return {
        codes : ['W3R0S8', '2X7P4H', 'Y1F9J6', 'L5T0D2', 'R8V3G7', 'B4H9W2', 'Z6P1N7', 'A2V5R0'],
        opti : ['B3U6S1'],
        codeWarning: false,
        Order: {
          StarterKit: 360,
          SecondEmploymentType: 25,
          SecondProfession: 50,
          AOS: 59,
          Forms: 39,
          Consultation: 80
        },
        discountStart: this.generateClosestDatesArray(),
        totalPrice: 0,
        showWaitingGif: false,
        promoCode: '',
        promoApplied: false,
        isEmail: false,
        submitted: false,
        userAddons: {
          aos: false,
          forms9089750: false,
          consultation: false,
        },
        userEmployment: {
          employee: false,
          entrepreneur: false,
          consultant: false
        },
        userProfession: {
          general: false,
          userInput: '',
          bankingFinance: false,
          softwareEngineer: false,
          it: false,
          dataScientist: false,
          dataAnalyst: false,
          productProjectManager: false,
          networkElectricalEngineer: false,
          electromechanicalEngineer: false,
          chemicalEngineer: false,
          marketing: false,
          hrRecruiter: false,
          scienceTeacher: false,
          physicianDentist: false,
          pilot: false,
          aeronauticalEngineer: false,
          techEntrepreneur: false,
          businessOwner: false
        },
        userData: {
          email: ''
        },
        paymentLinkMap: this.initPaymentLinkMap()
      }
    },
    components: {
        ExternalHeaderGreenCard,
        ExternalFooterGreenCard
    },
    mounted() {
      this.sendEvent('render-checkout-starterKit');
      
      if (this.$route.query.profession in this.userProfession) {
        this.userProfession[this.$route.query.profession] = true;
      } else {
        this.userProfession['bankingFinance'] = true;
      }
      if (this.$route.query.employmentType in this.userEmployment) {
        this.userEmployment[this.$route.query.employmentType] = true;
      } else {
        this.userEmployment['employee'] = true;
      }
      if(this.isPromo()) {
        const randomIndex = Math.floor(Math.random() * (this.codes.length));
        const randomEntry = this.codes[randomIndex];
        //console.log(randomIndex)
        //console.log(randomEntry)
        this.applyPromoCode(randomEntry)
      }
    },
    watch: {
      'userProfession.general'(val) {
          if (!val) {
            this.userProfession.userInput = '';  // Clear the input field when checkbox is unchecked
          }
      },
      'userProfession.userInput'(newValue) {
        // Check if there is any non-empty value typed in the input.
        if (newValue.trim().length > 0) {
          this.userProfession.general = true; // Enable the checkbox if there's any input
        }
      },
    },
    computed: {
      discountValue() {
        if(this.codes.includes(this.promoCode)){
          return 0.35;
        } else if (this.opti.includes(this.promoCode)) {
          return 0.14;
        } else return 0.0;
      },
      checkedCountProfession() {
        return Object.entries(this.userProfession)
            .filter(([key, value]) => value === true)
            .length
        },
      checkedCountEmployment() {
        return Object.entries(this.userEmployment)
            .filter(([key, value]) => value === true)
            .length
      },
      orderItems() {
        let colorSet = new Set();
        colorSet.add('StarterKit')
        if (this.checkedCountProfession > 1) {
          colorSet.add('SecondProfession')
        }
        if (this.checkedCountEmployment > 1) {
          colorSet.add('SecondEmploymentType')
        }

        if (this.userAddons.aos) {
          colorSet.add('AOS')
        }
        if (this.userAddons.forms9089750) {
          colorSet.add('Forms')
        }
        if (this.userAddons.consultation) {
          colorSet.add('Consultation')
        }
        return colorSet;
      },
      totalOrder() {
        return Array.from(this.orderItems).reduce((sum, item) => {
          // Check if the item exists in the Order constant
          if (item in this.Order) {
            sum += this.Order[item];
          }
          return sum;
        }, 0);
      },
      promoDiscount() {
        const discount  = this.Order.StarterKit * this.discountValue;
        return parseFloat(discount).toFixed(2);
      }
    },
    methods: {
      ...mapActions('account', ['apply']),
        sendEvent(eventType) {
          if (window.gtag) {
            window.gtag('event', eventType); // google analytics
          }
        },
        sendConversion() {
          if (window.gtag) {
            window.gtag('event', 'conversion', {
              'send_to': 'AW-10969381549/QkeJCNKAnbwZEK31ze4o',
              'transaction_id': ''
            }); //ads
          }
        },
        generateClosestDatesArray() {
          const currentDate = new Date();
          const year = currentDate.getFullYear();
          const startDate = new Date(year, 0, 0);
          const endDate = new Date(year + 1, 0, 0);

          const dateArray = [];
          let currentDateCandidate = startDate;

          while (currentDateCandidate < endDate) {
            dateArray.push(new Date(currentDateCandidate));
            currentDateCandidate.setDate(currentDateCandidate.getDate() + 15);
          }

          // Find the closest date that is before the current date
          let closestDate = dateArray[0];
          for (const date of dateArray) {
            if (date < currentDate && date > closestDate) {
              closestDate = date;
            }
          }

          return closestDate;
        },
        isPromo() {
          // const tenDaysInMilliseconds = 10 * 24 * 60 * 60 * 1000; // 10 days in milliseconds
          // //console.log(tenDaysInMilliseconds > (new Date() - this.discountStart))
          // return tenDaysInMilliseconds > (new Date() - this.discountStart)
          return false;
        },
        applyPromoCode(code) {
          if(this.codes.includes(code) || this.opti.includes(code)) {
            this.codeWarning = false;
            this.promoApplied = true;
            this.promoCode = code;
          } else {
            this.codeWarning = true;
            this.promoCode = ''
          }
        },
        validateEmail () {
          this.isEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.userData.email);
        },
        clearEmailError () {
          this.isEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.userData.email);
        },
        initPaymentLinkMap () {
          const myMap = new Map();
          const pro =  ['StarterKit'].join(',');
          const proEmployment =  ['StarterKit', 'SecondEmploymentType'].sort().join(',');
          const proProfession =  ['StarterKit', 'SecondProfession'].sort().join(',')
          const proProfessionEmployment =  ['StarterKit', 'SecondProfession', 'SecondEmploymentType'].sort().join(',');
          const proAos = ['StarterKit', 'AOS'].sort().join(',');
          const proForms = ['StarterKit', 'Forms'].sort().join(',');
          const proConsultation = ['StarterKit', 'Consultation'].sort().join(',');
          const proAosForms = ['StarterKit', 'AOS', 'Forms'].sort().join(',');
          const proAosConsultation = ['StarterKit', 'AOS', 'Consultation'].sort().join(',');
          const proFormsConsultation = ['StarterKit', 'Forms', 'Consultation'].sort().join(',');

          const proAosFormsConsultation = ['StarterKit', 'AOS', 'Forms', 'Consultation'].sort().join(',');
          const proProfessionAos = ['StarterKit', 'AOS', 'SecondProfession'].sort().join(',');
          const proProfessionForms = ['StarterKit', 'Forms', 'SecondProfession'].sort().join(',');
          const proProfessionConsultation = ['StarterKit', 'Consultation', 'SecondProfession'].sort().join(',');
          const proProfessionAosForms = ['StarterKit', 'AOS', 'SecondProfession', 'Forms'].sort().join(',');
          const proProfessionAosConsultation = ['StarterKit', 'AOS', 'SecondProfession', 'Consultation'].sort().join(',');
          const proProfessionFormsConsultation = ['StarterKit', 'Forms', 'SecondProfession', 'Consultation'].sort().join(',');
          const proProfessionAosFormsConsultation = ['StarterKit',  'AOS', 'Forms', 'SecondProfession', 'Consultation'].sort().join(',');

          const proEmploymentAos =  ['StarterKit', 'SecondEmploymentType', 'AOS'].sort().join(',');
          const proEmploymentForms =  ['StarterKit', 'SecondEmploymentType', 'Forms'].sort().join(',');
          const proEmploymentConsultation =  ['StarterKit', 'SecondEmploymentType', 'Consultation'].sort().join(',');
          const proEmploymentAosForms =  ['StarterKit', 'SecondEmploymentType', 'AOS', 'Forms'].sort().join(',');
          const proEmploymentAosConsultation =  ['StarterKit', 'SecondEmploymentType', 'AOS', 'Consultation'].sort().join(',');
          const proEmploymentFormsConsultation =  ['StarterKit', 'SecondEmploymentType', 'Forms', 'Consultation'].sort().join(',');
          const proEmploymentAosFormsConsultation =  ['StarterKit', 'SecondEmploymentType', 'AOS', 'Forms', 'Consultation'].sort().join(',');

          const proProfessionEmploymentAos =  ['StarterKit', 'SecondProfession', 'AOS', 'SecondEmploymentType'].sort().join(',');
          const proProfessionEmploymentForms =  ['StarterKit', 'SecondProfession', 'Forms', 'SecondEmploymentType'].sort().join(',');
          const proProfessionEmploymentAosForms =  ['StarterKit', 'SecondProfession', 'Forms', 'AOS', 'SecondEmploymentType'].sort().join(',');
          const proProfessionEmploymentConsultation =  ['StarterKit', 'SecondProfession',  'SecondEmploymentType', 'Consultation'].sort().join(',');
          const proProfessionEmploymentAosConsultation =  ['StarterKit', 'SecondProfession',  'SecondEmploymentType', 'AOS', 'Consultation'].sort().join(',');
          const proProfessionEmploymentFormsConsultation =  ['StarterKit', 'SecondProfession',  'SecondEmploymentType', 'Forms', 'Consultation'].sort().join(',');
          const proProfessionEmploymentAosFormsConsultation =  ['StarterKit', 'SecondProfession',  'SecondEmploymentType', 'AOS', 'Forms', 'Consultation'].sort().join(',');


          myMap.set(pro, 'https://pay.selfpetitionusa.com/b/7sIaIe7RR5Exbra9AG');
          myMap.set(proEmployment, 'https://pay.selfpetitionusa.com/b/aEUdUq5JJ0kd66Q7sD');
          myMap.set(proProfession, 'https://pay.selfpetitionusa.com/b/aEU6rYgon9UNgLu7sE');
          myMap.set(proProfessionEmployment, 'https://pay.selfpetitionusa.com/b/28o03A5JJ0kdan64gv');
          myMap.set(proAos, 'https://pay.selfpetitionusa.com/b/28o9Ea1tt4At52M6oG');
          myMap.set(proForms, 'https://pay.selfpetitionusa.com/b/bIY6rY0pp2sl0Mw8wP');
          myMap.set(proConsultation, 'https://pay.selfpetitionusa.com/b/00gg2y0ppc2V66Q28s');
          myMap.set(proAosForms, 'https://pay.selfpetitionusa.com/b/4gw03Ab43aYR52M6oJ');
          myMap.set(proAosConsultation, 'https://pay.selfpetitionusa.com/b/6oE9Ea9ZZeb366Q9AW');
          myMap.set(proFormsConsultation, 'https://pay.selfpetitionusa.com/b/4gwcQm5JJ4AtgLubJ5');

          myMap.set(proAosFormsConsultation, 'https://pay.selfpetitionusa.com/b/6oE03A0pp4At7aUfZm');
          myMap.set(proProfessionAos, 'https://pay.selfpetitionusa.com/b/6oEaIe9ZZc2Vcve4gF');
          myMap.set(proProfessionForms, 'https://pay.selfpetitionusa.com/b/3cs5nU9ZZ0kdgLu14u');
          myMap.set(proProfessionConsultation, 'https://pay.selfpetitionusa.com/b/7sI4jQc875Ex66QaF5');
          myMap.set(proProfessionAosForms, 'https://pay.selfpetitionusa.com/b/6oE7w2gonaYR0MwdRi');
          myMap.set(proProfessionAosConsultation, 'https://pay.selfpetitionusa.com/b/9AQg2y3BB7MF3YI8wZ');
          myMap.set(proProfessionFormsConsultation, 'https://pay.selfpetitionusa.com/b/3cs17Egon2sl3YIcNg');
          myMap.set(proProfessionAosFormsConsultation, 'https://pay.selfpetitionusa.com/b/00g7w2c872sl0MwdRl');

          myMap.set(proEmploymentAos, 'https://pay.selfpetitionusa.com/b/aEU9Eab430kd7aUcNi');
          myMap.set(proEmploymentForms, 'https://pay.selfpetitionusa.com/b/4gwaIe7RR7MFbra14B');
          myMap.set(proEmploymentConsultation, 'https://pay.selfpetitionusa.com/b/bIY6rY7RR9UNbrafZw');
          myMap.set(proEmploymentAosForms, 'https://pay.selfpetitionusa.com/b/14k6rYdcb5ExfHqeVt');
          myMap.set(proEmploymentAosConsultation, 'https://pay.selfpetitionusa.com/b/eVa4jQegf7MFdzi9Ba');
          myMap.set(proEmploymentFormsConsultation, 'https://pay.selfpetitionusa.com/b/28og2y6NN7MF0MwbJj');
          myMap.set(proEmploymentAosFormsConsultation, 'https://pay.selfpetitionusa.com/b/cN25nUb43gjbfHqbJk');

          myMap.set(proProfessionEmploymentAos, 'https://pay.selfpetitionusa.com/b/eVa6rY9ZZc2V1QAbJl');
          myMap.set(proProfessionEmploymentForms, 'https://pay.selfpetitionusa.com/b/3cseYu5JJd6ZbrabJm');
          myMap.set(proProfessionEmploymentAosForms, 'https://pay.selfpetitionusa.com/b/eVa3fMgongjb52M28N');
          myMap.set(proProfessionEmploymentConsultation, 'https://pay.selfpetitionusa.com/b/5kA9Eab434At66Q6p4');
          myMap.set(proProfessionEmploymentAosConsultation, 'https://pay.selfpetitionusa.com/b/cN27w20ppaYRgLudRx');
          myMap.set(proProfessionEmploymentFormsConsultation, 'https://pay.selfpetitionusa.com/b/00g7w29ZZ8QJ52MbJq');
          myMap.set(proProfessionEmploymentAosFormsConsultation, 'https://pay.selfpetitionusa.com/b/4gw6rYgon8QJ2UE00J');
          return myMap;
        },
        submit() {
          //console.log(Array.from(this.orderItems).sort().join(','));
          //console.log(this.paymentLinkMap);
          this.submitted = true;
          this.validateEmail();
          if (
              (this.checkedCountProfession > 0) && (this.checkedCountEmployment > 0) && this.isEmail
          ) {
            this.sendConversion();
            this.showWaitingGif = true;
            this.apply({
              userData: this.userData,
              userProfession: this.userProfession,
              userEmployment: this.userEmployment,
              userAddons: this.userAddons,
              });
            var query = ''
            if(this.promoApplied)
                query = '?prefilled_email=' + this.userData.email + '&prefilled_promo_code=' + this.promoCode;
            
            setTimeout(() => {
              window.location.href = this.paymentLinkMap.get(Array.from(this.orderItems).sort().join(',')) + query
            }, 1000);
          }
        }
      }
    }
</script>
