var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('section',{staticClass:"blog-details-area"},[_c('div',{staticClass:"article-content"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('vue-feather',{attrs:{"type":"clock"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("February 27, 2023")])],1),_c('li',[_c('vue-feather',{attrs:{"type":"user"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("Natalie")])],1)])])])]),_vm._m(1),_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"article-content"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Success story: EB-2 NIW")]),_vm._m(2),_c('div',{staticClass:"signupSelfPetition row justify-content-center align-items-center"},[_c('div',{staticClass:"col-xl-6 col-lg-7"},[_c('signup-self-petition')],1)]),_vm._m(3),_vm._m(4),_c('PriceOffer',{staticStyle:{"margin-bottom":"4rem"}}),_c('CarouselGreenCard',{staticStyle:{"margin-bottom":"4rem"}}),_vm._m(5),_vm._m(6),_vm._m(7)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area2"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('span',{staticClass:"article-tags"},[_c('a',{attrs:{"href":"#"}},[_vm._v("SEB-2 NIW BLOG")])]),_c('span',{staticClass:"article-tags2"},[_c('a',{attrs:{"href":"#"}},[_vm._v("SUCCESS STORY")])]),_c('h1',{staticStyle:{"font-weight":"600","margin-top":"30px","text-align":"left"}},[_vm._v("EB-2 NIW approval for marketing manager")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../resources/blog/blog18-16x9.webp"),"alt":"NIW template marketing"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("Anna, a "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("brand marketing manager")]),_vm._v(" with extensive experience in developing and implementing "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("marketing strategies for multiple multinational companies")]),_vm._v(" applied for EB-2 NIW (National Interest Waiver) immigration status. As a brand marketing manager, Anna has successfully orchestrated high-profile campaigns, resulting in substantial "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("revenue growth")]),_vm._v(" for the companies she has worked with")]),_vm._v(". Her expertise in leveraging digital platforms and market trends has not only elevated the visibility of the brands she represents but has also contributed significantly to the overall competitiveness of the global marketplace. Anna's experience demonstrated that her work had a significant "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("impact on the respective industries, leading to increased sales and market share")]),_vm._v(" for her clients.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Her petition highlighted the unique skills, expertise, and experience in developing and executing innovative marketing strategies that resulted in "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("improved brand recognition, customer loyalty and additional sales volume")]),_vm._v(" which increased US GDP. Anna also provided evidence of her "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("active involvement in professional organizations and her contributions to the advancement of marketing knowledge while speaking at conferences")]),_vm._v(". She based her petition on templates and examples provided in "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("EB-2 NIW Starter Kit")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Check "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("Starter Kit for EB-2 NIW")]),_vm._v(", which includes self-petition Green Card templates for professionals:")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("After careful review of the evidence, the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("USCIS approved Anna's EB-2 NIW petition")]),_vm._v(", allowing her to continue her work in the United States. All "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("without the need for a labor certification")]),_vm._v(". Anna's success story serves as an example of the impact and value that skilled professionals in the marketing field can bring to the United States, and the benefits of the EB-2 NIW program for highly skilled individuals.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("With her EB-2 NIW approval, Anna can now "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("pursue her career with increased flexibility and autonomy")]),_vm._v(", unencumbered by the traditional labor certification process. This achievement underscores the USCIS's recognition of her substantial contributions to the national interest, "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("affirming the importance of attracting and retaining top-tier talent")]),_vm._v(" in the ever-evolving landscape of marketing and brand management.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('a',{staticClass:"btn btn-primary",staticStyle:{"margin-top":"3rem","margin-bottom":"2rem"},attrs:{"href":"/marketing-sample"}},[_vm._v("MARKETING PROFESSIONAL TEMPLATE")])])])])
}]

export { render, staticRenderFns }