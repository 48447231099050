<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <div class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </div>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">What is the cost of EB-2 NIW?</h1>
          </div>
        </div>
      </div>
    </div>

    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">January 3, 2023</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Tom</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog5-16x9.webp" alt="EB2 NIW self petition templates">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">EB-2 NIW costs</h3>

          <div class="blog-paragraph">The cost of the EB-2 NIW process varies depending on a number of factors, including the individual's specific circumstances and if you decide to work with a lawyer or file yourself. Some of the expenses associated with the EB-2 NIW process include:</div>

          <div class="blog-paragraph"><span style="font-weight: 500; font-style: italic">Filing fees:</span> The USCIS (U.S. Citizenship and Immigration Services) charges a fee for filing the <span style="font-weight: 500; font-style: italic">Form I-140</span>, which is the Immigrant Petition for Alien Worker and <span style="font-weight: 500; font-style: italic">Form I-485</span>, which is Application to Register Permanent Residence or Adjust Status. <span style="background-color: rgba(68, 206, 111, 0.25)">As of <span style="font-style: italic">February 2023</span>, the fee for the <span style="font-weight: 500; font-style: italic">Form I-140</span> is $700, and <span style="font-weight: 500; font-style: italic">I-485</span> is $1,225 (including biometrics).</span></div>


          <div class="blog-paragraph"><span style="background-color: rgba(68, 206, 111, 0.25)">USCIS fees are likely to increase in the near future. Please follow the Proposed Fee Rule published <a style="font-weight: 600; text-decoration: underline" href="https://www.uscis.gov/newsroom/news-releases/uscis-issues-proposed-rule-to-adjust-certain-immigration-and-naturalization-fees">HERE</a> and <a style="font-weight: 600; text-decoration: underline" href="https://www.federalregister.gov/documents/2023/01/04/2022-27066/us-citizenship-and-immigration-services-fee-schedule-and-changes-to-certain-other-immigration">HERE</a>.</span> The proposal assumes increasing the <span style="font-weight: 500; font-style: italic">I-140</span> fee to $715 ($700 currently) and <span style="font-weight: 500; font-style: italic">I-485</span> fee to $1,540 ($1,225 currently, with biometrics). It also introduces additional fees for the Forms <span style="font-weight: 500; font-style: italic">I-131</span> (Application for Travel Document) and <span style="font-weight: 500; font-style: italic">I-765</span> (Application for Employment Authorization). Before both of them were included free of charge as part of the <span style="font-weight: 500; font-style: italic">I-485</span> fee.</div>

          <img style="margin-top: 2rem; margin-bottom: 1rem" src="../resources/i140.png" />
          <img src="../resources/i485.png" />
          <div style="font-style: italic; margin-bottom: 4rem; font-size: 12px">Source: U.S. Citizenship and Immigration Services Fee Schedule and Changes to Certain Other Immigration Benefit Request Requirements. A Proposed Rule by the Homeland Security Department on 01/04/2023</div>

          <div class="blog-paragraph"><span style="font-weight: 500; font-style: italic">Lawyer fees:</span> Hiring a lawyer is optional, but could be helpful if you are unfamiliar with immigration law, the requirements for the visa or need somebody to guide you through the admin process. Lawyer fees vary widely, so it is important to shop around and get quotes from several different companies if you decide to choose one. <span style="background-color: rgba(68, 206, 111, 0.25)">As authors of this blog, we researched 30+ lawyers while preparing our own application (took us 6 months of work!). The prices ranged from $5k to $20k for the family of three (main applicant, and two dependants).</span></div>

          <div class="blog-paragraph">Check <a href="/home" style="text-decoration: underline">Starter Kit for EB-2 NIW</a>, which includes self-petition Green Card templates for professionals:</div>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

          <div class="blog-paragraph"><span style="font-weight: 500; font-style: italic">Expert witness fees:</span> In some cases, individuals may need to provide expert witness letters to support their application. These letters are written by individuals with expertise in the individual's field, and they attest to the individual's exceptional ability. Expert witness fees can range from a few hundred dollars to several thousand dollars, depending on the individual's field and the number of letters required. These are not mandatory. <span style="background-color: rgba(68, 206, 111, 0.25)"><a href="/home" style="text-decoration: underline">As authors of this blog</a>, we didn't use expert witness letters, and relied purely on reference letters which were free of charge. For background: applied through EB-2 NIW, advanced degree.</span></div>
          <div class="blog-paragraph"><span style="font-weight: 500; font-style: italic">Miscellaneous expenses:</span> There may be other miscellaneous expenses associated with the EB-2 NIW process, such as fees for obtaining transcripts, paying for translation services, and more.</div>
          
          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem; padding-bottom: 0px">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                  <a href="/are-you-eligible" class="btn btn-primary btn-assess">Are you eligible for EB-2 NIW?</a>
              </div>
            </div>
          </div>

          <div class="blog-paragraph" style="margin-bottom: 50px;">It is important to keep in mind that the cost of the EB-2 NIW process is an investment in the individual's future, as obtaining a Green Card can have a significant impact on your career and financial prospects in the United States. And that's exactly what <span style="background-color: rgba(68, 206, 111, 0.25)">the authors of this blog experienced: with a <a href="/home" style="text-decoration: underline">self-sponsored Green Card</a> we were able to choose the employer freely (before L1 visa restricted us from changing a job), and with the layoffs happening in 2022-2023, Green Card gave us peace of mind</span> to stay in the US on our own terms.</div>
        
        </div>
      </div>
    </section>

  </div>
</template>
<script>

import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";

export default {
  name: 'EB2NIWCost',
  components: {
    PriceOffer,
    CarouselGreenCard
  }
}
</script>
