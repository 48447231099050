<template>
    <!-- Start Footer Area -->
    <footer class="footer-area bg-f7fafd">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <div class="logo">
                            <a href="/" v-scroll-to="'#home'">
                                <img width="150px" src="../resources/selfpetitionusa-logo.svg" alt="Self Petition USA"/>
                            </a>
                        </div>
                        <p style="font-weight: 600">Self-petition green card for professionals</p>
                        <p>Take matters into your own hands!</p>
                        <p style="margin-bottom: 0px"><font-awesome-icon class="fa-1x fa-rotate-90" icon="phone" style="margin-right: 5px"/> (+1) 650 880 5823</p>
                        <p><font-awesome-icon class="fa-1x" icon="envelope" style="margin-right: 5px"/> ceo@selfpetitionusa.com</p>
                        <a target="_blank" href="https://www.trustpilot.com/review/selfpetitionusa.com">
                            <img src="../resources/trustpilot-image.webp" style="text-align: left; width: 110px; margin-top: 1.5rem; margin-right: 15px" alt="Trustpilot reviews self petition usa"/>
                        </a>
                        <a target="_blank" href="https://maps.google.com/?cid=14962197312661238312">
                            <img src="../resources/googleLogo.webp" style="text-align: left; width: 110px; margin-top: 1.5rem" alt="Google reviews self petition usa"/>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget pl-5">
                        <h3>Company</h3>
                        <ul class="list">
                            <li><a href="/"  v-scroll-to="'#starterKit'" style="height: 25px">Starter Kit &#9997;&#127996;</a></li>
                            <li><a href="/"  v-scroll-to="'#selfpetitionAI'" style="height: 25px">Selfpetition AI &#129302;</a></li>
                            <li><a href="/"  v-scroll-to="'#rfes'" style="height: 25px">RFEs <span style="font-size: 18px">&#127919;</span></a></li>

                            <li><a href="/"  v-scroll-to="'#reviews'" style="height: 25px">Reviews</a></li>
                            <li><a href="/prices" style="height: 25px">Pricing</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Support</h3>
                        <ul class="list">
                            <li><a href="/Greencard_Terms_of_Service.pdf" style="height: 25px">Terms of Service</a></li>
                            <li><a href="/Greencard_Privacy_Policy.pdf" style="height: 25px">Privacy Policy</a></li>
                            <li><a href="/Greencard_Website_Terms_and_Conditions.pdf" style="height: 25px">Terms and Conditions</a></li>

                            <li><a href="/blog/1" style="height: 25px">Blog</a></li>
                            <li><a href="/faq" style="height: 25px">FAQ</a></li>
                            <li><a href="/contact" style="height: 25px">Contact</a></li>
                        </ul>
                    </div>
                </div>



                <div class="col-lg-12 col-md-12">
                    <div class="copyright-area">
                        <p>Copyright &copy;2024 Self Petition USA. All Rights Reserved</p>
                        <div style="text-align: justify; text-justify: inter-word;">
                            <p style="font-size: 10px">The information provided on this website does not constitute legal advice, and we cannot guarantee the success of your EB-2 NIW petition as we have no control over how and when you apply. The content presented on this site should not be considered as formal legal advice or the establishment of a lawyer-client relationship. Self Petition USA is not a legal firm and does not hire lawyers. If in doubt it is important to consult with a lawyer who can provide guidance tailored to your unique personal situation.</p>
                            <p style="font-size: 10px">All statements made are subject to certain conditions, may be subject to change, could be outdated at the time of application, may not be applicable to your specific circumstances, may have a limited timespan and are not legally or financially binding. The pricing for an EB-2 NIW petition may vary depending on your individual situation. Due to the nature of our services, the fees you pay are non-refundable.</p>
                            <p style="font-size: 10px">Self Petition USA does not guarantee the accuracy or validity of the information provided and hereby disclaims any liability for any loss or damage incurred as a result of errors or omissions. Additionally, Self Petition USA is not responsible for any materials or information found on external sites provided by partnering lawyers or obtained directly from partners.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <img src="../resources/map.png" class="map" alt="national interest waiver examples">
        <div class="shape1"><img src="../resources/shape1.png" alt="national interest waiver templates"></div>
        <div class="shape8 rotateme"><img src="../resources/shape2.svg" alt="niw templates"></div>
        <!-- <div class="go-top"><vue-feather type="arrow-up"></vue-feather></div> -->

        <back-to-top bottom="50px" right="50px">
            <div class="go-top"><vue-feather type="arrow-up"></vue-feather></div>
        </back-to-top>
    </footer>
    <!-- End Footer Area -->
</template>

<script>
import BackToTop from 'vue-backtotop'
export default {
    name: 'FooterGreenCard',
    components: { BackToTop }
}
</script>
