<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <EB2NIWAdjustmentConsular></EB2NIWAdjustmentConsular>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import EB2NIWAdjustmentConsular from "../blog-components/EB2NIWAdjustmentConsular";


export default {
  name: 'EB2NIWAdjustmentConsularView',
  components: {
    EB2NIWAdjustmentConsular,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "Adjustment of Status vs. Consular Processing - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "Two common ways to obtain a green card are through Adjustment of Status and Consular Processing. While both methods serve the same ultimate purpose, there are some significant differences between them."},
      ]
    }
  }
}
</script>
