<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <SuccessStoryDataScientist></SuccessStoryDataScientist>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import SuccessStoryDataScientist from "../blog-components/SuccessStoryDataScientist";


export default {
  name: 'SuccessStoryDataScientistView',
  components: {
    SuccessStoryDataScientist,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "EB2 NIW approval for data scientist - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "Alex's background was in data science, holding a bachelor's degree in computer science and having previously worked for a well-known internet company. Despite this, he recently graduated with a Master of Business Administration (MBA) in the U.S., which opened up new opportunities but also brought some immigration challenges, as he was still in the process of seeking an employer."},
      ]
    }
  }
}
</script>
