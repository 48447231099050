var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('section',{staticClass:"blog-details-area"},[_c('div',{staticClass:"article-content"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('vue-feather',{attrs:{"type":"clock"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("December 13, 2023")])],1),_c('li',[_c('vue-feather',{attrs:{"type":"user"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("Przemek")])],1)])])])]),_vm._m(1),_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"article-content"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Success story: EB-2 NIW")]),_vm._m(2),_c('div',{staticClass:"signupSelfPetition row justify-content-center align-items-center"},[_c('div',{staticClass:"col-xl-6 col-lg-7"},[_c('signup-self-petition')],1)]),_vm._m(3),_vm._m(4),_c('PriceOffer',{staticStyle:{"margin-bottom":"4rem"}}),_c('CarouselGreenCard',{staticStyle:{"margin-bottom":"4rem"}}),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area2"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('span',{staticClass:"article-tags"},[_c('a',{attrs:{"href":"#"}},[_vm._v("EB-2 NIW BLOG")])]),_c('span',{staticClass:"article-tags2"},[_c('a',{attrs:{"href":"#"}},[_vm._v("SUCCESS STORY")])]),_c('h1',{staticStyle:{"font-weight":"600","margin-top":"30px","text-align":"left"}},[_vm._v("EB-2 NIW approval for automotive engineer")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../resources/blog/blog41-16x9.webp"),"alt":"NIW template automotive engineer"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Meet Michael, an accomplished "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Electronic Engineering Leader")]),_vm._v(" in the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("automotive industry")]),_vm._v(", who successfully obtained approval for his EB2 NIW case in 2023. Despite initial skepticism from attorneys, Michael took matters into his own hands and achieved remarkable success.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Michael's impressive background includes a "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Bachelor of Science in Electronic Engineering")]),_vm._v(" from Germany and a "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Master of Science in Electrical Engineering")]),_vm._v(" from the USA. With "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("over 15 years of experience")]),_vm._v(" in the automotive industry, he played a pivotal role in introducing electronic innovations to various vehicles, earning recognition from major companies such as "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Ford")]),_vm._v(", "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("GM")]),_vm._v(", "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Caterpillar")]),_vm._v(", and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Volkswagen")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Michael identified that he met the eligibility criteria for NIW and decided to proceed. His "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("proposed endeavor and national interest were around "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("driving a substantial revenue growth")]),_vm._v(" through strategic business development initiatives and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("implement safety measures")]),_vm._v(" across diverse product lines.")]),_vm._v(" He wrote his application leveraging two of our templates: "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/electro-mechanical-engineer-sample"}},[_vm._v("(i) Electro-mechanical Engineer template")]),_vm._v(" and "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/product-project-mgmt-sample"}},[_vm._v("(ii) Product/Project Manager template")]),_vm._v(" - to enrich the petition with project and business related aspects.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("The "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/faq"}},[_vm._v("Starter Kit templates")]),_vm._v(" proved invaluable, especially when "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("crafting "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("recommendation letters")]),_vm._v(". Michael highlights the importance of these letters, which confirmed his contributions to "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("patents")]),_vm._v(". The Starter Kit provided excellent examples of the required "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("format")]),_vm._v(" and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("process")]),_vm._v(" to follow.")]),_vm._v(" Michael obtained letters from "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("tech leads")]),_vm._v(", "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("managers")]),_vm._v(", "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("directors")]),_vm._v(", and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("technical specialists")]),_vm._v(" at the companies he worked for, as well as major semiconductor suppliers.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("He also included all relevant "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("awards")]),_vm._v(", "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("recognitions")]),_vm._v(", and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("documentation")]),_vm._v(", showcasing his national-level contributions to the industry.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Michael recommends applying for NIW after obtaining an H-1B visa and shares that, despite not encountering a denial, the "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/what-if-my-eb2-niw-is-denied"}},[_vm._v("ability to reapply without penalty")]),_vm._v(" provides an extra layer of security.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Michael's story culminated in the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("approval of his I-140 for both himself and his wife")]),_vm._v(", without a single Request for Evidence (RFE). "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("The entire process took approximately "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("six months")]),_vm._v(", defying the odds and proving that success in the NIW category is attainable with the right resources and determination.")]),_vm._v(" Michael credits the SelfPetitionUSA’s "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/faq"}},[_vm._v("DIY Kit")]),_vm._v(" as instrumental in his triumph over the challenges that attorneys deemed insurmountable.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('a',{staticClass:"btn btn-primary",staticStyle:{"margin-top":"3rem","margin-bottom":"2rem"},attrs:{"href":"/electro-mechanical-engineer-sample"}},[_vm._v("ELECTRO-MECHANICAL ENG TEMPLATE")]),_c('a',{staticClass:"btn btn-primary",staticStyle:{"margin-top":"3rem","margin-bottom":"2rem"},attrs:{"href":"/product-project-mgmt-sample"}},[_vm._v("PRODUCT-PROJECT MANAGER TEMPLATE")])])])])
}]

export { render, staticRenderFns }