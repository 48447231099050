<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <ProfessionBankingFinance></ProfessionBankingFinance>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
    import ExternalHeaderGreenCard from "./components/ExternalHeaderGreenCard";
    import ExternalFooterGreenCard from "./components/ExternalFooterGreenCard";
    import ProfessionBankingFinance from "./components/ProfessionBankingFinance";

    export default {
      name: 'ProfessionBankingFinanceView',
      components: {
        ProfessionBankingFinance,
        ExternalHeaderGreenCard,
        ExternalFooterGreenCard,
      },
      data() {
        return {
          currentUrl: ''
        }
      },
      watch: {
        '$route'(pathUrl){
          this.currentUrl = pathUrl.path;
        }
      },
      mounted() {
        this.currentUrl = window.location.pathname;
      },
      metaInfo() {
        return {
          title: "Banking & Finance - Self Petition USA",
          meta: [
          { vmid: 'description', name: 'description', content:  "Effectively navigating the EB-2 NIW process in the financial sector requires a comprehensive presentation of the applicant's achievements, highlighting the pivotal role they play in shaping the nation's economic future. Furthermore, it is crucial to underscore the broader societal and economic implications of their work in the financial sector."},
          ]
        }
      }
    }
</script>