<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <div class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </div>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">What are EB-2 NIW eligibility requirements?</h1>
          </div>
        </div>
      </div>
    </div>

    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">December 15, 2022</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Natalie</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog3-16x9.webp" alt="EB2 NIW Template">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">Eligibility requirements</h3>

          <div class="blog-paragraph">EB-2 National Interest Waiver (NIW) is a type of visa that allows individuals with <span style="font-weight: 500; font-style: italic">advanced degree</span> or <span style="font-weight: 500; font-style: italic">exceptional ability</span> in the arts, sciences, business, education, or athletics, to apply for permanent residency without the need for a labor certification.</div>

          <div class="blog-paragraph">Here are the eligibility requirements for EB-2 NIW:</div>

          <h5>OPTION 1: Category advanced degree or equivalent</h5>
            <div class="blog-paragraph"><span style="background-color: rgba(68, 206, 111, 0.25)">The individual must hold an <span style="font-weight: 500; font-style: italic">advanced degree</span> (Master's or higher) or its equivalent, or a bachelor's degree plus at least 5 years of progressive work experience in the field.</span></div>

          <div class="blog-paragraph">Check <a href="/home" style="text-decoration: underline">Starter Kit for EB-2 NIW</a>, which includes self-petition Green Card templates for professionals (<span style="font-weight: 500; font-style: italic">advanced degree category</span>):</div>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

          <h5>OPTION 2: Category exceptional ability</h5>
            <div class="blog-paragraph">The individual must demonstrate <span style="background-color: rgba(68, 206, 111, 0.25)"><span style="font-weight: 500; font-style: italic">exceptional ability</span> in their field, which may include publications, patents, recognition from professional organizations, or other relevant evidence. You qualify if you meet at least <span style="font-weight: 500; font-style: italic">three of the criteria</span></span> below:</div>

          <ul class="features-list">
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Official academic record</span> showing that you have a degree, diploma, certificate, or similar award from a college, university, school, or other institution of learning relating to your area of exceptional ability</li>
            <li><vue-feather type="check"></vue-feather>Letters from current or former employers documenting at least <span style="font-weight: 500; font-style: italic">10 years of full-time experience</span> in your occupation</li>
            <li><vue-feather type="check"></vue-feather>A <span style="font-weight: 500; font-style: italic">license to practice your profession</span> or certification for your profession or occupation</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Evidence that you have commanded a salary</span> or other remuneration for services that demonstrates your exceptional ability</li>
            <li><vue-feather type="check"></vue-feather>Membership in a <span style="font-weight: 500; font-style: italic">professional association(s)</span></li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Recognition for your achievements</span> and significant contributions to your industry or field by your peers, government entities, professional or business organizations</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Other comparable</span> evidence of eligibility is also acceptable.</li>
          </ul>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                <router-link class="btn btn-primary" :to="{ name: 'Home', params: { location: 'Templates' }}">EB-2 NIW Templates</router-link>
              </div>
            </div>
          </div>

          <div class="blog-paragraph">After deciding whether you apply through <span style="font-weight: 500; font-style: italic">advanced degree</span> or <span style="font-weight: 500; font-style: italic">exceptional ability</span>, you also need to meet <span style="background-color: rgba(68, 206, 111, 0.25)">NIW (National Interest Waiver) requirements to <span style="font-weight: 500; font-style: italic">self-petition</span>. NIW means that the individual's work must be in the national interest, so it has the potential to significantly benefit the U.S. economy, culture, or welfare</span>. There are <span style="font-weight: 500; font-style: italic">three prongs</span> to prove NIW:</div>

          <ul class="features-list">
            <li><vue-feather type="check"></vue-feather>The proposed endeavor has both <span style="font-weight: 500; font-style: italic">substantial merit</span> and <span style="font-weight: 500; font-style: italic">national importance</span>.</li>
            <li><vue-feather type="check"></vue-feather>You are <span style="font-weight: 500; font-style: italic">well positioned to advance</span> the proposed endeavor.</li>
            <li><vue-feather type="check"></vue-feather>On balance, it would be <span style="font-weight: 500; font-style: italic">beneficial to the United States</span> to waive the requirements of a job offer, and thus the labor certification.</li>
          </ul>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                  <a href="/are-you-eligible" class="btn btn-primary btn-assess">Are you eligible for EB-2 NIW?</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>
<script>

import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";

export default {
  name: 'EB2NIWRequirements',
  components: {
    PriceOffer,
    CarouselGreenCard
  }
}
</script>
