var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('section',{staticClass:"blog-details-area"},[_c('div',{staticClass:"article-content"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('vue-feather',{attrs:{"type":"clock"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("September 21, 2023")])],1),_c('li',[_c('vue-feather',{attrs:{"type":"user"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("Natalie")])],1)])])])]),_vm._m(1),_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"article-content"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Success story: EB-2 NIW")]),_vm._m(2),_c('div',{staticClass:"signupSelfPetition row justify-content-center align-items-center"},[_c('div',{staticClass:"col-xl-6 col-lg-7"},[_c('signup-self-petition')],1)]),_vm._m(3),_vm._m(4),_c('PriceOffer',{staticStyle:{"margin-bottom":"4rem"}}),_c('CarouselGreenCard',{staticStyle:{"margin-bottom":"4rem"}}),_vm._m(5),_vm._m(6),_vm._m(7)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area2"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('span',{staticClass:"article-tags"},[_c('a',{attrs:{"href":"#"}},[_vm._v("EB-2 NIW BLOG")])]),_c('span',{staticClass:"article-tags2"},[_c('a',{attrs:{"href":"#"}},[_vm._v("SUCCESS STORY")])]),_c('h1',{staticStyle:{"font-weight":"600","margin-top":"30px","text-align":"left"}},[_vm._v("EB-2 NIW approval for data scientist")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../resources/blog/blog36-16x9.webp"),"alt":"NIW template data scientist"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("Alex's background was in data science, holding a "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("bachelor's degree in computer science")]),_vm._v(" and having previously worked for a well-known internet company. Despite this, he recently graduated with a "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Master of Business Administration (MBA)")]),_vm._v(" in the U.S.,")]),_vm._v(" which opened up new opportunities but also brought some immigration challenges, as he was still in the process of "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("seeking an employer")]),_vm._v(". Unlike many EB2 NIW applicants, Alex "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("didn't have published academic papers")]),_vm._v(" or publicly available research results due to the confidential nature of his work.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("However, since January 2022, President Biden's administration has been enacting measures to "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("attract and retain international STEM talent")]),_vm._v(", underscoring the critical importance of STEM to the nation's future. "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("This focus on "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("STEM")]),_vm._v(" reflects a commitment to fostering innovation, research, and the development of cutting-edge technologies that are essential for the United States to maintain its position as a global leader")]),_vm._v(" in technological and economic innovation. Alex recognized that these initiatives create an inviting environment for international STEM talent, contributing to ongoing progress and success in "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("science and technology")]),_vm._v(" while securing the nation's future leadership on the global stage.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Alex used the "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("EB-2 NIW Starter Kit")]),_vm._v(" for "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("data scientists")]),_vm._v(" and submitted an I-140 petition to the U.S. Citizenship and Immigration Services (USCIS):")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("As expected, the USCIS issued a Request for Evidence (RFE), requesting more details about Alex's "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("career plan")]),_vm._v(" and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("intended employment")]),_vm._v(" in the U.S. "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("At this time, Alex had secured a position as a "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Data Scientist")]),_vm._v(" in the field of "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("cybersecurity")]),_vm._v(", aligning with his original intention to work in "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("internet security")]),_vm._v(", "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("identity protection")]),_vm._v(", and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("privacy concerns")]),_vm._v(" that benefit the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("safety of U.S. citizens")]),_vm._v(".")]),_vm._v(" Alex responded to the RFE with strong evidence of his dedication to data science and the significance of his chosen field in internet security.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("The good news came shortly after: Alex's "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("NIW petition was approved")]),_vm._v(", and he was on his way to becoming a data scientist in the U.S. Alex's journey is a testament to determination and adaptability in the face of immigration challenges, showing that with the right approach and evidence, achieving permanent residency is possible.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('a',{staticClass:"btn btn-primary",staticStyle:{"margin-top":"3rem","margin-bottom":"2rem"},attrs:{"href":"/data-scientist-analyst-sample"}},[_vm._v("DATA SCIENTIST TEMPLATE")])])])])
}]

export { render, staticRenderFns }