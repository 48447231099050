<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <EB2NIWMistakes1></EB2NIWMistakes1>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import EB2NIWMistakes1 from "../blog-components/EB2NIWMistakes1";


export default {
  name: 'EB2NIWMistakes1View',
  components: {
    EB2NIWMistakes1,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "EB2 NIW cases that fail, avoid these mistakes! - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "Examples of not meeting basic requirement of Advanced Degree category and Exceptional Ability category"},
      ]
    }
  }
}
</script>
