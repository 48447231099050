<template>

    <section class="works-area ptb-80 bg-f9fafb">

      <b-modal ref="modal-cover-letter" title="Product & Project Manager" size="xl" hide-footer>
        <img src="../resources/sample-productManager.webp" alt="eb2 niw sample PM"/>
      </b-modal>

      <b-modal ref="modal-case-builder" title="Product & Project Manager" size="xl" hide-footer>
        <img src="../resources/caseBuilder-productManager.webp" alt="eb2 niw sample product manager"/>
      </b-modal>

      <div class="container" style="margin-top: 5rem">
        <div class="row">
          <div class="col-sm-3 col-8 offset-sm-0 offset-2" style="margin-bottom: 4rem">
            <img src="../resources/pm.webp" style="border-radius: 50%; border: 2px solid; border-color: #44ce6f; box-shadow: 10px 20px 20px 0px rgba(86, 97, 118, .25)" alt="eb2 niw sample project manager"/>
          </div>

          <div class="col-sm-8 offset-sm-1 col-12 sample-h1">
            <div>
              <h1 style="font-weight: 600;">Product & Project Manager</h1>
              <div class="bar"></div>
            </div>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 30px">
        <div style="margin: 1rem; margin-top: 2rem; max-width: 1000px">In the ever-evolving landscape of product development and innovation, product managers navigating the EB-2 NIW process encounter a distinctive set of dynamics and possibilities. For product and project managers, the journey toward a successful petition extends beyond showcasing product expertise; it involves articulating the profound impact of their contributions to product strategy, development, and market success.</div>
          <div style="margin: 1rem; max-width: 1000px">The EB-2 NIW process for product and project managers demands a meticulous presentation of achievements, emphasizing the strategic role played in shaping and advancing product solutions. This requires delving into the details of successful product launches, market penetration strategies, and innovative methodologies that have significantly influenced organizational dynamics and industry trends.</div>
          <div style="margin: 1rem; max-width: 1000px">Effectively navigating the EB-2 NIW process necessitates a comprehensive approach. Applicants must underscore their instrumental role in driving product success, optimizing development processes, and contributing to the overall organizational growth. Emphasizing the broader economic and market implications of their work, within the context of modern product and project management dynamics, is crucial. Advancements in product management align with national interests, such as economic growth, innovation, and strategic market positioning on a global scale.</div>
          <div style="margin: 1rem; max-width: 1000px">With the inherent alignment of product and project management expertise with national interests, applicants can position themselves as catalysts for economic growth, market efficiency, and product innovation. This alignment is recognized and emphasized by policymakers, reflecting the national interest in promoting innovation and addressing the evolving dynamics in product development and market competitiveness.</div>
          <div style="margin: 1rem; max-width: 1000px">It's important to note that, contrary to common belief, extensive research, publications, or citations are not obligatory for EB-2 NIW. Our templates are specifically tailored for product and project managers, focusing on the strategic impact and broader implications of their contributions to product and market success.</div>

          <div style="margin: 3rem 1rem 1rem; max-width: 200px; border: 2px solid #44ce6f; padding: 12px 12px 4px; border-radius: 5px;">
            <vue-feather type="shield" style="color: #44ce6f"></vue-feather> Read More:
            <ul>
              <li style="margin-top: 5px"><a href="/success-story-product-manager" target="_blank" style="text-decoration: underline">Success Story 1</a></li>
              <li style="margin-top: 5px"><a href="/success-story-automotive-engineer" target="_blank" style="text-decoration: underline">Success Story 2</a></li>
            </ul>
          </div>
        </div>

<!--Product & Project Manager-->
        <div id="profession-sample" style="margin-bottom: 1.5rem">
          <div style="margin: 6rem 0rem">
            <h4 style="text-align: center;">Product & Project Manager</h4>
            <div class="section-title">
              <div class="bar"></div>
            </div>
            
            <div style="text-align: center; margin: 4rem 0 6rem">
              <button v-on:click="navigateToCheckout" class="btn btn-primary">GET THIS TEMPLATE</button>
              <div class="price-offer-comment" style="color: #c4c4c4; margin-top: 50px;">*** Not quite right? Try <a style="color: #c4c4c4; text-decoration: underline" href="/ai">Selfpetition AI</a>  instead ***</div>            
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCoverLetterModal" style="cursor: pointer">
              <img src="../resources/sample-productManager.webp" alt="product manager i140 sample">
              <div class="works-content">
                <h3 style="color: #fff">Product & Project Manager</h3>
                <h3 style="color: #fff">Cover Letter (9 pages)</h3>
                <p>The cover letter comprises the following segments: meeting the criteria for an Advance Degree, Prong 1, Prong 2, Prong 3, and an appendix containing all supporting evidence. When checking out, you have the option to select up to two professions.</p>
              </div>
            </div>

            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCaseBuilderModal" style="cursor: pointer">
              <img src="../resources/caseBuilder-productManager.webp" alt="project manager green card">
              <div class="works-content">
                <h3 style="color: #fff">Product & Project Manager</h3>                    
                <h3 style="color: #fff">EB-2 NIW Case Builder (17 pages)</h3>
                <p>You might be wondering how to identify relevant NIW initiatives and supporting arguments. This is where the NIW Case Builder Pdf comes in – it's designed to inspire you with alternative points to emphasize in your cover letter as you modify our template.</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div style="margin: 5rem 0rem">
            <h4 style="text-align: center;">EB2 NIW Starter Kit</h4>
            <h6 style="text-align: center;">List of Documents</h6>
            <div class="section-title">
              <div class="bar"></div>
            </div>
          </div>

          <div class="section-title faq" style="margin-bottom: 1.5rem">
            <DocumentsList></DocumentsList>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 1.5rem">
          <div class="row">
            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Most suitable for</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">Product Manager</li>
                <li class="profession-page-list">Project Manager</li>
                <li class="profession-page-list">Program Manager</li>
                <li class="profession-page-list">Product Owner</li>
                <li class="profession-page-list">Scrum Master</li>
                <li class="profession-page-list">Account Manager</li>
                <li class="profession-page-list">Product Marketing Manager</li>
                <li class="profession-page-list">Operations Coordinator</li>
                <li class="profession-page-list">Technical Project Manager</li>
                <li class="profession-page-list">Customer Success Manager</li>
                <li class="profession-page-list">Process Improvement Manager</li>
                <li class="profession-page-list">Change Management Specialist</li>
                <li class="profession-page-list">Customer Experience Manager</li>
                <li class="profession-page-list">Innovation Manager</li>
                <li class="profession-page-list">... and other similar</li>
              </ul>
            </div>

            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Topics</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">Technology</li>
                <li class="profession-page-list">Data analytics</li>
                <li class="profession-page-list">Semiconductor industry</li>
                <li class="profession-page-list">Automation projects</li>
                <li class="profession-page-list">U.S. leadership in semiconductor manufacturing</li>
                <li class="profession-page-list">STEM talent</li>
                <li class="profession-page-list">U.S. economic growth</li>
                <li class="profession-page-list">Science and technology</li>
                <li class="profession-page-list">Data-driven solutions </li>
                <li class="profession-page-list">National importance of data analytics</li>
                <li class="profession-page-list">Chip manufacturing</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="shape2 rotateme" style="left: 90%"><img src="../resources/shape2.svg" alt="EB2 niw diy"></div>
      <div class="shape4"><img src="../resources/shape4.svg" alt="EB-2 niw product manager"></div>
      <div class="shape7"><img src="../resources/shape4.svg" alt="EB-2 niw project manager"></div>
    </section>

</template>

<script>

import { mapActions } from 'vuex';
import DocumentsList from "./DocumentsList.vue";

    export default {
      name: 'ProfessionProductProjectMgmt',
      components: {
        DocumentsList,
      },
      data() {
        return {
          user: {
            profession1: 'productProjectManager',
          }
        }
      },
      methods: {
        ...mapActions('account', ['apply']),
        navigateToCheckout() {
          //this.apply('Product Project Mgr Sample');
          // Handle navigation to checkout here
          this.$router.push({
            path: '/checkout-starter-kit',
            query: {
              profession: this.user.profession1
            }
          });
        },
        openCoverLetterModal() {
          setTimeout(() => {
            this.$refs['modal-cover-letter'].show();
          }, 100);
        },
        openCaseBuilderModal() {
          setTimeout(() => {
            this.$refs['modal-case-builder'].show();
          }, 100);
        },
      }
    }
</script>

