<template>

    <section class="feedback-area ptb-80 bg-f9fafb">
      <div id="reviewsCarousel">
        <div class="container">
            <div class="section-title">
                <h2>What Our Clients Are Saying</h2>
                <div class="bar"></div>
                
                <div class="row">
                    <a class="col-6 googleReviews" target="_blank" style="text-align: right" href="https://www.trustpilot.com/review/selfpetitionusa.com">
                        <img src="../resources/trustpilot-image.webp" alt="Trustpilot reviews self petition"/>
                    </a>
                    <a class="col-6 googleReviews" target="_blank" style="text-align: left" href="https://maps.google.com/?cid=14962197312661238312">
                        <img src="../resources/googleLogo.webp" alt="Google reviews self petition"/>
                    </a>
                </div>
            </div>


            <div class="feedback-slides">
                <div class="client-feedback" v-if="reviewsGoogle.length > 0 && reviewsTrustpilot.length > 0">
                    <div>
                        <slick
                            ref="slick"
                            class="slider-for"
                            :options="slickOptions"
                        >

                            <div v-for="(review, index) in reviewsTrustpilot" :key="'trustpilot-' + index" class="item">
                                <div class="single-feedback">
                                    <div class="row">
                                        <div class="col-md-2 col-4" style="padding-right: 0;">
                                            <img :src="review.photo" class="review-photo" alt="client-photo" @error="imageError($event)" />
                                        </div>
                                        <div class="col-md-10 col-8" style="margin-bottom: 15px; padding-right: 0; padding-left: 5px">
                                            <div class="stars" v-html="starsOutput(review.rating)"></div>
                                            <h3>{{ review.author }}</h3>
                                            <div>{{ review.time }}</div>
                                        </div>
                                        <h6 style="padding-left: 15px; padding-right: 15px">{{ review.title }}</h6>
                                        <a :href="trustpilotUri" target="_blank" class="review-link">
                                            <div class="col-12 review-text">{{ truncateText(review.text) }}</div>
                                            <p class="col-12"><img src="../resources/trustpilot-image.webp" style="height: 30px" alt="Trustpilot reviews self petition"/></p>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div v-for="(review, index) in reviewsGoogle" :key="'google-' + index" class="item">
                                <div class="single-feedback">
                                    <div class="row">
                                        <div class="col-md-2 col-4" style="padding-right: 0">
                                            <img :src="review.photo" class="review-photo" alt="client-photo" @error="imageError($event)"/>
                                        </div>
                                        <div class="col-md-10 col-8" style="margin-bottom: 15px; padding-right: 0; padding-left: 5px">
                                            <div class="stars" v-html="starsOutput(review.rating)"></div>
                                            <h3>{{ review.author }}</h3>
                                            <div>{{ review.time }}</div>
                                        </div>
                                        <a :href="googleMapsUri" target="_blank" class="review-link">
                                            <div class="col-12 review-text">{{ truncateText(review.text) }}</div>
                                            <p class="col-12"><img src="../resources/googleLogo.webp" style="height: 30px" alt="Google reviews self petition"/></p>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </slick>
                    </div>
                    
                    <!--<button class="prev-arrow slick-arrow">
                        <vue-feather type="arrow-left"></vue-feather>
                    </button>
                    
                    <button class="next-arrow slick-arrow">
                        <vue-feather type="arrow-right"></vue-feather>
                    </button>-->

                </div>

                <div class="client-thumbnails">
                    <div>
                        <slick
                            ref = "slick2"
                            class="slider-nav"
                            :options="slickOptions2"
                        >

                        </slick>
                    </div>

                    <!--<button class="prev-arrow slick-arrow">
                        <vue-feather type="arrow-left"></vue-feather>
                    </button>
                    
                    <button class="next-arrow slick-arrow">
                        <vue-feather type="arrow-right"></vue-feather>
                    </button>-->
                </div>
            </div>

        </div>

        <div class="shape2 rotateme" style="left: 90%"><img src="../resources/shape2.svg" alt="EB-2 niw for finance and banking"></div>
        <div class="shape4"><img src="../resources/shape4.svg" alt="EB-2 niw for engineer"></div>
        <div class="shape7"><img src="../resources/shape4.svg" alt="EB-2 niw for pilot"></div>
      </div>
    </section>

</template>

<script>
// import VueSlickCarousel from 'vue-slick-carousel';
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';

export default {
    name: 'FeedbackGreenCard',
    components: {
        Slick,
    },
    data() {
        return {
            googleMapsUri: '',
            trustpilotUri: 'https://www.trustpilot.com/review/selfpetitionusa.com',
            reviewsGoogle: [],
            reviewsTrustpilot: [
                {
                    photo: 'https://user-images.trustpilot.com/59c4b5eb0000ff000acc34cd/73x73.png',
                    author: 'David Staudinger',
                    rating: '5',
                    title: 'Hands down the best decision in your EB2 journey',
                    text: "If you're looking for assistance in your journey towards EB2 NIW self petition, the kits of self petition usa (in particular the AI version) are what you want to invest your money in. This is approx 10 times cheaper than any immigration lawyer and while it doesn't replace legal advice it's better in many ways. A highlight is access to a community of fellow petitioners on a dedicated discord server.",
                    time: '3 weeks ago',
                },
                {
                    photo: 'https://user-images.trustpilot.com/6698250862ad55392b044efc/73x73.png',
                    author: 'Samuel Baguma',
                    rating: '5',
                    title: 'Great tool',
                    text: 'I recently signed up for selfpetitionUSA AI and was greatly impressed by the quality of the work and the quick turnaround. I would strongly recommend the AI package, as its takes a lot of work off you hands and lets you focus on collecting and providing evidence.',
                    time: '3 weeks ago',
                },
            ],
            slickOptions: {
                speed: 300,
                autoplaySpeed: 2000,
                slidesToShow: 1,
                slidesToScroll: 1,
                cssEase: 'linear',
                fade: true,
                autoplay: true,
                draggable: true,
                asNavFor: '.slider-nav',
                //prevArrow: '.client-feedback .prev-arrow',
                //nextArrow: '.client-feedback .next-arrow'
            },
            slickOptions2: {
                speed: 300,
                autoplaySpeed: 2000,
                slidesToShow: 4,
                slidesToScroll: 1,
                cssEase: 'linear',
                autoplay: true,
                centerMode: true,
                draggable: false,
                focusOnSelect: true,
                asNavFor: '.slider-for',
                prevArrow: '.client-thumbnails .prev-arrow',
                nextArrow: '.client-thumbnails .next-arrow'
            },
        };
    },
    mounted() {
        this.fetchReviewsGoogle();
        this.$nextTick(() => {
            // Adding a small delay to ensure the slick arrows are rendered
            setTimeout(() => {
                const prevButton = document.querySelector('.slick-prev.slick-arrow');
                if (prevButton) {
                    prevButton.innerHTML = '&#8678;';
                }

                const nextButton = document.querySelector('.slick-next.slick-arrow');
                if (nextButton) {
                    nextButton.innerHTML = '&#8680;';
                }
            }, 100); // 100 milliseconds delay to ensure the elements are rendered
        });
    },
    methods: {
        next() {
            this.$refs.slick.next();
        },
        prev() {
            this.$refs.slick.prev();
        },
        truncateText(text) {
            return text.length > 500 ? text.slice(0, 450) + '...' : text;
        },
        async fetchReviewsGoogle() {
            try {
                // Fetch the JSON file from the public directory
                const response = await fetch('/reviewsGoogle.json');
                if (!response.ok) {
                throw new Error('Network response was not ok');
                }
                const data = await response.json();
                this.googleMapsUri = data.googleMapsUri;
                this.reviewsGoogle = data.reviews;
            } catch (error) {
                console.error('Error fetching reviews:', error);
            }
        },
        starsOutput(ratingGT) {
            const maxRating = 5;
            let stars = '';
            for (let i = 0; i < maxRating; i++) {
                stars += i < ratingGT ? '★' : '☆';
            }
            return stars;
        },
        imageError(event) {
            const img = event.target;
            console.log("Image failed to load:", img.src);

            // Correctly reference the placeholder image within the src directory
            const placeholderImage = require('@/views/ideas/green-card/resources/avatar_female_cropped.png');

            const currentSrc = new URL(img.src).pathname;
            const placeholderPathname = new URL(placeholderImage, window.location.href).pathname;

            if (currentSrc !== placeholderPathname) {
                img.src = placeholderImage;
            }
        },
    }
}
</script>
