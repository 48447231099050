<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <div class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </div>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">ETA-9089 vs. ETA-750</h1>
          </div>
        </div>
      </div>
    </div>
    
    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">August 17, 2023</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Przemek</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog33-16x9.webp" alt="eb2 niw ETA-9089 vs ETA-750">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">Which one to choose?</h3>

          <div class="blog-paragraph">Are you on the path to obtaining an EB-2 National Interest Waiver (NIW) and confused about whether to include Labor Certification forms in your application package? In this article, we'll clarify the requirements and discuss which forms you need to use: <span style="font-weight: 500; font-style: italic">ETA-9089</span> or <span style="font-weight: 500; font-style: italic">ETA-750</span>.</div>

          <h5>Understanding the Labor Certification requirement</h5>
          
            <div class="blog-paragraph">Before diving into the specific forms, let's first understand why labor certification forms are required in some cases. The term "NIW" stands for National Interest Waiver, which essentially means you're requesting a waiver for the labor certification component of the <a href="/home" style="text-decoration: underline">EB-2 Green Card application</a> process. So, do you need to include a labor certification form? The <span style="font-weight: 500; font-style: italic">short answer is yes</span>, and here's why:</div>

            <div class="blog-paragraph"><span style="background-color: rgba(68, 206, 111, 0.25)"><span style="font-weight: 500; font-style: italic">In the broader context, employment-based Green Card applications typically consist of three main parts.</span></span></div>

          <ul class="features-list">
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Labor Certification (PERM):</span> This is the process where an employer sponsors you and has to prove that there are no qualified American workers available for the position you're being hired for. It involves various steps such as job description, job posting, and assessing applications from U.S. workers. If successful, the Department of Labor approves the labor certification.</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic"><a href="/home" style="text-decoration: underline">I-140 Petition</a>:</span> After obtaining labor certification (unless waived), you proceed to file an I-140 petition. This is the stage where you prove your eligibility for the EB-2 or other employment-based categories.</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Adjustment of Status (I-485) or Consular Processing:</span> Finally, depending on your location, you either apply for Adjustment of Status (if you're in the U.S.) or go through Consular Processing (if you're abroad) to receive your Green Card.</li>
          </ul>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

            <div class="blog-paragraph"><span style="background-color: rgba(68, 206, 111, 0.25)">However, if you're self-petitioning for your Green Card under the EB-2 NIW category (or the EB1A category), you <span style="font-weight: 500; font-style: italic">bypass the labor certification process</span> entirely. This is where the confusion often arises.</span></div>

          <h5>Why include Labor Certification forms in your application?</h5>
          
            <div class="blog-paragraph">Here's where it gets interesting. Even though you don't have to undergo the labor certification process, you still <span style="font-weight: 500; font-style: italic">need to include certain labor certification forms</span> as part of your I-140 submission. Why? Because the I-140 form, used for various employment-based Green Card categories, requires specific evidence. This evidence should be generic enough to be used for all these categories, including EB-2 NIW.</div>

            <div class="blog-paragraph">So, the <span style="background-color: rgba(68, 206, 111, 0.25)">labor certification documents are included as part of your <a href="/home" style="text-decoration: underline">I-140 package</a> to <span style="font-weight: 500; font-style: italic">fulfill USCIS requirements</span>. The good news is you don't have to go through the entire labor certification process</span> – you only need to fill out certain sections of the form yourself. <span style="font-weight: 500; font-style: italic">No need to seek approval from the Department of Labor; just fill it out, sign it, and include it in your I-140 submission.</span></div>

            <div class="blog-paragraph">By doing this, you're essentially saving yourself months of labor certification hassle while still providing the necessary documentation to meet USCIS requirements.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                <router-link class="btn btn-primary" :to="{ name: 'Home', params: { location: 'Templates' }}">EB-2 NIW Templates</router-link>
              </div>
            </div>
          </div>

          <h5>Choosing the right form: ETA-9089 or ETA-750?</h5>

            <div class="blog-paragraph">Now, let's talk about the specific forms you can use. Currently, USCIS allows two options:</div>

            <div class="blog-paragraph"><span style="font-weight: 500; font-style: italic">ETA-9089:</span> This is the more common choice, and it's the one we recommend. Even though it's not a USCIS form (hence the "ETA" prefix), it serves the purpose well. It's widely used and recognized.</div>

            <div class="blog-paragraph"><span style="font-weight: 500; font-style: italic">ETA-750:</span> While USCIS still allows the use of ETA-750, it seems that this form's relevance is diminishing. It's worth noting that, according to the Department of Labor, form ETA-750 is now primarily used for athletes. It's likely that in the future, USCIS might discontinue the use of ETA-750 for Green Card applications.</div>

          <h5>How to access and fill out the form</h5>

            <div class="blog-paragraph">To obtain the appropriate form, you can visit the <span style="font-weight: 500; font-style: italic">Department of Labor's official website</span> and search for "Application for Permanent Employment Certification Form." Download the form and, crucially, ensure you download it <span style="font-weight: 500; font-style: italic">from an official ".gov" website.</span></div>
            
            <div class="blog-paragraph"><span style="background-color: rgba(68, 206, 111, 0.25)">The form you need to fill out for your EB2 NIW application includes sections <span style="font-weight: 500; font-style: italic">J</span>, <span style="font-weight: 500; font-style: italic">K</span>, <span style="font-weight: 500; font-style: italic">L</span>, and <span style="font-weight: 500; font-style: italic">M</span>. You don't need to submit the entire form, just these specific parts.</span> While the form may appear expired, it's an exception, and the Department of Labor shall accept it.</div>
          
            <div class="blog-paragraph">Please be aware that forms and regulations can change over time. Therefore, it is essential to double-check the information in this content to ensure its accuracy at the time you are reading it.</div>
          
          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                  <a href="/new-eta9089" class="btn btn-primary">Continue Reading</a>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>

  </div>
</template>
<script>
import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";

export default {
  name: 'EB2NIWETA9089vsETA750',
  components: {
    PriceOffer,
    CarouselGreenCard
  }
}
</script>
