<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <EB2NIWNewEta9089></EB2NIWNewEta9089>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import EB2NIWNewEta9089 from "../blog-components/EB2NIWNewEta9089";


export default {
  name: 'EB2NIWNewEta9089View',
  components: {
    EB2NIWNewEta9089,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "Use new Eta 9089 form for EB2 NIW - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "In this article, we'll delve into the intricacies of Form ETA-9089, an essential document for those seeking a National Interest Waiver (NIW) Green Card. We will provide you with valuable insights into the updated version of this form, recent changes, and which version to use."},
      ]
    }
  }
}
</script>
