<template>
<!-- Image generator-->
<!-- https://www.creativefabrica.com/spark/tools/art-generator/-->
    <div>
		<div class="page-title-area section-title" style="margin-bottom: 0px; padding-bottom: 10px">
			<div class="d-table"><div class="d-table-cell"><div class="container"><h1 class="page-title" >EB-2 NIW Blog</h1></div></div></div>
			<div class="bar"></div>
		<!-- End Page Title -->
			<div style="top:20px" class="shape1"><img src="../resources/shape1.png" alt="national interest waiver templates"></div>
			<div class="shape8 rotateme"><img src="../resources/shape2.svg" alt="niw templates"></div>
			<div class="shape7"><img src="../resources/shape4.svg" alt="eb2 niw engineer"></div>
		</div>
		
		<!-- Start Blog Area -->
		<section class="blog-area ptb-80">
			<div class="container">
				<div class="row">

					<!-- Empty template
					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="#">
									<img height="" src="../resources/blog/blogXX-1x1.webp" alt="#">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> Month XXX, 2024
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="#">#</a></h4>

								<span>Author: <a href="#">#</a></span>

								<p>#</p>

								<a href="#" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>-->

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/success-story-rfe-data-analyst">
									<img height="" src="../resources/blog/blog48-1x1.webp" alt="rfe response">
								</a>

								<div class="story">
									<vue-feather type="shield"></vue-feather> Success story
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/success-story-rfe-data-analyst">Successful RFE Response for data analyst</a></h4>

								<span>Author: <a href="#">Przemek</a></span>

								<p>Aria, a data analyst with extensive expertise in the payment processing industry, initially applied for an EB-2 NIW on her own. During the application process, she encountered a Request for Evidence (RFE) that questioned...</p>

								<a href="/success-story-rfe-data-analyst" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/success-story-frontend-engineer">
									<img height="" src="../resources/blog/blog47-1x1.webp" alt="eb2 niw engineer">
								</a>

								<div class="story">
									<vue-feather type="shield"></vue-feather> Success story
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/success-story-frontend-engineer">EB-2 NIW approval for frontend engineer</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>This case study examines the journey of Pawan, a frontend software engineer at a leading FAANG company, who successfully navigated the complex process of obtaining an EB2 National Interest Waiver (NIW) using Selfpetition AI.</p>

								<a href="/success-story-frontend-engineer" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/eb2niw-guide">
									<img height="" src="../resources/blog/blog46-1x1.webp" alt="eb2 niw guide">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> June 29, 2024
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/eb2niw-guide">EB-2 NIW Comprehensive Guide</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>Securing a U.S. green card via the EB-2 National Interest Waiver (NIW) involves a meticulously prepared I-140 petition. This detailed guide, based on personal and professional experiences, offers essential steps and insights.</p>

								<a href="/eb2niw-guide" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/stem-approvals">
									<img height="" src="../resources/blog/blog45-1x1.webp" alt="stem approvals">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> April 20, 2024
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/stem-approvals">EB-2 NIW approval rates for STEM</a></h4>

								<span>Author: <a href="#">Przemek</a></span>

								<p>While quarterly reports from USCIS provide valuable insights, a recent report delves deeper into the nuances of approval rates, particularly in the context of STEM (Science, Technology, Engineering, and Mathematics) versus non-STEM fields.</p>

								<a href="/stem-approvals" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>
					
					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/success-story-chips-engineer">
									<img height="" src="../resources/blog/blog44-1x1.webp" alt="chips engineer">
								</a>

								<div class="story">
									<vue-feather type="shield"></vue-feather> Success story
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/success-story-chips-engineer">EB-2 NIW approval for chips engineer</a></h4>

								<span>Author: <a href="#">Tom</a></span>

								<p>Meet Kevin, a seasoned chips engineer, who embarked on the EB-2 NIW immigration pathway via consular processing. Faced with geopolitical uncertainties surrounding Taiwan's semiconductor industry and buoyed by the Biden...</p>

								<a href="/success-story-chips-engineer" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/ai-blog">
									<img height="" src="../resources/blog/blog43-1x1.webp" alt="eb2 niw ai">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> April 5, 2024
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/ai-blog">Write EB-2 NIW with artificial intelligence</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>We offer two products: the Starter Kit, which consists of off-the-shelf standardized EB-2 NIW templates for various professions, and SelfPetitionAI, our recently launched service designed to reflect your personalized background in both the cover letter and reference...</p>

								<a href="/ai-blog" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/petition-length">
									<img height="" src="../resources/blog/blog42-1x1.webp" alt="eb2 niw petition length">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> January 3, 2024
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/petition-length">Finding the perfect length for your petition</a></h4>

								<span>Author: <a href="#">Tom</a></span>

								<p>Navigating the complex immigration landscape of the United States requires careful planning and attention to detail. One common question that arises during the petition process is, "How long should my EB-2 NIW (...)"</p>

								<a href="/petition-length" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/success-story-automotive-engineer">
									<img height="" src="../resources/blog/blog41-1x1.webp" alt="eb2 niw automotive engineer">
								</a>

								<div class="story">
									<vue-feather type="shield"></vue-feather> Success story
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/success-story-automotive-engineer">EB-2 NIW approval for automotive engineer</a></h4>

								<span>Author: <a href="#">Przemek</a></span>

								<p>Meet Michael, an accomplished Electronic Engineering Leader in the automotive industry, who successfully obtained approval for his EB2 NIW case in 2023. Despite initial skepticism from attorneys, Michael took matters into his own...</p>

								<a href="/success-story-automotive-engineer" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/l1-visa-during-layoffs">
									<img height="" src="../resources/blog/blog40-1x1.webp" alt="green card for L1 visa">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> November 7, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/l1-visa-during-layoffs">L1 visa holders during 2023 layoffs</a></h4>

								<span>Author: <a href="#">Tom</a></span>

								<p>As someone who was once on an L1 visa, I can personally attest to the significance of this journey. In 2019, I embarked on the green card application process, and by mid-2020, I had successfully secured it. This endeavor involved navigating a certain level of uncertainty.</p>

								<a href="/l1-visa-during-layoffs" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

							
					<div class="col-lg-12 col-md-12">
						<div class="pagination-area">
							<nav aria-label="Page navigation">
								<ul class="pagination justify-content-center">
									<li class="page-item active"><a class="page-link" href="#">1</a></li>
									<li class="page-item"><a class="page-link" href="/blog/2">2</a></li>
									<li class="page-item"><a class="page-link" href="/blog/3">3</a></li>
									<li class="page-item"><a class="page-link" href="/blog/4">4</a></li>
									<li class="page-item"><a class="page-link" href="/blog/5">5</a></li>
									<li class="page-item"><a class="page-link" href="/blog/6">6</a></li>
									<li class="page-item"><a class="page-link" href="/blog/2">Next</a></li>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Blog Area -->
    </div>
</template>
<script>
export default {
    name: 'BlogGrid1'
}
</script>
