var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('section',{staticClass:"blog-details-area"},[_c('div',{staticClass:"article-content"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('vue-feather',{attrs:{"type":"clock"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("January 3, 2023")])],1),_c('li',[_c('vue-feather',{attrs:{"type":"user"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("Tom")])],1)])])])]),_vm._m(1),_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"article-content"},[_c('h3',{staticClass:"subtitle"},[_vm._v("EB-2 NIW costs")]),_c('div',{staticClass:"blog-paragraph"},[_vm._v("The cost of the EB-2 NIW process varies depending on a number of factors, including the individual's specific circumstances and if you decide to work with a lawyer or file yourself. Some of the expenses associated with the EB-2 NIW process include:")]),_vm._m(2),_vm._m(3),_c('img',{staticStyle:{"margin-top":"2rem","margin-bottom":"1rem"},attrs:{"src":require("../resources/i140.png")}}),_c('img',{attrs:{"src":require("../resources/i485.png")}}),_c('div',{staticStyle:{"font-style":"italic","margin-bottom":"4rem","font-size":"12px"}},[_vm._v("Source: U.S. Citizenship and Immigration Services Fee Schedule and Changes to Certain Other Immigration Benefit Request Requirements. A Proposed Rule by the Homeland Security Department on 01/04/2023")]),_vm._m(4),_vm._m(5),_c('PriceOffer',{staticStyle:{"margin-bottom":"4rem"}}),_c('CarouselGreenCard',{staticStyle:{"margin-bottom":"4rem"}}),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area2"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('div',{staticClass:"article-tags"},[_c('a',{attrs:{"href":"#"}},[_vm._v("EB-2 NIW BLOG")])]),_c('h1',{staticStyle:{"font-weight":"600","margin-top":"30px","text-align":"left"}},[_vm._v("What is the cost of EB-2 NIW?")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../resources/blog/blog5-16x9.webp"),"alt":"EB2 NIW self petition templates"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Filing fees:")]),_vm._v(" The USCIS (U.S. Citizenship and Immigration Services) charges a fee for filing the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Form I-140")]),_vm._v(", which is the Immigrant Petition for Alien Worker and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Form I-485")]),_vm._v(", which is Application to Register Permanent Residence or Adjust Status. "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("As of "),_c('span',{staticStyle:{"font-style":"italic"}},[_vm._v("February 2023")]),_vm._v(", the fee for the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Form I-140")]),_vm._v(" is $700, and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("I-485")]),_vm._v(" is $1,225 (including biometrics).")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("USCIS fees are likely to increase in the near future. Please follow the Proposed Fee Rule published "),_c('a',{staticStyle:{"font-weight":"600","text-decoration":"underline"},attrs:{"href":"https://www.uscis.gov/newsroom/news-releases/uscis-issues-proposed-rule-to-adjust-certain-immigration-and-naturalization-fees"}},[_vm._v("HERE")]),_vm._v(" and "),_c('a',{staticStyle:{"font-weight":"600","text-decoration":"underline"},attrs:{"href":"https://www.federalregister.gov/documents/2023/01/04/2022-27066/us-citizenship-and-immigration-services-fee-schedule-and-changes-to-certain-other-immigration"}},[_vm._v("HERE")]),_vm._v(".")]),_vm._v(" The proposal assumes increasing the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("I-140")]),_vm._v(" fee to $715 ($700 currently) and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("I-485")]),_vm._v(" fee to $1,540 ($1,225 currently, with biometrics). It also introduces additional fees for the Forms "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("I-131")]),_vm._v(" (Application for Travel Document) and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("I-765")]),_vm._v(" (Application for Employment Authorization). Before both of them were included free of charge as part of the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("I-485")]),_vm._v(" fee.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Lawyer fees:")]),_vm._v(" Hiring a lawyer is optional, but could be helpful if you are unfamiliar with immigration law, the requirements for the visa or need somebody to guide you through the admin process. Lawyer fees vary widely, so it is important to shop around and get quotes from several different companies if you decide to choose one. "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("As authors of this blog, we researched 30+ lawyers while preparing our own application (took us 6 months of work!). The prices ranged from $5k to $20k for the family of three (main applicant, and two dependants).")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Check "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("Starter Kit for EB-2 NIW")]),_vm._v(", which includes self-petition Green Card templates for professionals:")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Expert witness fees:")]),_vm._v(" In some cases, individuals may need to provide expert witness letters to support their application. These letters are written by individuals with expertise in the individual's field, and they attest to the individual's exceptional ability. Expert witness fees can range from a few hundred dollars to several thousand dollars, depending on the individual's field and the number of letters required. These are not mandatory. "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("As authors of this blog")]),_vm._v(", we didn't use expert witness letters, and relied purely on reference letters which were free of charge. For background: applied through EB-2 NIW, advanced degree.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Miscellaneous expenses:")]),_vm._v(" There may be other miscellaneous expenses associated with the EB-2 NIW process, such as fees for obtaining transcripts, paying for translation services, and more.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem","padding-bottom":"0px"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('a',{staticClass:"btn btn-primary btn-assess",attrs:{"href":"/are-you-eligible"}},[_vm._v("Are you eligible for EB-2 NIW?")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph",staticStyle:{"margin-bottom":"50px"}},[_vm._v("It is important to keep in mind that the cost of the EB-2 NIW process is an investment in the individual's future, as obtaining a Green Card can have a significant impact on your career and financial prospects in the United States. And that's exactly what "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("the authors of this blog experienced: with a "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("self-sponsored Green Card")]),_vm._v(" we were able to choose the employer freely (before L1 visa restricted us from changing a job), and with the layoffs happening in 2022-2023, Green Card gave us peace of mind")]),_vm._v(" to stay in the US on our own terms.")])
}]

export { render, staticRenderFns }