<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <SuccessStoryChipsEngineer></SuccessStoryChipsEngineer>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import SuccessStoryChipsEngineer from "../blog-components/SuccessStoryChipsEngineer";


export default {
  name: 'SuccessStoryChipsEngineerView',
  components: {
    SuccessStoryChipsEngineer,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: " EB-2 NIW approval for chips engineer - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "Meet Kevin, a seasoned chips engineer, who embarked on the EB-2 NIW immigration pathway via consular processing. Faced with geopolitical uncertainties surrounding Taiwan's semiconductor industry and buoyed by the Biden administration's Chips Act, Kevin sought a pathway to immigration to the United States. His journey, powered by SelfPetitionAI, underscores the intersection of technological expertise and immigration ambition. Let's explore Kevin's journey and the role of SelfPetitionAI in his successful immigration application."},
      ]
    }
  }
}
</script>
