var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('section',{staticClass:"blog-details-area"},[_c('div',{staticClass:"article-content"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('vue-feather',{attrs:{"type":"clock"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("March 9, 2023")])],1),_c('li',[_c('vue-feather',{attrs:{"type":"user"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("Natalie")])],1)])])])]),_vm._m(1),_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"article-content"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Success story: EB-2 NIW")]),_vm._m(2),_vm._m(3),_c('div',{staticClass:"signupSelfPetition row justify-content-center align-items-center"},[_c('div',{staticClass:"col-xl-6 col-lg-7"},[_c('signup-self-petition')],1)]),_vm._m(4),_vm._m(5),_c('PriceOffer',{staticStyle:{"margin-bottom":"4rem"}}),_c('CarouselGreenCard',{staticStyle:{"margin-bottom":"4rem"}}),_vm._m(6),_vm._m(7),_vm._m(8),_c('div',{staticClass:"blog-paragraph",staticStyle:{"padding-bottom":"6rem"}},[_vm._v("This success story demonstrates the benefits of the EB-2 National Interest Waiver for healthcare professionals and highlights the importance of presenting a compelling and well-documented case to the USCIS. With the right preparation, it is possible for skilled and experienced healthcare professionals to achieve their immigration goals and pursue their careers in the United States.")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area2"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('span',{staticClass:"article-tags"},[_c('a',{attrs:{"href":"#"}},[_vm._v("EB-2 NIW BLOG")])]),_c('span',{staticClass:"article-tags2"},[_c('a',{attrs:{"href":"#"}},[_vm._v("SUCCESS STORY")])]),_c('h1',{staticStyle:{"font-weight":"600","margin-top":"30px","text-align":"left"}},[_vm._v("EB-2 NIW approval for healthcare professional")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../resources/blog/blog21-16x9.webp"),"alt":"NIW template healthcare"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("The following is "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("Kayla's success story of an EB-2 National Interest Waiver (NIW) approval for a dental healthcare professional.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Kayla is a highly skilled and experienced "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("dental healthcare professional from overseas")]),_vm._v(" who has been practicing in the United States for many years. She learned about the EB-2 National Interest Waiver (NIW) and realized that it could provide a fast path to permanent residency. She prepared a compelling case utilizing templates from "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("EB-2 NIW Starter Kit")]),_vm._v(" where she highlighted her unique qualifications and contributions to the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("field of dentistry")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Kayla's arguments centered on the fact that her work as a "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("dental healthcare professional")]),_vm._v(" had a significant "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("positive impact on the health and well-being of countless patients")]),_vm._v(", and that her continued presence in the United States was in the national interest. She also provided "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("evidence of her "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("clinical expertise")]),_vm._v(", the large number of dentists entering the field who have benefited from her online educational materials, and her leadership in the dental community.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Check "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("Starter Kit for EB-2 NIW")]),_vm._v(", which includes self-petition Green Card templates for professionals:")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("In addition, Kayla "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("demonstrated that her work had garnered widespread recognition and support from her colleagues, patients, and the broader community")]),_vm._v(". She submitted letters of recommendation from respected "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("dental healthcare professionals and community leaders")]),_vm._v(" attesting to her contributions and the importance of her work.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("The USCIS was thoroughly impressed by Kayla's application and quickly approved her EB-2 National Interest Waiver. With the green card in hand, Kayla was free to continue her work as a "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("dental healthcare professional")]),_vm._v(" without worrying about immigration-related issues. She was able to expand her practice, take on new challenges, and continue making a positive impact on the lives of patients in the United States.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem","padding-bottom":"0px"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('a',{staticClass:"btn btn-primary",staticStyle:{"margin-top":"3rem","margin-bottom":"2rem"},attrs:{"href":"/physician-dentist-sample"}},[_vm._v("DENTIST-PHYSICIAN TEMPLATE")])])])])
}]

export { render, staticRenderFns }