<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <span class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </span>
            <span class="article-tags2">
              <a href="#">SUCCESS STORY</a>
            </span>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">EB-2 NIW approval for data analyst</h1>
          </div>
        </div>
      </div>
    </div>

    <section class="blog-details-area">
        <div class="article-content">
          <div class="article-margin">
            <div class="entry-meta">
              <ul>
                <li><vue-feather type="clock"></vue-feather> <a href="#">April 29, 2023</a></li>
                <li><vue-feather type="user"></vue-feather> <a href="#">Natalie</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <img src="../resources/blog/blog26-16x9.webp" alt="NIW template data analyst">
        </div>
        <div class="article-margin">
          <div class="article-content">
            <h3 class="subtitle">Success story: EB-2 NIW</h3>

            <div class="blog-paragraph">Jason applied for permanent residency in March 2021 through the <span style="font-weight: 500; font-style: italic">self-petition route of National Interest Waiver (NIW)</span>, classified as EB2. Jason's country of birth had a current EB2 priority date, allowing for a short time frame for permanent residency if successful.</div>

            <div class="signupSelfPetition row justify-content-center align-items-center">
              <div class="col-xl-6 col-lg-7">
                <signup-self-petition></signup-self-petition>
              </div>
            </div>

            <div class="blog-paragraph">Jason had a <span style="background-color: rgba(68, 206, 111, 0.25)">bachelor's degree in <span style="font-weight: 500; font-style: italic">computer science</span> and had worked for a well-known internet company before pursuing graduate studies in the US. He was also <span style="font-weight: 500; font-style: italic">about to graduate his MBA</span></span> and was in the process of looking for a job.</div>

            <div class="blog-paragraph">Jason had <span style="font-weight: 500; font-style: italic">no published papers</span> in a traditional academic venue, and his research results were not published or publicized due to the confidential nature of internal business practices.</div>

            <div class="blog-paragraph">Jason filed a NIW based I-140 petition <span style="background-color: rgba(68, 206, 111, 0.25)">leveraging templates from <a href="/home" style="text-decoration: underline">EB-2 NIW Starter Kit - data analyst.</a></span></div>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

            <div class="blog-paragraph">However, as expected, he received a <span style="font-weight: 500; font-style: italic">Request for Evidence (RFE)</span>, asking about his career plan and intended employment.</div>

            <div class="blog-paragraph">By the time he received the RFE, Jason had already graduated MBA and <span style="background-color: rgba(68, 206, 111, 0.25)">landed a job as a Data Analyst for a <span style="font-weight: 500; font-style: italic">crypto startup</span>. He responded to the RFE, providing evidence to support the original claim that he intended to continue work related to <span style="font-weight: 500; font-style: italic">internet security and identity protection.</span></span></div>

            <div class="blog-paragraph">Soon after, Jason received the good news that USCIS has approved his NIW petition. With his <span style="font-weight: 500; font-style: italic">NIW petition approved</span>, Jason's journey toward permanent residency gained momentum. The approval brought a sense of relief and accomplishment, signaling a new chapter in his life. He could now <span style="font-weight: 500; font-style: italic">explore a much wider range of professional opportunities</span>, no longer subject to employer discretion to sponsor his visa. Celebrating this milestone, Jason continued to excel in his role as a <span style="font-weight: 500; font-style: italic">Data Analyst for the crypto startup</span>. The unique blend of his computer science background, MBA education, and experience in internet security positioned him as a valuable asset in the dynamic field of cryptocurrency.</div>

            <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
              <div class="col-xl-7 col-lg-9">
                <div class="row justify-content-center">
                  <a href="/data-scientist-analyst-sample" class="btn btn-primary" style="margin-top: 3rem; margin-bottom: 2rem">DATA ANALYST TEMPLATE</a>
                </div>
              </div>
            </div>

            <div class="blog-paragraph" style="margin-bottom: 3rem">Reflecting on the challenges he overcame, the experience reinforced Jason's belief in the importance of <span style="font-weight: 500; font-style: italic">determination</span> and <span style="font-weight: 500; font-style: italic">strategic planning</span>. With permanent residency secured, he set his sights on contributing to the innovation and progress of his adopted homeland. Continuing to make meaningful strides in his career, Jason's story became an inspiration for others navigating the intricate path toward achieving their <span style="font-weight: 500; font-style: italic">American dream</span>.</div>

          </div>
        </div>
    </section>

  </div>
</template>
<script>

import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";
import SignupSelfPetition from "../components/SignupSelfPetition.vue";

export default {
  name: 'SuccessStoryDataAnalyst',
  components: {
    PriceOffer,
    CarouselGreenCard,
    SignupSelfPetition
  }
}
</script>
