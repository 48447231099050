<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <span class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </span>
            <span class="article-tags2">
              <a href="#">SUCCESS STORY</a>
            </span>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">EB-2 NIW approval for HR consultant</h1>
          </div>
        </div>
      </div>
    </div>

    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">March 1, 2023</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Przemek</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog19-16x9.webp" alt="NIW template HR human resources">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">Success story: EB-2 NIW</h3>

          <div class="blog-paragraph"><span style="background-color: rgba(68, 206, 111, 0.25)">Helen, is a consultant who has worked in the field of Human Resources for over 10 years with</span> a lot of experience in helping companies expand their workforce in a sustainable way. However, she faced a challenge when she decided to immigrate to the United States. She knew that her skills and experience would be highly valued in the US, but she <span style="font-weight: 500; font-style: italic">didn’t have any research to back up her claims</span>.</div>

          <div class="signupSelfPetition row justify-content-center align-items-center">
            <div class="col-xl-6 col-lg-7">
              <signup-self-petition></signup-self-petition>
            </div>
          </div>

          <div class="blog-paragraph">Despite this challenge, Helen decided to go ahead with her immigration journey and applied for the EB-2 National Interest Waiver (NIW) visa. Leveraging <a href="/home" style="text-decoration: underline">EB-2 NIW Starter Kit</a> for professionals, she put together a comprehensive petition package that highlighted her skills, experience, and achievements in the field of Human Resources. She included letters of reference from industry experts and previous clients, as well as a detailed explanation of the important work she had done. There were <span style="background-color: rgba(68, 206, 111, 0.25)">many companies which benefited from her help in expanding the workforce in a sustainable way. Helen was also an expert in unlocking companies growth potential by filling in positions which were difficult to be filled in.</span></div>

          <div class="blog-paragraph">Check <a href="/home" style="text-decoration: underline">Starter Kit</a> for EB-2 NIW, which includes self-petition Green Card templates for professionals:</div>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

          <div class="blog-paragraph">After several months of waiting, Helen received the great news that her petition had been approved. She was overjoyed that her skills and experience in Human Resources had been recognized as being of national importance, and that she could now move to the United States to continue her career. After several months of waiting, Helen received the great news that her petition had been approved. She was overjoyed that her skills and experience in Human Resources had been recognized as being of national importance, and that she could now move to the United States to continue her career.</div>

          <div class="blog-paragraph">Helen <span style="font-weight: 500; font-style: italic">moved to the US and started working</span> in her field soon after. She quickly became a sought-after consultant, and her skills and expertise were highly valued by her US clients. She continued to work in the field for many years, helping organizations to improve their human resource management and make a positive impact on the employees.</div>

          <div class="blog-paragraph">This success story is a testament to the fact that, even <span style="font-weight: 500; font-style: italic">without research</span>, individuals can still be approved for the EB-2 NIW visa if they can demonstrate their value and the importance of their work. It serves as an inspiration for those who are seeking to immigrate to the US and continue careers in their field of expertise.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                <a href="/hr-recruiter-sample" class="btn btn-primary" style="margin-top: 3rem; margin-bottom: 2rem">HR-RECRUITER TEMPLATE</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>
<script>

import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";
import SignupSelfPetition from "../components/SignupSelfPetition.vue";

export default {
  name: 'SuccessStoryHRConsultant',
  components: {
    PriceOffer,
    CarouselGreenCard,
    SignupSelfPetition
  }
}
</script>
