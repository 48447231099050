<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <span class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </span>
            <span class="article-tags2">
              <a href="#">SUCCESS STORY</a>
            </span>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">EB-2 NIW approval for healthcare professional</h1>
          </div>
        </div>
      </div>
    </div>

    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">March 9, 2023</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Natalie</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog21-16x9.webp" alt="NIW template healthcare">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">Success story: EB-2 NIW</h3>

          <div class="blog-paragraph">The following is <span style="background-color: rgba(68, 206, 111, 0.25)">Kayla's success story of an EB-2 National Interest Waiver (NIW) approval for a dental healthcare professional.</span></div>

          <div class="blog-paragraph">Kayla is a highly skilled and experienced <span style="font-weight: 500; font-style: italic">dental healthcare professional from overseas</span> who has been practicing in the United States for many years. She learned about the EB-2 National Interest Waiver (NIW) and realized that it could provide a fast path to permanent residency. She prepared a compelling case utilizing templates from <a href="/home" style="text-decoration: underline">EB-2 NIW Starter Kit</a> where she highlighted her unique qualifications and contributions to the <span style="font-weight: 500; font-style: italic">field of dentistry</span>.</div>

          <div class="signupSelfPetition row justify-content-center align-items-center">
            <div class="col-xl-6 col-lg-7">
              <signup-self-petition></signup-self-petition>
            </div>
          </div>

          <div class="blog-paragraph">Kayla's arguments centered on the fact that her work as a <span style="font-weight: 500; font-style: italic">dental healthcare professional</span> had a significant <span style="background-color: rgba(68, 206, 111, 0.25)">positive impact on the health and well-being of countless patients</span>, and that her continued presence in the United States was in the national interest. She also provided <span style="background-color: rgba(68, 206, 111, 0.25)">evidence of her <span style="font-weight: 500; font-style: italic">clinical expertise</span>, the large number of dentists entering the field who have benefited from her online educational materials, and her leadership in the dental community.</span></div>

          <div class="blog-paragraph">Check <a href="/home" style="text-decoration: underline">Starter Kit for EB-2 NIW</a>, which includes self-petition Green Card templates for professionals:</div>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

          <div class="blog-paragraph">In addition, Kayla <span style="background-color: rgba(68, 206, 111, 0.25)">demonstrated that her work had garnered widespread recognition and support from her colleagues, patients, and the broader community</span>. She submitted letters of recommendation from respected <span style="font-weight: 500; font-style: italic">dental healthcare professionals and community leaders</span> attesting to her contributions and the importance of her work.</div>

          <div class="blog-paragraph">The USCIS was thoroughly impressed by Kayla's application and quickly approved her EB-2 National Interest Waiver. With the green card in hand, Kayla was free to continue her work as a <span style="font-weight: 500; font-style: italic">dental healthcare professional</span> without worrying about immigration-related issues. She was able to expand her practice, take on new challenges, and continue making a positive impact on the lives of patients in the United States.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem; padding-bottom: 0px">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                <a href="/physician-dentist-sample" class="btn btn-primary" style="margin-top: 3rem; margin-bottom: 2rem">DENTIST-PHYSICIAN TEMPLATE</a>
              </div>
            </div>
          </div>

          <div class="blog-paragraph" style="padding-bottom: 6rem">This success story demonstrates the benefits of the EB-2 National Interest Waiver for healthcare professionals and highlights the importance of presenting a compelling and well-documented case to the USCIS. With the right preparation, it is possible for skilled and experienced healthcare professionals to achieve their immigration goals and pursue their careers in the United States.</div>

        </div>
      </div>
    </section>

  </div>
</template>
<script>

import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";
import SignupSelfPetition from "../components/SignupSelfPetition.vue";

export default {
  name: 'SuccessStoryDentist',
  components: {
    PriceOffer,
    CarouselGreenCard,
    SignupSelfPetition
  }
}
</script>
