<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <div class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </div>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">Empowering EB-2 NIW applicants and building a thriving community</h1>
          </div>
        </div>
      </div>
    </div>

    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">October 25, 2023</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Przemek</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog39-16x9.webp" alt="Self Petition USA track record">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">Paving the way for EB-2 NIW success</h3>

          <div class="blog-paragraph">If you're considering the <span style="font-weight: 500; font-style: italic">EB-2 NIW</span>, you're not alone; many applicants have found success in this journey, and it's a decision that can profoundly impact your life and career. In this article, we'll explore why we set up <span style="font-weight: 500; font-style: italic">Self Petition USA</span> and how it can be a transformative step towards your American dream.</div>

          <h5>Our background and motivation</h5>
          <div class="blog-paragraph">A <span style="font-weight: 500; font-style: italic">family from Europe</span>, who dared to dream of a new life in the United States. With determination and perseverance, we successfully secured a green card through the EB-2 NIW in just <span style="font-weight: 500; font-style: italic">nine months</span>. <span style="background-color: rgba(68, 206, 111, 0.25)">This journey exposed us to a crucial need in the immigration landscape – the lack of information and support for professionals <span style="font-weight: 500; font-style: italic">outside the realm of research and PhD candidates</span>. Even legal experts seemed inclined towards applicants with a research-oriented background. It's worth noting that we ourselves <span style="font-weight: 500; font-style: italic">lacked publications</span> or <span style="font-weight: 500; font-style: italic">citations to our names</span>.</span></div>

          <div class="blog-paragraph">It was this realization that led us to create <a href="/home" style="text-decoration: underline">Self Petition USA</a>, a platform designed to provide <a href="/home" style="text-decoration: underline">I-140 templates</a> tailored to a wide range of professions. We understood that aspiring immigrants come from various backgrounds, and their expertise should be recognized and valued. <span style="font-weight: 500; font-style: italic">Our templates</span> cater to professionals such as:</div>

          <ul class="features-list">
            <li><vue-feather type="check"></vue-feather><a href="/banking-finance-sample" target="_blank" style="text-decoration: underline">Bankers</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/banking-finance-sample" target="_blank" style="text-decoration: underline">Finance Experts</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/banking-finance-sample" target="_blank" style="text-decoration: underline">PE & VC Investors</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/software-engineer-it-sample" target="_blank" style="text-decoration: underline">Software Engineers</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/software-engineer-it-sample" target="_blank" style="text-decoration: underline">IT</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/data-scientist-analyst-sample" target="_blank" style="text-decoration: underline">Data Scientists</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/data-scientist-analyst-sample" target="_blank" style="text-decoration: underline">Data Analysts</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/product-project-mgmt-sample" target="_blank" style="text-decoration: underline">Product & Project & Program Managers</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/network-electrical-engineer-sample" target="_blank" style="text-decoration: underline">Network & Electrical Engineers</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/electro-mechanical-engineer-sample" target="_blank" style="text-decoration: underline">Electro-mechanical Engineers</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/chemical-engineer-sample" target="_blank" style="text-decoration: underline">Chemical Engineers</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/marketing-sample" target="_blank" style="text-decoration: underline">Marketing Professionals</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/hr-recruiter-sample" target="_blank" style="text-decoration: underline">HR Professionals</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/hr-recruiter-sample" target="_blank" style="text-decoration: underline">Recruiters</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/science-teacher-sample" target="_blank" style="text-decoration: underline">Science Teachers</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/physician-dentist-sample" target="_blank" style="text-decoration: underline">Physicians</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/physician-dentist-sample" target="_blank" style="text-decoration: underline">Dentists</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/pilot-sample" target="_blank" style="text-decoration: underline">Pilots & Aeronautical Engineers</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/tech-entrepreneur-sample" target="_blank" style="text-decoration: underline">Tech Entrepreneurs</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/physician-dentist-sample" target="_blank" style="text-decoration: underline">Brick-and-mortar Business Owners</a></li>            
          </ul>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>
          
          <h5>Self Petition USA's impact</h5>
          <div class="blog-paragraph"><span style="background-color: rgba(68, 206, 111, 0.25)">Self Petition USA has experienced <span style="font-weight: 500; font-style: italic">organic growth</span>, thanks to the support and success stories of our customers. As of the <span style="font-weight: 500; font-style: italic">third quarter of 2023</span>, we have proudly assisted <span style="font-weight: 500; font-style: italic">more than 60 applicants</span> on their EB-2 NIW journey, and the results have been truly remarkable.</span> What's even more encouraging is that almost half of these individuals have become valued members of our vibrant <span style="font-weight: 500; font-style: italic">Discord Community</span>.</div>
          <img class="col-lg-6 col-md-8 col-12" style="border: #5457c1 solid 1px; margin-bottom: 4rem" src="../resources/stripe.webp">

          <h5>Building a supportive Discord Community</h5>
          <div class="blog-paragraph">When you opt for the <a href="/faq" style="text-decoration: underline">Starter Kit PRO</a> from <a href="/home" style="text-decoration: underline">Self Petition USA</a>, you gain access to our exclusive Discord community. <span style="background-color: rgba(68, 206, 111, 0.25)">This online space is dedicated to providing <span style="font-weight: 500; font-style: italic">additional resources</span>, <span style="font-weight: 500; font-style: italic">answering your questions</span>, and keeping you <span style="font-weight: 500; font-style: italic">updated on the EB-2 NIW process</span>.</span> It's a place where you can connect with others who are navigating the same immigration path, exchange valuable suggestions and tips, and stay informed about any developments that could affect your application.</div>
          <img class="col-lg-6 col-md-8 col-12" style="border: #5457c1 solid 1px; margin-bottom: 4rem" src="../resources/discord.webp">

          <h5>Hear from our customers</h5>
          <div class="blog-paragraph">Don't just take our word for it; check out the testimonials on <a href="/home" style="text-decoration: underline">our website</a> and the reviews on <a target="_blank" href="https://www.google.com/search?q=selfpetitionusa&rlz=1C5CHFA_enUS915US915&oq=selfpetitionusa&aqs=chrome.0.69i59j69i60l5.2146j0j7&sourceid=chrome&ie=UTF-8&bshm=rime/1#lrd=0x8b2e746496699c67:0xcfa4672ec87a3228,1,,,," style="text-decoration: underline">Google</a> to hear about the success stories of our clients. At Self Petition USA, we are committed to <span style="font-weight: 500; font-style: italic">helping professionals from diverse backgrounds</span> achieve their American dream through the EB-2 NIW program. Join us on this incredible journey and make your mark in the land of opportunity.</div>
            <div style="margin-bottom: 3rem">
              <a class="googleReviews" style="width: 200px; margin-right: 2rem" target="_blank" href="https://www.google.com/search?q=selfpetitionusa&rlz=1C5CHFA_enUS915US915&oq=selfpetitionusa&aqs=chrome.0.69i59j69i60l5.2146j0j7&sourceid=chrome&ie=UTF-8&bshm=rime/1#lrd=0x8b2e746496699c67:0xcfa4672ec87a3228,1,,,,">
                <img src="../resources/googleLogo.webp" alt="Google reviews self petition usa"/>
                <div style="font-size: 11px; font-style: italic">Click for reviews on Google</div>
              </a>
              <a class="googleReviews" style="width: 300px" target="_blank" href="/#testimonials">
                <img src="../resources/selfpetitionusa-logo.svg" alt="SelfPetitionUsa logo"/>
                <div style="font-size: 11px; font-style: italic">Click for testimonials on our website</div>
              </a>
            </div>
        </div>
      </div>
    </section>

  </div>
</template>
<script>

import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";

export default {
  name: 'EB2NIWSelfPetitionUsa',
  components: {
    PriceOffer,
    CarouselGreenCard
  }
}
</script>
