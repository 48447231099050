<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <div class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </div>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">What are the benefits of EB-2 NIW petition?</h1>
          </div>
        </div>
      </div>
    </div>
    
    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">February 1, 2023</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Przemek</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog11-16x9.webp" alt="niw petition letter sample">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">Benefits of EB-2 National Interest Waiver</h3>

          <div class="blog-paragraph">The EB-2 National Interest Waiver (NIW) is a type of immigration visa that allows individuals with advanced degrees or exceptional abilities to work in the United States without the need for a labor certification. This means that <span style="background-color: rgba(68, 206, 111, 0.25)">the process is faster and more straightforward than other types of immigration visas, as there is no requirement to first find a job offer</span> from a US employer.</div>

          <div class="blog-paragraph">Here are some of the benefits of petitioning under EB-2 NIW:</div>


          <ul class="features-list">
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">No Job Offer Required:</span> Unlike other types of immigration visas, the EB-2 NIW does not require a job offer from a US employer. This makes it easier for individuals to immigrate to the US without having to first secure a job offer. With an EB-2 NIW, individuals can also start their own business or work as a self-employed individual.</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Faster Processing Time:</span> Since there is no requirement for a labor certification, the EB-2 NIW process can be completed more quickly than other types of visas. This can be especially beneficial for individuals who need to start working in the US as soon as possible.</li>
            
            <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
              <div class="col-xl-7 col-lg-9">
                <div class="row justify-content-center">
                  <router-link class="btn btn-primary" :to="{ name: 'Home', params: { location: 'Templates' }}">EB-2 NIW Templates</router-link>
                </div>
              </div>
            </div>
            
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Greater Flexibility:</span> With an EB-2 NIW, individuals can work for any employer in the US, without the need to first secure a job offer. This provides greater flexibility and allows individuals to pursue their desired career path.
            </li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Process continuation:</span> The process can continue even if you change or lose a job. Do you remember the 2022-2023 layoffs period? <span style="background-color: rgba(68, 206, 111, 0.25)">That's when the authors of this blog truly appreciated the effort of securing a Green Card earlier.</span></li>
          </ul>

          <div>Check <a href="/home" style="text-decoration: underline">Starter Kit for EB-2 NIW</a>, which includes self-petition Green Card templates for professionals:</div>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

          <div class="blog-paragraph">In conclusion, the EB-2 National Interest Waiver is an attractive option for individuals who have advanced degrees or exceptional abilities and want to work in the US. With <span style="font-weight: 500; font-style: italic">faster processing times</span>, <span style="font-weight: 500; font-style: italic">greater flexibility</span>, and <span style="font-weight: 500; font-style: italic">no requirement for a job offer</span>, the EB-2 NIW offers many benefits to eligible individuals.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                  <a href="/are-you-eligible" class="btn btn-primary btn-assess">Are you eligible for EB-2 NIW?</a>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>

  </div>
</template>
<script>

import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";

export default {
  name: 'EB2NIWBenefits',
  components: {
    PriceOffer,
    CarouselGreenCard
  }
}
</script>
