<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <span class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </span>
            <span class="article-tags2">
              <a href="#">SUCCESS STORY</a>
            </span>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">EB-2 NIW approval for investment banker</h1>
          </div>
        </div>
      </div>
    </div>
    
    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">March 15, 2023</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Natalie</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog22-16x9.webp" alt="NIW template banking finance">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">Success story: EB-2 NIW</h3>

          <div class="blog-paragraph">In this success story, we will highlight <span style="background-color: rgba(68, 206, 111, 0.25)">Mark's case, a Vice President in Investment Banking</span> who was able to secure an EB-2 NIW approval. This individual had a long and successful career in <span style="font-weight: 500; font-style: italic">investment banking</span>, having worked for several prominent <span style="font-weight: 500; font-style: italic">financial institutions</span>. He had a strong track record of making significant contributions to the industry, including several innovative financial products that had been widely adopted by the market.</div>

          <div class="signupSelfPetition row justify-content-center align-items-center">
            <div class="col-xl-6 col-lg-7">
              <signup-self-petition></signup-self-petition>
            </div>
          </div>

          <div class="blog-paragraph">Mark had very little idea what kind of arguments he could use to support the NIW case. However, with the help of our <a href="/home" style="text-decoration: underline">EB-2 NIW Starter Kit</a> which includes a profession-specific NIW builder with examples of arguments and wording to be used in the application, and additional support from the immigration lawyer, Mark was able to successfully obtain an EB-2 NIW approval.</div>

          <div class="blog-paragraph">Check <a href="/home" style="text-decoration: underline">Starter Kit for EB-2 NIW</a>, which includes self-petition Green Card templates for professionals:</div>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

          <div class="blog-paragraph">In his application he demonstrated that the Vice President's skills and experience made him a valuable asset to the United States, and that the contributions in the investment banking field would be in the national interest. He provided <span style="background-color: rgba(68, 206, 111, 0.25)">extensive evidence of his professional achievements, such as the work in complex financial transactions, salary much above the market average, and contribution to unlocking the hiring process by his corporate clients</span> who received funds from his bank.</div>

          <div class="blog-paragraph">The EB-2 NIW approval allowed Mark to bypass the usual labor certification process, which can be lengthy and time-consuming. This allowed him to achieve his dream of living and working permanently in the United States. Mark's success story highlights <span style="font-weight: 500; font-style: italic">the importance of using strong but simple arguments</span> in the NIW case, which could be achieved with our <a href="/home" style="text-decoration: underline">EB-2 NIW templates for professionals</a>, with the immigration lawyer, or as a combination of both.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                  <a href="/banking-finance-sample" class="btn btn-primary" style="margin-top: 3rem; margin-bottom: 2rem">Banking-Finance Template</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>
<script>

import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";
import SignupSelfPetition from "../components/SignupSelfPetition.vue";

export default {
  name: 'SuccessStoryBanker',
  components: {
    PriceOffer,
    CarouselGreenCard,
    SignupSelfPetition
  }
}
</script>
