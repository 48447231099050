<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <PricingGreenCard style="padding-top: 10rem"></PricingGreenCard>
      <FeedbackGreenCard></FeedbackGreenCard>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
  import ExternalHeaderGreenCard from "./components/ExternalHeaderGreenCard";
  import ExternalFooterGreenCard from "./components/ExternalFooterGreenCard";
  import PricingGreenCard from './components/PricingGreenCard';
  import FeedbackGreenCard from './components/FeedbackGreenCard';

  export default {
    name: 'HomeGreenCardPricing',
    components: {
      ExternalHeaderGreenCard,
      ExternalFooterGreenCard,
      PricingGreenCard,
      FeedbackGreenCard,
    },
    data() {
      return {
        currentUrl: ''
      }
    },

    watch: {
      '$route'(pathUrl){
        this.currentUrl = pathUrl.path;
      }
    },

    mounted() {
      this.currentUrl = window.location.pathname;
    },
    metaInfo() {
      return {
        title: "Pricing",
        meta: [
          { vmid: 'description', name: 'description', content: "We prioritize transparency in our pricing structure. No hidden fees or surprises – what you see is what you get. Our commitment is to provide you with a clear understanding of the investment you're making in your future."},
        ]
      }
    }
  }
</script>
