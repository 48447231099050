var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('section',{staticClass:"blog-details-area"},[_c('div',{staticClass:"article-content"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('vue-feather',{attrs:{"type":"clock"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("January 22, 2023")])],1),_c('li',[_c('vue-feather',{attrs:{"type":"user"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("Tom")])],1)])])])]),_vm._m(1),_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"article-content"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Visa Bulletin")]),_vm._m(2),_vm._m(3),_vm._m(4),_c('PriceOffer',{staticStyle:{"margin-bottom":"4rem"}}),_c('CarouselGreenCard',{staticStyle:{"margin-bottom":"4rem"}}),_vm._m(5),_vm._m(6),_vm._m(7)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area2"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('div',{staticClass:"article-tags"},[_c('a',{attrs:{"href":"#"}},[_vm._v("EB-2 NIW BLOG")])]),_c('h1',{staticStyle:{"font-weight":"600","margin-top":"30px","text-align":"left"}},[_vm._v("What is the USCIS Visa Bulletin?")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../resources/blog/blog8-16x9.webp"),"alt":"eb2 niw cover letter sample"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("The "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("USCIS Visa Bulletin")]),_vm._v(" is a monthly publication issued by the United States Citizenship and Immigration Services (USCIS) that provides updates on the availability of immigrant visas for individuals")]),_vm._v(" seeking to become permanent residents of the United States. It is an important tool for individuals and their immigration lawyers, as it helps determine the priority date for when an individual can apply for a Green Card.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("The visa bulletin lists the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("cut-off dates")]),_vm._v(" for each category of immigrant visas, including family-sponsored visas and employment-based visas. The "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("cut-off date")]),_vm._v(" is the date after which an applicant is eligible to apply for a Green Card. The visa bulletin is updated each month to reflect the current demand for visas and the availability of visas.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Check "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("Starter Kit for EB-2 NIW")]),_vm._v(", which includes self-petition Green Card templates for professionals:")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("The USCIS Visa Bulletin plays a crucial role in the immigration process, particularly for individuals who have already filed an immigration petition. For these individuals, the "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("bulletin determines when they can proceed to the final step of their immigration process, known as "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Adjustment of Status")]),_vm._v(" or "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Consular Processing")]),_vm._v(".")]),_vm._v(" This final step is essential for obtaining a Green Card. However, it's important to note that there can be a "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("backlog")]),_vm._v(" in the number of visas available, leading to extended wait times for applicants. Navigating through these complexities requires a thorough understanding of the visa bulletin and its regular updates to make informed decisions about the immigration timeline.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("In summary, the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("USCIS Visa Bulletin")]),_vm._v(" is an important resource for individuals seeking to become permanent residents of the United States. It provides updates on the availability of visas, which helps determine "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("when an individual can apply")]),_vm._v(" for a Green Card. Understanding the visa bulletin and its updates is crucial for those navigating the immigration process.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('a',{staticClass:"btn btn-primary btn-assess",attrs:{"href":"/are-you-eligible"}},[_vm._v("Are you eligible for EB-2 NIW?")])])])])
}]

export { render, staticRenderFns }