<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <EB2NIWMistakes2></EB2NIWMistakes2>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import EB2NIWMistakes2 from "../blog-components/EB2NIWMistakes2";


export default {
  name: 'EB2NIWMistakes2View',
  components: {
    EB2NIWMistakes2,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "EB2 NIW cases that fail, avoid these mistakes! - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "In this blog post, we will discuss a specific case and dissect the appeal process to understand the petitioner's errors in meeting Prong-1. Goal is to gain valuable insights into what to avoid in your own petitions."},
      ]
    }
  }
}
</script>
