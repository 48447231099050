<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <EB2NIWRequirements></EB2NIWRequirements>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import EB2NIWRequirements from "../blog-components/EB2NIWRequirements";


export default {
  name: 'EB2NIWRequirementsView',
  components: {
    EB2NIWRequirements,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "Eligibility requirements - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "OPTION 1: Category advanced degree or equivalent. OPTION 2: Category exceptional ability where you qualify if you meet at least three of the listed criteria."},
      ]
    }
  }
}
</script>
