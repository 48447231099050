<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <span class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </span>
            <span class="article-tags2">
              <a href="#">SUCCESS STORY</a>
            </span>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">EB-2 NIW approval for data scientist</h1>
          </div>
        </div>
      </div>
    </div>
    
    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">September 21, 2023</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Natalie</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog36-16x9.webp" alt="NIW template data scientist">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">Success story: EB-2 NIW</h3>

          <div class="blog-paragraph"><span style="background-color: rgba(68, 206, 111, 0.25)">Alex's background was in data science, holding a <span style="font-weight: 500; font-style: italic">bachelor's degree in computer science</span> and having previously worked for a well-known internet company. Despite this, he recently graduated with a <span style="font-weight: 500; font-style: italic">Master of Business Administration (MBA)</span> in the U.S.,</span> which opened up new opportunities but also brought some immigration challenges, as he was still in the process of <span style="font-weight: 500; font-style: italic">seeking an employer</span>. Unlike many EB2 NIW applicants, Alex <a href="/home" style="text-decoration: underline">didn't have published academic papers</a> or publicly available research results due to the confidential nature of his work.</div>

          <div class="signupSelfPetition row justify-content-center align-items-center">
            <div class="col-xl-6 col-lg-7">
              <signup-self-petition></signup-self-petition>
            </div>
          </div>

          <div class="blog-paragraph">However, since January 2022, President Biden's administration has been enacting measures to <span style="font-weight: 500; font-style: italic">attract and retain international STEM talent</span>, underscoring the critical importance of STEM to the nation's future. <span style="background-color: rgba(68, 206, 111, 0.25)">This focus on <span style="font-weight: 500; font-style: italic">STEM</span> reflects a commitment to fostering innovation, research, and the development of cutting-edge technologies that are essential for the United States to maintain its position as a global leader</span> in technological and economic innovation. Alex recognized that these initiatives create an inviting environment for international STEM talent, contributing to ongoing progress and success in <span style="font-weight: 500; font-style: italic">science and technology</span> while securing the nation's future leadership on the global stage.</div>

          <div class="blog-paragraph">Alex used the <a href="/home" style="text-decoration: underline">EB-2 NIW Starter Kit</a> for <span style="font-weight: 500; font-style: italic">data scientists</span> and submitted an I-140 petition to the U.S. Citizenship and Immigration Services (USCIS):</div>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

          <div class="blog-paragraph">As expected, the USCIS issued a Request for Evidence (RFE), requesting more details about Alex's <span style="font-weight: 500; font-style: italic">career plan</span> and <span style="font-weight: 500; font-style: italic">intended employment</span> in the U.S. <span style="background-color: rgba(68, 206, 111, 0.25)">At this time, Alex had secured a position as a <span style="font-weight: 500; font-style: italic">Data Scientist</span> in the field of <span style="font-weight: 500; font-style: italic">cybersecurity</span>, aligning with his original intention to work in <span style="font-weight: 500; font-style: italic">internet security</span>, <span style="font-weight: 500; font-style: italic">identity protection</span>, and <span style="font-weight: 500; font-style: italic">privacy concerns</span> that benefit the <span style="font-weight: 500; font-style: italic">safety of U.S. citizens</span>.</span> Alex responded to the RFE with strong evidence of his dedication to data science and the significance of his chosen field in internet security.</div>

          <div class="blog-paragraph">The good news came shortly after: Alex's <span style="font-weight: 500; font-style: italic">NIW petition was approved</span>, and he was on his way to becoming a data scientist in the U.S. Alex's journey is a testament to determination and adaptability in the face of immigration challenges, showing that with the right approach and evidence, achieving permanent residency is possible.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                <a href="/data-scientist-analyst-sample" class="btn btn-primary" style="margin-top: 3rem; margin-bottom: 2rem">DATA SCIENTIST TEMPLATE</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>
<script>

import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";
import SignupSelfPetition from "../components/SignupSelfPetition.vue";

export default {
  name: 'SuccessStoryDataScientist',
  components: {
    PriceOffer,
    CarouselGreenCard,
    SignupSelfPetition
  }
}
</script>
