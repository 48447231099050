<template>

    <section class="works-area ptb-80 bg-f9fafb">

      <b-modal ref="modal-cover-letter" title="Software Engineer" size="xl" hide-footer>
        <img src="../resources/sample-softwareEngineer.webp" alt="eb2 niw sample software engineer"/>
      </b-modal>

      <b-modal ref="modal-case-builder" title="Software Engineer" size="xl" hide-footer>
        <img src="../resources/caseBuilder-softwareEngineer.webp" alt="eb2 niw sample software engineer"/>
      </b-modal>

      <b-modal ref="modal-cover-letter-2" title="IT" size="xl" hide-footer>
        <img src="../resources/sample-it.webp" alt="eb2 niw sample it"/>
      </b-modal>

      <b-modal ref="modal-case-builder-2" title="IT" size="xl" hide-footer>
        <img src="../resources/caseBuilder-it.webp" alt="eb2 niw sample it"/>
      </b-modal>

      <div class="container" style="margin-top: 5rem">
        <div class="row">
          <div class="col-sm-3 col-8 offset-sm-0 offset-2" style="margin-bottom: 4rem">
            <img src="../resources/softwareEng.webp" style="border-radius: 50%; border: 2px solid; border-color: #44ce6f; box-shadow: 10px 20px 20px 0px rgba(86, 97, 118, .25)" alt="eb2 niw sample developer"/>
          </div>

          <div class="col-sm-8 offset-sm-1 col-12 sample-h1">
            <div>
              <h1 style="font-weight: 600;">Software Engineer & IT</h1>
              <div class="bar"></div>
            </div>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 30px">
          <div style="margin: 1rem; margin-top: 2rem; max-width: 1000px">In pursuit of the EB-2 NIW, software engineers and IT professionals are tasked with not only showcasing their technical expertise but also highlighting the widespread impact of their work on the nation's welfare. This involves providing a detailed account of innovations, advancements, or contributions that have positively affected industries, society, or national security within the realm of software development and information technology.</div>
          <div style="margin: 1rem; max-width: 1000px">Effectively navigating the EB-2 NIW process requires a comprehensive presentation of the engineer's or IT professional's accomplishments, emphasizing the pivotal role they play in advancing the nation's technological landscape. Furthermore, it is crucial to underscore the broader societal and economic implications of their work in software engineering and IT.</div>
          <div style="margin: 1rem; max-width: 1000px">It is reasonable to presume that STEM and data fields inherently align with national interests, given their essential role in the contemporary era of innovation. This alignment has been explicitly acknowledged by the Biden Administration in January 2022.</div>
          <div style="margin: 1rem; max-width: 1000px">It's noteworthy that, contrary to common belief, research, publications, or citations are not obligatory for EB-2 NIW, and our templates are specifically tailored for professionals in software engineering and IT without these traditional markers of achievement.</div>
          
          <div style="margin: 3rem 1rem 1rem; max-width: 200px; border: 2px solid #44ce6f; padding: 12px 12px 4px; border-radius: 5px;">
            <vue-feather type="shield" style="color: #44ce6f"></vue-feather> Read More:
            <ul>
              <li style="margin-top: 5px"><a href="/success-story-software-engineer" target="_blank" style="text-decoration: underline">Success Story 1</a></li>
              <li style="margin-top: 5px"><a href="/success-story-software-engineer-2" target="_blank" style="text-decoration: underline">Success Story 2</a></li>
            </ul>
          </div>
        </div>

<!--Software Engineer-->
        <div id="profession-sample" style="margin-bottom: 1.5rem">
          <div style="margin: 6rem 0rem">
            <h4 style="text-align: center;">Software Engineer</h4>
            <div class="section-title">
              <div class="bar"></div>
            </div>
            
            <div style="text-align: center; margin: 4rem 0 6rem">
              <button v-on:click="navigateToCheckoutFirst" class="btn btn-primary">GET THIS TEMPLATE</button>
              <div class="price-offer-comment" style="color: #c4c4c4; margin-top: 50px;">*** Not quite right? Try <a style="color: #c4c4c4; text-decoration: underline" href="/ai">Selfpetition AI</a>  instead ***</div>            
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCoverLetterModal" style="cursor: pointer">
              <img src="../resources/sample-softwareEngineer.webp" alt="software engineer i140 sample">
              <div class="works-content">
                <h3 style="color: #fff">Software Engineer</h3>
                <h3 style="color: #fff">Cover Letter (9 pages)</h3>
                <p>The cover letter comprises the following segments: meeting the criteria for an Advance Degree, Prong 1, Prong 2, Prong 3, and an appendix containing all supporting evidence. When checking out, you have the option to select up to two professions.</p>
              </div>
            </div>

            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCaseBuilderModal" style="cursor: pointer">
              <img src="../resources/caseBuilder-softwareEngineer.webp" alt="software engineer green card">
              <div class="works-content">
                <h3 style="color: #fff">Software Engineer</h3>                    
                <h3 style="color: #fff">EB-2 NIW Case Builder (18 pages)</h3>
                <p>You might be wondering how to identify relevant NIW initiatives and supporting arguments. This is where the NIW Case Builder Pdf comes in – it's designed to inspire you with alternative points to emphasize in your cover letter as you modify our template.</p>
              </div>
            </div>
          </div>

  <!--IT Professional-->
          <div style="margin: 6rem 0rem 5rem">
            <h4 style="text-align: center;">IT Professional</h4>
            <div class="section-title">
              <div class="bar"></div>
            </div>
            
            <div style="text-align: center; margin: 4rem 0 6rem">
              <button v-on:click="navigateToCheckoutSecond" class="btn btn-primary">GET THIS TEMPLATE</button>
              <div class="price-offer-comment" style="color: #c4c4c4; margin-top: 50px;">*** Not quite right? Try <a style="color: #c4c4c4; text-decoration: underline" href="/ai">Selfpetition AI</a>  instead ***</div>            
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCoverLetterModal2" style="cursor: pointer">
              <img src="../resources/sample-it.webp" alt="it i140 sample">
              <div class="works-content">
                <h3 style="color: #fff">IT</h3>
                <h3 style="color: #fff">Cover Letter (9 pages)</h3>
                <p>The cover letter comprises the following segments: meeting the criteria for an Advance Degree, Prong 1, Prong 2, Prong 3, and an appendix containing all supporting evidence. When checking out, you have the option to select up to two professions.</p>
              </div>
            </div>

            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCaseBuilderModal2" style="cursor: pointer">
              <img src="../resources/caseBuilder-it.webp" alt="it green card">
              <div class="works-content">
                <h3 style="color: #fff">IT</h3>                    
                <h3 style="color: #fff">EB-2 NIW Case Builder (19 pages)</h3>
                <p>You might be wondering how to identify relevant NIW initiatives and supporting arguments. This is where the NIW Case Builder Pdf comes in – it's designed to inspire you with alternative points to emphasize in your cover letter as you modify our template.</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div style="margin: 5rem 0rem">
            <h4 style="text-align: center;">EB2 NIW Starter Kit</h4>
            <h6 style="text-align: center;">List of Documents</h6>
            <div class="section-title">
              <div class="bar"></div>
            </div>
          </div>

          <div class="section-title faq" style="margin-bottom: 1.5rem">
            <DocumentsList></DocumentsList>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 1.5rem">
          <div class="row">
            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Most suitable for</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">Software Developer</li>
                <li class="profession-page-list">Software Engineer</li>
                <li class="profession-page-list">Backend Developer</li>
                <li class="profession-page-list">Frontend Developer</li>
                <li class="profession-page-list">Full Stack Developer</li>
                <li class="profession-page-list">DevOps Engineer</li>
                <li class="profession-page-list">Mobile App Developer</li>
                <li class="profession-page-list">Quality Assurance (QA) Engineer</li>
                <li class="profession-page-list">Systems Engineer</li>
                <li class="profession-page-list">Data Engineer</li>
                <li class="profession-page-list">Machine Learning Engineer</li>
                <li class="profession-page-list">Game Developer</li>
                <li class="profession-page-list">Security Engineer</li>
                <li class="profession-page-list">Cybersecurity Engineer</li>
                <li class="profession-page-list">Cloud Engineer</li>
                <li class="profession-page-list">AR / VR Developer</li>
                <li class="profession-page-list">IT Project Manager</li>
                <li class="profession-page-list">System Administrator</li>
                <li class="profession-page-list">IT Support Specialist</li>
                <li class="profession-page-list">IT Consultant</li>
                <li class="profession-page-list">IT Auditor</li>
                <li class="profession-page-list">... and other similar</li>
              </ul>
            </div>

            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Topics</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">Artificial Intelligence</li>
                <li class="profession-page-list">Machine Learning</li>
                <li class="profession-page-list">Voice recognition</li>
                <li class="profession-page-list">Efficiency, reliability, and security of data and banking operations</li>
                <li class="profession-page-list">Security</li>
                <li class="profession-page-list">Cyber threats mitigation</li>
                <li class="profession-page-list">Technical solutions</li>
                <li class="profession-page-list">IT architecture</li>
                <li class="profession-page-list">U.S. technology advancement</li>
                <li class="profession-page-list">Team management and mentorship</li>
                <li class="profession-page-list">Career progression</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="shape2 rotateme" style="left: 90%"><img src="../resources/shape2.svg" alt="EB2 niw diy"></div>
      <div class="shape4"><img src="../resources/shape4.svg" alt="EB-2 niw software engineer"></div>
      <div class="shape7"><img src="../resources/shape4.svg" alt="EB-2 niw it"></div>
    </section>

</template>

<script>

import { mapActions } from 'vuex';
import DocumentsList from "./DocumentsList.vue";

    export default {
      name: 'ProfessionSoftwareEngineerIT',
      components: {
        DocumentsList,
      },
      data() {
        return {
          user: {
            profession1: 'softwareEngineer',
            profession2: 'it',
          }
        }
      },
      methods: {
        ...mapActions('account', ['apply']),
        navigateToCheckoutFirst() {
          //this.apply('Software Engineer Sample');
          // Handle navigation to checkout here
          this.$router.push({
            path: '/checkout-starter-kit',
            query: {
              profession: this.user.profession1
            }
          });
        },
        navigateToCheckoutSecond() {
          //this.apply('IT Sample');
          // Handle navigation to checkout here
          this.$router.push({
            path: '/checkout-starter-kit',
            query: {
              profession: this.user.profession2
            }
          });
        },
        openCoverLetterModal() {
          setTimeout(() => {
            this.$refs['modal-cover-letter'].show();
          }, 100);
        },
        openCaseBuilderModal() {
          setTimeout(() => {
            this.$refs['modal-case-builder'].show();
          }, 100);
        },
        openCoverLetterModal2() {
          setTimeout(() => {
            this.$refs['modal-cover-letter-2'].show();
          }, 100);
        },
        openCaseBuilderModal2() {
          setTimeout(() => {
            this.$refs['modal-case-builder-2'].show();
          }, 100);
        },
      }
    }
</script>

