<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <div class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </div>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">What if my EB-2 NIW is denied?</h1>
          </div>
        </div>
      </div>
    </div>

    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">February 13, 2023</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Natalie</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog14-16x9.webp" alt="NIW template non-academia">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">EB-2 National Interest Waiver denied</h3>

          <div class="blog-paragraph">EB-2 National Interest Waiver (NIW) is a popular immigration category for individuals with exceptional ability or advanced degrees who seek a Green Card in the United States. However, like any other immigration application, there is always a risk of denial. In this article, we will discuss the possible outcomes if your EB-2 NIW is denied and what you can do to increase your chances of success in your future immigration efforts.</div>

          <h5>Appeal the decision</h5>
          <div class="blog-paragraph">If your EB-2 NIW is denied, you have the right to <span style="background-color: rgba(68, 206, 111, 0.25)">file an appeal with the Administrative Appeals Office (AAO) within 30 days of the denial</span>. This process <span style="font-weight: 500; font-style: italic">can be lengthy</span> and requires a detailed understanding of immigration law.</div>

          <h5>Reapply</h5>
          <div class="blog-paragraph">If you believe that the denial was based on an <span style="background-color: rgba(68, 206, 111, 0.25)">error or misinterpretation of the evidence submitted, you can reapply for EB-2 NIW</span>. However, it is important to <span style="font-weight: 500; font-style: italic">address the weaknesses</span> in the initial application and provide additional evidence to support your case.</div>

          <div class="blog-paragraph">Check <a href="/home" style="text-decoration: underline">Starter Kit for EB-2 NIW</a>, which includes self-petition Green Card templates for professionals:</div>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

          <h5>Consider other immigration categories</h5>
          <div class="blog-paragraph">If your EB-2 NIW is denied, it may be worth considering alternative immigration categories, such as <span style="background-color: rgba(68, 206, 111, 0.25)">EB-1A (Alien of Extraordinary Ability) or EB-3 (Skilled Worker)</span>. These categories have different eligibility criteria and requirements, so it is important to assess which category may be a better fit for you. Keep in mind that <span style="font-weight: 500; font-style: italic">EB-3 does not allow self-petition</span>.</div>

          <h5>Wait for better timing</h5>
          <div class="blog-paragraph">Sometimes, the denial of an EB-2 NIW is simply a result of the high demand for Green Cards and the limited number of visas available. In these cases, it may be wise to wait for a better time to reapply, when  <span style="background-color: rgba(68, 206, 111, 0.25)">demand is lower and the visa availability is higher</span>.</div>

          <div class="blog-paragraph">In conclusion, a denial of an EB-2 NIW application is not the end of the road for your immigration journey. By understanding the possible outcomes and taking the appropriate steps, you can increase your chances of success in your future immigration efforts.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                  <a href="/are-you-eligible" class="btn btn-primary btn-assess">Are you eligible for EB-2 NIW?</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>
<script>

import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";

export default {
  name: 'EB2NIWDenied',
  components: {
    PriceOffer,
    CarouselGreenCard
  }
}
</script>
