<template>

    <form id="signupSelfPetition" @submit.prevent="signupEmail()" class="mt-4">
        <div class="row">
            <div class="col-sm-7">
                <input type="email"  name="emailSelfPetition" id="emailSelfPetition" class="form-control"  v-model="email" placeholder="Enter your email" style="border-radius: 4px; height: 50px">
                <p style="font-size: 10px; color:#495057">Get notified when EB-2 NIW Success Story is published</p>
                <div class="mt-4">
                    <p style="color: #3b2d9b; font-weight: 500; margin-bottom: 20px; font-size: 14px;">{{ message }}</p>
                </div>
            </div>
            <div class="col-sm-5 col-6 offset-sm-0 offset-4" style="padding: 0px">
                <button type="submit" class="btn btn-primary">Notify me</button>
            </div>
        </div>
    </form>

</template>

<script>

    export default {
        name: 'SignupSelfPetition',
        data() {
            return {
                email: '',
                message: ''
            }
        },
        methods: {
            signupEmail() {
                if(!this.validateEmail(this.email)) {
                    this.message = "The email address is badly formatted.";
                } else {
                  const url = `/api/contact/landing`;
                  const requestOptions = {
                    method: "POST",
                    headers: {"Content-Type" : "application/json"},
                    body: JSON.stringify({ name: 'SelfPetition', email: this.email, message: 'Success Story signup' })
                  };
                  fetch(url, requestOptions).then(() => {
                    this.message = "🎉 Successful signup 🎉";
                    setTimeout(() => {
                        this.email = '';
                        this.message = '';
                    }, 4000); // Clears after 4 seconds, adjust time as needed
                  })
                }
            },
            validateEmail(email) {
                var re = /\S+@\S+\.\S+/;
                return re.test(String(email).toLowerCase());
            }
        }
    }

</script>
