<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <div class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </div>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">How to apply for EB-2 NIW Green Card?</h1>
          </div>
        </div>
      </div>
    </div>

    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">January 17, 2023</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Natalie</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog7-16x9.webp" alt="eb2niw">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">Application process</h3>

          <div class="blog-paragraph">The EB-2 National Interest Waiver (NIW) Green Card is a type of employment-based immigration visa that allows <span style="background-color: rgba(68, 206, 111, 0.25)">individuals with advanced degrees (ie. above Bachelors, or Bachelors with minimum 5 years of consecutive experience) or having exceptional ability</span> to work and live permanently in the United States.</div>

          <div class="blog-paragraph">You can <span style="font-weight: 500; font-style: italic">self-petition</span> which means that you <span style="font-weight: 500; font-style: italic">do not need the employer</span> to sponsor you. Most importantly, the employer doesn't even have to know about your application! To apply for this type of visa, there are several steps you need to follow:</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                <router-link class="btn btn-primary" :to="{ name: 'Home', params: { location: 'Templates' }}">EB-2 NIW Templates</router-link>
              </div>
            </div>
          </div>

          <ul class="features-list">
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Gather the required documentation:</span> Before you start the application process, you will need to gather all of the <a href="/home" style="text-decoration: underline">necessary documents</a>, including your resume, transcripts, degree certificates, and any other relevant evidence that demonstrates your advanced degree or exceptional ability.</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Prepare a comprehensive petition:</span> The next step is to prepare a comprehensive petition that outlines why you qualify for an EB-2 NIW Green Card. This should include a detailed explanation of your background, education, and work experience, as well as any evidence of your exceptional ability or advanced degree.</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Submit your petition:</span> After you have completed your petition, you will need to submit it to the United States Citizenship and Immigration Services (USCIS).</li>
          </ul>

          <div class="blog-paragraph">Check <a href="/home" style="text-decoration: underline">Starter Kit for EB-2 NIW</a>, which includes self-petition Green Card templates for professionals:</div>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

          <ul class="features-list">
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Attend an interview:</span> If your petition is approved, you will be asked to attend an interview with a USCIS officer. This is an opportunity for you to further explain your qualifications and answer any questions the officer may have. <span style="background-color: rgba(68, 206, 111, 0.25)">It happens that USCIS waives the interview - this happened to the authors of this blog in 2020 &#128513;</span></li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Receive a decision:</span> After your interview, USCIS will review your case and make a decision on whether to grant you an EB-2 NIW Green Card. If your <a href="/home" style="text-decoration: underline">application</a> is approved, you will receive a Green Card in the mail.</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Maintain your status:</span> Once you have received your Green Card, it is important to maintain your status as a permanent resident. This means following all immigration laws and regulations, including paying taxes and updating your address with USCIS if you move.</li>
          </ul>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem; padding-bottom: 0px">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                  <a href="/are-you-eligible" class="btn btn-primary btn-assess">Are you eligible for EB-2 NIW?</a>
              </div>
            </div>
          </div>

          <div class="blog-paragraph" style="padding-bottom: 6rem">By following these steps, you can increase your chances of successfully obtaining an EB-2 NIW Green Card and living and working in the United States permanently.</div>
          
        </div>
      </div>
    </section>

  </div>
</template>
<script>

import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";

export default {
  name: 'EB2NIWApplication',
  components: {
    PriceOffer,
    CarouselGreenCard
  }
}
</script>
