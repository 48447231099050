<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <BlogGrid1></BlogGrid1>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import BlogGrid1 from "../blog-components/BlogGrid1";


export default {
  name: 'BlogGridView1',
  components: {
    BlogGrid1,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "EB2NIW Blog: success stories",
      meta: [
        { vmid: 'description', name: 'description', content:  'Traditional knowledge will tell you that only researchers can obtain an EB2 National Interest (NIW) green card. Business, entrepreneurs, and professionals can do it too!'},
      ]
    }
  }
}
</script>
