<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <EB2NIWLettersOfRecommendation></EB2NIWLettersOfRecommendation>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import EB2NIWLettersOfRecommendation from "../blog-components/EB2NIWLettersOfRecommendation";


export default {
  name: 'EB2NIWLettersOfRecommendationView',
  components: {
    EB2NIWLettersOfRecommendation,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "Prepare EB2 NIW recommendation letters - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "Letters of recommendation are a critical component of the EB-2 National Interest Waiver (NIW) immigration petition. These letters provide insight into the applicant's accomplishments, skills, and experience and help to support the claim that the applicant's work is of exceptional merit and national importance."},
      ]
    }
  }
}
</script>
