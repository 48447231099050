<template>
    <!-- Start Works Area -->
    <section class="works-area" style="background: white; padding-bottom: 0">

        <b-modal ref="modal-zoom" :modal-class="modalZoom" :return-focus="this.$parent" size="lg" hide-footer>
            <template #modal-title>
               {{modalProperties[index].title}}
            </template>
            <div style="text-align: center;">
                <img :src="getImgUrl()" alt="eb-2 niw ai" />
            </div>
        </b-modal>

        <div class="section-title" style="margin-bottom: 0px">
            <div class="bar"></div>
            <div class="paragraph" style="margin-bottom: 0px">
                <h5 id="carousel" style="text-align: center; color: #6084a4">Selfpetition AI input form</h5>
                <h6 style="text-align: center; color: #6084a4">(available after placing the order)</h6>
                <h2 style="text-align: center; color: #6084a4; margin-top: 20px; margin-bottom: 0px">&#8681;</h2>
            </div>
        </div>

        <div class="works-slides" style="margin-top: 3rem">
            <carousel
                :autoplay = "true"
                :loop = "true"
                :autoplaySpeed = "true"
                :autoplayTimeout = "5000"
                :perPageCustom = "[[0, 1],[768, 2],[1200, 3], [1500, 4]]"
            >
                <slide>
                    <a @click="index = 0; $refs['modal-zoom'].show()" style="cursor: pointer;">
                        <div class="single-works carousel-green-hover">
                            <img src="../resources/Form1.svg" alt="ai draft eb2 niw">
                            <a v-if="windowWidth > 767" class="icon"><vue-feather type="zoom-in"></vue-feather></a>

                            <div class="works-content">
                                <h3 style="color: #fff">Form sample</h3>
                                <p>Your profile overview</p>
                            </div>
                        </div>
                    </a>
                </slide>

                <slide>
                    <a @click="index = 1; $refs['modal-zoom'].show()" style="cursor: pointer;">
                        <div class="single-works carousel-green-hover">
                            <img src="../resources/Form2.svg" alt="national interest waiver ai">
                            <a v-if="windowWidth > 767" class="icon"><vue-feather type="zoom-in"></vue-feather></a>

                            <div class="works-content">
                                <h3 style="color: #fff">Form sample</h3>
                                <p>Proposed Endeavor</p>
                            </div>
                        </div>
                    </a>
                </slide>

                <slide>
                    <a @click="index = 2; $refs['modal-zoom'].show()" style="cursor: pointer;">
                        <div class="single-works carousel-green-hover">
                            <img src="../resources/Form3.svg" alt="ai niw template">
                            <a v-if="windowWidth > 767" class="icon"><vue-feather type="zoom-in"></vue-feather></a>

                            <div class="works-content">
                                <h3 style="color: #fff">Form sample</h3>
                                <p>Prong-1</p>
                            </div>
                        </div>
                    </a>
                </slide>

                <slide>
                    <a @click="index = 3; $refs['modal-zoom'].show()" style="cursor: pointer;">
                        <div class="single-works carousel-green-hover">
                            <img src="../resources/Form4.svg" alt="ai input">
                            <a v-if="windowWidth > 767" class="icon"><vue-feather type="zoom-in"></vue-feather></a>

                            <div class="works-content">
                                <h3 style="color: #fff">Form sample</h3>
                                <p>Prong-2</p>
                            </div>
                        </div>
                    </a>
                </slide>

                <slide>
                    <a @click="index = 4; $refs['modal-zoom'].show()" style="cursor: pointer;">
                        <div class="single-works carousel-green-hover">
                            <img src="../resources/Form5.svg" alt="ai niw template">
                            <a v-if="windowWidth > 767" class="icon"><vue-feather type="zoom-in"></vue-feather></a>

                            <div class="works-content">
                                <h3 style="color: #fff">Form sample</h3>
                                <p>Prong-3</p>
                            </div>
                        </div>
                    </a>
                </slide>

            </carousel>
        </div>
        <div class="shape4"><img src="../resources/shape4.svg" alt="self petition artificial intelligence"></div>
    </section>
    <!-- End Works Area -->
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
    name: 'CarouselAi',
    components: { Carousel, Slide },
    data() {
        return {
            modalZoom: ['modal-zoom'],
            windowWidth: window.innerWidth,
            index: 0,
            modalProperties: [
                {
                    title : "Your profile overview",
                    img: "./Form1.svg"
                },
                {
                   title : "Proposed Endeavor",
                   img: "./Form2.svg"
                },
                {
                    title : "Prong-1",
                    img: "./Form3.svg"
                },
                {
                   title : "Prong-2",
                   img: "./Form4.svg"
                },
                {
                    title : "Prong-3",
                    img: "./Form5.svg"
                }
            ]
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
      var resizeEvent = window.document.createEvent('UIEvents');
      resizeEvent.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(resizeEvent);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth
        },
        getImgUrl() {
            var images = require.context('../resources/', false, /\.svg$/)
            return images(this.modalProperties[this.index].img)
        },
        closeBannerModal() {
        // Emit a custom event to notify the parent component
        this.$emit('close-ai-modal');
        }
    }
}
</script>
