<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <EB2NIWGuide></EB2NIWGuide>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import EB2NIWGuide from "../blog-components/EB2NIWGuide";


export default {
  name: 'EB2NIWGuideView',
  components: {
    EB2NIWGuide,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "EB2 NIW Comprehensive Guide - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "Navigating the path to a U.S. green card via the EB-2 National Interest Waiver (NIW) requires a meticulously prepared I-140 petition. This comprehensive guide, rooted in personal and professional experience, outlines essential steps and insights to craft a compelling petition that meets USCIS standards."},
      ]
    }
  }
}
</script>
