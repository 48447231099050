<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <SuccessStoryEntrepreneur></SuccessStoryEntrepreneur>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import SuccessStoryEntrepreneur from "../blog-components/SuccessStoryEntrepreneur";


export default {
  name: 'SuccessStoryEntrepreneurView',
  components: {
    SuccessStoryEntrepreneur,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "EB2 NIW approval for entrepreneur - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "Tom was a scientist and a biotech startup founder who was passionate about developing new and innovative treatments for rare diseases. He had a PhD in biochemistry and years of experience working in the industry, but he knew that he needed to bring his ideas to the next level."},
      ]
    }
  }
}
</script>
