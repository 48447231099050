var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('section',{staticClass:"blog-details-area"},[_c('div',{staticClass:"article-content"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('vue-feather',{attrs:{"type":"clock"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("February 23, 2023")])],1),_c('li',[_c('vue-feather',{attrs:{"type":"user"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("Tom")])],1)])])])]),_vm._m(1),_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"article-content"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Success story: EB-2 NIW")]),_vm._m(2),_c('div',{staticClass:"signupSelfPetition row justify-content-center align-items-center"},[_c('div',{staticClass:"col-xl-6 col-lg-7"},[_c('signup-self-petition')],1)]),_vm._m(3),_vm._m(4),_c('PriceOffer',{staticStyle:{"margin-bottom":"4rem"}}),_c('CarouselGreenCard',{staticStyle:{"margin-bottom":"4rem"}}),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area2"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('span',{staticClass:"article-tags"},[_c('a',{attrs:{"href":"#"}},[_vm._v("EB-2 NIW BLOG")])]),_c('span',{staticClass:"article-tags2"},[_c('a',{attrs:{"href":"#"}},[_vm._v("SUCCESS STORY")])]),_c('h1',{staticStyle:{"font-weight":"600","margin-top":"30px","text-align":"left"}},[_vm._v("EB-2 NIW approval for petroleum engineer")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../resources/blog/blog17-16x9.webp"),"alt":"NIW template oil gas engineer"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Maxwell is a "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("petroleum engineering consultant")]),_vm._v(" who received approval for an EB-2 National Interest Waiver (NIW) "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("without any research experience")]),_vm._v(". Despite the common perception that research is necessary for NIW approval, Maxwell managed to demonstrate the significance of his work through the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("extensive industry experience")]),_vm._v(" and impact on the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("petroleum engineering field")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Maxwell utilized the "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/chemical-engineer-sample"}},[_vm._v("Chemical Engineer DIY Template")]),_vm._v(" to effectively showcase his distinctive qualifications, "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("drawing attention to his "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("15 years of consulting experience")]),_vm._v(". During this period, he played a pivotal role in "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("high-impact projects for leading companies")]),_vm._v(" in the petroleum engineering sector.")]),_vm._v(" His active participation in various industry organizations and professional associations further exemplified his dedication and influence within the field.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Check "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("Starter Kit for EB-2 NIW")]),_vm._v(", which includes self-petition "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/chemical-engineer-sample"}},[_vm._v("Green Card templates")]),_vm._v(" for professionals. The Starter Kit equips individuals with the tools needed to articulate the impact of their work, emphasizing accomplishments and industry involvement beyond traditional research settings. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Moreover, Maxwell strategically emphasized the "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("positive outcomes of his contributions")]),_vm._v(", illustrating how his work significantly enhanced the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("efficiency")]),_vm._v(" and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("effectiveness")]),_vm._v(" of petroleum engineering projects, as well as the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("safety of the U.S. energy sector")]),_vm._v(". By linking his endeavors to the broader impact on the industry and the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("U.S. economy")])]),_vm._v(", Maxwell successfully portrayed the critical role he played in advancing the field. The approval of his EB-2 NIW served as a testament to the recognition of his expertise and its essential contribution to the growth and development of petroleum engineering.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("This approval story underscores a crucial narrative in the EB-2 NIW process, challenging the conventional belief that research is the sole determinant for approval. "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("Maxwell's case highlights that individuals armed with "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("extensive industry experience")]),_vm._v(" and substantial "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("contributions")]),_vm._v(" can also meet the criteria for a successful "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/chemical-engineer-sample"}},[_vm._v("EB-2 NIW petition")]),_vm._v(".")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("It also emphasizes the need for "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("comprehensive documentation")]),_vm._v(" and strategic presentation of one's qualifications. This approval story reshapes perceptions surrounding NIW criteria, encouraging professionals to recognize and leverage their unique strengths in their pursuit of U.S. permanent residency.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('a',{staticClass:"btn btn-primary",staticStyle:{"margin-top":"3rem","margin-bottom":"2rem"},attrs:{"href":"/chemical-engineer-sample"}},[_vm._v("CHEMICAL ENGINEER TEMPLATE")])])])])
}]

export { render, staticRenderFns }