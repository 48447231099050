<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
        <div class="d-table"><div class="d-table-cell">
          <div class="article-tags">
            <a href="#">EB-2 NIW BLOG</a>
          </div>
          <h1 style="font-weight: 600; margin-top: 30px; text-align: left">EB-2 NIW documents checklist</h1>
        </div>
        </div>
      </div>
    </div>

    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">December 11, 2022</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Tom</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog1-16x9.webp" alt="niw petition letter template">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">Documents checklist</h3>

          <div class="blog-paragraph">If you are applying for an EB-2 National Interest Waiver (NIW), it's important to make sure you have all the <span style="background-color: rgba(68, 206, 111, 0.25)"><a href="/faq" style="text-decoration: underline">necessary documents</a> in order to increase your chances of success. Below is a checklist of documents that you should consider</span> preparing for your NIW application.</div>

          <ul class="features-list">
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Form I-140, Immigrant Petition for Alien Worker:</span> This form is the official petition for an EB-2 NIW.</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Labor Certification Form:</span> Check whether to choose <a href="/eta9089-vs-eta750" style="text-decoration: underline">ETA-9089 or ETA-750</a>.</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Educational Degree(s):</span> Copies of all degree certificates, transcripts, and any other related documents that demonstrate your advanced level of education and professional qualifications.</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Resume or CV:</span> A comprehensive and up-to-date resume or CV highlighting your professional accomplishments and experience.</li>
            
            <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
              <div class="col-xl-7 col-lg-9">
                <div class="row justify-content-center">
                  <router-link class="btn btn-primary" :to="{ name: 'Home', params: { location: 'Templates' }}">EB-2 NIW Templates</router-link>
                </div>
              </div>
            </div>

            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Letters of Recommendation:</span> A minimum of <a href="/faq" style="text-decoration: underline">three letters of recommendation</a> from well-respected individuals in your field of work, who can attest to your exceptional ability and the national importance of your work.</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Proof of National Interest:</span> <a href="/home" style="text-decoration: underline">Evidence that your work is in the national interest</a> of the United States. This can include awards, publications, patents, or other achievements that demonstrate your exceptional ability and contributions to your field.</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Supporting Documentation:</span> Any additional supporting documents that demonstrate the national importance of your work, such as research papers, presentations, or articles.</li>
          </ul>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

          <div class="blog-paragraph"><span style="background-color: rgba(68, 206, 111, 0.25)">This list is <span style="font-weight: 500; font-style: italic">not exhaustive</span> and the actual requirements for your NIW application may vary</span> based on your individual circumstances. By preparing a <a href="/faq" style="text-decoration: underline">comprehensive and well-documented application</a>, you increase your chances of success and can continue to contribute to the United States.</div>

          <div class="blog-paragraph">For those considering the EB-2 NIW visa, it's essential to explore the <a href="/home" style="text-decoration: underline">Starter Kit for EB-2 NIW</a>, which includes self-petition <router-link style="text-decoration: underline" :to="{ name: 'Home', params: { location: 'Templates' }}">Green Card templates</router-link> tailored for professionals seeking this category. This kit serves as a valuable resource, guiding applicants through the intricate process of applying for the EB-2 NIW visa, streamlining the documentation required for a successful petition:</div>

          <ul class="features-list">
            <li><vue-feather type="check"></vue-feather><a href="/banking-finance-sample" target="_blank" style="text-decoration: underline">Bankers</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/banking-finance-sample" target="_blank" style="text-decoration: underline">Finance Experts</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/banking-finance-sample" target="_blank" style="text-decoration: underline">PE & VC Investors</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/software-engineer-it-sample" target="_blank" style="text-decoration: underline">Software Engineers</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/software-engineer-it-sample" target="_blank" style="text-decoration: underline">IT</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/data-scientist-analyst-sample" target="_blank" style="text-decoration: underline">Data Scientists</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/data-scientist-analyst-sample" target="_blank" style="text-decoration: underline">Data Analysts</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/product-project-mgmt-sample" target="_blank" style="text-decoration: underline">Product & Project & Program Managers</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/network-electrical-engineer-sample" target="_blank" style="text-decoration: underline">Network & Electrical Engineers</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/electro-mechanical-engineer-sample" target="_blank" style="text-decoration: underline">Electro-mechanical Engineers</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/chemical-engineer-sample" target="_blank" style="text-decoration: underline">Chemical Engineers</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/marketing-sample" target="_blank" style="text-decoration: underline">Marketing Professionals</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/hr-recruiter-sample" target="_blank" style="text-decoration: underline">HR Professionals</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/hr-recruiter-sample" target="_blank" style="text-decoration: underline">Recruiters</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/science-teacher-sample" target="_blank" style="text-decoration: underline">Science Teachers</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/physician-dentist-sample" target="_blank" style="text-decoration: underline">Physicians</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/physician-dentist-sample" target="_blank" style="text-decoration: underline">Dentists</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/pilot-sample" target="_blank" style="text-decoration: underline">Pilots & Aeronautical Engineers</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/success-story-entrepreneur" target="_blank" style="text-decoration: underline">Tech Entrepreneurs</a></li>
            <li><vue-feather type="check"></vue-feather><a href="/physician-dentist-sample" target="_blank" style="text-decoration: underline">Brick-and-mortar Business Owners</a></li>            
          </ul>

          <div class="blog-paragraph">By investing time and effort into preparing a thorough and <span style="font-weight: 500; font-style: italic">well-documented application</span>, applicants significantly enhance their chances of success. The journey toward obtaining the EB-2 NIW visa is complex, and the provided Starter Kit for EB-2 NIW proves to be an invaluable resource. Tailored for various professionals seeking this category, the kit streamlines the intricate process, offering self-petition <router-link style="text-decoration: underline" :to="{ name: 'Home', params: { location: 'Templates' }}">Green Card templates</router-link> designed for specific fields. Whether one is a finance expert, software engineer, physician, or entrepreneur, the Starter Kit provides a comprehensive framework, making the application process more accessible.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                  <a href="/are-you-eligible" class="btn btn-primary btn-assess">Are you eligible for EB-2 NIW?</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>
<script>

import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";

export default {
  name: 'EB2NIWChecklist',
  components: {
    PriceOffer,
    CarouselGreenCard
  }
}
</script>
