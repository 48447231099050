<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <div class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </div>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">EB-2 NIW cases that fail - avoid these mistakes! (1)</h1>
          </div>
        </div>
      </div>
    </div>

    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">April 11, 2023</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Przemek</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog24-16x9.webp" alt="eb-2 NIW advanced degree DIY">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">Failing Advanced Degree or Exceptional Ability requirement</h3>

          <div class="blog-paragraph">Understanding why some cases fail can help applicants avoid making similar mistakes. In this article, we will look at individual EB-2 NIW cases and discuss the lessons we can learn from the most common mistakes applicants make.</div>

          <h5>Mistake #1: Not meeting basic requirement for Advanced Degree</h5>
          <h6 style="margin-bottom: 2rem">USCIS source: <a style="text-decoration: underline" href="https://www.uscis.gov/sites/default/files/err/B5%20-%20Members%20of%20the%20Professions%20holding%20Advanced%20Degrees%20or%20Aliens%20of%20Exceptional%20Ability/Decisions_Issued_in_2023/MAR012023_01B5203.pdf">HERE</a></h6>

          <div class="blog-paragraph">The first case involves a petitioner who is seeking an <span style="font-weight: 500; font-style: italic">EB-2 classification as a professional with an advanced degree</span>. The petitioner is a police investigator who holds a bachelor's degree in Accounting. Unfortunately, the USCIS concluded that the petitioner did not qualify for the EB-2 classification with an advanced degree, nor did they provide evidence regarding exceptional ability.</div>

          <div class="blog-paragraph">The lesson we can learn from this case is the importance of providing evidence that meets the requirements for the EB-2 classification. In particular, the USCIS requires evidence of either an advanced degree or exceptional ability. If you're going to apply under the advanced degree category, <span style="background-color: rgba(68, 206, 111, 0.25)">make sure your degree is related to the field in which you are seeking employment, so your proposed endeavor. In case of doubts, make sure you explain the relationship in your application letter.</span> Otherwise, your application may be denied.</div>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

          <h5>Mistake #2:  Not meeting basic requirement for Exceptional Ability</h5>
          <h6 style="margin-bottom: 2rem">USCIS source: <a style="text-decoration: underline" href="https://www.uscis.gov/sites/default/files/err/B5%20-%20Members%20of%20the%20Professions%20holding%20Advanced%20Degrees%20or%20Aliens%20of%20Exceptional%20Ability/Decisions_Issued_in_2023/FEB282023_01B5203.pdf">HERE</a></h6>

          <div class="blog-paragraph">The case in question involves an aviation pilot from Germany who applied for a <span style="font-weight: 500; font-style: italic">second preference immigrant classification as an individual of exceptional ability (EB-2 NIW)</span>. The petitioner's initial application was denied because they had not established eligibility as an individual of exceptional ability. The petitioner met three required criteria but <span style="font-weight: 500; font-style: italic">did not demonstrate how their professional achievements set them apart from other airline pilots.</span></div>

          <div class="blog-paragraph">The case highlights an essential lesson to understand when applying for an EB-2 visa. While we must meet at least three criteria listed in the policy manual (see the full list <a target="_blank" href="https://www.uscis.gov/working-in-the-united-states/permanent-workers/employment-based-immigration-second-preference-eb-2" style="text-decoration: underline">HERE</a>), that does not guarantee qualification under the exceptional ability rule.</div>

          <div class="blog-paragraph"><span style="background-color: rgba(68, 206, 111, 0.25)">The USCIS evaluates each application in a <span style="font-weight: 500; font-style: italic">two-step process</span>. The first step is to determine if the applicant meets at least three of the six criteria. If they do, the second step is to evaluate the applicant's overall profile and determine if they possess a degree of expertise significantly above that ordinarily encountered in their field.</span></div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                <router-link class="btn btn-primary" :to="{ name: 'Home', params: { location: 'Templates' }}">EB-2 NIW Templates</router-link>
              </div>
            </div>
          </div>

          <div class="blog-paragraph">The petitioner in their RFE tried to add more criteria to their list, hoping that they would approve the case because they met many of the criteria. <span style="background-color: rgba(68, 206, 111, 0.25)">However, the USCIS requires applicants to demonstrate that they are above average and possess a level of expertise significantly above other individuals in their field. It is not enough to have a long list of accomplishments;</span> applicants must show that they are among the best in their field and possess skills that are unique and irreplaceable.</div>

          <div class="blog-paragraph">One way to demonstrate exceptional ability is through salary. Applicants can provide evidence of their high salary, which indicates that they are valued and in-demand professionals. However, this alone is not enough to qualify for an EB-2 visa. <span style="background-color: rgba(68, 206, 111, 0.25)">Remember to provide a comparable average salary relevant to your role, as a benchmark - to show you are significantly above the average profile in your profession.</span></div>
          
          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                  <a href="/eb2niw-mistakes-to-avoid-2" class="btn btn-primary">Continue Reading</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>
<script>
import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";

export default {
  name: 'EB2NIWMistakes',
  components: {
    PriceOffer,
    CarouselGreenCard
  }
}
</script>
