var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('section',{staticClass:"blog-details-area"},[_c('div',{staticClass:"article-content"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('vue-feather',{attrs:{"type":"clock"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("November 7, 2023")])],1),_c('li',[_c('vue-feather',{attrs:{"type":"user"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("Tom")])],1)])])])]),_vm._m(1),_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"article-content"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Why L1 visa holders should apply for a Green Card ASAP")]),_vm._m(2),_vm._m(3),_c('h5',[_vm._v("Job security")]),_vm._m(4),_vm._m(5),_c('PriceOffer',{staticStyle:{"margin-bottom":"4rem"}}),_c('CarouselGreenCard',{staticStyle:{"margin-bottom":"4rem"}}),_c('h5',[_vm._v("Flexibility in career choices")]),_c('div',{staticClass:"blog-paragraph"},[_vm._v("A green card offers L1 visa holders greater flexibility in terms of their career choices. With a green card, you are not tied to a specific employer or location. You can pursue job opportunities across various industries and locations without the constraints that come with temporary work visas.")]),_vm._m(6),_c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{ name: 'Home', params: { location: 'Templates' }}}},[_vm._v("EB-2 NIW Templates")])],1)])]),_c('h5',[_vm._v("Protection for family")]),_vm._m(7),_c('h5',[_vm._v("Pathway to U.S. citizenship")]),_vm._m(8),_c('h5',[_vm._v("Avoiding visa extension hassles")]),_vm._m(9),_vm._m(10),_vm._m(11)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area2"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('div',{staticClass:"article-tags"},[_c('a',{attrs:{"href":"#"}},[_vm._v("EB-2 NIW BLOG")])]),_c('h1',{staticStyle:{"font-weight":"600","margin-top":"30px","text-align":"left"}},[_vm._v("L1 visa holders during 2023 layoffs")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../resources/blog/blog40-16x9.webp"),"alt":"green card layoffs"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("The United States has long been a hub for skilled professionals from around the world, offering various visa options for foreign workers. One such visa, the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("L1 visa, allows multinational companies to transfer their employees to the U.S. temporarily")]),_vm._v(". While the L1 visa is an excellent opportunity for international professionals, recent economic uncertainties and ongoing layoffs have highlighted the importance of "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("applying for a green card")]),_vm._v(" as soon as possible.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("As someone who was once on an L1 visa, I can personally attest to the significance of this journey. In 2019, "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("I embarked on the green card application process")]),_vm._v(", and by mid-2020, I had successfully secured it. This endeavor involved navigating a certain level of uncertainty, particularly amidst the backdrop of Covid-related layoffs.")]),_vm._v(" However, the challenges posed by the Covid-related workforce disruptions now seem relatively minor when juxtaposed with the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("economic upheaval witnessed between 2022 and 2023")]),_vm._v(". It is against this backdrop that I've chosen to share the key motivating factors that led me to pursue a green card while holding an L1 visa.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("In recent years, the U.S. job market has exhibited volatility, marked by a growing prevalence of "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("layoffs across various industries")]),_vm._v(". L1 visa holders, including myself in the past, find themselves particularly susceptible to these uncertainties owing to the limitations imposed by their visa status. "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("As L1 visa holders, we are "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("bound to the employer")]),_vm._v(" who sponsored our visa, and a loss of this status often necessitates a return to our home country, potentially disrupting our lives and careers.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("Applying for a green card")]),_vm._v(" can offer L1 visa holders a sense of job security as it allows them to become lawful permanent residents, reducing the risk of being laid off due to changes in their employer's circumstances or the overall job market.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("This flexibility allows to explore "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("better job prospects")]),_vm._v(", switch employers, or even start own businesses. A green card often translates to a financial advantage, primarily by affording the freedom to select from a diverse array of employers and opportunities.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("One significant advantage of obtaining a green card is that it provides protection for your family. L1 visa holders can include their "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("immediate family members (spouse and children)")]),_vm._v(" in their green card applications. This ensures that your family members can legally live and work in the United States, alleviating concerns about their own job security and future prospects.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Acquiring a green card is the first step toward achieving U.S. citizenship. While L1 visa holders are generally temporary workers, a green card can "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("open the door to becoming a U.S. citizen")]),_vm._v(", granting you the rights and privileges of a permanent resident. U.S. citizens have access to a wider range of job opportunities and social benefits, further ensuring financial stability and a brighter future.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("L1 visa holders are initially granted up to three years in the U.S., with the possibility of extensions. However, constantly renewing your L1 visa can be a cumbersome and uncertain process, especially during periods of economic uncertainty. Applying for a green card can "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("eliminate the need for frequent visa extensions")]),_vm._v(" and the associated paperwork, ensuring your continued legal status in the U.S.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('a',{staticClass:"btn btn-primary btn-assess",attrs:{"href":"/are-you-eligible"}},[_vm._v("Are you eligible for EB-2 NIW?")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph",staticStyle:{"margin-bottom":"3rem"}},[_vm._v("In the face of ongoing layoffs and economic uncertainties, L1 visa holders should consider "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("applying for a green card")]),_vm._v(" as soon as possible. The path to permanent residency may have its challenges, but the long-term benefits far outweigh the effort required to secure a green card. By taking this step, L1 visa holders can protect their future and embrace the opportunities that the United States has to offer.")])
}]

export { render, staticRenderFns }