var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('section',{staticClass:"blog-details-area"},[_c('div',{staticClass:"article-content"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('vue-feather',{attrs:{"type":"clock"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("March 2, 2023")])],1),_c('li',[_c('vue-feather',{attrs:{"type":"user"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("Przemek")])],1)])])])]),_vm._m(1),_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"article-content"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Success story: EB-2 NIW")]),_vm._m(2),_c('div',{staticClass:"signupSelfPetition row justify-content-center align-items-center"},[_c('div',{staticClass:"col-xl-6 col-lg-7"},[_c('signup-self-petition')],1)]),_vm._m(3),_c('div',{staticClass:"blog-paragraph"},[_vm._v("One of the biggest obstacles was obtaining a green card, which would allow him to live and work in the United States permanently. Tom knew that his work was important and that he needed to stay in the country to continue it. He heard about the EB-2 NIW (National Interest Waiver) visa, which could allow him to bypass the traditional green card process if he could demonstrate that his work was in the national interest of the United States.")]),_vm._m(4),_vm._m(5),_c('PriceOffer',{staticStyle:{"margin-bottom":"4rem"}}),_c('CarouselGreenCard',{staticStyle:{"margin-bottom":"4rem"}}),_c('div',{staticClass:"blog-paragraph"},[_vm._v("After several months of waiting, Tom received the good news that his EB-2 NIW petition had been approved. This meant that he could now live and work in the United States permanently, without having to worry about the typical green card process.")]),_vm._m(6),_vm._m(7),_vm._m(8)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area2"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('span',{staticClass:"article-tags"},[_c('a',{attrs:{"href":"#"}},[_vm._v("EB-2 NIW BLOG")])]),_c('span',{staticClass:"article-tags2"},[_c('a',{attrs:{"href":"#"}},[_vm._v("SUCCESS STORY")])]),_c('h1',{staticStyle:{"font-weight":"600","margin-top":"30px","text-align":"left"}},[_vm._v("EB-2 NIW approval for biotech startup founder")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../resources/blog/blog20-16x9.webp"),"alt":"NIW template entrepreneur"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("Tom was a scientist and a biotech startup founder who was passionate about developing new and innovative treatments for rare diseases.")]),_vm._v(" He had a "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("PhD in biochemistry and years of experience working in the industry")]),_vm._v(", but he knew that he needed to bring his ideas to the next level. So, he decided to "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("start his own company")]),_vm._v(" and focused on finding new ways to treat rare genetic disorders that affected children.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Tom's company was in its early stages of development, but he was already making a big impact in the industry. His work was gaining "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("recognition from leading experts")]),_vm._v(" in the field. Despite these accomplishments, Tom faced many challenges in growing his company.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("For compiling an application Tom leveraged "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("EB-2 NIW Starter Kit")]),_vm._v(" for professionals. He gathered all the necessary documents and prepared a strong petition that highlighted the importance of his work and how it benefited the United States. "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("He listed all received awards and grants, press and media coverage of his startup work, traction of discussions which he held with investors and the main milestones")]),_vm._v(" that the startup achieved so far.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Check "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("Starter Kit for EB-2 NIW")]),_vm._v(", which includes self-petition Green Card templates for professionals:")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("With his new status, Tom was able to focus fully on his work and his company. He also "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("secured additional funding and hired more scientists")]),_vm._v(", which allowed him to expand his research and develop new treatments faster. His company became one of the leading biotech startups in the country and his work was recognized by the medical community as some of the most important research in the field.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem","padding-bottom":"0px"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('a',{staticClass:"btn btn-primary btn-assess",attrs:{"href":"/are-you-eligible"}},[_vm._v("Are you eligible for EB-2 NIW?")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph",staticStyle:{"padding-bottom":"6rem"}},[_vm._v("Tom's EB-2 NIW approval was a turning point in his career and allowed him to continue his important work and make a real impact in the lives of those affected by "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("rare genetic disorders")]),_vm._v(". He was grateful for the opportunity and never forgot how the EB-2 NIW visa had helped him reach his goals.")])
}]

export { render, staticRenderFns }