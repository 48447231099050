<template>

    <section class="works-area ptb-80 bg-f9fafb">

      <b-modal ref="modal-cover-letter" title="Pilot" size="xl" hide-footer>
        <img src="../resources/sample-pilot.webp" alt="eb2 niw sample pilot"/>
      </b-modal>

      <b-modal ref="modal-case-builder" title="Pilot" size="xl" hide-footer>
        <img src="../resources/caseBuilder-pilot.webp" alt="eb2 niw sample pilot"/>
      </b-modal>

      <b-modal ref="modal-cover-letter-2" title="Pilot & Aeronautical Engineer" size="xl" hide-footer>
        <img src="../resources/sample-aeronautical.webp" alt="eb2 niw sample aeronautical"/>
      </b-modal>

      <b-modal ref="modal-case-builder-2" title="Pilot & Aeronautical Engineer" size="xl" hide-footer>
        <img src="../resources/caseBuilder-aeronautical.webp" alt="eb2 niw sample aeronautical"/>
      </b-modal>

      <div class="container" style="margin-top: 5rem">
        <div class="row">
          <div class="col-sm-3 col-8 offset-sm-0 offset-2" style="margin-bottom: 4rem">
            <img src="../resources/pilot.webp" style="border-radius: 50%; border: 2px solid; border-color: #44ce6f; box-shadow: 10px 20px 20px 0px rgba(86, 97, 118, .25)" alt="eb2 niw sample pilot"/>
          </div>

          <div class="col-sm-8 offset-sm-1 col-12 sample-h1">
            <div>
              <h1 style="font-weight: 600;">Pilot & Aeronautical Engineer</h1>
              <div class="bar"></div>
            </div>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 30px">
          <div style="margin: 1rem; margin-top: 2rem; max-width: 1000px">In the dynamic sphere of aviation, pilots and aeronautical engineers embarking on the EB-2 NIW journey encounter a unique set of challenges and opportunities. For these aviation professionals, the path to a successful petition extends beyond showcasing flying or engineering skills; it involves articulating the profound impact of their contributions to aviation safety, technological advancements, and overall industry development.</div>
          <div style="margin: 1rem; max-width: 1000px">The EB-2 NIW process for pilots and aeronautical engineers demands a meticulous presentation of achievements, emphasizing the strategic role played in shaping and advancing aviation solutions. This requires delving into the details of successful flight operations, innovative engineering methodologies, and safety protocols that have significantly influenced the aviation landscape.</div>
          <div style="margin: 1rem; max-width: 1000px">Effectively navigating the EB-2 NIW process in aviation necessitates a comprehensive approach. Applicants must underscore their instrumental role in ensuring safe and efficient flights, optimizing engineering processes, and contributing to the overall safety and success of the aviation industry. Emphasizing the broader societal and economic implications of their work, within the context of modern aviation dynamics, is crucial. Advancements in aviation align with national interests, such as technological leadership, economic growth, and ensuring the security and well-being of the nation.</div>
          <div style="margin: 1rem; max-width: 1000px">With the inherent alignment of aviation expertise with national interests, applicants can position themselves as contributors to economic growth, technological innovation, and national security. This alignment is recognized and emphasized by policymakers, reflecting the national interest in maintaining a leading position in aviation, fostering innovation, and addressing the evolving dynamics in the aviation industry.</div>
          <div style="margin: 1rem; max-width: 1000px">It's important to note that, contrary to common belief, extensive research, publications, or citations are not obligatory for EB-2 NIW. Our templates are specifically tailored for pilots and aeronautical engineers, focusing on the strategic impact and broader implications of their contributions to aviation safety and industry development.</div>
        </div>

<!--Pilot-->
        <div id="profession-sample" style="margin-bottom: 1.5rem">
          <div style="margin: 6rem 0rem">
            <h4 style="text-align: center;">Pilot</h4>
            <div class="section-title">
              <div class="bar"></div>
            </div>
            
            <div style="text-align: center; margin: 4rem 0 6rem">
              <button v-on:click="navigateToCheckoutFirst" class="btn btn-primary">GET THIS TEMPLATE</button>
              <div class="price-offer-comment" style="color: #c4c4c4; margin-top: 50px;">*** Not quite right? Try <a style="color: #c4c4c4; text-decoration: underline" href="/ai">Selfpetition AI</a>  instead ***</div>            
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCoverLetterModal" style="cursor: pointer">
              <img src="../resources/sample-pilot.webp" alt="pilot i140 sample">
              <div class="works-content">
                <h3 style="color: #fff">Pilot</h3>
                <h3 style="color: #fff">Cover Letter (9 pages)</h3>
                <p>The cover letter comprises the following segments: meeting the criteria for an Advance Degree, Prong 1, Prong 2, Prong 3, and an appendix containing all supporting evidence. When checking out, you have the option to select up to two professions.</p>
              </div>
            </div>

            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCaseBuilderModal" style="cursor: pointer">
              <img src="../resources/caseBuilder-pilot.webp" alt="pilot green card">
              <div class="works-content">
                <h3 style="color: #fff">Pilot</h3>                    
                <h3 style="color: #fff">EB-2 NIW Case Builder (20 pages)</h3>
                <p>You might be wondering how to identify relevant NIW initiatives and supporting arguments. This is where the NIW Case Builder Pdf comes in – it's designed to inspire you with alternative points to emphasize in your cover letter as you modify our template.</p>
              </div>
            </div>
          </div>

  <!--Pilot & Aeronautical Engineer-->
          <div style="margin: 6rem 0rem 5rem">
            <h4 style="text-align: center;">Pilot & Aeronautical Engineer</h4>
            <div class="section-title">
              <div class="bar"></div>
            </div>
            
            <div style="text-align: center; margin: 4rem 0 6rem">
              <button v-on:click="navigateToCheckoutSecond" class="btn btn-primary">GET THIS TEMPLATE</button>
              <div class="price-offer-comment" style="color: #c4c4c4; margin-top: 50px;">*** Not quite right? Try <a style="color: #c4c4c4; text-decoration: underline" href="/ai">Selfpetition AI</a>  instead ***</div>            
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCoverLetterModal2" style="cursor: pointer">
              <img src="../resources/sample-aeronautical.webp" alt="aeronautical i140 sample">
              <div class="works-content">
                <h3 style="color: #fff">Pilot & Aeronautical Engineer</h3>
                <h3 style="color: #fff">Cover Letter (9 pages)</h3>
                <p>The cover letter comprises the following segments: meeting the criteria for an Advance Degree, Prong 1, Prong 2, Prong 3, and an appendix containing all supporting evidence. When checking out, you have the option to select up to two professions.</p>
              </div>
            </div>

            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCaseBuilderModal2" style="cursor: pointer">
              <img src="../resources/caseBuilder-aeronautical.webp" alt="aeronautical green card">
              <div class="works-content">
                <h3 style="color: #fff">Pilot & Aeronautical Engineer</h3>                    
                <h3 style="color: #fff">EB-2 NIW Case Builder (20 pages)</h3>
                <p>You might be wondering how to identify relevant NIW initiatives and supporting arguments. This is where the NIW Case Builder Pdf comes in – it's designed to inspire you with alternative points to emphasize in your cover letter as you modify our template.</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div style="margin: 5rem 0rem">
            <h4 style="text-align: center;">EB2 NIW Starter Kit</h4>
            <h6 style="text-align: center;">List of Documents</h6>
            <div class="section-title">
              <div class="bar"></div>
            </div>
          </div>

          <div class="section-title faq" style="margin-bottom: 1.5rem">
            <DocumentsList></DocumentsList>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 1.5rem">
          <div class="row">
            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Most suitable for</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">Pilot</li>
                <li class="profession-page-list">Aeronautical Engineer</li>
                <li class="profession-page-list">Air Traffic Controller</li>
                <li class="profession-page-list">Aerospace Technician</li>
                <li class="profession-page-list">Flight Dispatcher</li>
                <li class="profession-page-list">Aircraft Maintenance Engineer</li>
                <li class="profession-page-list">Aviation Safety Inspector</li>
                <li class="profession-page-list">Aerospace Design Engineer</li>
                <li class="profession-page-list">Avionics Technician</li>
                <li class="profession-page-list">Flight Test Engineer:</li>
                <li class="profession-page-list">Airport Manager</li>
                <li class="profession-page-list">Aircraft Systems Engineer</li>
                <li class="profession-page-list">Aerospace Project Manager</li>
                <li class="profession-page-list">Aircraft Interior Designer</li>
                <li class="profession-page-list">Airport Planner</li>
                <li class="profession-page-list">Airline Operations Manager</li>
                <li class="profession-page-list">Cargo pilot</li>
                <li class="profession-page-list">Flight Simulator Technician</li>
                <li class="profession-page-list">Airport Security Manager</li>
                <li class="profession-page-list">Aircraft Sales Representative</li>
                <li class="profession-page-list">Aircraft Leasing Manager</li>
                <li class="profession-page-list">Satellite Systems Engineer</li>
                <li class="profession-page-list">Aerospace Quality Engineer</li>
                <li class="profession-page-list">Aircraft Manufacturing Engineer</li>
                <li class="profession-page-list">Airline Training Instructor</li>
                <li class="profession-page-list">... and other similar</li>
              </ul>
            </div>

            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Topics</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">More Electric Aircraft (MEA)</li>
                <li class="profession-page-list">Aerospace products and systems</li>
                <li class="profession-page-list">Electrical power distribution</li>
                <li class="profession-page-list">Circuit breakers</li>
                <li class="profession-page-list">Motion control</li>
                <li class="profession-page-list">Aircraft engines</li>
                <li class="profession-page-list">Sensing and propulsion solutions</li>
                <li class="profession-page-list">Smart energy management</li>
                <li class="profession-page-list">Aviation environmental sustainability</li>
                <li class="profession-page-list">Reducing carbon emissions</li>
                <li class="profession-page-list">Aviation energy efficiency</li>
                <li class="profession-page-list">U.S. aviation technology innovation</li>
                <li class="profession-page-list">U.S. energy independence</li>
                <li class="profession-page-list">Aviation system reliability</li>
                <li class="profession-page-list">Fault tolerance in aircraft systems</li>
                <li class="profession-page-list">Advanced power electronics</li>
                <li class="profession-page-list">Efficient aircraft power distribution</li>
                <li class="profession-page-list">U.S. aviation market leadership</li>
                <li class="profession-page-list">Cargo</li>
                <li class="profession-page-list">Aviation logistics</li>
                <li class="profession-page-list">Hazardous goods transportation</li>
                <li class="profession-page-list">Strategic goods transport</li>
                <li class="profession-page-list">National defense logistics</li>
                <li class="profession-page-list">Healthcare supply chain</li>
                <li class="profession-page-list">Manufacturing logistics</li>
                <li class="profession-page-list">Agriculture cargo transport</li>
                <li class="profession-page-list">Safety regulations</li>
                <li class="profession-page-list">Hazardous materials handling</li>
                <li class="profession-page-list">Cold chain management</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="shape2 rotateme" style="left: 90%"><img src="../resources/shape2.svg" alt="EB2 niw diy"></div>
      <div class="shape4"><img src="../resources/shape4.svg" alt="EB-2 niw pilot"></div>
      <div class="shape7"><img src="../resources/shape4.svg" alt="EB-2 niw aeronautical"></div>
    </section>

</template>

<script>

import { mapActions } from 'vuex';
import DocumentsList from "./DocumentsList.vue";

    export default {
      name: 'ProfessionPilot',
      components: {
        DocumentsList,
      },
      data() {
        return {
          user: {
            profession1: 'pilot',
            profession2: 'aeronauticalEngineer',
          }
        }
      },
      methods: {
        ...mapActions('account', ['apply']),
        navigateToCheckoutFirst() {
          //this.apply('Pilot Sample');
          // Handle navigation to checkout here
          this.$router.push({
            path: '/checkout-starter-kit',
            query: {
              profession: this.user.profession1
            }
          });
        },
        navigateToCheckoutSecond() {
          //this.apply('Aeronautical Engineer Sample');
          // Handle navigation to checkout here
          this.$router.push({
            path: '/checkout-starter-kit',
            query: {
              profession: this.user.profession2
            }
          });
        },
        openCoverLetterModal() {
          setTimeout(() => {
            this.$refs['modal-cover-letter'].show();
          }, 100);
        },
        openCaseBuilderModal() {
          setTimeout(() => {
            this.$refs['modal-case-builder'].show();
          }, 100);
        },
        openCoverLetterModal2() {
          setTimeout(() => {
            this.$refs['modal-cover-letter-2'].show();
          }, 100);
        },
        openCaseBuilderModal2() {
          setTimeout(() => {
            this.$refs['modal-case-builder-2'].show();
          }, 100);
        },
      }
    }
</script>

