<template>
  <div style="margin-bottom: 2rem">

    <b-modal ref="modal-ai" title="Selfpetition AI Samples" size="xl" hide-footer>
      <CarouselAiSample></CarouselAiSample>
    </b-modal>

    <b-modal ref="modal-gpt-vs-selfpetition" title="Selfpetition vs ChatGPT" size="xl" hide-footer>
      <div class="row">
        <img class="col-lg-10 col-md-12 col-10 offset-lg-1 offset-md-0 offset-1" src="../resources/vs-selfpetition.svg" style="padding-bottom: 2rem" alt="eb2 niw selfpetition" />
        <img class="col-lg-10 col-md-12 col-10 offset-lg-1 offset-md-0 offset-1" src="../resources/vs-gpt.svg" alt="eb2 niw gpt" />
      </div>
    </b-modal>

<!-- Start AI Main Banner -->
    <div style="padding-top: 110px">
      <div  style="background: #1a3717; color: #b0ff7a; padding-top:40px; padding-bottom: 60px;">
        <section id="ai"/>
                      
        <div class="row">
          <img class="d-block d-sm-none col-7" src="../resources/robot.webp" style="width: 70%; margin: 0 auto; border-radius: 50%; border: 2px solid; border-color: #ff4e00; box-shadow: 10px 20px 20px 0px rgba(68, 206, 111, .25)" alt="eb2 niw ai" />
        </div>
        
        <div class="d-table">
          <div class="d-table-cell">
            <div class="container">
              
              <h1 style="color: #feff76; text-align: left"><img src="../resources/newIcon.webp" style="height: 50px" alt="eb2 niw ai" /><span style="margin-left: -15px">Selfpetition AI</span></h1>
              
              <div class="row h-100 justify-content-center align-items-center">  
                <div class="col-xl-7 col-md-7">
                  <div class="hero-content">
                    <div class="row">
                      <div class="col-lg-11"> 
                        <h5 style="color: #feff76">Your customized EB2 NIW application, written by artificial intelligence</h5>
                        <div style="font-size: 18px; margin-top: 2rem;">1) Provide input for AI: fill out a form with information about your unique background</div>
                        <div style="font-size: 18px; margin-top: 1rem;">2) Let AI handle the drafting…</div>
                        <div style="font-size: 18px; margin-top: 1rem;">3) …while you review and comment to finalize the application</div>
                        <a href="#" v-on:click="handleSubmitStart" class="btn btn-primary btn-ai-banner">START ORDER --></a>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="col-xl-5 col-md-5" style="display: flex; align-center: flex-end;">
                  <div class="row">
                    <img class="d-none d-sm-block col-8" src="../resources/robot.webp" style="width:70%; margin: 0 auto; border-radius: 50%; border: 2px solid; border-color: #ff4e00; box-shadow: 10px 20px 20px 0px rgba(68, 206, 111, .25)" alt="eb2 niw ai" />
                  </div>
                </div>

              </div>    
            </div>
          </div>
        </div>

        <div class="shape3"><img src="../resources/shape3.svg" alt="eb2 visa"></div>
        <div class="shape4"><img src="../resources/shape4.svg" alt="eb-2 niw"></div>
        <div class="shape5" style="top: 55%"><img src="../resources/shape5.png" alt="eb-2 visa"></div>
        <div class="shape6 rotateme"><img src="../resources/shape4.svg" alt="eb-2 niw green card"></div>
        <div class="shape7"><img src="../resources/shape4.svg" alt="eb-2"></div>
      </div>
    </div>

<!-- Start GPT vs. Selfpetition Section -->
    <div class="services-area ptb-80" style="padding-top: 3rem">
      <section id="gpt-compared"/>
      <div class="container">

        <div class="section-title">
          <h2>The Ultimate Test</h2>
          <div class="bar"></div>
          <div class="paragraph">
            <p>If you think ChatGPT will draft EB-2 NIW petition as well as Selfpetition AI, try GPT today. We disagree, which is why we created our own USCIS-trained model</p>
          </div>
        </div>

        <div class="col-xl-4 col-md-6 col-12 offset-md-3 offset-xl-4" style="background: #0e314c; padding: 15px; border-radius: 15px">
          <h6 style="text-align: center; color: #fff">Using ChatGPT is always an option...</h6>
          <h6 style="text-align: center; color: #fff">...but will the quality satisfy USCIS?</h6>
        </div>
        <h2 style="text-align: center; color: #6084a4; margin-top: 20px; margin-bottom: 1rem">&#8681;</h2>

        <div class="output-desktop-container">
            <img class="output-desktop" src="../resources/gpt-vs-selfpetition-output.svg" @click="openGptVsSelfpetition" style="cursor: pointer;" alt="eb2 niw gpt" />
            <vue-feather class="overlay" type="zoom-in" @click="openGptVsSelfpetition"></vue-feather>
        </div>

        <img class="output-mobile" src="../resources/vs-comparison.svg" alt="selfpetition vs gpt" />
        <img class="output-mobile" src="../resources/vs-selfpetition.svg" alt="eb2 niw selfpetition" />
        <img class="output-mobile" src="../resources/vs-gpt.svg" alt="eb2 niw gpt" />

      </div>
    </div>

<!-- Start How it Works Section -->
    <div class="services-area ptb-80" style="padding-bottom: 2rem; padding-top: 0">
      <section id="ai-product"/>
      <div class="container">

        <div class="section-title">
          <h2>How Does It Work?</h2>
          <div class="bar"></div>
          <div class="paragraph">
            <p>AI model trained on successful cases and USCIS data. Designed for those seeking a tailored solution and a desire to outsource their petition drafting, Selfpetition AI adapts its services to match your unique profile</p>
          </div>
        </div>

        <SignupAiSamples style="padding-bottom: 2rem; padding-top: 0rem"></SignupAiSamples>

        <div class="services-content">

          <div class="d-block d-lg-none">
            <div class="row">

              <div class="col-sm-10 col-12 offset-sm-1">
                <div style="display: flex; justify-content: center;">
                  <div class="single-works"  @click="openAiModal" style="cursor: pointer; margin-bottom: 0px;">
                    <div class="glass-sample">SAMPLE</div>
                    <vue-feather class="glass-zoom" type="zoom-in"></vue-feather>

                    <div style="padding: 0px; opacity: 0.4">
                      <img src="../resources/ai-sample-prong.webp" alt="eb2 niw">
                    </div>
                  </div>
                </div>
                  <a href="/checkout-selfpetition-ai" style="width: 100%; display: block;">
                    <div class="box" style="display: flex; align-items: center;">
                      <div style="width: 30px"><font-awesome-icon class="fontawesome circle" icon="1" style="margin-right: 10px;"/></div>
                      <div style="margin-left: 10px">You <span style="color: #5457c1">fill out a form</span> describing your unique background, for Selfpetition AI to build YOUR case.</div>
                    </div>
                  </a>

                  <a href="/checkout-selfpetition-ai" style="width: 100%; display: block;">
                    <div class="box" style="display: flex; align-items: center;">
                      <div style="width: 30px"><font-awesome-icon class="fontawesome circle" icon="2" style="margin-right: 10px;"/></div>
                      <div style="margin-left: 10px">Receive your first cover letter draft in 48 hours, which includes various AI-generated <span style="color: #5457c1">suggestions</span> of <span style="color: #5457c1">proposed endeavor</span> and <span style="color: #5457c1">national importance</span>.</div>
                    </div>
                  </a>

                  <a href="/checkout-selfpetition-ai" style="width: 100%; display: block;">
                    <div class="box" style="display: flex; align-items: center;">
                      <div style="width: 30px"><font-awesome-icon class="fontawesome circle" icon="3" style="margin-right: 10px;"/></div>
                      <div style="margin-left: 10px">Review and <span style="color: #5457c1">respond with comments</span> or required changes, documents in ediatable Word format (Google Docs).</div>
                    </div>
                  </a>

                  <a href="/checkout-selfpetition-ai" style="width: 100%; display: block;">
                    <div class="box" style="display: flex; align-items: center;">
                      <div style="width: 30px"><font-awesome-icon class="fontawesome circle" icon="4" style="margin-right: 10px;"/></div>
                      <div style="margin-left: 10px">Once you are happy with the cover letter, we proceed to <span style="color: #5457c1">draft reference letters</span>, otherwise you get a partial refund.</div>
                    </div>
                  </a>

                  <a href="/checkout-selfpetition-ai" style="width: 100%; display: block;">
                    <div class="box" style="display: flex; align-items: center;">
                      <div style="width: 30px"><font-awesome-icon class="fontawesome circle" icon="5" style="margin-right: 10px;"/></div>
                      <div style="margin-left: 10px">Exclusive lifetime access to <img src="../resources/discord-logo.svg" style="width: 90px; margin-left: 5px; margin-right: 5px" alt="Self Petition USA community"/> community for users working on their EB-2 NIW.</div>
                    </div>
                  </a>

              </div>
              
              <a href="#" v-on:click="handleSubmitStart" class="btn btn-primary btn-ai-banner" style="margin: 5rem auto 2rem">START ORDER --></a>

            </div>
          </div>


            <div class="d-none d-lg-block">
              <div class="row">
                <div class="col-xl-7 col-lg-6 col-md-12">

                  <a href="/checkout-selfpetition-ai" style="width: 100%; display: block;">
                    <div class="box" style="display: flex; align-items: center;">
                      <div style="width: 30px"><font-awesome-icon class="fontawesome circle" icon="1" style="margin-right: 10px;"/></div>
                      <div style="margin-left: 10px">You <span style="color: #5457c1">fill out a form</span> describing your unique background, for Selfpetition AI to build YOUR case.</div>
                    </div>
                  </a>

                  <a href="/checkout-selfpetition-ai" style="width: 100%; display: block;">
                    <div class="box" style="display: flex; align-items: center;">
                      <div style="width: 30px"><font-awesome-icon class="fontawesome circle" icon="2" style="margin-right: 10px;"/></div>
                      <div style="margin-left: 10px">Receive your first cover letter draft in 48 hours, which includes various AI-generated <span style="color: #5457c1">suggestions</span> of <span style="color: #5457c1">proposed endeavor</span> and <span style="color: #5457c1">national importance</span>.</div>
                    </div>
                  </a>

                  <a href="/checkout-selfpetition-ai" style="width: 100%; display: block;">
                    <div class="box" style="display: flex; align-items: center;">
                      <div style="width: 30px"><font-awesome-icon class="fontawesome circle" icon="3" style="margin-right: 10px;"/></div>
                      <div style="margin-left: 10px">Review and <span style="color: #5457c1">respond with comments</span> or required changes, documents in ediatable Word format (Google Docs).</div>
                    </div>
                  </a>

                  <a href="/checkout-selfpetition-ai" style="width: 100%; display: block;">
                    <div class="box" style="display: flex; align-items: center;">
                      <div style="width: 30px"><font-awesome-icon class="fontawesome circle" icon="4" style="margin-right: 10px;"/></div>
                      <div style="margin-left: 10px">Once you are happy with the cover letter, we proceed to <span style="color: #5457c1">draft reference letters</span>, otherwise you get a partial refund.</div>
                    </div>
                  </a>

                  <a href="/checkout-selfpetition-ai" style="width: 100%; display: block;">
                    <div class="box" style="display: flex; align-items: center;">
                      <div style="width: 30px"><font-awesome-icon class="fontawesome circle" icon="5" style="margin-right: 10px;"/></div>
                      <div style="margin-left: 10px">Exclusive lifetime access to <img src="../resources/discord-logo.svg" style="width: 90px; margin-left: 5px; margin-right: 5px" alt="Self Petition USA community"/> community for users working on their EB-2 NIW.</div>
                    </div>
                  </a>

                </div>

                <div class="col-xl-5 col-lg-6 col-md-0 d-flex justify-content-center align-items-center" style="padding-right: 8px;">
                  <div class="single-works"  @click="openAiModal" style="cursor: pointer; margin-bottom: 0px; width: 90%">
                    <div class="glass-sample">SAMPLE</div>
                    <vue-feather class="glass-zoom" type="zoom-in"></vue-feather>

                    <div style="padding: 0px; opacity: 0.4">
                      <img src="../resources/ai-sample-prong.webp" alt="eb2 niw">
                    </div>
                  </div>
                </div>

                <div class="col-12" style="padding-left: 8px;">
                  <a href="/checkout-selfpetition-ai">
                    <div class="box" style="margin-top: 0px">
                      <div style="color: #ff4e00; font-weight: 600;"><span style="font-size: 2rem;">&#127941;</span> Satisfaction Guarantee</div>
                      <div>If you are dissatisfied with the cover letter after iterations, we offer a <span style="font-weight: 600;">$<span style="text-decoration: underline">500 refund</span></span> and will refrain from proceeding to draft reference letters.</div>
                    </div>
                  </a>
                </div>
                
              </div>

              <a href="#" v-on:click="handleSubmitStart" class="btn btn-primary btn-ai-banner">START ORDER --></a>
            </div>
                
        </div>
      </div>
    </div>

<!-- Start Product Comparison Section -->
    <div class="services-area ptb-80" style="padding-top: 1rem">
      <section id="products-compared"/>
      <div class="container">

        <div class="section-title">
          <h2>Selfpetition AI <span style="text-transform: lowercase">vs.</span> Starter Kit</h2>
          <div class="bar"></div>
          <div class="paragraph">
            <p>While Starter Kit provides a standard template for a given profession, Selfpetition AI customizes its approach based on your professional profile captured through a simple input form</p>
          </div>
        </div>

        <ProductsCompared :contains-faq="containsFaq"></ProductsCompared>
        <CarouselAi></CarouselAi>

      </div>
    </div>

<!-- Start How can I be sure that Selfpetition AI provides value? -->
    <div class="services-area ptb-80" style="padding-top: 1rem">
      <section id="selfpetitionai-value"/>
      <div class="container">

        <div class="section-title">
          <h2>How can I trust Selfpetition AI?</h2>
          <div class="bar"></div>
          <div class="paragraph">
            <p>Although artificial intelligence models can seem mysterious, Selfpetition AI ensures that your petition will be far from generic fluff</p>
          </div>
        </div>

        <div class="star-section">
          <div class="row">
<!-- small screens-->                        
            <div class="d-block d-sm-none col-12">
              <div class="star-row">
                <font-awesome-icon class="star-icon" icon="star" />
                <p class="star-input" style="font-weight: 600">Satisfaction Guarantee: <span style="font-weight: 400">If you are dissatisfied with the cover letter after iterations, we offer a $500 refund and will refrain from proceeding to draft reference letters</span></p>
              </div>
            </div>

            <div class="d-block d-sm-none col-12">
              <div class="star-row">
                <font-awesome-icon class="star-icon" icon="star" />
                <p class="star-input" style="font-weight: 600">Unlimited Revisions: <span style="font-weight: 400">as many edits as requested to ensure your satisfaction</span></p>
              </div>
            </div>
              
            <div class="d-block d-sm-none col-12">
              <div class="star-row">
                <font-awesome-icon class="star-icon" icon="star" />
                <p class="star-input" style="font-weight: 600">Quality Assurance: <span style="font-weight: 400">We review each Selfpetition AI draft before it reaches you to maintain high quality</span></p>
              </div>
            </div>
          </div>

<!-- desktop screens-->
          <div class="d-none d-sm-block col-6 offset-3">
            <div class="star-row">
              <font-awesome-icon class="star-icon" icon="star" />
              <p class="star-input" style="font-weight: 600">Satisfaction Guarantee: <span style="font-weight: 400">If you are dissatisfied with the cover letter after iterations, we offer a $500 refund and will refrain from proceeding to draft reference letters</span></p>
            </div>
          </div>

          <div class="d-none d-sm-block col-6 offset-3">
            <div class="star-row">
              <font-awesome-icon class="star-icon" icon="star" />
              <p class="star-input" style="font-weight: 600">Unlimited Revisions: <span style="font-weight: 400">as many edits as requested to ensure your satisfaction</span></p>
            </div>
          </div>

          <div class="d-none d-sm-block col-6 offset-3">
            <div class="star-row">
              <font-awesome-icon class="star-icon" icon="star" />
              <p class="star-input" style="font-weight: 600">Quality Assurance: <span style="font-weight: 400">We review each Selfpetition AI draft before it reaches you to maintain high quality</span></p>
            </div>
          </div>

        </div>      
      </div>
    </div>
    
  </div>    
</template>

<script>

  import { mapActions } from 'vuex';
  import CarouselAi from "./CarouselAiForm.vue";
  import CarouselAiSample from "./CarouselAiSample.vue";
  import ProductsCompared from "./ProductsCompared.vue";
  import SignupAiSamples from "./SignupAiSamples.vue";

  export default {
    name: 'GreenCardAi',
    data() {
      return {
        containsFaq: false,
      }
    },  
    components: {
      SignupAiSamples,
      CarouselAi,
      CarouselAiSample,
      ProductsCompared,
    },
    methods: {
      ...mapActions('account', ['apply']),
      handleSubmitStart(event) {
        event.preventDefault();
        this.sendEvent("ai-start-single");
        window.location.href = '/checkout-selfpetition-ai';
      },
      sendEvent(eventType) {
        if (window.gtag) {
          window.gtag('event', eventType); // google analytics
        }
      },
      openAiModal() {
        setTimeout(() => {
            this.$refs['modal-ai'].show();
        }, 100);
      },
      openGptVsSelfpetition() {
        setTimeout(() => {
            this.$refs['modal-gpt-vs-selfpetition'].show();
        }, 100);
      }
    }
  }

</script>
