<template>
    <div>

        <div style="padding-top: 5rem; margin-top: 2rem; margin-bottom: 0rem;" >
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container" style="margin-top: 14px">
                        <h5 class="order-conf" style="color: #5457c1; margin-bottom: 20px">Thank you for ordering 🎉🎉🎉</h5>
                        <h1 style="height:70px;">Now... fill out the form to provide <span style="color: #5457c1">AI input</span></h1>
                        <div class="mobile-center" style="display: flex; margin-top: 4rem">
                            <h2>&#129302;</h2>
                            <h6 style="padding-left: 10px; margin-bottom: 0px; margin: auto 0">Allow up to 48h to receive the first draft</h6>
                        </div>

                        <div  style="margin-top: 2rem; border: 2px solid #5457c1" class="alert alert-light" role="alert">
                            The greater the level of detail you provide, the more comprehensive your petition can become, reducing the need for numerous iterations with respect to the cover letter and reference letters
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="d-table" style="padding-top: 3rem; background-color: #f9fafb">
            <div class="d-table-cell">
                <div class="container">

                    <div class="row" style="margin-bottom: 3rem">
                        <h5 class="col-12">Your profile overview</h5>

                        <div class="col-md-8" style="padding-left: 2rem; padding-top: 1rem">
                            <div class="form-group">
                                <label for="email" style="margin-bottom: 3px">Confirm your e-mail</label>
                                <input type="email" class="form-control" id="email" @input="handleInputChange" aria-describedby="email" placeholder="E-mail to receive Selfpetition AI draft" style="border-radius: 5px; border-color: #44ce6f" v-model="userBackground.email" v-on:input="clearEmailError">
                            </div>
                            <div class="form-group">
                                <label for="profession" style="margin-bottom: 3px">Profession and job title</label>
                                <input type="text" class="form-control" id="profession" @input="handleInputChange" aria-describedby="profession" style="border-radius: 5px; border-color: #44ce6f" v-model="userBackground.profession">
                            </div>
                            <div class="form-group">
                                <label for="company" style="margin-bottom: 3px">Current company</label>
                                <input type="text" class="form-control" id="company" @input="handleInputChange" aria-describedby="company" style="border-radius: 5px; border-color: #44ce6f" v-model="userBackground.company">
                            </div>
                            <div class="form-group">
                                <label for="industry" style="margin-bottom: 3px">Industry/sector that you work in</label>
                                <input type="text" class="form-control" id="industry" @input="handleInputChange" aria-describedby="industry" style="border-radius: 5px; border-color: #44ce6f" v-model="userBackground.industry">
                            </div>
                            <div class="form-group">
                                <label for="linkedin" style="margin-bottom: 3px">LinkedIn profile</label>
                                <input type="text" class="form-control" id="linkedin" @input="handleInputChange" aria-describedby="linkedin" placeholder="https://www.linkedin.com/" style="border-radius: 5px; border-color: #44ce6f" v-model="userBackground.linkedin">
                            </div>
                            <div class="form-group" style="margin-bottom: 0px;">
                                <label for="resume" style="margin-bottom: 0px;">
                                    <span>Upload Resume</span>
                                    <div style="cursor: pointer; font-size: 2.5rem; margin-left: 2px;"><font-awesome-icon icon="file-upload" style="color: #44ce6f;"/></div>
                                </label>
                                <input type="file" class="form-control-file" id="resume" ref="resume" v-on:change="() => checkFileSize('resume')" @input="handleInputChange" style="display: none;">
                                <p v-if="userBackground.resume" style="display: flex; align-items: center">Uploaded: {{ userBackground.resumeName }}
                                    <span v-on:click="() => deleteFile('resume')" style="margin-left: 18px; font-size: 18px; cursor: pointer;">
                                        <font-awesome-icon icon="trash-can" />
                                    </span>
                                </p>
                            </div>
                        </div>

<!-- Alerts to validate submission-->
                        <div class="col-8" style="padding-left: 2rem; padding-top: 1rem">
                            <div v-if="submittedAlert && !submittedSuccess && !isEmail" class="alert alert-warning" role="alert" style="margin-bottom: 5px">
                                <div style="font-weight: 500">Enter a valid e-mail</div>
                            </div>
                            
                            <div v-if="submittedAlert && !submittedSuccess && !userBackground.profession" class="alert alert-warning" role="alert" style="margin-bottom: 5px">
                                <div style="font-weight: 500">Your profession is required</div>
                            </div>
                            
                            <div v-if="submittedAlert && !submittedSuccess && !userBackground.company" class="alert alert-warning" role="alert" style="margin-bottom: 5px">
                                <div style="font-weight: 500">Your company is required (current or previous one)</div>
                            </div>
<!-- Alerts to validate Resume submission-->
                            <div v-if="submittedAlert && !submittedSuccess && !userBackground.linkedin && !userBackground.resumeName" class="alert alert-warning" role="alert" style="margin-bottom: 5px">
                                <div style="font-weight: 500">At least one is required: LinkedIn or Resume</div>
                            </div>
                            <div v-if="resumeTooLarge" class="alert alert-danger" role="alert" style="margin-bottom: 5px">
                                File size exceeds 3MB limit: you can also e-mail us at ceo@selfpetitionusa.com
                            </div>
                        </div>

                    </div>

                    <div class="row" style="margin-bottom: 3rem">
                        <h5 class="col-12">Proposed endeavor</h5>
                        <div class="col-12" style="padding-left: 2rem; padding-top: 1rem">
                            <div class="form-group">
                                <label for="roleDesc" style="margin-bottom: 3px">Describe your current role
                                    <br><span style="font-weight: 400">- How company or clients benefit from your work?</span>
                                    <br><span style="font-weight: 400">- Who benefits from your work: is it internal team, external clients, other?</span></label>
                                <textarea class="form-control" id="roleDesc" @input="handleInputChange" aria-describedby="roleDesc" rows="4" style="border-radius: 5px; border-color: #44ce6f" v-model="userProposedEndeavor.roleDesc"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="proposedEndeavor" style="margin-bottom: 3px">Suggest your proposed endeavor (it's ok if you don't have one, we will provide several choices)</label>
                                <textarea class="form-control" id="proposedEndeavor" @input="handleInputChange" aria-describedby="proposedEndeavor" rows="4" style="border-radius: 5px; border-color: #44ce6f" v-model="userProposedEndeavor.proposedEndeavor"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="proposedEndeavorNiche" style="margin-bottom: 3px">In this endeavor, are you looking to focus on a specific project, client segment, industry, or niche area? If yes - describe</label>
                                <textarea class="form-control" id="proposedEndeavorNiche" @input="handleInputChange" aria-describedby="proposedEndeavorNiche" rows="1" style="border-radius: 5px; border-color: #44ce6f" v-model="userProposedEndeavor.proposedEndeavorNiche"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="project1" style="margin-bottom: 3px">Your previous work/project (#1 example)
                                    <br><span style="font-weight: 400">- Who benefited from the project?</span>
                                    <br><span style="font-weight: 400">- What were the positive outcomes? (include metrics such as revenue generated, number of new clients, percentage growth, efficiency improvements, other relevant)</span>
                                    <br><span style="font-weight: 400">- What did you contribute? (include specifics like the number of presentations given, lines of code written, teams coordinated, other relevant)</span></label>
                                <textarea class="form-control" id="project1" @input="handleInputChange" aria-describedby="project1" rows="4" style="border-radius: 5px; border-color: #44ce6f" v-model="userProposedEndeavor.project1"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="project2" style="margin-bottom: 3px">Your previous work/project (#2 example)
                                    <br><span style="font-weight: 400">- Who benefited from the project?</span>
                                    <br><span style="font-weight: 400">- What were the positive outcomes? (include metrics such as revenue generated, number of new clients, percentage growth, efficiency improvements, other relevant)</span>
                                    <br><span style="font-weight: 400">- What did you contribute? (include specifics like the number of presentations given, lines of code written, teams coordinated, other relevant)</span></label>
                                <textarea class="form-control" id="project2" @input="handleInputChange" aria-describedby="project2" rows="4" style="border-radius: 5px; border-color: #44ce6f" v-model="userProposedEndeavor.project2"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="project3" style="margin-bottom: 3px">Your previous work/project (#3 example)
                                    <br><span style="font-weight: 400">- Who benefited from the project?</span>
                                    <br><span style="font-weight: 400">- What were the positive outcomes? (include metrics such as revenue generated, number of new clients, percentage growth, efficiency improvements, other relevant)</span>
                                    <br><span style="font-weight: 400">- What did you contribute? (include specifics like the number of presentations given, lines of code written, teams coordinated, other relevant)</span></label>
                                    <textarea class="form-control" id="project3" @input="handleInputChange" aria-describedby="project3" rows="4" style="border-radius: 5px; border-color: #44ce6f" v-model="userProposedEndeavor.project3"></textarea>
                            </div>
                        </div>
                    </div>

                    <div class="row" style="margin-bottom: 3rem">
                        <h5 class="col-12">Prong 1 - Substantial merit and national importance</h5>
                        <div class="col-12" style="padding-left: 2rem; padding-top: 1rem">
                            <div class="form-group">
                                <label for="nationalImportance" style="margin-bottom: 3px">Do you have particular points or arguments you wish to use for Prong 1? Ok if not, we will provide several choices</label>
                                <textarea class="form-control" id="nationalImportance" @input="handleInputChange" aria-describedby="nationalImportance" rows="4" style="border-radius: 5px; border-color: #44ce6f" v-model="userProng1.nationalImportance"></textarea>
                            </div>                       
                            <div class="form-group">
                                <label for="jobCreation" style="margin-bottom: 3px">Does your role contribute to job creation in the U.S., either by directly generating employment or indirectly by enabling other companies to create jobs thanks to additional funding or support for their growth? If yes - describe</label>
                                <textarea class="form-control" id="jobCreation" @input="handleInputChange" aria-describedby="jobCreation" rows="2" style="border-radius: 5px; border-color: #44ce6f" v-model="userProng1.jobCreation"></textarea>
                            </div>   
                            <div class="form-group">
                                <label for="scaleImpact" style="margin-bottom: 3px">Does your work impact a large number of people, for instance, affecting thousands or millions across the U.S.? If yes - describe</label>
                                <textarea class="form-control" id="scaleImpact" @input="handleInputChange" aria-describedby="scaleImpact" rows="2" style="border-radius: 5px; border-color: #44ce6f" v-model="userProng1.scaleImpact"></textarea>
                            </div>   
                            <div class="form-group">
                                <label for="impactOnIndustry" style="margin-bottom: 3px">Does your work impact broader industry? If yes - describe</label>
                                <textarea class="form-control" id="impactOnIndustry" @input="handleInputChange" aria-describedby="impactOnIndustry" rows="2" style="border-radius: 5px; border-color: #44ce6f" v-model="userProng1.impactOnIndustry"></textarea>
                            </div>   
                            <div class="form-group">
                                <label for="fundsInflow" style="margin-bottom: 3px">Are you involved in facilitating the inflow of funds from overseas to the U.S. (for example loans or investments)? If yes - describe</label>
                                <textarea class="form-control" id="fundsInflow" @input="handleInputChange" aria-describedby="fundsInflow" rows="2" style="border-radius: 5px; border-color: #44ce6f" v-model="userProng1.fundsInflow"></textarea>
                            </div>   
                        </div>
                    </div>

                    <div class="row" style="margin-bottom: 3rem">
                        <h5 class="col-12">Prong 2 - Well positioned to advance the proposed endeavor (education)</h5>
                        <div class="col-12" style="padding-left: 2rem; padding-top: 1rem">
                            <div class="form-group">
                                <label for="degrees" style="margin-bottom: 3px">List all your degrees (BSc, MSc, PhD, MBA, etc.)</label>
                                <textarea class="form-control" id="degrees" @input="handleInputChange" aria-describedby="degrees" rows="2" style="border-radius: 5px; border-color: #44ce6f" v-model="userProng2Education.degrees"></textarea>
                            </div>     
                            <div class="form-group">
                                <label for="certificates" style="margin-bottom: 3px">List all your certificates, certifications, courses, diplomas, etc.</label>
                                <textarea class="form-control" id="certificates" @input="handleInputChange" aria-describedby="certificates" rows="2" style="border-radius: 5px; border-color: #44ce6f" v-model="userProng2Education.certificates"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="associations" style="margin-bottom: 3px">List the industry associations you are affiliated with. Have you actively contributed to these associations, or are you simply a member?</label>
                                <textarea class="form-control" id="associations" @input="handleInputChange" aria-describedby="associations" rows="2" style="border-radius: 5px; border-color: #44ce6f" v-model="userProng2Education.associations"></textarea>
                            </div>
                        </div>
                    </div>

                    <div class="row" style="margin-bottom: 3rem">
                        <h5 class="col-12">Prong 2 - Well positioned to advance the proposed endeavor (market demand)</h5>
                        <div class="col-12" style="padding-left: 2rem; padding-top: 1rem">
                            <div class="form-group">
                                <label for="salary" style="margin-bottom: 3px">What is your total compensation (include breakdown: base, bonuses, RSUs, etc)?</label>
                                <textarea class="form-control" id="salary" @input="handleInputChange" aria-describedby="salary" rows="2" placeholder="To benchmark your salary against the market average" style="border-radius: 5px; border-color: #44ce6f" v-model="userProng2Demand.salary"></textarea>
                            </div>     
                            <div class="form-group">
                                <label for="promotions" style="margin-bottom: 3px">Have you received promotions or above-average bonuses in your current role? If yes - describe</label>
                                <textarea class="form-control" id="promotions" @input="handleInputChange" aria-describedby="promotions" rows="2" style="border-radius: 5px; border-color: #44ce6f" v-model="userProng2Demand.promotions"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="awards" style="margin-bottom: 3px">Have you been honored with awards or chosen for internal sponsorship/mentorship programs? If yes - describe</label>
                                <textarea class="form-control" id="awards" @input="handleInputChange" aria-describedby="awards" rows="2" style="border-radius: 5px; border-color: #44ce6f" v-model="userProng2Demand.awards"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="patents" style="margin-bottom: 3px">Do you possess patented inventions that have been commercialized? If yes - describe</label>
                                <textarea class="form-control" id="patents" @input="handleInputChange" aria-describedby="patents" rows="2" style="border-radius: 5px; border-color: #44ce6f" v-model="userProng2Demand.patents"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="citations" style="margin-bottom: 3px">Have you been cited or quoted in any publications? Have your publications been downloaded? If yes - describe, including how many times</label>
                                <textarea class="form-control" id="citations" @input="handleInputChange" aria-describedby="citations" rows="2" style="border-radius: 5px; border-color: #44ce6f" v-model="userProng2Demand.citations"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="mediaRecognition" style="margin-bottom: 3px">Have you or your work been mentioned in the media? If yes - provide links or description</label>
                                <textarea class="form-control" id="mediaRecognition" @input="handleInputChange" aria-describedby="mediaRecognition" rows="2" style="border-radius: 5px; border-color: #44ce6f" v-model="userProng2Demand.mediaRecognition"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="educatingOthers" style="margin-bottom: 3px">Do you engage in mentoring or training others? If yes - describe</label>
                                <textarea class="form-control" id="educatingOthers" @input="handleInputChange" aria-describedby="educatingOthers" rows="2" style="border-radius: 5px; border-color: #44ce6f" v-model="userProng2Demand.educatingOthers"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="employerSelection" style="margin-bottom: 3px">What distinguished you from other candidates and led your employer to choose you for the role?
                                    <br><span style="font-weight: 400">- If you are an entrepreneur, business owner or consultant: Why do clients choose your product or service?</span></label>
                                <textarea class="form-control" id="employerSelection" @input="handleInputChange" aria-describedby="employerSelection" rows="2" style="border-radius: 5px; border-color: #44ce6f" v-model="userProng2Demand.employerSelection"></textarea>
                            </div>
                        </div>
                    </div>

                    <div class="row" style="margin-bottom: 5rem">
                        <h5 class="col-12">Prong 3 - Beneficial to the U.S. to waive the job offer and labor certification</h5>
                        <div class="col-12" style="padding-left: 2rem; padding-top: 1rem">
                            <div class="form-group">
                                <label for="additionalInfo" style="margin-bottom: 3px">Is there any other information you would like to add to the EB-2 NIW draft?</label>
                                <textarea class="form-control" id="additionalInfo" @input="handleInputChange" aria-describedby="additionalInfo" rows="4" style="border-radius: 5px; border-color: #44ce6f" v-model="userProng3.additionalInfo"></textarea>
                            </div>
                            <div class="form-group" style="height: 6rem">
                                <label for="additionalFiles" style="margin-bottom: 0px;">
                                    Upload Additional Documents - if relevant
                                    <div style="cursor: pointer; font-size: 2.5rem; margin-left: 2px;"><font-awesome-icon icon="file-upload" style="color: #44ce6f;"/></div>
                                </label>
                                <input type="file" class="form-control-file" id="additionalFiles" ref="additionalFiles" multiple v-on:change="checkMultipleFileSizes" @input="handleInputChange" style="display: none;">
                                <p v-for="(file, index) in userProng3.additionalFiles" :key="'uploaded-additional-file-' + index" style="display: flex; align-items: center; margin-bottom: 0px">
                                    Uploaded: {{ file.name }}
                                    <span v-on:click="() => deleteAdditionalFile(index)" style="margin-left: 18px; font-size: 18px; cursor: pointer;">
                                        <font-awesome-icon icon="trash-can" />
                                    </span>
                                </p>
                            </div>
                            <div v-if="fileTooLarge" class="alert alert-danger" role="alert">
                                File size exceeds 3MB limit: you can also e-mail us at ceo@selfpetitionusa.com
                            </div>
                        </div>
                    </div>

                    <form  @submit.prevent="submit()" class="row" style="justify-content: center; margin-bottom: 5rem">
                        <div class="col-xl-3 col-sm-5 col-10">
                            <button type="submit" class="btn btn-primary" style="width: 100%; margin-bottom: 5px">Submit</button>
                            <div :class="{ nonvisible: !submitted || submittedSuccess }" class="alert alert-warning" role="alert" style="font-weight: 500; text-align: center">Oops... check your inputs in "Your profile overview"</div>
                        </div>
                        <div v-if="submittedSuccess" class="col-12" style="margin-top: 1rem">
                            <div class="alert alert-primary" role="alert" style="font-weight: 500; text-align: center">
                                <h6>🎉 Successfully submitted 🎉</h6>
                                <div>We will reach to you in the <span style="text-decoration: underline">next 24h</span> to confirm the order</div>
                            </div>
                            <div class="alert alert-primary" role="alert" style="font-weight: 500; text-align: center">
                                <h6>To join our Exclusive<img src="../resources/discord-logo.svg" style="width: 95px; margin-left: 5px; margin-right: 5px" alt="discord self petition"/>Community, use the link below:</h6>
                                <a href="https://discord.gg/zjUAbDzR2R" style="color: #004085; text-decoration: underline">https://discord.gg/zjUAbDzR2R</a>
                            </div>
                       </div>
                    </form>

                </div>
            </div>
        </div>
    
    </div>
</template>

<script>

import {mapActions} from "vuex";

    export default {
        name: 'GreenCardAiForm2',
        data () {
            return {
                userBackground: {
                    email: '',
                    profession: '',
                    company: '',
                    industry: '',
                    linkedin: '',
                    resume: '',
                    resumeName: '',
                },
                userProposedEndeavor: {
                    roleDesc: '',
                    proposedEndeavor: '',
                    proposedEndeavorNiche: '',
                    project1: '',
                    project2: '',
                    project3: '',
                },
                userProng1: {
                    nationalImportance: '',
                    jobCreation: '',
                    scaleImpact: '',
                    impactOnIndustry: '',
                    fundsInflow: '',
                },
                userProng2Education: {
                    degrees: '',
                    certificates: '',
                    associations: '',
                },
                userProng2Demand: {
                    salary: '',
                    promotions: '',
                    awards: '',
                    patents: '',
                    citations: '',
                    mediaRecognition: '',
                    educatingOthers: '',
                    employerSelection: '',
                },
                userProng3: {
                    additionalInfo: '',
                    additionalFiles: [],
                },
                submitted: false,
                submittedSuccess: false,
                submittedAlert: false,
                isEmail: false,
                fileTooLarge: false,
                resumeTooLarge: false,
            }
        },
        methods: {
            ...mapActions('account', ['apply']),
            sendEvent(eventType) {
              if (window.gtag) {
                window.gtag('event', eventType); // google analytics
              }
            },
            validateEmail () {
                this.isEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.userBackground.email);
            },
            clearEmailError () {
                this.isEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.userBackground.email);
            },
            checkFileSize(refName, maxFileSize = 3 * 1024 * 1024) {
                const fileInput = this.$refs[refName];
                if (fileInput.files.length === 0) return; // Exit if no file selected
                const fileSize = fileInput.files[0].size;
                let sizeLimitExceeded = (fileSize > maxFileSize);

                if (sizeLimitExceeded) {
                    fileInput.value = ''; // Correct way to reset the file input
                    if (refName === 'resume') {
                        this.resumeTooLarge = true;
                    } else {
                        this.fileTooLarge = true;
                    }
                    setTimeout(() => {
                        if (refName === 'resume') {
                            this.resumeTooLarge = false;
                        } else {
                            this.fileTooLarge = false;
                        }
                    }, 5000);
                } else {
                    if (refName === 'resume') {
                        this.setImage('resume');
                    } else {
                        this.checkMultipleFileSizes();
                    }
                }
            },
            checkMultipleFileSizes() {
                const files = this.$refs.additionalFiles.files;
                Array.from(files).forEach(file => {
                    if (file.size <= 3 * 1024 * 1024) { // 3MB size limit per file
                        this.readAndUploadFile(file, 'additionalFiles');
                    } else {
                        this.userProng3.fileTooLarge = true;
                        setTimeout(() => {
                            this.userProng3.fileTooLarge = false;
                        }, 5000);
                    }
                });
            },
            readAndUploadFile(file, refName) {
                const reader = new FileReader();
                reader.onload = () => {
                    this.userProng3[refName] = this.userProng3[refName] || [];
                    this.userProng3[refName].push({
                        name: file.name,  // Ensure the file name is stored
                        data: reader.result
                    });
                };
                reader.onerror = error => console.log('Error: ', error);
                reader.readAsDataURL(file);
            },
            setImage(refName) {
                this.getBase64(this.$refs[refName].files[0], refName);
            },
            getBase64(file, refName) {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    if (refName === 'resume') {
                        this.userBackground.resume = reader.result;
                        this.userBackground.resumeName = file.name;
                    } else {
                        let index = parseInt(refName.slice(-1)) - 1;
                        if (!this.userProng3.additionalFiles[index]) {
                            this.userProng3.additionalFiles[index] = {};
                        }
                        this.userProng3.additionalFiles[index].data = reader.result;
                        this.userProng3.additionalFiles[index].name = file.name;
                    }
                };
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                };
            },
            deleteFile(refName) {
                this.userBackground[refName] = '';
                this.userBackground[`${refName}Name`] = '';
            },
            deleteAdditionalFile(index) {
                this.userProng3.additionalFiles.splice(index, 1);
            },
            clearInputs () {
                this.userBackground.email = '';
                this.userBackground.profession = '';
                this.userBackground.company = '';
                this.userBackground.industry = '';
                this.userBackground.linkedin = '';
                this.userBackground.resume = '';
                this.userBackground.resumeName = '';
                
                this.userProposedEndeavor.roleDesc = '';
                this.userProposedEndeavor.proposedEndeavor = '';
                this.userProposedEndeavor.proposedEndeavorNiche = '';
                this.userProposedEndeavor.project1 = '';
                this.userProposedEndeavor.project2 = '';
                this.userProposedEndeavor.project3 = '';

                this.userProng1.nationalImportance = '';
                this.userProng1.jobCreation = '';
                this.userProng1.scaleImpact = '';
                this.userProng1.impactOnIndustry = '';
                this.userProng1.fundsInflow = '';

                this.userProng2Education.degrees = '';
                this.userProng2Education.certificates = '';
                this.userProng2Education.associations = '';

                this.userProng2Demand.salary = '';
                this.userProng2Demand.promotions = '';
                this.userProng2Demand.awards = '';
                this.userProng2Demand.patents = '';
                this.userProng2Demand.citations = '';
                this.userProng2Demand.mediaRecognition = '';
                this.userProng2Demand.educatingOthers = '';
                this.userProng2Demand.employerSelection = '';

                this.userProng3.additionalInfo = '';
                this.userProng3.additionalFiles = [];
            },
            handleInputChange() {
                this.submitted = false; // Reset the submission status when any input changes
            },
            submit() {
                this.validateEmail ();
                this.submitted = true;
                this.submittedAlert = true;
                if(this.isEmail && this.userBackground.profession && this.userBackground.company && (this.userBackground.linkedin || this.userBackground.resumeName)) {
                  this.sendEvent('AI-form-input');
                    this.apply({
                        userEmail: this.userBackground.email,
                        userProfession: this.userBackground.profession,
                        userCompany: this.userBackground.company,
                        userIndustry: this.userBackground.industry,
                        userLinkedin: this.userBackground.linkedin,
                        userProposedEndeavor: this.userProposedEndeavor,
                        userProng1: this.userProng1,
                        userProng2Education: this.userProng2Education,
                        userProng2Demand: this.userProng2Demand,
                        userProng3: this.userProng3.additionalInfo,
                        userResumeName: this.userBackground.resumeName,
                        userResume: this.userBackground.resume,
                        userAdditionalFiles: this.userProng3.additionalFiles,
                    })
                this.submittedSuccess = true;
                this.clearInputs();
                }
            }
        }
    }

</script>
