var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('section',{staticClass:"blog-details-area"},[_c('div',{staticClass:"article-content"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('vue-feather',{attrs:{"type":"clock"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("February 13, 2023")])],1),_c('li',[_c('vue-feather',{attrs:{"type":"user"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("Natalie")])],1)])])])]),_vm._m(1),_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"article-content"},[_c('h3',{staticClass:"subtitle"},[_vm._v("EB-2 National Interest Waiver denied")]),_c('div',{staticClass:"blog-paragraph"},[_vm._v("EB-2 National Interest Waiver (NIW) is a popular immigration category for individuals with exceptional ability or advanced degrees who seek a Green Card in the United States. However, like any other immigration application, there is always a risk of denial. In this article, we will discuss the possible outcomes if your EB-2 NIW is denied and what you can do to increase your chances of success in your future immigration efforts.")]),_c('h5',[_vm._v("Appeal the decision")]),_vm._m(2),_c('h5',[_vm._v("Reapply")]),_vm._m(3),_vm._m(4),_c('PriceOffer',{staticStyle:{"margin-bottom":"4rem"}}),_c('CarouselGreenCard',{staticStyle:{"margin-bottom":"4rem"}}),_c('h5',[_vm._v("Consider other immigration categories")]),_vm._m(5),_c('h5',[_vm._v("Wait for better timing")]),_vm._m(6),_c('div',{staticClass:"blog-paragraph"},[_vm._v("In conclusion, a denial of an EB-2 NIW application is not the end of the road for your immigration journey. By understanding the possible outcomes and taking the appropriate steps, you can increase your chances of success in your future immigration efforts.")]),_vm._m(7)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area2"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('div',{staticClass:"article-tags"},[_c('a',{attrs:{"href":"#"}},[_vm._v("EB-2 NIW BLOG")])]),_c('h1',{staticStyle:{"font-weight":"600","margin-top":"30px","text-align":"left"}},[_vm._v("What if my EB-2 NIW is denied?")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../resources/blog/blog14-16x9.webp"),"alt":"NIW template non-academia"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("If your EB-2 NIW is denied, you have the right to "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("file an appeal with the Administrative Appeals Office (AAO) within 30 days of the denial")]),_vm._v(". This process "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("can be lengthy")]),_vm._v(" and requires a detailed understanding of immigration law.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("If you believe that the denial was based on an "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("error or misinterpretation of the evidence submitted, you can reapply for EB-2 NIW")]),_vm._v(". However, it is important to "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("address the weaknesses")]),_vm._v(" in the initial application and provide additional evidence to support your case.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Check "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("Starter Kit for EB-2 NIW")]),_vm._v(", which includes self-petition Green Card templates for professionals:")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("If your EB-2 NIW is denied, it may be worth considering alternative immigration categories, such as "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("EB-1A (Alien of Extraordinary Ability) or EB-3 (Skilled Worker)")]),_vm._v(". These categories have different eligibility criteria and requirements, so it is important to assess which category may be a better fit for you. Keep in mind that "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("EB-3 does not allow self-petition")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Sometimes, the denial of an EB-2 NIW is simply a result of the high demand for Green Cards and the limited number of visas available. In these cases, it may be wise to wait for a better time to reapply, when "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("demand is lower and the visa availability is higher")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('a',{staticClass:"btn btn-primary btn-assess",attrs:{"href":"/are-you-eligible"}},[_vm._v("Are you eligible for EB-2 NIW?")])])])])
}]

export { render, staticRenderFns }