<template>

    <section class="works-area ptb-80 bg-f9fafb">

      <b-modal ref="modal-cover-letter" title="HR & Recruiter" size="xl" hide-footer>
        <img src="../resources/sample-hrRecruiter.webp" alt="eb2 niw sample hr"/>
      </b-modal>

      <b-modal ref="modal-case-builder" title="HR & Recruiter" size="xl" hide-footer>
        <img src="../resources/caseBuilder-hrRecruiter.webp" alt="eb2 niw sample recruiter"/>
      </b-modal>

      <div class="container" style="margin-top: 5rem">
        <div class="row">
          <div class="col-sm-3 col-8 offset-sm-0 offset-2" style="margin-bottom: 4rem">
            <img src="../resources/hr.webp" style="border-radius: 50%; border: 2px solid; border-color: #44ce6f; box-shadow: 10px 20px 20px 0px rgba(86, 97, 118, .25)" alt="eb2 niw sample human resources"/>
          </div>

          <div class="col-sm-8 offset-sm-1 col-12 sample-h1">
            <div>
              <h1 style="font-weight: 600;">HR & Recruiter</h1>
              <div class="bar"></div>
            </div>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 30px">
          <div style="margin: 1rem; margin-top: 2rem; max-width: 1000px">In the dynamic realm of Human Resources (HR) and recruiting, professionals navigating the EB-2 NIW process encounter a distinctive set of dynamics and possibilities. For HR specialists and recruiters, the journey toward a successful petition goes beyond showcasing recruiting prowess; it involves articulating the profound impact of their contributions to talent acquisition and workforce development.</div>
          <div style="margin: 1rem; max-width: 1000px">The EB-2 NIW process for HR and recruiting professionals demands a meticulous presentation of achievements, emphasizing the strategic role played in shaping and advancing talent acquisition solutions. This requires delving into the details of successful recruitment campaigns, talent retention initiatives, and innovative methodologies that have significantly influenced organizational dynamics and workforce trends.</div>
          <div style="margin: 1rem; max-width: 1000px">Effectively navigating the EB-2 NIW process in HR and recruiting necessitates a comprehensive approach. Applicants must underscore their instrumental role in attracting top talent, optimizing hiring processes, and contributing to the overall organizational success. Emphasizing the broader societal and economic implications of their work, within the context of modern HR dynamics, is crucial. Advancements in HR align with national interests, such as workforce development, economic growth, and strategic talent management on a global scale.</div>
          <div style="margin: 1rem; max-width: 1000px">With the inherent alignment of HR and recruiting expertise with national interests, applicants can position themselves as catalysts for economic growth, workforce efficiency, and talent development. This alignment is recognized and emphasized by policymakers, reflecting the national interest in building a skilled workforce, fostering innovation, and addressing the evolving dynamics in HR and talent management.</div>
          <div style="margin: 1rem; max-width: 1000px">It's important to note that, contrary to common belief, extensive research, publications, or citations are not obligatory for EB-2 NIW. Our templates are specifically tailored for HR and recruiting professionals, focusing on the strategic impact and broader implications of their contributions to talent acquisition and workforce development.</div>


          <div style="margin: 3rem 1rem 1rem; max-width: 200px; border: 2px solid #44ce6f; padding: 12px 12px 4px; border-radius: 5px;">
            <vue-feather type="shield" style="color: #44ce6f"></vue-feather> Read More:
            <ul>
              <li style="margin-top: 5px"><a href="/success-story-human-resources-consultant" target="_blank" style="text-decoration: underline">Success Story 1</a></li>
              <li style="margin-top: 5px"><a href="/success-story-human-resources" target="_blank" style="text-decoration: underline">Success Story 2</a></li>
            </ul>
          </div>
        </div>

<!--HR & Recruiter-->
        <div id="profession-sample" style="margin-bottom: 1.5rem">
          <div style="margin: 6rem 0rem">
            <h4 style="text-align: center;">HR & Recruiter</h4>
            <div class="section-title">
              <div class="bar"></div>
            </div>
            
            <div style="text-align: center; margin: 4rem 0 6rem">
              <button v-on:click="navigateToCheckout" class="btn btn-primary">GET THIS TEMPLATE</button>
              <div class="price-offer-comment" style="color: #c4c4c4; margin-top: 50px;">*** Not quite right? Try <a style="color: #c4c4c4; text-decoration: underline" href="/ai">Selfpetition AI</a>  instead ***</div>            
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCoverLetterModal" style="cursor: pointer">
              <img src="../resources/sample-hrRecruiter.webp" alt="human resources i140 sample">
              <div class="works-content">
                <h3 style="color: #fff">HR & Recruiter</h3>
                <h3 style="color: #fff">Cover Letter (9 pages)</h3>
                <p>The cover letter comprises the following segments: meeting the criteria for an Advance Degree, Prong 1, Prong 2, Prong 3, and an appendix containing all supporting evidence. When checking out, you have the option to select up to two professions.</p>
              </div>
            </div>

            <div class="col-sm-6 col-12 single-works carousel-green-hover" @click="openCaseBuilderModal" style="cursor: pointer">
              <img src="../resources/caseBuilder-hrRecruiter.webp" alt="recruiter green card">
              <div class="works-content">
                <h3 style="color: #fff">HR & Recruiter</h3>                    
                <h3 style="color: #fff">EB-2 NIW Case Builder (16 pages)</h3>
                <p>You might be wondering how to identify relevant NIW initiatives and supporting arguments. This is where the NIW Case Builder Pdf comes in – it's designed to inspire you with alternative points to emphasize in your cover letter as you modify our template.</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div style="margin: 5rem 0rem">
            <h4 style="text-align: center;">EB2 NIW Starter Kit</h4>
            <h6 style="text-align: center;">List of Documents</h6>
            <div class="section-title">
              <div class="bar"></div>
            </div>
          </div>

          <div class="section-title faq" style="margin-bottom: 1.5rem">
            <DocumentsList></DocumentsList>
          </div>
        </div>

        <div class="section-title faq" style="margin-bottom: 1.5rem">
          <div class="row">
            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Most suitable for</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">Human Resources (HR) Professional</li>
                <li class="profession-page-list">Recruiter</li>
                <li class="profession-page-list">Talent Acquisition Specialist</li>
                <li class="profession-page-list">Employee Relations Manager</li>
                <li class="profession-page-list">Compensation and Benefits Analyst</li>
                <li class="profession-page-list">Training and Development Specialist</li>
                <li class="profession-page-list">Organizational Development Consultant</li>
                <li class="profession-page-list">HR Business Partner</li>
                <li class="profession-page-list">Diversity and Inclusion Specialist</li>
                <li class="profession-page-list">Learning and Development Manager</li>
                <li class="profession-page-list">Talent Management Consultant</li>
                <li class="profession-page-list">Employee Engagement Coordinator</li>
                <li class="profession-page-list">Workforce Planning Analyst</li>
                <li class="profession-page-list">HR Compliance Specialist</li>
                <li class="profession-page-list">Human Resources Information System Analyst</li>
                <li class="profession-page-list">Employee Wellness Coordinator</li>
                <li class="profession-page-list">Labor Relations Specialist</li>
                <li class="profession-page-list">Executive Recruiter</li>
                <li class="profession-page-list">Onboarding Specialist</li>
                <li class="profession-page-list">Career Development Advisor</li>
                <li class="profession-page-list">Benefits Administrator</li>
                <li class="profession-page-list">Employee Assistance Program (EAP) Coordinator</li>
                <li class="profession-page-list">Performance Management Specialist</li>
                <li class="profession-page-list">Corporate Culture Specialist</li>
                <li class="profession-page-list">HR Operations Manager</li>
                <li class="profession-page-list">Change Management Consultant</li>
                <li class="profession-page-list">Employment Branding Manager</li>
                <li class="profession-page-list">... and other similar</li>
              </ul>
            </div>

            <div class="col-sm-6 col-12">
              <h6 style="padding-top: 1rem">Topics</h6>
              <ul style="padding-left: 24px">
                <li class="profession-page-list-first">HR consulting services</li>
                <li class="profession-page-list">Talent management</li>
                <li class="profession-page-list">Workforce development</li>
                <li class="profession-page-list">Employee satisfaction improvement</li>
                <li class="profession-page-list">Recruitment strategies</li>
                <li class="profession-page-list">Onboarding strategies</li>
                <li class="profession-page-list">HR for healthcare industry</li>
                <li class="profession-page-list">STEM talent development</li>
                <li class="profession-page-list">Employee retention programs</li>
                <li class="profession-page-list">Training programs for engineering staff</li>
                <li class="profession-page-list">Strategic HR initiatives</li>
                <li class="profession-page-list">Workplace culture</li>
                <li class="profession-page-list">Human Resources policies and practices</li>
                <li class="profession-page-list">Employee engagement strategies</li>
                <li class="profession-page-list">Organizational development</li>
                <li class="profession-page-list">Employee survey analysis</li>
                <li class="profession-page-list">HR data analytics</li>
                <li class="profession-page-list">Healthcare industry workforce solutions</li>
                <li class="profession-page-list">HR best practices</li>
                <li class="profession-page-list">Employee performance improvement</li>
                <li class="profession-page-list">STEM talent recruitment</li>
                <li class="profession-page-list">Employee satisfaction surveys</li>   
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="shape2 rotateme" style="left: 90%"><img src="../resources/shape2.svg" alt="EB2 niw diy"></div>
      <div class="shape4"><img src="../resources/shape4.svg" alt="EB-2 niw human resources"></div>
      <div class="shape7"><img src="../resources/shape4.svg" alt="EB-2 niw recruiter"></div>
    </section>

</template>

<script>

import { mapActions } from 'vuex';
import DocumentsList from "./DocumentsList.vue";

    export default {
      name: 'ProfessionHRRecruiter',
      components: {
        DocumentsList,
      },
      data() {
        return {
          user: {
            profession1: 'hrRecruiter',
          }
        }
      },
      methods: {
        ...mapActions('account', ['apply']),
        navigateToCheckout() {
          //this.apply('HR Recruiter Sample');
          // Handle navigation to checkout here
          this.$router.push({
            path: '/checkout-starter-kit',
            query: {
              profession: this.user.profession1
            }
          });
        },
        openCoverLetterModal() {
          setTimeout(() => {
            this.$refs['modal-cover-letter'].show();
          }, 100);
        },
        openCaseBuilderModal() {
          setTimeout(() => {
            this.$refs['modal-case-builder'].show();
          }, 100);
        }
      }
    }
</script>

