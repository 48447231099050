<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <EB2NIWBacklog></EB2NIWBacklog>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import EB2NIWBacklog from "../blog-components/EB2NIWBacklog";


export default {
  name: 'EB2NIWBacklogView',
  components: {
    EB2NIWBacklog,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "What can I do if there is EB2 NIW backlog? - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "Due to the limited number of Green Cards available each year, there may be a backlog for EB-2 NIW. If you are facing a backlog for EB-2 NIW, here are some things you can do."},
      ]
    }
  }
}
</script>
