var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('section',{staticClass:"blog-details-area"},[_c('div',{staticClass:"article-content"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('vue-feather',{attrs:{"type":"clock"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("August 22, 2023")])],1),_c('li',[_c('vue-feather',{attrs:{"type":"user"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("Natalie")])],1)])])])]),_vm._m(1),_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"article-content"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Understanding form ETA-9089")]),_vm._m(2),_c('h5',[_vm._v("The purpose of form ETA-9089")]),_vm._m(3),_c('PriceOffer',{staticStyle:{"margin-bottom":"4rem"}}),_c('CarouselGreenCard',{staticStyle:{"margin-bottom":"4rem"}}),_c('h5',[_vm._v("Overview of the NIW process")]),_vm._m(4),_c('h5',[_vm._v("The new version of form ETA-9089")]),_vm._m(5),_c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{ name: 'Home', params: { location: 'Templates' }}}},[_vm._v("EB-2 NIW Templates")])],1)])]),_c('h5',[_vm._v("Which version should you use?")]),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area2"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('div',{staticClass:"article-tags"},[_c('a',{attrs:{"href":"#"}},[_vm._v("EB-2 NIW BLOG")])]),_c('h1',{staticStyle:{"font-weight":"600","margin-top":"30px","text-align":"left"}},[_vm._v("Use new ETA-9089 form for EB-2 NIW")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../resources/blog/blog34-16x9.webp"),"alt":"eb2 niw diy kit"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("In this article, we'll delve into the intricacies of "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Form ETA-9089")]),_vm._v(", an essential document for those seeking a "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("National Interest Waiver (NIW) Green Card")]),_vm._v(". We will provide you with valuable insights into the updated version of this form, recent changes, and which version you should use. Let's get started!")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Form ETA-9089, commonly referred to as the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("labor certification form")]),_vm._v(", plays a crucial role in the NIW Green Card application process. "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("Despite the \"waiver of labor certification\" aspect of the NIW category, you must still submit this form.")]),_vm._v(" Failing to do so may result in a Request for Evidence (RFE) or rejection of your application. It might seem counterintuitive, but "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("this form is directed to the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("United States Citizenship and Immigration Services (USCIS)")]),_vm._v(", not the Department of Labor.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Before diving into the specifics of the new version of Form ETA-9089, let's briefly revisit the NIW Green Card process. Unlike traditional green card categories that involve a lengthy labor certification process, "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("NIW applicants can bypass these steps")]),_vm._v(". Instead, they can start directly with an "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("I-140 petition")]),_vm._v(". If applying from abroad, the process concludes with consular processing, while applicants in the U.S. file for an adjustment of status using Form I-485.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("The new version of Form ETA-9089 was released by the Department of State, featuring a significant overhaul. It's important to note that the USCIS "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("has not yet provided official guidance")]),_vm._v(" on which version to use.")]),_vm._v(" Therefore, confusion abounds in determining whether to use the older, expired version or the new one. The key difference is that the new form "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("includes various appendices, increasing its complexity")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("As a rule of thumb, it's wise to rely on "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("official USCIS guidance")]),_vm._v(". Unfortunately, the USCIS website has not been updated to reflect the new version of Form ETA-9089, and it still "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("links to the older, expired form")]),_vm._v(".")]),_vm._v(" Until USCIS issues clear instructions and updates its policy manual, many applicants keep using the older version of the form.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("While awaiting official guidance, we can anticipate the sections that will likely need completion on the new Form ETA-9089. Expect to fill out the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("main form, particularly Part C")]),_vm._v(", where agent information is provided. You may also need to select and complete "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Appendix A")]),_vm._v(", which is relevant to NIW applicants and resembles the previous version's educational and job history details. Additionally, "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Appendix E")]),_vm._v(", \"Final Determination,\" will be essential for NIW applicants, as it requires your signature.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Navigating the world of immigration can be challenging, especially when faced with updates to essential forms like Form ETA-9089. However, by "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("staying informed")]),_vm._v(" and following official USCIS guidance, you can ensure a smoother NIW Green Card application process.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Please be aware that forms and regulations can change over time")]),_vm._v(". The information provided above is "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("not intended as legal advice")]),_vm._v(" but rather represents the author's personal anticipation of potential future developments. Therefore, it is essential to double-check the information in this content and to ensure its accuracy at the time you are reading it.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('a',{staticClass:"btn btn-primary",attrs:{"href":"/eta9089-vs-eta750"}},[_vm._v("Continue Reading")])])])])
}]

export { render, staticRenderFns }