<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <HeaderGreenCard ></HeaderGreenCard>
    <div>
      <BannerGreenCard id="home"></BannerGreenCard>
      <Funfacts></Funfacts>
      <StarterKitGreenCard id="starterKit"></StarterKitGreenCard>
      <SelfpetitionAIGreenCard id="selfpetitionAI"></SelfpetitionAIGreenCard>
      <RfeGreenCard id="rfes"></RfeGreenCard>
      <FeedbackGreenCard2 id="reviews"></FeedbackGreenCard2>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <FooterGreenCard v-else></FooterGreenCard>
  </div>

</template>

<script>
  import BannerGreenCard from './components/BannerGreenCard';
  import Funfacts from './components/Funfacts'
  import StarterKitGreenCard from './components/StarterKitGreenCard';
  import SelfpetitionAIGreenCard from './components/SelfpetitionAIGreenCard';
  import RfeGreenCard from './components/RfeGreenCard';
  import FeedbackGreenCard2 from './components/FeedbackGreenCard2';
  import HeaderGreenCard from "./components/HeaderGreenCard";
  import FooterGreenCard from "./components/FooterGreenCard";


  export default {
    name: 'HomeGreenCard',
    data() {
      return {
        currentUrl: '',
      };
    },
    components: {
      BannerGreenCard,
      Funfacts,
      StarterKitGreenCard,
      SelfpetitionAIGreenCard,
      RfeGreenCard,
      FeedbackGreenCard2,
      HeaderGreenCard,
      FooterGreenCard
    },
    watch: {
      '$route'(pathUrl){
        this.currentUrl = pathUrl.path;
      }
    },
    mounted() {
      this.currentUrl = window.location.pathname;

      setTimeout(function(){
        if (this.$route.params.location === 'Home' || this.$route.query.location === 'Home') {
          this.$scrollTo('#home');
        } else if (this.$route.params.location === 'StarterKit' || this.$route.query.location === 'StarterKit') {
          this.$scrollTo('#starterKit');
        } else if (this.$route.params.location === 'SelfpetitionAI'  || this.$route.query.location === 'SelfpetitionAI') {
          this.$scrollTo('#selfpetitionAI');
        } else if (this.$route.params.location === 'RFEs' || this.$route.query.location === 'RFEs') {
          this.$scrollTo('#rfes');
        } else if (this.$route.params.location === 'Reviews' || this.$route.query.location === 'Reviews') {
          this.$scrollTo('#reviews');
        }

      }.bind(this), 100)
    },
    metaInfo() {
      return {
        title: "EB2 NIW TEMPLATE: Self petition green card for professionals",
        meta: [
          { vmid: 'description', name: 'description', content:  'Templates for EB2 NIW Advanced Degree. Green card Starter Kit to self-petition: successful I-140 cover letter, reference letters and Discord community.'},
        ]
      }
    }
  }

</script>
