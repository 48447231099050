<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <EB2NIWIndependentRecommendationLetters></EB2NIWIndependentRecommendationLetters>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import EB2NIWIndependentRecommendationLetters from "../blog-components/EB2NIWIndependentRecommendationLetters";


export default {
  name: 'EB2NIWIndependentRecommendatioLettersView',
  components: {
    EB2NIWIndependentRecommendationLetters,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "EB2 NIW independent recommendation letters - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "In this article we will focus exclusively on objective recommenders as USCIS might send you an RFE if they are not part of your application. Independent recommendation letters are crucial for your EB-2 NIW petition, and we will cover why and how to obtain them."},
      ]
    }
  }
}
</script>
