<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <EB2NIWStemApproval></EB2NIWStemApproval>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import EB2NIWStemApproval from "../blog-components/EB2NIWStemApproval";


export default {
  name: 'EB2NIWStemApprovalView',
  components: {
    EB2NIWStemApproval,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "EB2 NIW STEM Approval Rates - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "Approval rates for EB2 NIW (National Interest Waiver) petitions have always been a topic of interest among those navigating the complex landscape of U.S. immigration. While quarterly reports from USCIS provide valuable insights, a recent report delves deeper into the nuances of approval rates, particularly in the context of STEM (Science, Technology, Engineering, and Mathematics) versus non-STEM fields."},
      ]
    }
  }
}
</script>
