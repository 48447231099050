var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('section',{staticClass:"blog-details-area"},[_c('div',{staticClass:"article-content"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('vue-feather',{attrs:{"type":"clock"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("May 15, 2023")])],1),_c('li',[_c('vue-feather',{attrs:{"type":"user"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("Tom")])],1)])])])]),_vm._m(1),_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"article-content"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Success story: EB-2 NIW")]),_vm._m(2),_c('div',{staticClass:"signupSelfPetition row justify-content-center align-items-center"},[_c('div',{staticClass:"col-xl-6 col-lg-7"},[_c('signup-self-petition')],1)]),_vm._m(3),_vm._m(4),_vm._m(5),_c('PriceOffer',{staticStyle:{"margin-bottom":"4rem"}}),_c('CarouselGreenCard',{staticStyle:{"margin-bottom":"4rem"}}),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area2"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('span',{staticClass:"article-tags"},[_c('a',{attrs:{"href":"#"}},[_vm._v("EB-2 NIW BLOG")])]),_c('span',{staticClass:"article-tags2"},[_c('a',{attrs:{"href":"#"}},[_vm._v("SUCCESS STORY")])]),_c('h1',{staticStyle:{"font-weight":"600","margin-top":"30px","text-align":"left"}},[_vm._v("EB-2 NIW approval for product manager")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../resources/blog/blog27-16x9.webp"),"alt":"NIW template product manager"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("In today's rapidly advancing world of technology, professionals who possess the skills to drive "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("innovation and automation")]),_vm._v(" are in high demand. "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("One such individual, a "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("product manager")]),_vm._v(" overseeing and executing projects that automate technology processes for a "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("semiconductor company")]),_vm._v(" using data analytics,")]),_vm._v(" recently achieved a significant milestone - obtaining an EB-2 National Interest Waiver (NIW). Let's dive into this inspiring success story and explore how the EB-2 NIW paved the way for this product manager's immigration goals.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("Meet John, a highly skilled and experienced product manager who has been at the forefront of the semiconductor industry for years. His expertise lies in "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("leveraging data analytics")]),_vm._v(" to optimize and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("automate technology processes")]),_vm._v(", thereby driving efficiency and enhancing the competitive edge of his company.")]),_vm._v(" Recognizing the potential of the EB-2 NIW to expedite his path to permanent residency, John embarked on a compelling journey to showcase his unique qualifications and contributions to the semiconductor field.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("To navigate the complex EB-2 NIW application process, John turned to the "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("EB-2 NIW Starter Kit")]),_vm._v(", a valuable resource providing "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("templates specifically tailored for professionals seeking self-petition Green Cards")]),_vm._v(". Armed with this toolkit, John meticulously prepared his case, highlighting his profound impact on the semiconductor industry.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Check "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("Starter Kit for EB-2 NIW")]),_vm._v(", which includes self-petition Green Card templates for professionals:")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("John's arguments centered on the significance of his work in automating technology processes for the semiconductor company. "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("By employing data analytics and spearheading projects, he consistently drove innovation, accelerated production, and improved overall efficiency.")]),_vm._v(" His accomplishments directly contributed to the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("growth and competitiveness of his organization")]),_vm._v(", ultimately "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("benefiting the national interest.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("To strengthen his case, John gathered compelling evidence showcasing his expertise and leadership in the semiconductor field. He presented a "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("comprehensive "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("portfolio")]),_vm._v(" highlighting successful projects, outlining the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("advancements achieved")]),_vm._v(" through automation, and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("quantifying the positive impact")]),_vm._v(" on the company's bottom line.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Furthermore, John included "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("letters of recommendation")]),_vm._v(" from respected professionals in the semiconductor industry, highlighting his "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("exceptional skills and the crucial role he played")]),_vm._v(" in driving technological innovation. These testimonials not only demonstrated the value he brought to his organization but also "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("highlighted the broader impact his work had on the industry")]),_vm._v(" as a whole.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("This success story exemplifies the immense benefits the EB-2 NIW offers professionals in niche fields, enabling them to pursue their immigration goals and make a lasting impact in their respective industries. John's journey from a talented product manager to a permanent resident in the United States through the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("EB-2 NIW is a testament to the opportunities available to skilled professionals who revolutionize their fields")]),_vm._v(". His remarkable achievements in automating technology processes for a semiconductor company using data analytics played a significant role in securing his NIW approval.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('a',{staticClass:"btn btn-primary",staticStyle:{"margin-top":"3rem","margin-bottom":"2rem"},attrs:{"href":"/product-project-mgmt-sample"}},[_vm._v("PRODUCT-PROJECT MANAGER TEMPLATE")])])])])
}]

export { render, staticRenderFns }