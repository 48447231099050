<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <EB2NIWTimeline></EB2NIWTimeline>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import EB2NIWTimeline from "../blog-components/EB2NIWTimeline";


export default {
  name: 'EB2NIWTimelineView',
  components: {
    EB2NIWTimeline,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "EB2 NIW green card timeline in 2023 - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "Overall, the estimated timeline for the EB2 NIW green card petition in 2023 is between 18 to 28 months. However, it's important to note that USCIS processing times can change, and delays can occur due to various reasons such as security checks or administrative errors."},
      ]
    }
  }
}
</script>
