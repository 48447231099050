<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <ProfessionSoftwareEngineerIT></ProfessionSoftwareEngineerIT>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
    import ExternalHeaderGreenCard from "./components/ExternalHeaderGreenCard";
    import ExternalFooterGreenCard from "./components/ExternalFooterGreenCard";
    import ProfessionSoftwareEngineerIT from "./components/ProfessionSoftwareEngineerIT";

    export default {
      name: 'ProfessionSoftwareEngineerITView',
      components: {
        ProfessionSoftwareEngineerIT,
        ExternalHeaderGreenCard,
        ExternalFooterGreenCard,
      },
      data() {
        return {
          currentUrl: ''
        }
      },
      watch: {
        '$route'(pathUrl){
          this.currentUrl = pathUrl.path;
        }
      },
      mounted() {
        this.currentUrl = window.location.pathname;
      },
      metaInfo() {
        return {
          title: "Software Engineer & IT - Self Petition USA",
          meta: [
          { vmid: 'description', name: 'description', content:  "In pursuit of the EB-2 NIW, software engineers and IT professionals are tasked with not only showcasing their technical expertise but also highlighting the widespread impact of their work on the nation's welfare. This involves providing a detailed account of innovations, advancements, or contributions that have positively affected industries, society, or national security within the realm of software development and information technology."},
          ]
        }
      }
    }
</script>