<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <FAQ></FAQ>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
    import ExternalHeaderGreenCard from "./components/ExternalHeaderGreenCard";
    import ExternalFooterGreenCard from "./components/ExternalFooterGreenCard";
    import FAQ from "./components/GreenCardFAQ";

    export default {
        name: 'FAQView',
        components: {
            FAQ,
            ExternalHeaderGreenCard,
            ExternalFooterGreenCard,
        },
        data() {
            return {
              currentUrl: '',
            }
        },
        mounted() {
          this.currentUrl = window.location.pathname;
        },
        watch: {
          '$route'(pathUrl){
            this.currentUrl = pathUrl.path;
          }
        },
        metaInfo() {
            return {
                title: "EB-2 NIW TEMPLATE FAQ: How to self petition for a green card",
                meta: [
                { vmid: 'description', name: 'description', content:  'You can apply for EB-2 NIW without a permanent job offer from U.S. employer. It is very fast process because NIW waives lengthy Labor Certification.'},
                ]
            }
        }
    }
</script>