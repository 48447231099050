<template>
    <div class="signupSelfPetition row justify-content-center align-items-center">
        <div class="col-xl-6 col-lg-7">
        <form id="AiSample" @submit.prevent="signupEmail()" class="mt-4">
            <div class="row">
                <div class="col-sm-7">
                    <input type="email" name="AiSample" id="AiSample" class="form-control"  v-model="email" placeholder="Enter your email" style="border-radius: 4px; height: 50px">
                    <p style="font-size: 10px; color:#495057">Request samples drafted by Selfpetition AI</p>
                    <div class="mt-4">
                        <p style="color: #3b2d9b; font-weight: 500; margin-bottom: 20px; font-size: 14px;">{{ message }}</p>
                    </div>
                </div>
                <div class="col-sm-5 col-6 offset-sm-0 offset-3" style="padding: 0px; text-align: center">
                    <button type="submit" class="btn btn-primary btn-assess" style="margin: 0; padding-left: 25px; padding-right: 25px">Get AI samples</button>
                </div>
            </div>
        </form>
        </div>
    </div>
</template>

<script>

export default {
  name: 'SignupAiSamples',
  data() {
    return {
      email: '',
      message: ''
    }
  },
  methods: {
    signupEmail() {
      if(!this.validateEmail(this.email)) {
        this.message = "The email address is badly formatted.";
      } else {
        const url = `/api/contact/landing`;
        const requestOptions = {
          method: "POST",
          headers: {"Content-Type" : "application/json"},
          body: JSON.stringify({ name: 'AiSample', email: this.email, message: 'Requested AI samples' })
        };
        fetch(url, requestOptions).then(() => {
          this.message = "🎉 We will send you AI samples within 24h 🎉";
          setTimeout(() => {
            this.email = '';
            this.message = '';
          }, 4000); // Clears after 4 seconds, adjust time as needed
        })
      }
    },
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(String(email).toLowerCase());
    }
  }
}
</script>