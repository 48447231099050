import VueRouter from 'vue-router';
// Pages

import HomeGreenCard from "./views/ideas/green-card/HomeGreenCard";

import HomeGreenCardStarterKit from "./views/ideas/green-card/HomeGreenCardStarterKit";
import HomeGreenCardSelfpetitionAI from "./views/ideas/green-card/HomeGreenCardSelfpetitionAI";
import HomeGreenCardPricing from "./views/ideas/green-card/HomeGreenCardPricing";
import HomeGreenCardContact from "./views/ideas/green-card/HomeGreenCardContact";

import SelectProduct from "./views/ideas/green-card/SelectProduct";
import CheckoutStarterKit from "./views/ideas/green-card/CheckoutStarterKit";
import CheckoutSelfpetitionAI from "./views/ideas/green-card/CheckoutSelfpetitionAI";
import CheckoutRfeResponse from "./views/ideas/green-card/CheckoutRfeResponse";
import GreenCardOrdered from "./views/ideas/green-card/GreenCardOrdered";
import GreenCardOrderedRfe from "./views/ideas/green-card/GreenCardOrderedRfe";

import GreenCardFAQView from "./views/ideas/green-card/GreenCardFAQView";
import GreenCardAIView from "./views/ideas/green-card/GreenCardAIView";
import GreenCardAiForm2View from "./views/ideas/green-card/GreenCardAiForm2View";

import GreenCardTypeformView from "./views/ideas/green-card/GreenCardTypeformView"

import ProfessionSoftwareEngineerITView from "./views/ideas/green-card/ProfessionSoftwareEngineerITView";
import ProfessionBankingFinanceView from "./views/ideas/green-card/ProfessionBankingFinanceView";
import ProfessionDataScientistAnalystView from "./views/ideas/green-card/ProfessionDataScientistAnalystView";
import ProfessionProductProjectMgmtView from "./views/ideas/green-card/ProfessionProductProjectMgmtView";
import ProfessionNetworkElectricalEngView from "./views/ideas/green-card/ProfessionNetworkElectricalEngView";
import ProfessionElectroMechanicalEngView from "./views/ideas/green-card/ProfessionElectroMechanicalEngView";
import ProfessionChemicalEngineerView from "./views/ideas/green-card/ProfessionChemicalEngineerView";
import ProfessionMarketingView from "./views/ideas/green-card/ProfessionMarketingView";
import ProfessionHRRecruiterView from "./views/ideas/green-card/ProfessionHRRecruiterView";
import ProfessionScienceTeacherView from "./views/ideas/green-card/ProfessionScienceTeacherView";
import ProfessionPhysicianDentistView from "./views/ideas/green-card/ProfessionPhysicianDentistView";
import ProfessionPilotView from "./views/ideas/green-card/ProfessionPilotView";
import ProfessionTechEntrepreneurView from "./views/ideas/green-card/ProfessionTechEntrepreneurView";

import BlogGridView1 from "./views/ideas/green-card/blog-view/BlogGridView1";
import BlogGridView2 from "./views/ideas/green-card/blog-view/BlogGridView2";
import BlogGridView3 from "./views/ideas/green-card/blog-view/BlogGridView3";
import BlogGridView4 from "./views/ideas/green-card/blog-view/BlogGridView4";
import BlogGridView5 from "./views/ideas/green-card/blog-view/BlogGridView5";
import BlogGridView6 from "./views/ideas/green-card/blog-view/BlogGridView6";

import SuccessStoryBankerView from "./views/ideas/green-card/blog-view/SuccessStoryBankerView";
import SuccessStoryDentistView from "./views/ideas/green-card/blog-view/SuccessStoryDentistView";
import SuccessStoryEntrepreneurView from "./views/ideas/green-card/blog-view/SuccessStoryEntrepreneurView";
import SuccessStoryHRConsultantView from "./views/ideas/green-card/blog-view/SuccessStoryHRConsultantView";
import SuccessStoryMarketerView from "./views/ideas/green-card/blog-view/SuccessStoryMarketerView";
import SuccessStoryPetroleumEngineerView from "./views/ideas/green-card/blog-view/SuccessStoryPetroleumEngineerView";
import SuccessStorySoftwareEngineerView from "./views/ideas/green-card/blog-view/SuccessStorySoftwareEngineerView";
import SuccessStoryDataAnalystView from "./views/ideas/green-card/blog-view/SuccessStoryDataAnalystView";
import SuccessStoryProductManagerView from "./views/ideas/green-card/blog-view/SuccessStoryProductManagerView";
import SuccessStoryHRView from "./views/ideas/green-card/blog-view/SuccessStoryHRView";
import SuccessStoryNetworkEngineerView from "./views/ideas/green-card/blog-view/SuccessStoryNetworkEngineerView";
import SuccessStoryFinanceExpertView from "./views/ideas/green-card/blog-view/SuccessStoryFinanceExpertView";
import SuccessStoryScienceTeacherView from "./views/ideas/green-card/blog-view/SuccessStoryScienceTeacherView";
import SuccessStoryVentureCapitalView from "./views/ideas/green-card/blog-view/SuccessStoryVentureCapitalView";
import SuccessStoryDataScientistView from "./views/ideas/green-card/blog-view/SuccessStoryDataScientistView";
import SuccessStorySoftwareEngineerRetailView from "./views/ideas/green-card/blog-view/SuccessStorySoftwareEngineerRetailView";
import SuccessStoryAutomotiveEngineerView from "./views/ideas/green-card/blog-view/SuccessStoryAutomotiveEngineerView";
import SuccessStoryChipsEngineerView from "./views/ideas/green-card/blog-view/SuccessStoryChipsEngineerView";
import SuccessStoryFrontendEngineerView from "./views/ideas/green-card/blog-view/SuccessStoryFrontendEngineerView";

import SuccessStoryRfeDataAnalystView from "./views/ideas/green-card/blog-view/SuccessStoryRfeDataAnalystView";

import EB2NIWApplicationView from "./views/ideas/green-card/blog-view/EB2NIWApplicationView";
import EB2NIWVisaView from "./views/ideas/green-card/blog-view/EB2NIWVisaView";
import EB2NIWCostView from "./views/ideas/green-card/blog-view/EB2NIWCostView";
import EB2NIWProcessingView from "./views/ideas/green-card/blog-view/EB2NIWProcessingView";
import EB2NIWRequirementsView from "./views/ideas/green-card/blog-view/EB2NIWRequirementsView";
import EB2NIWChecklistView from "./views/ideas/green-card/blog-view/EB2NIWChecklistView";
import EB2NIWLettersOfRecommendationView from "./views/ideas/green-card/blog-view/EB2NIWLettersOfRecommendationView";
import EB2NIWDeniedView from "./views/ideas/green-card/blog-view/EB2NIWDeniedView";
import EB2NIWEvidenceView from "./views/ideas/green-card/blog-view/EB2NIWEvidenceView";
import EB2NIWVSPermView from "./views/ideas/green-card/blog-view/EB2NIWVSPermView";
import EB2NIWBacklogView from "./views/ideas/green-card/blog-view/EB2NIWBacklogView";
import EB2NIWBenefitsView from "./views/ideas/green-card/blog-view/EB2NIWBenefitsView";
import EB2NIWAdjustmentConsularView from "./views/ideas/green-card/blog-view/EB2NIWAdjustmentConsularView";
import EB2NIWIndianChineseView from "./views/ideas/green-card/blog-view/EB2NIWIndianChineseView";
import EB2NIWBulletinView from "./views/ideas/green-card/blog-view/EB2NIWBulletinView";
import EB2NIWTimelineView from "./views/ideas/green-card/blog-view/EB2NIWTimelineView";
import EB2NIWMistakes1View from "./views/ideas/green-card/blog-view/EB2NIWMistakes1View";
import EB2NIWIndependentRecommendationLettersView from "./views/ideas/green-card/blog-view/EB2NIWIndependentRecommendationLettersView";
import EB2NIWMistakes2View from "./views/ideas/green-card/blog-view/EB2NIWMistakes2View";
import EB2NIWETA9089vsETA750View from "./views/ideas/green-card/blog-view/EB2NIWETA9089vsETA750View";
import EB2NIWNewEta9089View from "./views/ideas/green-card/blog-view/EB2NIWNewEta9089View";
import EB2NIWI485View from "./views/ideas/green-card/blog-view/EB2NIWI485View";
import EB2NIWSelfPetitionUsaView from "./views/ideas/green-card/blog-view/EB2NIWSelfPetitionUsaView";
import EB2NIWL1VisaView from "./views/ideas/green-card/blog-view/EB2NIWL1VisaView";
import EB2NIWPetitionLengthView from "./views/ideas/green-card/blog-view/EB2NIWPetitionLengthView";
import EB2NIWAiBlogView from "./views/ideas/green-card/blog-view/EB2NIWAiBlogView";
import EB2NIWStemApprovalView from "./views/ideas/green-card/blog-view/EB2NIWStemApprovalView";
import EB2NIWGuideView from "./views/ideas/green-card/blog-view/EB2NIWGuideView";


export const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    scrollBehavior() {
      return { x: 0, y: 0 };
    },
    routes: [
      { path: '/', component: HomeGreenCard, name: 'Home' },
      
      { path: '/starterkit', component: HomeGreenCardStarterKit },
      { path: '/selfpetition-ai', component: HomeGreenCardSelfpetitionAI },
      { path: '/prices', component: HomeGreenCardPricing },
      { path: '/contact', component: HomeGreenCardContact },
      
      { path: '/select-product', component: SelectProduct},
      { path: '/checkout-starter-kit', component: CheckoutStarterKit},
      { path: '/checkout-selfpetition-ai', component: CheckoutSelfpetitionAI},
      { path: '/checkout-rfe-response', component: CheckoutRfeResponse},
      { path: '/ordered', component: GreenCardOrdered },
      { path: '/ordered-rfe', component: GreenCardOrderedRfe },
      
      { path: '/faq', component: GreenCardFAQView, name: 'Faq'},
      { path: '/ai', component: GreenCardAIView},
      { path: '/aiform2', component: GreenCardAiForm2View},

      { path: '/are-you-eligible', component: GreenCardTypeformView },

      { path: '/software-engineer-it-sample', component: ProfessionSoftwareEngineerITView },
      { path: '/banking-finance-sample', component: ProfessionBankingFinanceView },
      { path: '/data-scientist-analyst-sample', component: ProfessionDataScientistAnalystView },
      { path: '/product-project-mgmt-sample', component: ProfessionProductProjectMgmtView },
      { path: '/network-electrical-engineer-sample', component: ProfessionNetworkElectricalEngView },
      { path: '/electro-mechanical-engineer-sample', component: ProfessionElectroMechanicalEngView },
      { path: '/chemical-engineer-sample', component: ProfessionChemicalEngineerView },
      { path: '/marketing-sample', component: ProfessionMarketingView },
      { path: '/hr-recruiter-sample', component: ProfessionHRRecruiterView },
      { path: '/science-teacher-sample', component: ProfessionScienceTeacherView },
      { path: '/physician-dentist-sample', component: ProfessionPhysicianDentistView },
      { path: '/pilot-sample', component: ProfessionPilotView },
      { path: '/tech-entrepreneur-sample', component: ProfessionTechEntrepreneurView },

      { path: '/blog/1', component: BlogGridView1 },
      { path: '/blog/2', component: BlogGridView2 },
      { path: '/blog/3', component: BlogGridView3 },
      { path: '/blog/4', component: BlogGridView4 },
      { path: '/blog/5', component: BlogGridView5 },
      { path: '/blog/6', component: BlogGridView6 },

      { path: '/success-story-investment-banker', component: SuccessStoryBankerView},
      { path: '/success-story-dentist', component: SuccessStoryDentistView},
      { path: '/success-story-entrepreneur', component: SuccessStoryEntrepreneurView},
      { path: '/success-story-human-resources-consultant', component: SuccessStoryHRConsultantView},
      { path: '/success-story-marketing-manager', component: SuccessStoryMarketerView},
      { path: '/success-story-petroleum-engineer', component: SuccessStoryPetroleumEngineerView},
      { path: '/success-story-software-engineer', component: SuccessStorySoftwareEngineerView},
      { path: '/success-story-data-analyst', component: SuccessStoryDataAnalystView},
      { path: '/success-story-product-manager', component: SuccessStoryProductManagerView},
      { path: '/success-story-human-resources', component: SuccessStoryHRView},
      { path: '/success-story-network-engineer', component: SuccessStoryNetworkEngineerView},
      { path: '/success-story-finance-expert', component: SuccessStoryFinanceExpertView},
      { path: '/success-story-science-teacher', component: SuccessStoryScienceTeacherView},
      { path: '/success-story-venture-capitalist', component: SuccessStoryVentureCapitalView},
      { path: '/success-story-data-scientist', component: SuccessStoryDataScientistView},
      { path: '/success-story-software-engineer-2', component: SuccessStorySoftwareEngineerRetailView},
      { path: '/success-story-automotive-engineer', component: SuccessStoryAutomotiveEngineerView},
      { path: '/success-story-chips-engineer', component: SuccessStoryChipsEngineerView},
      { path: '/success-story-frontend-engineer', component: SuccessStoryFrontendEngineerView},

      { path: '/success-story-rfe-data-analyst', component: SuccessStoryRfeDataAnalystView},

      { path: '/eb2niw-mistakes-to-avoid-1', component: EB2NIWMistakes1View },
      { path: '/2023-eb2niw-timeline', component: EB2NIWTimelineView },
      { path: '/how-to-apply-for-eb2-niw-green-card', component: EB2NIWApplicationView },
      { path: '/what-is-an-eb2-niw-visa', component: EB2NIWVisaView },
      { path: '/what-is-the-cost-of-eb2-niw', component: EB2NIWCostView },
      { path: '/what-is-eb2-niw-processing-time', component: EB2NIWProcessingView },
      { path: '/what-are-eb2-niw-eligibility-requirements', component: EB2NIWRequirementsView },
      { path: '/what-evidence-can-you-use-for-eb2-niw', component: EB2NIWEvidenceView },
      { path: '/eb2-national-interest-waiver-documents-checklist', component: EB2NIWChecklistView },
      { path: '/prepare-eb2-niw-recommendation-letters', component: EB2NIWLettersOfRecommendationView },
      { path: '/what-if-my-eb2-niw-is-denied', component: EB2NIWDeniedView },
      { path: '/my-options-to-apply-for-eb2-visa-niw-vs-perm', component: EB2NIWVSPermView},
      { path: '/what-can-i-do-if-there-is-a-backlog-for-eb2-niw', component: EB2NIWBacklogView},
      { path: '/what-are-the-benefits-of-eb2-niw-petition', component: EB2NIWBenefitsView},
      { path: '/adjustment-of-status-vs-consular-processing', component: EB2NIWAdjustmentConsularView},
      { path: '/eb2-niw-for-china-and-india-born-applicants', component: EB2NIWIndianChineseView},      
      { path: '/what-is-the-uscis-visa-bulletin', component: EB2NIWBulletinView},
      { path: '/independent-recommendation-letters', component: EB2NIWIndependentRecommendationLettersView},
      { path: '/eb2niw-mistakes-to-avoid-2', component: EB2NIWMistakes2View },
      { path: '/eta9089-vs-eta750', component: EB2NIWETA9089vsETA750View },
      { path: '/new-eta9089', component: EB2NIWNewEta9089View },
      { path: '/i485', component: EB2NIWI485View },
      { path: '/our-track-record', component: EB2NIWSelfPetitionUsaView },
      { path: '/l1-visa-during-layoffs', component: EB2NIWL1VisaView },
      { path: '/petition-length', component: EB2NIWPetitionLengthView },
      { path: '/ai-blog', component: EB2NIWAiBlogView },
      { path: '/stem-approvals', component: EB2NIWStemApprovalView },
      { path: '/eb2niw-guide', component: EB2NIWGuideView },

      // otherwise redirect to home
      { path: '*', redirect: '/' }
    ]
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page

    next();
})
