<template>
    <div>

		<div class="page-title-area section-title" style="margin-bottom: 0px; padding-bottom: 10px">
			<div class="d-table"><div class="d-table-cell"><div class="container"><h1 class="page-title" >EB-2 NIW Blog</h1></div></div></div>
			<div class="bar"></div>
		<!-- End Page Title -->
			<div style="top:20px" class="shape1"><img src="../resources/shape1.png" alt="national interest waiver templates"></div>
			<div class="shape8 rotateme"><img src="../resources/shape2.svg" alt="niw templates"></div>
			<div class="shape7"><img src="../resources/shape4.svg" alt="eb2 niw project manager"></div>
		</div>

		<!-- Start Blog Area -->
		<section class="blog-area ptb-80">
			<div class="container">
				<div class="row">


					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/eb2niw-mistakes-to-avoid-2">
									<img height="" src="../resources/blog/blog30-1x1.webp" alt="eb2 niw mistakes">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> June 27, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/eb2niw-mistakes-to-avoid-2">EB-2 NIW cases that fail - avoid these mistakes! (2)</a></h4>

								<span>Author: <a href="#">Tom</a></span>

								<p>In this blog post, we will discuss a specific case and dissect the appeal process to understand the petitioner's errors in meeting Prong-1. Goal is to gain valuable insights into what to avoid in your own petitions.</p>

								<a href="/eb2niw-mistakes-to-avoid-2" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/success-story-network-engineer">
									<img src="../resources/blog/blog29-1x1.webp" alt="eb2 niw network engineer">
								</a>

								<div class="story">
									<vue-feather type="shield"></vue-feather> Success story
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/success-story-network-engineer">EB-2 NIW approval for 5G network engineer</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>This success story revolves around Juan, a 5G network engineer, whose dedication and contributions have not only propelled his career but have also fostered innovation, driven economic growth, and...</p>

								<a href="/success-story-network-engineer" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/success-story-human-resources">
									<img height="" src="../resources/blog/blog28-1x1.webp" alt="eb2 niw hr">
								</a>

								<div class="story">
									<vue-feather type="shield"></vue-feather> Success story
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/success-story-human-resources">EB-2 NIW approval for HR employee</a></h4>

								<span>Author: <a href="#">Przemek</a></span>

								<p>We explore Lisa's journey and the ways in which she provides top-notch HR solutions, with a focus on employee satisfaction and retention, as well as the strategic hiring and training. Then, we examine how Lisa's endeavor aligns with the substantial merit...</p>

								<a href="/success-story-human-resources" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/success-story-product-manager">
									<img height="" src="../resources/blog/blog27-1x1.webp" alt="eb2 niw product manager">
								</a>

								<div class="story">
									<vue-feather type="shield"></vue-feather> Success story
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/success-story-product-manager">EB-2 NIW approval for product manager</a></h4>

								<span>Author: <a href="#">Tom</a></span>

								<p>A talented product manager overseeing and executing projects that automate technology processes for a semiconductor company using data analytics, recently achieved a significant milestone - obtaining an EB-2 NIW.</p>

								<a href="/success-story-product-manager" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/success-story-data-analyst">
									<img height="" src="../resources/blog/blog26-1x1.webp" alt="eb2 niw data analyst">
								</a>

								<div class="story">
									<vue-feather type="shield"></vue-feather> Success story
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/success-story-data-analyst">EB-2 NIW approval for data analyst</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>Jason had a bachelor's degree in computer science and had worked for a well-known internet company before pursuing graduate studies in the US. He was also about to graduate his MBA and was in the process of looking for a job.</p>

								<a href="/success-story-data-analyst" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather></a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/independent-recommendation-letters">
									<img height="" src="../resources/blog/blog25-1x1.webp" alt="eb2 niw recommendation letters template">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> April 21, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/independent-recommendation-letters">Independent recommendation letters</a></h4>

								<span>Author: <a href="#">Tom</a></span>

								<p>Objective recommenders, also known as independent recommenders, independent advisory opinions, or independent expert opinions. These are experts who do not know you personally but can vouch for your work.</p>

								<a href="/independent-recommendation-letters" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/eb2niw-mistakes-to-avoid-1">
									<img height="" src="../resources/blog/blog24-1x1.webp" alt="eb2 niw petition dyi">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> April 11, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/eb2niw-mistakes-to-avoid-1">EB-2 NIW cases that fail - avoid these mistakes! (1)</a></h4>

								<span>Author: <a href="#">Przemek</a></span>

								<p>Understanding why some cases fail can help applicants avoid making similar mistakes. In this article, we will look at individual EB-2 NIW cases and discuss the lessons we can learn from the most common mistakes applicants make.</p>

								<a href="/eb2niw-mistakes-to-avoid-1" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/2023-eb2niw-timeline">
									<img height="" src="../resources/blog/blog23-1x1.webp" alt="eb2 niw templates">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> April 4, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/2023-eb2niw-timeline">EB-2 NIW green card timeline in 2023</a></h4>

								<span>Author: <a href="#">Tom</a></span>

								<p>The EB2 NIW green card process involves several steps, including filing the I-140 form and the I-485 form, and attending a biometrics appointment and an interview. The processing time for each of these steps can vary, and the overall timeline can be affected...</p>

								<a href="/2023-eb2niw-timeline" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/success-story-investment-banker">
									<img height="" src="../resources/blog/blog22-1x1.webp" alt="eb2 niw banking">
								</a>

								<div class="story">
									<vue-feather type="shield"></vue-feather> Success story
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/success-story-investment-banker">EB-2 NIW approval for investment banker</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>In this success story, we will highlight Mark's case, a Vice President in Investment Banking who was able to secure an EB-2 NIW approval. This individual had a long and successful career in investment banking...</p>

								<a href="/success-story-investment-banker" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					
					<div class="col-lg-12 col-md-12">
						<div class="pagination-area">
							<nav aria-label="Page navigation">
								<ul class="pagination justify-content-center">
									<li class="page-item"><a class="page-link" href="/blog/2">Prev</a></li>
									<li class="page-item"><a class="page-link" href="/blog/1">1</a></li>
									<li class="page-item"><a class="page-link" href="/blog/2">2</a></li>
									<li class="page-item active"><a class="page-link" href="#">3</a></li>
									<li class="page-item"><a class="page-link" href="/blog/4">4</a></li>
									<li class="page-item"><a class="page-link" href="/blog/5">5</a></li>
									<li class="page-item"><a class="page-link" href="/blog/6">6</a></li>
									<li class="page-item"><a class="page-link" href="/blog/4">Next</a></li>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Blog Area -->
    </div>
</template>
<script>
export default {
    name: 'BlogGrid3'
}
</script>
