<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <div class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </div>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">What can I do if there is EB-2 NIW backlog?</h1>
          </div>
        </div>
      </div>
    </div>
    
    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">February 2, 2023</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Przemek</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog12-16x9.webp" alt="eb2-niw do it yourself package">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">EB-2 NIW backlog</h3>

          <div class="blog-paragraph">EB-2 National Interest Waiver (NIW) is a type of employment-based Green Card for individuals with exceptional ability or advanced degrees who can demonstrate that their work in the United States is in the national interest. However, <span style="background-color: rgba(68, 206, 111, 0.25)">due to the limited number of Green Cards available each year, there may be a backlog for EB-2 NIW.</span></div>

          <div class="blog-paragraph">If you are facing a backlog for EB-2 NIW, here are some things you can do:</div>


          <ul class="features-list">
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Initiate the process of separate filing:</span> To obtain NIW Green Card, you must file <span style="background-color: rgba(68, 206, 111, 0.25)">I-140 Immigrant Petition and an I-485 Adjustment of Status Application. These could be filed concurrently or separately</span>. You can file the first part, ie. <span style="font-weight: 500; font-style: italic">Form I-140</span> (Immigrant Petition for Alien Worker) even if there is a backlog in visa availability. In other words, only <span style="font-weight: 500; font-style: italic">Form I-485</span> is subject to backlog, so keep checking the Visa Bulletin to determine whether you can proceed to this step, which is adjustment of status or consular processing.</li>
          </ul>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

          <ul class="features-list">
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Check for updates on the visa bulletin:</span> The visa bulletin is published monthly by the U.S. Department of State and provides information on the availability of immigrant visas.</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Consider alternative options:</span> If the backlog for EB-2 NIW is too long, you may want to consider alternative options such as applying for a different type of Green Card, such as <span style="font-weight: 500; font-style: italic">EB-1A</span>, or considering other options such as <span style="font-weight: 500; font-style: italic">H-1B</span> or <span style="font-weight: 500; font-style: italic">L-1</span> visas.</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Wait:</span> While waiting for the backlog to clear, you may be able to continue working in the United States under a different type of visa or work authorization. Preparing <a href="/home" style="text-decoration: underline">EB-2 NIW application</a> can take up to a few months. Could be that by the time you are able to file, the backlog will be resolved.</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Join advocacy groups:</span> Joining advocacy groups or organizations that support immigration can provide you with updates on the backlog and connect you with other individuals who are facing similar challenges.</li>
          </ul>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem; padding-bottom: 0px">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                  <a href="/are-you-eligible" class="btn btn-primary btn-assess">Are you eligible for EB-2 NIW?</a>
              </div>
            </div>
          </div>

          <div class="blog-paragraph" style="padding-bottom: 6rem">It is important to keep in mind that the <span style="font-weight: 500; font-style: italic">backlog for EB-2 NIW can change</span> from time to time, so it is important to stay informed and be prepared to take advantage of any opportunities that may arise.</div>
          
        </div>
      </div>
    </section>

  </div>
</template>
<script>

import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";

export default {
  name: 'EB2NIWBacklog',
  components: {
    PriceOffer,
    CarouselGreenCard
  }
}
</script>
