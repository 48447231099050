<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <EB2NIWVSPerm></EB2NIWVSPerm>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import EB2NIWVSPerm from "../blog-components/EB2NIWVSPerm";


export default {
  name: 'EB2NIWVSPermView',
  components: {
    EB2NIWVSPerm,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "NIW vs PERM Labor Certification EB2 NIW - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "National Interest Waiver (NIW) and Permanent Employment Certification (PERM) are two different processes for foreign nationals seeking permanent residency in the United States. Both offer pathways to a Green Card."},
      ]
    }
  }
}
</script>
