var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('section',{staticClass:"blog-details-area"},[_c('div',{staticClass:"article-content"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('vue-feather',{attrs:{"type":"clock"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("February 18, 2023")])],1),_c('li',[_c('vue-feather',{attrs:{"type":"user"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("Tom")])],1)])])])]),_vm._m(1),_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"article-content"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Success story: EB-2 NIW")]),_vm._m(2),_vm._m(3),_c('div',{staticClass:"signupSelfPetition row justify-content-center align-items-center"},[_c('div',{staticClass:"col-xl-6 col-lg-7"},[_c('signup-self-petition')],1)]),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('PriceOffer',{staticStyle:{"margin-bottom":"4rem"}}),_c('CarouselGreenCard',{staticStyle:{"margin-bottom":"4rem"}}),_c('div',{staticClass:"blog-paragraph"},[_vm._v("Finally, John also demonstrated that his work was in the national interest by showing how it contributed to the growth and competitiveness of the software industry in the United States.")]),_vm._m(8),_vm._m(9),_vm._m(10)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area2"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('span',{staticClass:"article-tags"},[_c('a',{attrs:{"href":"#"}},[_vm._v("EB-2 NIW BLOG")])]),_c('span',{staticClass:"article-tags2"},[_c('a',{attrs:{"href":"#"}},[_vm._v("SUCCESS STORY")])]),_c('h1',{staticStyle:{"font-weight":"600","margin-top":"30px","text-align":"left"}},[_vm._v("EB-2 NIW approval for software engineer")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../resources/blog/blog16-16x9.webp"),"alt":"NIW template software engineer"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Meet "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("John, a talented machine learning software engineer from Germany with over 7 years of experience")]),_vm._v(" in the field. Despite his vast knowledge and expertise, he faced several roadblocks in obtaining a Green Card through the EB-2 category.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("The main challenge was that John "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("had no research publications or patents")]),_vm._v(" in his name. Whereas the most common way of applying for EB-2 NIW involves documenting the publication of research papers, patents, or recognition through awards or prizes.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("However, John was employed by a leading software company, and convinced that with "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("7 years of experience and an advanced degree")]),_vm._v(" he meets EB-2 National Interest Waiver (NIW) criteria. He decided to self-petition through EB-2 NIW.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("To maintain the US's position as a leader in technological and economic innovation for future generations, "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("President Biden")]),_vm._v(" and his administration have implemented various measures "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("since January 2022")]),_vm._v(" aimed at "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("attracting and retaining top international talent in Science, Technology, Engineering, and Mathematics (STEM)")]),_vm._v(". John knew it is a good signal that "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("STEM")]),_vm._v(" is important to national interest.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("John compiled a comprehensive and persuasive application package using our "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("EB-2 NIW Starter Kit")]),_vm._v(". He emphasized his contributions to the "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("development of several innovative machine learning algorithms that were being used by top companies in various industries. They also highlighted John's leadership and mentorship skills")]),_vm._v(", and how he had trained and nurtured several junior engineers, helping them to develop into highly skilled software engineers.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Check "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("Starter Kit for EB-2 NIW")]),_vm._v(", which includes self-petition Green Card templates for professionals with no research background:")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("After several months of waiting, John received his approval notice for the EB-2 NIW. He was overjoyed and grateful for discovering "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("EB-2 NIW templates for professionals")]),_vm._v(" which helped him to achieve his goal of obtaining a Green Card.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("John is now a permanent resident of the United States and continues to make significant contributions to the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("software industry")]),_vm._v(". He is a valuable member of the community and a testament to the fact that even those "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("without research publications")]),_vm._v(" can achieve success through the EB-2 NIW category.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('a',{staticClass:"btn btn-primary",staticStyle:{"margin-top":"3rem","margin-bottom":"2rem"},attrs:{"href":"/software-engineer-it-sample"}},[_vm._v("SOFTWARE ENG & IT TEMPLATE")])])])])
}]

export { render, staticRenderFns }