<template>
    <!-- Start Pricing Area -->
    <section class="pricing-area ptb-80">
        <section />
        <div class="container">
            <div class="section-title">
                <h2>Our Prices</h2>
                <div class="bar"></div>
                <div class="paragraph">
                    <p>None of the information or packages on this site constitutes legal advice. We offer only a general information, based on our experience with the green card application process.</p>
                </div>
                <div class="paragraph">
                    <p>It is essential that you conduct your own research or seek the advice of an attorney if needed.</p>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="pricing-table active-plan">
                        <img v-if="isPromo" src="../resources/discount.webp" style="height: 100px; position: absolute; right: 20px; top: 10px; z-index: 2">
                        
                        <div class="pricing-header">
                            <h3>Starter Kit</h3>
                        </div>
                        
                        <div class="price">
                            <span v-if="!isPromo"><sup>$</sup>360</span>
                        </div>

                        <div v-if="isPromo" class="price">
                            <span><sup>$</sup>234
                                <span style="color: #b30000; font-size: 20px; text-decoration: line-through"><sup>$</sup>360</span>
                            </span>
                        </div>

                        <div class="pricing-features">
                            <ul>
                                <li class="active" style="color: #0e314c; font-weight: 600; margin-bottom: 30px">Profession-specific standard template</li>
                                <li class="active" style="color: #0e314c;">Sample I-140 cover letter for your profession</li>
                                <li class="active" style="color: #0e314c;">Profession specific NIW builder</li>
                                <li class="active" style="color: #0e314c;">Our original I-140 cover letter (*banking)</li>
                                <li class="active" style="color: #0e314c;">Our three original reference letters (*banking)</li>
                                <li class="active" style="color: #0e314c;">Continuation of the endeavor (*banking)</li>
                                <li class="active" style="color: #0e314c;">Steps to prepare successful reference letters</li>
                                <li class="active" style="color: #0e314c;">Guide to understand VISA Bulletin</li>
                                <li class="active" style="color: #0e314c;">Access to <img src="../resources/discord-logo.svg" style="width: 90px; margin-left: 2px; margin-right: 2px" alt="discord eb2 niw"/> community</li>
                            </ul>
                        </div>

                        <div class="pricing-footer">
                            <a href="/checkout-starter-kit" v-on:click="sendEvent('plan-premium')" class="btn btn-primary">BUY</a>
                        </div>
                    </div>
                </div>  

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="pricing-table active-plan">
                        <img v-if="isPromo" src="../resources/discount.webp" style="height: 100px; position: absolute; right: 20px; top: 10px; z-index: 2">
                        
                        <div class="pricing-header">
                            <h3>Selfpetition AI</h3>
                        </div>
                        
                        <div class="price">
                            <span v-if="!isPromo"><sup>$</sup>1,000</span>
                        </div>

                        <div v-if="isPromo" class="price">
                            <span><sup>$</sup>650
                                <span style="color: #b30000; font-size: 20px; text-decoration: line-through"><sup>$</sup>1,000</span>
                            </span>
                        </div>

                        <div class="pricing-features">
                            <ul>
                                <li class="active" style="color: #0e314c; font-weight: 600; margin-bottom: 30px">Custom petition reflecting your unique case</li>
                                <li class="active" style="color: #0e314c;">AI trained on EB2 NIW cases and USCIS data</li>
                                <li class="active" style="color: #0e314c;">Custom cover letter (9-13 pages)</li>
                                <li class="active" style="color: #0e314c;">Three reference letters (each 1-2 pages)</li>
                                <li class="active" style="color: #0e314c;">Excludes forms, package assembly and filing</li>
                                <li class="active" style="color: #0e314c;">Unlimited revisions</li>
                                <li class="active" style="color: #0e314c;">Option to buy more reference letters</li>
                                <li class="active" style="color: #0e314c;">Satisfaction guarantee refund policy</li>
                                <li class="active" style="color: #0e314c;">Access to <img src="../resources/discord-logo.svg" style="width: 90px; margin-left: 2px; margin-right: 2px" alt="discord eb2 niw"/> community</li>
                            </ul>
                        </div>

                        <div class="pricing-footer">
                            <a href="/checkout-selfpetition-ai" v-on:click="sendEvent('plan-ai')" class="btn btn-primary">BUY</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row pricing-rows">

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="pricing-table">
                        <img v-if="isPromo" src="../resources/discount.webp" style="height: 100px; position: absolute; right: 20px; top: 10px; z-index: 2">

                        <div class="pricing-header">
                            <h3>RFE Response (Selfpetition AI)</h3>
                        </div>

                        <div v-if="!isPromo" class="price">
                            <span><sup>$</sup>500</span>
                            <br>
                            <span><sup></sup><span>( Per prong: select prongs in the checkout )</span></span>
                        </div>

                        <div v-if="isPromo" class="price">
                            <span><sup>$</sup>325
                                <span style="color: #b30000; font-size: 20px; text-decoration: line-through"><sup>$</sup>500</span>
                            </span>
                            <br>
                            <span><sup></sup><span>( Per prong: select prongs in the checkout )</span></span>
                        </div>

                        <div class="pricing-features">
                            <ul>
                                <li class="active" style="color: #0e314c;">Tailored to address points from RFE letter</li>
                                <li class="active" style="color: #0e314c;">Choose arguments from AI suggestions</li>
                                <li class="active" style="color: #0e314c;">Full refund if the RFE response is unsuccessful</li>
                            </ul>
                        </div>

                        <div class="pricing-footer">
                            <a href="checkout-rfe-response"  v-on:click="sendEvent('plan-rfe')" class="btn btn-primary">BUY</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="pricing-table">
                        <div class="pricing-header">
                            <h3>Adjustment of Status</h3>
                        </div>

                        <div class="price">
                            <span><sup>$</sup>59</span>
                            <br>
                            <span><sup></sup><span>( Forms I-485, I-765, I-131 )</span></span>
                        </div>

                        <div class="pricing-features">
                            <ul>
                                <li class="active" style="color: #0e314c;">50+ page editable sample</li>
                                <li class="active" style="color: #0e314c;">This is original petition filed in 2020</li>
                                <li class="active" style="color: #0e314c;">10+ page Adjustment of Status guide</li>
                            </ul>
                        </div>

                        <div class="pricing-footer">
                            <a href="https://buy.stripe.com/9AQ8A66NN6IBbrabIZ"  v-on:click="sendEvent('plan-aos')" class="btn btn-primary">BUY</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="shape7"><img src="../resources/shape4.svg" ></div>
    </section>
    <!-- End Pricing Area -->
</template>

<script>

export default {
  name: 'PricingAnnotate',
  data() {
    return {
      $gtag: null,
      discountStart: this.generateClosestDatesArray(),
    }
  },
  methods: {
    sendEvent(eventType) {
      if (window.gtag) {
        window.gtag('event', eventType);
      }
    },
    generateClosestDatesArray() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const startDate = new Date(year, 0, 0);
      const endDate = new Date(year + 1, 0, 0);

      const dateArray = [];
      let currentDateCandidate = startDate;

      while (currentDateCandidate < endDate) {
        dateArray.push(new Date(currentDateCandidate));
        currentDateCandidate.setDate(currentDateCandidate.getDate() + 15);
      }

      // Find the closest date that is before the current date
      let closestDate = dateArray[0];
      for (const date of dateArray) {
        if (date < currentDate && date > closestDate) {
          closestDate = date;
        }
      }

      return closestDate;
    }
  },
  computed: {
    isPromo() {
      // const tenDaysInMilliseconds = 10 * 24 * 60 * 60 * 1000; // 10 days in milliseconds
      //
      // return tenDaysInMilliseconds > (new Date() - this.discountStart)
      return false;
    }
  }
}
</script>
