var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('section',{staticClass:"blog-details-area"},[_c('div',{staticClass:"article-content"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('vue-feather',{attrs:{"type":"clock"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("September 2, 2023")])],1),_c('li',[_c('vue-feather',{attrs:{"type":"user"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("Tom")])],1)])])])]),_vm._m(1),_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"article-content"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Success story: EB-2 NIW")]),_vm._m(2),_vm._m(3),_c('div',{staticClass:"signupSelfPetition row justify-content-center align-items-center"},[_c('div',{staticClass:"col-xl-6 col-lg-7"},[_c('signup-self-petition')],1)]),_vm._m(4),_vm._m(5),_c('PriceOffer',{staticStyle:{"margin-bottom":"4rem"}}),_c('CarouselGreenCard',{staticStyle:{"margin-bottom":"4rem"}}),_vm._m(6),_vm._m(7),_vm._m(8)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area2"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('span',{staticClass:"article-tags"},[_c('a',{attrs:{"href":"#"}},[_vm._v("EB-2 NIW BLOG")])]),_c('span',{staticClass:"article-tags2"},[_c('a',{attrs:{"href":"#"}},[_vm._v("SUCCESS STORY")])]),_c('h1',{staticStyle:{"font-weight":"600","margin-top":"30px","text-align":"left"}},[_vm._v("EB-2 NIW approval for the Head of Investments & Portfolio Capital")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../resources/blog/blog35-16x9.webp"),"alt":"NIW template venture capital"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("In this compelling success narrative, we delve into the inspiring journey of Sarah, a seasoned professional whose illustrious career reached its pinnacle as the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Head of Investments & Portfolio Capital")]),_vm._v(" at a distinguished "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Venture Capital firm")]),_vm._v(". Sarah's remarkable achievements are underscored by her recent triumph in securing an EB-2 NIW approval, a testament to her exceptional contributions and influence within the realm of venture capital.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Throughout her extensive career, Sarah emerged as a "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("trailblazer")]),_vm._v(", "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("spearheading innovative investment strategies")]),_vm._v(" that redefined the landscape and left an "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("indelible mark on the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("growth trajectory")]),_vm._v(" of numerous startups. Her strategic acumen and visionary leadership propelled the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("success of individual ventures")]),_vm._v(", playing a pivotal role in shaping the broader landscape of the venture capital industry")]),_vm._v(". Sarah's journey serves as a beacon of inspiration, showcasing the transformative power of dedication, innovation, and a relentless pursuit of excellence in the dynamic world of investments and portfolio capital.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Initially, Sarah faced uncertainties about crafting arguments to support her EB-2 NIW case. However, with the invaluable support of our "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("EB-2 NIW Starter Kit")]),_vm._v(", tailored for professionals like Sarah, featuring a specialized NIW builder with argument examples and application wording designed for the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("finance")]),_vm._v(", "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("banking")]),_vm._v(" and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("venture capital")]),_vm._v(" industry")]),_vm._v(", Sarah achieved the coveted EB-2 NIW approval.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Check "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("Starter Kit for EB-2 NIW")]),_vm._v(", which includes self-petition Green Card templates for professionals:")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Within her application, Sarah adeptly showcased how her skills, experience, and leadership in her role were of substantial "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("value to the United States")]),_vm._v(". She presented compelling evidence of her professional "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("achievements, including her instrumental role in the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("growth of the startups")]),_vm._v(" in her portfolio, "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("startups' hiring potential")]),_vm._v(", a "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("salary exceeding industry standards")]),_vm._v(", and her contributions to "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("fostering innovation and entrepreneurship")])]),_vm._v(" in the country.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("The EB-2 NIW approval not only validated Sarah's contributions but also allowed her to "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("bypass the cumbersome labor certification")]),_vm._v(" process, making it easier for her to realize her dream of permanent residence and employment in the United States. Sarah's success story underscores the importance of using persuasive yet straightforward arguments in an NIW case. These arguments can be effectively crafted using "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("EB-2 NIW templates")]),_vm._v(" tailored for professionals in the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("banking and finance")]),_vm._v(" industry, just as Sarah did.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('a',{staticClass:"btn btn-primary",staticStyle:{"margin-top":"3rem","margin-bottom":"2rem"},attrs:{"href":"/banking-finance-sample"}},[_vm._v("BANKING-FINANCE TEMPLATE")])])])])
}]

export { render, staticRenderFns }