<template>

    <section class="services-area ptb-80" style="background: white; padding-bottom: 3rem; padding-top: 2rem">
        <section />
        <div class="container">

            <div class="section-title">
                <h2>Selfpetition AI &#129302;</h2>
                <div class="bar"></div>
                <div class="paragraph">
                    <p style="margin-bottom: 10px">Artificial Intelligence trained with past cases and USCIS data.</p>
                    <p>Designed for individuals whose requirements exceed the scope of our standard Starter Kit, whether due to a unique combination of professions or unavailable templates, or who prefer not to spend time adjusting templates to align with their unique background.</p>
                </div>
                <h2 style="text-align: center; color: #6084a4; margin-top: 20px; margin-bottom: 0px">&#8681;</h2>
            </div>

            <div class="row h-100 justify-content-center align-items-center">
                <div class="pricing-table col-lg-5 col-md-5 col-12" style="top: -20px">
                    <img width="350px" src="../resources/gpt-vs-selfpetition.png" alt="permanent resident self petition">
                </div>
            </div>

            <div style="text-align: center; margin-top: 2rem">
            <h6>Don't just take our word for it</h6>
            <h6 style="color: #5457c1;">See the difference by yourself!</h6>
            </div>

            <div id="rfe" style="text-align: center; margin-top: 1.5rem">
              <a href="/ai" class="btn btn-primary">GPT <span style="text-transform: lowercase">vs</span> Selfpetition</a>
            </div>

            <div class="shape3-services"><img src="../resources/shape3.svg" alt="i-140"></div>
            <div class="shape4-services"><img src="../resources/shape4.svg" alt="i-485"></div>

        </div>
    </section>
 
</template>

<script>
    export default {
        name: 'SelfpetitionAIGreenCard',
    }
</script>
