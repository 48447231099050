<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <ProfessionTechEntrepreneur></ProfessionTechEntrepreneur>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
    import ExternalHeaderGreenCard from "./components/ExternalHeaderGreenCard";
    import ExternalFooterGreenCard from "./components/ExternalFooterGreenCard";
    import ProfessionTechEntrepreneur from "./components/ProfessionTechEntrepreneur";

    export default {
      name: 'ProfessionTechEntrepreneurView',
      components: {
        ProfessionTechEntrepreneur,
        ExternalHeaderGreenCard,
        ExternalFooterGreenCard,
      },
      data() {
        return {
          currentUrl: ''
        }
      },
      watch: {
        '$route'(pathUrl){
          this.currentUrl = pathUrl.path;
        }
      },
      mounted() {
        this.currentUrl = window.location.pathname;
      },
      metaInfo() {
        return {
          title: "Tech Entrepreneur - Self Petition USA",
          meta: [
          { vmid: 'description', name: 'description', content:  "For entrepreneurs aiming to navigate the EB-2 NIW process, it's essential to effectively showcase their innovative contributions and the transformative impact of their ventures on the industry. The application should emphasize how their work not only drives advancement but also fosters significant economic growth and competitiveness on a national scale. Highlighting the entrepreneur’s role in pioneering new technologies or business models that address pressing societal needs can further strengthen the case for their substantial influence and merit within the sector."},
          ]
        }
      }
    }
</script>