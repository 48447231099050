var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('section',{staticClass:"blog-details-area"},[_c('div',{staticClass:"article-content"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('vue-feather',{attrs:{"type":"clock"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("April 29, 2023")])],1),_c('li',[_c('vue-feather',{attrs:{"type":"user"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("Natalie")])],1)])])])]),_vm._m(1),_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"article-content"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Success story: EB-2 NIW")]),_vm._m(2),_c('div',{staticClass:"signupSelfPetition row justify-content-center align-items-center"},[_c('div',{staticClass:"col-xl-6 col-lg-7"},[_c('signup-self-petition')],1)]),_vm._m(3),_vm._m(4),_vm._m(5),_c('PriceOffer',{staticStyle:{"margin-bottom":"4rem"}}),_c('CarouselGreenCard',{staticStyle:{"margin-bottom":"4rem"}}),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area2"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('span',{staticClass:"article-tags"},[_c('a',{attrs:{"href":"#"}},[_vm._v("EB-2 NIW BLOG")])]),_c('span',{staticClass:"article-tags2"},[_c('a',{attrs:{"href":"#"}},[_vm._v("SUCCESS STORY")])]),_c('h1',{staticStyle:{"font-weight":"600","margin-top":"30px","text-align":"left"}},[_vm._v("EB-2 NIW approval for data analyst")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../resources/blog/blog26-16x9.webp"),"alt":"NIW template data analyst"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Jason applied for permanent residency in March 2021 through the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("self-petition route of National Interest Waiver (NIW)")]),_vm._v(", classified as EB2. Jason's country of birth had a current EB2 priority date, allowing for a short time frame for permanent residency if successful.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Jason had a "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("bachelor's degree in "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("computer science")]),_vm._v(" and had worked for a well-known internet company before pursuing graduate studies in the US. He was also "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("about to graduate his MBA")])]),_vm._v(" and was in the process of looking for a job.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Jason had "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("no published papers")]),_vm._v(" in a traditional academic venue, and his research results were not published or publicized due to the confidential nature of internal business practices.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Jason filed a NIW based I-140 petition "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("leveraging templates from "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("EB-2 NIW Starter Kit - data analyst.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("However, as expected, he received a "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Request for Evidence (RFE)")]),_vm._v(", asking about his career plan and intended employment.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("By the time he received the RFE, Jason had already graduated MBA and "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("landed a job as a Data Analyst for a "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("crypto startup")]),_vm._v(". He responded to the RFE, providing evidence to support the original claim that he intended to continue work related to "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("internet security and identity protection.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Soon after, Jason received the good news that USCIS has approved his NIW petition. With his "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("NIW petition approved")]),_vm._v(", Jason's journey toward permanent residency gained momentum. The approval brought a sense of relief and accomplishment, signaling a new chapter in his life. He could now "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("explore a much wider range of professional opportunities")]),_vm._v(", no longer subject to employer discretion to sponsor his visa. Celebrating this milestone, Jason continued to excel in his role as a "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("Data Analyst for the crypto startup")]),_vm._v(". The unique blend of his computer science background, MBA education, and experience in internet security positioned him as a valuable asset in the dynamic field of cryptocurrency.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('a',{staticClass:"btn btn-primary",staticStyle:{"margin-top":"3rem","margin-bottom":"2rem"},attrs:{"href":"/data-scientist-analyst-sample"}},[_vm._v("DATA ANALYST TEMPLATE")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph",staticStyle:{"margin-bottom":"3rem"}},[_vm._v("Reflecting on the challenges he overcame, the experience reinforced Jason's belief in the importance of "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("determination")]),_vm._v(" and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("strategic planning")]),_vm._v(". With permanent residency secured, he set his sights on contributing to the innovation and progress of his adopted homeland. Continuing to make meaningful strides in his career, Jason's story became an inspiration for others navigating the intricate path toward achieving their "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("American dream")]),_vm._v(".")])
}]

export { render, staticRenderFns }