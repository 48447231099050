<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <EB2NIWETA9089vsETA750></EB2NIWETA9089vsETA750>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import EB2NIWETA9089vsETA750 from "../blog-components/EB2NIWETA9089vsETA750";


export default {
  name: 'EB2NIWETA9089vsETA750View',
  components: {
    EB2NIWETA9089vsETA750,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "ETA 9089 vs. ETA 750 - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "The labor certification documents are included as part of your I-140 package to fulfill USCIS requirements. The good news is you don't have to go through the entire labor certification process – you only need to fill out certain sections of the form yourself. No need to seek approval from the Department of Labor; just fill it out, sign it, and include it in your I-140 submission."},
      ]
    }
  }
}
</script>
