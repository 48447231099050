<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <div class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </div>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">EB-2 NIW for China & India born applicants</h1>
          </div>
        </div>
      </div>
    </div>

    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">January 23, 2023</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Natalie</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog9-16x9.webp" alt="EB2 NIW Template">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">EB-2 NIW according to country of birth</h3>

          <div class="blog-paragraph">The number of visas available under the EB-2 NIW category is <span style="background-color: rgba(68, 206, 111, 0.25)"><span style="font-weight: 500; font-style: italic">restricted geographically</span>, and the demand for these visas is particularly high among <span style="font-weight: 500; font-style: italic">Chinese</span> and <span style="font-weight: 500; font-style: italic">Indian</span> citizens. As a result, a <span style="font-weight: 500; font-style: italic">backlog</span> has developed, causing wait times of several years for Chinese and Indian citizens</span> seeking to obtain an EB-2 NIW Green Card.</div>

          <div class="blog-paragraph">In recent years, the wait times for EB-2 NIW visas have become increasingly long. This has caused significant frustration and uncertainty for many individuals and their families, who may be unable to pursue their careers or make long-term plans while they wait for their visas to be processed.</div>

          <div class="blog-paragraph">Additionally, individuals born in India or China facing extended wait times for EB-2 NIW visas should be aware of the strategic <span style="font-weight: 500; font-style: italic">advantages offered by obtaining an NIW I-140 Immigrant Petition approval</span>. Despite the Green Card delay, this approval can <span style="background-color: rgba(68, 206, 111, 0.25)">facilitate <span style="font-weight: 500; font-style: italic">H-1B</span> extensions, provide <span style="font-weight: 500; font-style: italic">H-4</span> work authorization for spouses, and enable the <span style="font-weight: 500; font-style: italic">transfer of the I-140 priority date</span> for future petitions filed under different categories.</span> Exploring these options can offer practical solutions to navigate the challenges posed by the current backlog.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                <router-link class="btn btn-primary" :to="{ name: 'Home', params: { location: 'Templates' }}">EB-2 NIW Templates</router-link>
              </div>
            </div>
          </div>

          <div class="blog-paragraph">All this means that if you were born in <span style="font-weight: 500; font-style: italic">India</span> or <span style="font-weight: 500; font-style: italic">China</span>, even though a Green Card may not be available to you in the near term future because of the EB-2 Visa Bulletin backlogs, <span style="background-color: rgba(68, 206, 111, 0.25)">there may still be advantages you can gain by obtaining an NIW I-140 Immigrant Petition approval, which is the first step of Green Card process:</span></div>

          <ul class="features-list">
            <li><vue-feather type="check"></vue-feather>NIW I-140 approval can be used to support <span style="font-weight: 500; font-style: italic">H-1B extensions</span> beyond the normal six-year limitation;</li>
            <li><vue-feather type="check"></vue-feather>NIW I-140 approval can be used to support an application for <span style="font-weight: 500; font-style: italic">H-4 work authorization</span> for your spouse; and</li>
            <li><vue-feather type="check"></vue-feather>NIW I-140 approval can give you the ability to <span style="font-weight: 500; font-style: italic">transfer I-140 priority date</span> to another future I-140 Immigrant Petition filed under a different category.</li>
          </ul>

          <div class="blog-paragraph">Check <a href="/home" style="text-decoration: underline">Starter Kit for EB-2 NIW</a>, which includes self-petition Green Card templates for professionals:</div>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>
          
          <div class="blog-paragraph">In conclusion, the EB-2 NIW backlog is a complex issue that has affected many Chinese and Indian citizens seeking to obtain a U.S. Green Card. While the wait times for these visas can be long and uncertain, there are <span style="font-weight: 500; font-style: italic">benefits to filing I-140</span> which is the first step of the Green Card process.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                  <a href="/are-you-eligible" class="btn btn-primary btn-assess">Are you eligible for EB-2 NIW?</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>
<script>

import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";

export default {
  name: 'EB2NIWIndianChinese',
  components: {
    PriceOffer,
    CarouselGreenCard
  }
}
</script>
