<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <SuccessStoryProductManager></SuccessStoryProductManager>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import SuccessStoryProductManager from "../blog-components/SuccessStoryProductManager";


export default {
  name: 'SuccessStoryProductManagerView',
  components: {
    SuccessStoryProductManager,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "EB2 NIW approval for product manager - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "Let's delve into this inspiring success story and explore how the EB-2 NIW paved the way for this product manager's immigration goals."},
      ]
    }
  }
}
</script>
