var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('section',{staticClass:"blog-details-area"},[_c('div',{staticClass:"article-content"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('vue-feather',{attrs:{"type":"clock"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("October 6, 2023")])],1),_c('li',[_c('vue-feather',{attrs:{"type":"user"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("Natalie")])],1)])])])]),_vm._m(1),_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"article-content"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Success story: EB-2 NIW")]),_vm._m(2),_c('div',{staticClass:"signupSelfPetition row justify-content-center align-items-center"},[_c('div',{staticClass:"col-xl-6 col-lg-7"},[_c('signup-self-petition')],1)]),_vm._m(3),_vm._m(4),_c('PriceOffer',{staticStyle:{"margin-bottom":"4rem"}}),_c('CarouselGreenCard',{staticStyle:{"margin-bottom":"4rem"}}),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area2"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('span',{staticClass:"article-tags"},[_c('a',{attrs:{"href":"#"}},[_vm._v("EB-2 NIW BLOG")])]),_c('span',{staticClass:"article-tags2"},[_c('a',{attrs:{"href":"#"}},[_vm._v("SUCCESS STORY")])]),_c('h1',{staticStyle:{"font-weight":"600","margin-top":"30px","text-align":"left"}},[_vm._v("EB-2 NIW approval for software engineer")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../resources/blog/blog37-16x9.webp"),"alt":"NIW template software engineer"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Meet Rafael, a skilled "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("software engineer")]),_vm._v(" from Germany with over "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("5 years of experience")]),_vm._v(" in the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("retail sector")]),_vm._v(" who applied for a green card through EB-2 NIW process.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("One of the key criteria for the EB-2 NIW is demonstrating the significant impact one's work has on the national interest of the United States. "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("In the world of retail, "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("data-driven decision-making")]),_vm._v(" is pivotal for success. Rafael decided to leverage his extensive experience and knowledge to emphasize the positive impact he had made in this domain. He showcased how his innovative software solutions had "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("optimized inventory management")]),_vm._v(", "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("enhanced customer experiences")]),_vm._v(", and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("boosted revenue")]),_vm._v(" for several prominent retail organizations.")]),_vm._v(" These achievements were a testament to his expertise in the field and the undeniable national interest in improving the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("competitiveness and efficiency")]),_vm._v(" of the retail sector.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Rafael used "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("Starter Kit for EB-2 NIW")]),_vm._v(", which includes self-petition Green Card templates for professionals:")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Furthermore, Rafael actively sought out "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("letters of recommendation")]),_vm._v(" from figures in the retail industry who could "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("vouch for the national importance")]),_vm._v(" of his work. Their endorsements emphasized the vital role Rafael played in the advancement of technology in retail, further bolstering his EB-2 NIW application.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Rafael ensured that his "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("petition")]),_vm._v(" was well-documented, with detailed "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("evidence of his contributions")]),_vm._v(" to the retail software industry. He compiled "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("case studies")]),_vm._v(", "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("performance metrics")]),_vm._v(", and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("testimonials")]),_vm._v(" from clients and colleagues to substantiate his claim")]),_vm._v(" as a vital asset to the national interest.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("His journey serves as a beacon of hope for "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("software engineers and professionals")]),_vm._v(" outside the typical research or patent-oriented fields. It illustrates that paths can lead to successful EB-2 NIW applications when there's a clear demonstration of the profound impact one's work has on the United States'")]),_vm._v(" national interests.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem","padding-bottom":"0px"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('a',{staticClass:"btn btn-primary",staticStyle:{"margin-top":"3rem","margin-bottom":"2rem"},attrs:{"href":"/software-engineer-it-sample"}},[_vm._v("SOFTWARE ENG & IT TEMPLATE")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph",staticStyle:{"padding-bottom":"6rem"}},[_vm._v("Rafael's determination, adaptability, and commitment to showcasing the significance of his work in the retail sector ultimately led to the approval of his "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("EB-2 NIW petition")]),_vm._v(". For software engineers like Rafael, demonstrating the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("national interest significance")]),_vm._v(" of their work in the retail industry can be a successful strategy, opening doors to a promising future in the United States.")])
}]

export { render, staticRenderFns }