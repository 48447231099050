var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('section',{staticClass:"blog-details-area"},[_c('div',{staticClass:"article-content"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('vue-feather',{attrs:{"type":"clock"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("May 29, 2023")])],1),_c('li',[_c('vue-feather',{attrs:{"type":"user"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("Przemek")])],1)])])])]),_vm._m(1),_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"article-content"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Success story: EB-2 NIW")]),_vm._m(2),_c('div',{staticClass:"signupSelfPetition row justify-content-center align-items-center"},[_c('div',{staticClass:"col-xl-6 col-lg-7"},[_c('signup-self-petition')],1)]),_vm._m(3),_c('div',{staticClass:"blog-paragraph"},[_vm._v("Lisa's achievement of the EB-2 NIW demonstrates her exceptional abilities:")]),_c('ul',{staticClass:"features-list"},[_c('li',[_c('vue-feather',{attrs:{"type":"check"}}),_vm._m(4),_vm._v(", which is crucial for innovation and economic prosperity.")],1),_c('li',[_c('vue-feather',{attrs:{"type":"check"}}),_vm._m(5),_vm._v(". Unlocking the company growth also helps to mitigate negative demographic trends related to the reproductive age group.")],1)]),_vm._m(6),_c('PriceOffer',{staticStyle:{"margin-bottom":"4rem"}}),_c('CarouselGreenCard',{staticStyle:{"margin-bottom":"4rem"}}),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area2"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('span',{staticClass:"article-tags"},[_c('a',{attrs:{"href":"#"}},[_vm._v("EB-2 NIW BLOG")])]),_c('span',{staticClass:"article-tags2"},[_c('a',{attrs:{"href":"#"}},[_vm._v("SUCCESS STORY")])]),_c('h1',{staticStyle:{"font-weight":"600","margin-top":"30px","text-align":"left"}},[_vm._v("EB-2 NIW approval for HR employee")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../resources/blog/blog28-16x9.webp"),"alt":"NIW template HR human resources"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("In today's competitive business landscape, the role of human resources professionals cannot be understated. They play a pivotal role in "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("creating a positive work environment")]),_vm._v(", improving "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("employee satisfaction and retention")]),_vm._v(", and driving the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("success of organizations")]),_vm._v(". "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("Lisa, an exceptional HR employee responsible for "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("engineering talent highly specialized in healthcare equipment")]),_vm._v(", recently achieved a significant milestone by successfully obtaining an EB-2 National Interest Waiver (NIW).")]),_vm._v(" This accomplishment highlights Lisa's expertise and dedication to her craft, as well as her remarkable contributions to the field of human resources.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("In this article, we will explore Lisa's journey and the ways in which she provides top-notch HR solutions, with a focus on "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("employee satisfaction and retention")]),_vm._v(", as well as the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("strategic hiring")]),_vm._v(" and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("training of new staff")]),_vm._v(". Furthermore, we will examine how Lisa's endeavors align with the substantial merits and importance of her contributions to national interests.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("Lisa's efforts focus on nurturing and developing "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("talent in science, technology, engineering, and mathematics (STEM) fields")]),_vm._v(". By ensuring a robust pipeline of skilled professionals, she contributes to the growth of a highly competent workforce")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("Her hiring expertise facilitates the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("provision of critical medical equipment to hospitals")]),_vm._v(". Her work contributes to saving lives, particularly among Americans aged 30-50 who drive economic growth")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Check "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("Starter Kit")]),_vm._v(" for EB-2 NIW, which includes self-petition Green Card templates for professionals:")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Lisa's passion and dedication in the field of human resources are commendable. She understands that an engaged and satisfied workforce is crucial for organizational success. "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("Lisa goes above and beyond to comprehend the needs and aspirations of each employee, ensuring they receive the necessary support and resources to excel in their roles. With her exceptional communication and interpersonal skills, Lisa fosters a positive work culture that enhances overall employee satisfaction. Lisa advocates for open communication channels, encouraging regular feedback and fostering constructive dialogue")]),_vm._v(" between employees and management. Lisa's comprehensive employee retention strategies not only reduce turnover rates but also cultivate a sense of loyalty and commitment among the workforce.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Lisa understands the critical role of "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("hiring and training in organizational success")]),_vm._v(". She collaborates with department heads to identify "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("skill gaps")]),_vm._v(" and develops compelling job descriptions to attract qualified candidates. Lisa's expertise in "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("conducting interviews")]),_vm._v(" and "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("assessing cultural fit")]),_vm._v(" ensures that new hires seamlessly integrate into the company. Furthermore, she recognizes the value of "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("continuous training and professional development")]),_vm._v(". By providing resources, mentorship, and growth opportunities, Lisa helps employees enhance their skills and adapt to evolving business needs.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Lisa's achievement of the EB-2 NIW highlights the alignment of her efforts with substantial merit and national importance. As we strive to create workplaces that prioritize employee well-being and contribute to broader societal goals, Lisa's story serves as a reminder of the "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("transformative power of HR professionals and their crucial role")]),_vm._v(" in building successful organizations that benefit both employees and the US.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('a',{staticClass:"btn btn-primary",staticStyle:{"margin-top":"3rem","margin-bottom":"2rem"},attrs:{"href":"/hr-recruiter-sample"}},[_vm._v("HR-RECRUITER TEMPLATE")])])])])
}]

export { render, staticRenderFns }