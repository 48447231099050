<template>
    <div>

		<div class="page-title-area section-title" style="margin-bottom: 0px; padding-bottom: 10px">
			<div class="d-table"><div class="d-table-cell"><div class="container"><h1 class="page-title" >EB-2 NIW Blog</h1></div></div></div>
			<div class="bar"></div>
		<!-- End Page Title -->
			<div style="top:20px" class="shape1"><img src="../resources/shape1.png" alt="eb2 niw examples"></div>
			<div class="shape8 rotateme"><img src="../resources/shape2.svg" alt="self petition eb2"></div>
			<div class="shape7"><img src="../resources/shape4.svg" alt="niw diy"></div>
		</div>

		<!-- Start Blog Area -->
		<section class="blog-area ptb-80">
			<div class="container">
				<div class="row">


					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/what-are-eb2-niw-eligibility-requirements">
									<img src="../resources/blog/blog3-1x1.webp" alt="niw diy">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> December 15, 2022
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/what-are-eb2-niw-eligibility-requirements">What are EB-2 NIW eligibility requirements?</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>EB-2 National Interest Waiver (NIW) is a type of visa that allows individuals with advanced degree or exceptional ability in the arts, sciences, business, education, or athletics, to apply for permanent residency.</p>

								<a href="/what-are-eb2-niw-eligibility-requirements" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/what-evidence-can-you-use-for-eb2-niw">
									<img src="../resources/blog/blog2-1x1.webp" alt="eb2-niw do it yourself package">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> December 12, 2022
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/what-evidence-can-you-use-for-eb2-niw">What evidence can you use for EB-2 NIW?</a></h4>

								<span>Author: <a href="#">Tom</a></span>

								<p>There are many types of evidence that can be used to support EB-2 NIW application. Below you will find some examples. This list is not exhaustive and depends on your profession and type of arguments that you want to use in the main...</p>

								<a href="/what-evidence-can-you-use-for-eb2-niw" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/eb2-national-interest-waiver-documents-checklist">
									<img src="../resources/blog/blog1-1x1.webp" alt="eb2 niw petition how to guide">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> December 11, 2022
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/eb2-national-interest-waiver-documents-checklist">EB-2 NIW documents checklist</a></h4>

								<span>Author: <a href="#">Tom</a></span>

								<p>If you are applying for an EB-2 National Interest Waiver (NIW), it's important to make sure you have all the necessary documents in order to increase your chances of success. Below is a checklist of documents that you should...</p>

								<a href="/eb2-national-interest-waiver-documents-checklist" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>
					
					
					<div class="col-lg-12 col-md-12">
						<div class="pagination-area">
							<nav aria-label="Page navigation">
								<ul class="pagination justify-content-center">
									<li class="page-item"><a class="page-link" href="/blog/5">Prev</a></li>
									<li class="page-item "><a class="page-link" href="/blog/1">1</a></li>
									<li class="page-item"><a class="page-link" href="/blog/2">2</a></li>
									<li class="page-item"><a class="page-link" href="/blog/3">3</a></li>
									<li class="page-item"><a class="page-link" href="/blog/4">4</a></li>
									<li class="page-item"><a class="page-link" href="/blog/5">5</a></li>
									<li class="page-item active"><a class="page-link" href="#">6</a></li>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Blog Area -->
    </div>
</template>
<script>
export default {
    name: 'BlogGrid3'
}
</script>
