<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <div class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </div>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">My options for EB-2 visa: NIW vs. PERM</h1>
          </div>
        </div>
      </div>
    </div>

    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">February 6, 2023</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Natalie</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog13-16x9.webp" alt="NIW template non-academia">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">NIW vs. PERM</h3>

          <div class="blog-paragraph">National Interest Waiver (NIW) and Permanent Employment Certification (PERM) are two different processes for foreign nationals seeking permanent residency in the United States. Both offer <span style="font-weight: 500; font-style: italic">pathways to a Green Card</span>, but there are differences between the two in terms of <span style="font-weight: 500; font-style: italic">eligibility, application process, and benefits</span>:</div>

          <h5>National Interest Waiver</h5>
          <div class="blog-paragraph">The National Interest Waiver (NIW) is a type of visa for highly <span style="font-weight: 500; font-style: italic">skilled individuals</span> who have <span style="font-weight: 500; font-style: italic">exceptional ability</span> in their field and whose work in the United States is in the national interest. This visa is <span style="background-color: rgba(68, 206, 111, 0.25)">specifically designed for individuals whose skills, knowledge, and achievements have the potential to significantly benefit the United States. The NIW is a waiver of the labor certification requirement</span> and enables applicants to self-petition for a Green Card without the need for an employer sponsor.</div>

          <div class="blog-paragraph">Check <a href="/home" style="text-decoration: underline">Starter Kit for EB-2 NIW</a>, which includes self-petition Green Card templates for professionals:</div>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>
          
          <h5>PERM</h5>
          <div class="blog-paragraph"><span style="background-color: rgba(68, 206, 111, 0.25)">The Permanent Employment Certification (PERM) is a labor certification process that employers must go through to sponsor a foreign worker</span> for permanent residency in the United States. The process involves a <span style="font-weight: 500; font-style: italic">test of the labor market</span> to determine whether there are qualified and available U.S. workers for the job offered to the foreign worker. The PERM process is <span style="font-weight: 500; font-style: italic">typically time-consuming</span>.</div>

          <h5>Eligibility</h5>
          <div class="blog-paragraph">For the National Interest Waiver, applicants must demonstrate that their work in the United States is in the national interest or that they possess exceptional ability in their field. To apply <span style="font-weight: 500; font-style: italic">you are NOT required to have a job in the US</span>, or even to be present in the US.</div>

          <div class="blog-paragraph">On the other hand, for <span style="font-weight: 500; font-style: italic">the Permanent Employment Certification, applicants must have a job offer from a U.S. employer</span> and meet the qualifications for the job. The employer must also prove that there are no qualified and available U.S. workers for the job.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem; padding-bottom: 0px">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                  <a href="/are-you-eligible" class="btn btn-primary btn-assess">Are you eligible for EB-2 NIW?</a>
              </div>
            </div>
          </div>

          <h5>Application process</h5>
          <div class="blog-paragraph">The National Interest Waiver is a self-petitioned visa category, which means that applicants do not need an employer sponsor. This makes the application process much simpler and faster compared to the Permanent Employment Certification, which requires a lengthy labor certification process.</div>

          <div class="blog-paragraph">The Permanent Employment Certification, on the other hand, requires a lengthy and complex process involving the U.S. Department of Labor, the employer, and the foreign worker. The employer must first conduct a test of the labor market to determine whether there are qualified and available U.S. workers for the job. If there are no qualified and available U.S. workers, the employer must then file a labor certification application with the Department of Labor.</div>

          <h5>Benefits</h5>
          <div class="blog-paragraph">The benefits of the National Interest Waiver are that it is a faster and simpler process compared to the Permanent Employment Certification and that applicants do not need an employer sponsor.</div>

          <div class="blog-paragraph">The benefits of the Permanent Employment Certification are that it provides a more secure path to permanent residency in the United States, as the employer is responsible for sponsoring the foreign worker. Very often the process is run by lawyers paid by the employer, so this service comes at no or limited cost to the individual.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                <router-link class="btn btn-primary" :to="{ name: 'Home', params: { location: 'Templates' }}">EB-2 NIW Templates</router-link>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>

  </div>
</template>
<script>

import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";

export default {
  name: 'EB2NIWVSPerm',
  components: {
    PriceOffer,
    CarouselGreenCard
  }
}
</script>
