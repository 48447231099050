<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <SuccessStoryHRConsultant></SuccessStoryHRConsultant>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import SuccessStoryHRConsultant from "../blog-components/SuccessStoryHRConsultant";


export default {
  name: 'SuccessStoryHRConsultantView',
  components: {
    SuccessStoryHRConsultant,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "EB2 NIW approval for Human Resources consultant - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "Helen, is a consultant who has worked in the field of Human Resources for over 10 years with a lot of experience in helping companies expand their workforce in a sustainable way. However, she faced a challenge when she decided to immigrate to the United States."},
      ]
    }
  }
}
</script>
