var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('section',{staticClass:"blog-details-area"},[_c('div',{staticClass:"article-content"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('vue-feather',{attrs:{"type":"clock"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("March 15, 2023")])],1),_c('li',[_c('vue-feather',{attrs:{"type":"user"}}),_vm._v(" "),_c('a',{attrs:{"href":"#"}},[_vm._v("Natalie")])],1)])])])]),_vm._m(1),_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"article-content"},[_c('h3',{staticClass:"subtitle"},[_vm._v("Success story: EB-2 NIW")]),_vm._m(2),_c('div',{staticClass:"signupSelfPetition row justify-content-center align-items-center"},[_c('div',{staticClass:"col-xl-6 col-lg-7"},[_c('signup-self-petition')],1)]),_vm._m(3),_vm._m(4),_c('PriceOffer',{staticStyle:{"margin-bottom":"4rem"}}),_c('CarouselGreenCard',{staticStyle:{"margin-bottom":"4rem"}}),_vm._m(5),_vm._m(6),_vm._m(7)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area2"},[_c('div',{staticClass:"article-margin"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('span',{staticClass:"article-tags"},[_c('a',{attrs:{"href":"#"}},[_vm._v("EB-2 NIW BLOG")])]),_c('span',{staticClass:"article-tags2"},[_c('a',{attrs:{"href":"#"}},[_vm._v("SUCCESS STORY")])]),_c('h1',{staticStyle:{"font-weight":"600","margin-top":"30px","text-align":"left"}},[_vm._v("EB-2 NIW approval for investment banker")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../resources/blog/blog22-16x9.webp"),"alt":"NIW template banking finance"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("In this success story, we will highlight "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("Mark's case, a Vice President in Investment Banking")]),_vm._v(" who was able to secure an EB-2 NIW approval. This individual had a long and successful career in "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("investment banking")]),_vm._v(", having worked for several prominent "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("financial institutions")]),_vm._v(". He had a strong track record of making significant contributions to the industry, including several innovative financial products that had been widely adopted by the market.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Mark had very little idea what kind of arguments he could use to support the NIW case. However, with the help of our "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("EB-2 NIW Starter Kit")]),_vm._v(" which includes a profession-specific NIW builder with examples of arguments and wording to be used in the application, and additional support from the immigration lawyer, Mark was able to successfully obtain an EB-2 NIW approval.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("Check "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("Starter Kit for EB-2 NIW")]),_vm._v(", which includes self-petition Green Card templates for professionals:")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("In his application he demonstrated that the Vice President's skills and experience made him a valuable asset to the United States, and that the contributions in the investment banking field would be in the national interest. He provided "),_c('span',{staticStyle:{"background-color":"rgba(68, 206, 111, 0.25)"}},[_vm._v("extensive evidence of his professional achievements, such as the work in complex financial transactions, salary much above the market average, and contribution to unlocking the hiring process by his corporate clients")]),_vm._v(" who received funds from his bank.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-paragraph"},[_vm._v("The EB-2 NIW approval allowed Mark to bypass the usual labor certification process, which can be lengthy and time-consuming. This allowed him to achieve his dream of living and working permanently in the United States. Mark's success story highlights "),_c('span',{staticStyle:{"font-weight":"500","font-style":"italic"}},[_vm._v("the importance of using strong but simple arguments")]),_vm._v(" in the NIW case, which could be achieved with our "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/home"}},[_vm._v("EB-2 NIW templates for professionals")]),_vm._v(", with the immigration lawyer, or as a combination of both.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row service-action-btns",staticStyle:{"margin-top":"3rem","margin-bottom":"3rem"}},[_c('div',{staticClass:"col-xl-7 col-lg-9"},[_c('div',{staticClass:"row justify-content-center"},[_c('a',{staticClass:"btn btn-primary",staticStyle:{"margin-top":"3rem","margin-bottom":"2rem"},attrs:{"href":"/banking-finance-sample"}},[_vm._v("Banking-Finance Template")])])])])
}]

export { render, staticRenderFns }