<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <SuccessStoryBanker></SuccessStoryBanker>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import SuccessStoryBanker from "../blog-components/SuccessStoryBanker";


export default {
  name: 'SuccessStoryBankerView',
  components: {
    SuccessStoryBanker,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "EB2 NIW approval for investment banker - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "In this success story, we will highlight Mark's case, a Vice President in Investment Banking who was able to secure an EB-2 NIW approval. This individual had a long and successful career in investment banking, having worked for several prominent financial institutions."},
      ]
    }
  }
}
</script>
