<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <EB2NIWL1Visa></EB2NIWL1Visa>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
     <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import EB2NIWL1Visa from "../blog-components/EB2NIWL1Visa";


export default {
  name: 'EB2NIWL1VisaView',
  components: {
    EB2NIWL1Visa,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "L1 visa holders during the ongoing layoffs - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "The United States has long been a hub for skilled professionals from around the world, offering various visa options for foreign workers. One such visa, the L1 visa, allows multinational companies to transfer their employees to the U.S. temporarily. While the L1 visa is an excellent opportunity for international professionals, recent economic uncertainties and ongoing layoffs have highlighted the importance of applying for a green card as soon as possible for L1 visa holders. As someone who was once on an L1 visa, I can personally attest to the significance of this journey."},
      ]
    }
  }
}
</script>
