<template>    
    <div class="row" style="max-width: 520px; margin: 0 auto; margin-top: 3rem">

        <div class="col-3" style="padding-bottom: 0; padding-left: 0px; padding-right: 0px; margin-bottom: 1.1rem; height: 50px">
            <div class="form-group">
                <label for="rfeFile">
                    <span style="font-size: 12px; font-weight: 400">Upload RFE</span>
                    <div style="cursor: pointer; font-size: 50px;"><font-awesome-icon icon="file-upload" style="color: #44ce6f;"/></div>
                </label>
                <input type="file" class="form-control-file" id="rfeFile" ref="file" v-on:change="checkFileSize" style="display: none;">
            </div>
        </div>

        <div class="col-6" style="padding: 0; margin-bottom: 1rem; height: 50px">
            <div class="form-group" style="text-align: left">
                <label for="email" style="margin-bottom: 14px; font-size: 12px; font-weight: 400">Let's see if we can help</label>
                <input type="email" class="form-control" id="email" aria-describedby="email" placeholder="Your e-mail" style="border-radius: 5px; border-color: #44ce6f; height: 50px" v-model="email" v-on:input="clearEmailError">
            </div>
        </div>

        <form @submit.prevent="submit()" class="col-3" style="padding-top: 34px; padding-left: 5px; padding-right: 5px; margin-bottom: 1rem; height: 50px">
            <button type="submit" class="btn btn-primary btn-assess" style="margin-top: 0; padding-left: 5px; padding-right: 5px; padding-top: 15px; padding-bottom: 15px; width: 100%;">Submit</button>
        </form>

        <div class="row" style="margin: 2rem auto;">
            <div class="col-12">
                <p v-if="rfe" style="margin-top: 15px; margin-bottom: 15px; text-align: center">Uploaded: {{this.$refs.file.files[0].name}}
                    <span v-on:click="deleteFile" style="margin-left: 18px; font-size: 18px; cursor: pointer;">
                        <font-awesome-icon icon="trash-can" />
                    </span>
                </p>
            </div>

            <div v-if="fileIsTooLarge" class="col-12 alert alert-danger" role="alert">
                <div style="font-weight: 500; text-align: center">File size exceeds 3MB limit: you can also e-mail us at ceo@selfpetitionusa.com</div>
            </div>

            <div v-if="submitted && !isEmail" class="col-12 alert alert-warning" role="alert">
                <div style="font-weight: 500; text-align: center">Enter a valid e-mail</div>
            </div>

            <div v-if="!rfe && submitted" class="col-12 alert alert-warning" role="alert">
                <div style="font-weight: 500; text-align: center">No file uploaded: you can also e-mail us at ceo@selfpetitionusa.com</div>
            </div>

            <div v-if="submitted && isEmail && rfe" class="col-12 alert alert-success" role="alert">
                <div style="font-weight: 500; text-align: center">🎉 Successfully submitted 🎉</div>
                <div style="font-weight: 500; margin-top: 5px; text-align: center">We will contact you over email within 24h</div>
            </div>
        </div>

    </div>
</template>


<script>
import {mapActions} from "vuex";

    export default {
        name: 'SignupRfe',
        data () {
            return {
                email: '',
                rfe: '',
                fileName: '',
                submitted: false,
                isEmail: false,
                fileIsTooLarge: false,
            }
        },
        methods: {
        ...mapActions('account', ['apply']),
            checkFileSize() {
                const fileInput = this.$refs.file;
                const fileSize = fileInput.files[0].size; // Size in bytes
                const maxSize = 3 * 1024 * 1024; // 3MB in bytes

                if (fileSize > maxSize) {
                    this.fileIsTooLarge = true;
                    // You can optionally clear the file input
                    fileInput.value = null;

                    setTimeout(() => {
                        this.fileIsTooLarge = false;
                    }, 8000); // Hide after 8 seconds
                } else {
                    this.setImage();
                }
            },
            setImage() {
                this.getBase64(this.$refs.file.files[0]);
            },
            getBase64(file) {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                const self = this;
                reader.onload = function () {
                    const res = reader.result;
                    self.rfe = res;
                    self.fileName = self.$refs.file.files[0].name;
                };
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                };
            },
            validateEmail () {
                this.isEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
            },
            clearEmailError () {
                this.isEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
            },
            deleteFile () {
                this.rfe = '';
                this.fileName = '';
            },
            clearInputs () {
                setTimeout(() => {
                    this.email = '',
                    this.rfe = '',
                    this.fileName = '',
                    this.submitted = false,
                    this.isEmail = false,
                    this.fileIsTooLarge = false
                }, 4000)
            },
            submit() {
                this.submitted = true;
                this.validateEmail();

                if (this.isEmail && this.rfe !== '') {
                    this.apply({
                        rfeEmail: this.email,
                        rfeFile: this.rfe,
                    });
                }
                this.clearInputs ();
            },
        }
    }
    
</script>