<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <span class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </span>
            <span class="article-tags2">
              <a href="#">SUCCESS STORY</a>
            </span>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">EB-2 NIW approval for software engineer</h1>
          </div>
        </div>
      </div>
    </div>

    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">February 18, 2023</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Tom</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog16-16x9.webp" alt="NIW template software engineer">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">Success story: EB-2 NIW</h3>

          <div class="blog-paragraph">Meet <span style="background-color: rgba(68, 206, 111, 0.25)">John, a talented machine learning software engineer from Germany with over 7 years of experience</span> in the field. Despite his vast knowledge and expertise, he faced several roadblocks in obtaining a Green Card through the EB-2 category.</div>

          <div class="blog-paragraph">The main challenge was that John <span style="font-weight: 500; font-style: italic">had no research publications or patents</span> in his name. Whereas the most common way of applying for EB-2 NIW involves documenting the publication of research papers, patents, or recognition through awards or prizes.</div>

          <div class="signupSelfPetition row justify-content-center align-items-center">
            <div class="col-xl-6 col-lg-7">
              <signup-self-petition></signup-self-petition>
            </div>
          </div>

          <div class="blog-paragraph">However, John was employed by a leading software company, and convinced that with <span style="font-weight: 500; font-style: italic">7 years of experience and an advanced degree</span> he meets EB-2 National Interest Waiver (NIW) criteria. He decided to self-petition through EB-2 NIW.</div>

          <div class="blog-paragraph">To maintain the US's position as a leader in technological and economic innovation for future generations, <span style="font-weight: 500; font-style: italic">President Biden</span> and his administration have implemented various measures <span style="font-weight: 500; font-style: italic">since January 2022</span> aimed at <span style="background-color: rgba(68, 206, 111, 0.25)">attracting and retaining top international talent in Science, Technology, Engineering, and Mathematics (STEM)</span>. John knew it is a good signal that <span style="font-weight: 500; font-style: italic">STEM</span> is important to national interest.</div>

          <div class="blog-paragraph">John compiled a comprehensive and persuasive application package using our <a href="/home" style="text-decoration: underline">EB-2 NIW Starter Kit</a>. He emphasized his contributions to the <span style="background-color: rgba(68, 206, 111, 0.25)">development of several innovative machine learning algorithms that were being used by top companies in various industries. They also highlighted John's leadership and mentorship skills</span>, and how he had trained and nurtured several junior engineers, helping them to develop into highly skilled software engineers.</div>

          <div class="blog-paragraph">Check <a href="/home" style="text-decoration: underline">Starter Kit for EB-2 NIW</a>, which includes self-petition Green Card templates for professionals with no research background:</div>
          
          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

          <div class="blog-paragraph">Finally, John also demonstrated that his work was in the national interest by showing how it contributed to the growth and competitiveness of the software industry in the United States.</div>

          <div class="blog-paragraph">After several months of waiting, John received his approval notice for the EB-2 NIW. He was overjoyed and grateful for discovering <a href="/home" style="text-decoration: underline">EB-2 NIW templates for professionals</a> which helped him to achieve his goal of obtaining a Green Card.</div>

          <div class="blog-paragraph">John is now a permanent resident of the United States and continues to make significant contributions to the <span style="font-weight: 500; font-style: italic">software industry</span>. He is a valuable member of the community and a testament to the fact that even those <span style="font-weight: 500; font-style: italic">without research publications</span> can achieve success through the EB-2 NIW category.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                <a href="/software-engineer-it-sample" class="btn btn-primary" style="margin-top: 3rem; margin-bottom: 2rem">SOFTWARE ENG & IT TEMPLATE</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>
<script>

import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";
import SignupSelfPetition from "../components/SignupSelfPetition.vue";

export default {
  name: 'SuccessStorySoftwareEngineer',
  components: {
    PriceOffer,
    CarouselGreenCard,
    SignupSelfPetition
  }
}
</script>
