<template>
    <div>

		<div class="page-title-area section-title" style="margin-bottom: 0px; padding-bottom: 10px">
			<div class="d-table"><div class="d-table-cell"><div class="container"><h1 class="page-title" >EB-2 NIW Blog</h1></div></div></div>
			<div class="bar"></div>
		<!-- End Page Title -->
			<div style="top:20px" class="shape1"><img src="../resources/shape1.png" alt="eb2 niw examples"></div>
			<div class="shape8 rotateme"><img src="../resources/shape2.svg" alt="self petition eb2"></div>
			<div class="shape7"><img src="../resources/shape4.svg" alt="niw diy"></div>
		</div>

		<!-- Start Blog Area -->
		<section class="blog-area ptb-80">
			<div class="container">
				<div class="row">


					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/what-can-i-do-if-there-is-a-backlog-for-eb2-niw">
									<img src="../resources/blog/blog12-1x1.webp" alt="niw petition letter template">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> February 2, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/what-can-i-do-if-there-is-a-backlog-for-eb2-niw">What can I do if there is EB-2 NIW backlog?</a></h4>

								<span>Author: <a href="#">Przemek</a></span>

								<p>EB-2 NIW is a type of employment-based Green Card for individuals with exceptional ability who can demonstrate that their work in the United States is in the national interest. However, due to the limited number of Green Cards available.</p>

								<a href="/what-can-i-do-if-there-is-a-backlog-for-eb2-niw" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/what-are-the-benefits-of-eb2-niw-petition">
									<img src="../resources/blog/blog11-1x1.webp" alt="EB2 NIW self petition templates">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> February 1, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/what-are-the-benefits-of-eb2-niw-petition">What are the benefits of EB-2 NIW petition?</a></h4>

								<span>Author: <a href="#">Przemek</a></span>

								<p>The EB-2 National Interest Waiver (NIW) is a process that is faster and more straightforward than other types of immigration visas, as there is no requirement to first find a job offer from a US employer.</p>

								<a href="/what-are-the-benefits-of-eb2-niw-petition" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>


					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/adjustment-of-status-vs-consular-processing">
									<img src="../resources/blog/blog10-1x1.webp" alt="NIW template non-academia">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> January 30, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/adjustment-of-status-vs-consular-processing">Adjustment of Status vs. Consular Processing</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>Two common ways to obtain a green card are through Adjustment of Status and Consular Processing. While both methods serve the same ultimate purpose, there are some significant differences between them.</p>

								<a href="/adjustment-of-status-vs-consular-processing" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/eb2-niw-for-china-and-india-born-applicants">
									<img src="../resources/blog/blog9-1x1.webp" alt="NIW petition guides for non-academia professionals">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> January 23, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/eb2-niw-for-china-and-india-born-applicants">EB-2 NIW for China & India born applicants</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>The number of visas available under the EB-2 NIW category is restricted geographically, as a result, a backlog has developed, causing wait times of several years for Chinese and Indian citizens seeking to obtain...</p>

								<a href="/eb2-niw-for-china-and-india-born-applicants" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/what-is-the-uscis-visa-bulletin">
									<img src="../resources/blog/blog8-1x1.webp" alt="EB2 NIW Template">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> January 22, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/what-is-the-uscis-visa-bulletin">What is the USCIS Visa Bulletin?</a></h4>

								<span>Author: <a href="#">Tom</a></span>

								<p>The USCIS Visa Bulletin is a monthly publication issued by the United States Citizenship and Immigration Services (USCIS) that provides updates on the availability of immigrant visas for individuals.</p>

								<a href="/what-is-the-uscis-visa-bulletin" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/how-to-apply-for-eb2-niw-green-card">
									<img src="../resources/blog/blog7-1x1.webp" alt="green card self-service">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> January 17, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/how-to-apply-for-eb2-niw-green-card">How to apply for EB-2 NIW Green Card?</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>You can self-petition which means that you do not need the employer to sponsor you. Most importantly, the employer doesn't even have to know about your application! To apply for this type of visa, there are several steps you need to follow.</p>

								<a href="/how-to-apply-for-eb2-niw-green-card" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/what-is-an-eb2-niw-visa">
									<img src="../resources/blog/blog6-1x1.webp" alt="eb2 self petition">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> January 8, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/what-is-an-eb2-niw-visa">What is an EB-2 NIW visa?</a></h4>

								<span>Author: <a href="#">Natalie</a></span>

								<p>EB-2 NIW (National Interest Waiver) is a type of U.S. employment-based visa for individuals who have advanced degree or exceptional ability in their field and whose work is in the national interest of the United States.</p>

								<a href="/what-is-an-eb2-niw-visa" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/what-is-the-cost-of-eb2-niw">
									<img src="../resources/blog/blog5-1x1.webp" alt="eb2 niw petition how to guide">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> January 3, 2023
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/what-is-the-cost-of-eb2-niw">What is the cost of EB-2 NIW?</a></h4>

								<span>Author: <a href="#">Tom</a></span>

								<p>The cost of the EB-2 NIW process varies depending on a number of factors, including the individual's specific circumstances and if you decide to work with a lawyer or file yourself.</p>

								<a href="/what-is-the-cost-of-eb2-niw" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="/what-is-eb2-niw-processing-time">
									<img src="../resources/blog/blog4-1x1.webp" alt="eb2 niw diy kit">
								</a>

								<div class="date">
									<vue-feather type="calendar"></vue-feather> December 20, 2022
								</div>
							</div>

							<div class="blog-post-content">
								<h4><a href="/what-is-eb2-niw-processing-time">What is EB-2 NIW processing time?</a></h4>

								<span>Author: <a href="#">Przemek</a></span>

								<p>One of the most significant benefits of the EB-2 NIW is the shorter processing time compared to the standard labor certification process. The processing time for the EB-2 NIW is dependent on various factors.</p>

								<a href="/what-is-eb2-niw-processing-time" class="read-more-btn">Read More <vue-feather type="arrow-right"></vue-feather> </a>
							</div>
						</div>
					</div>
					
					
					<div class="col-lg-12 col-md-12">
						<div class="pagination-area">
							<nav aria-label="Page navigation">
								<ul class="pagination justify-content-center">
									<li class="page-item"><a class="page-link" href="/blog/4">Prev</a></li>
									<li class="page-item "><a class="page-link" href="/blog/1">1</a></li>
									<li class="page-item"><a class="page-link" href="/blog/2">2</a></li>
									<li class="page-item"><a class="page-link" href="/blog/3">3</a></li>
									<li class="page-item"><a class="page-link" href="/blog/4">4</a></li>
									<li class="page-item active"><a class="page-link" href="#">5</a></li>
									<li class="page-item"><a class="page-link" href="/blog/6">6</a></li>
									<li class="page-item"><a class="page-link" href="/blog/6">Next</a></li>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Blog Area -->
    </div>
</template>
<script>
export default {
    name: 'BlogGrid3'
}
</script>
