<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <span class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </span>
            <span class="article-tags2">
              <a href="#">SUCCESS STORY</a>
            </span>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">EB-2 NIW approval for chips engineer</h1>
          </div>
        </div>
      </div>
    </div>
    
    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">April 13, 2024</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Tom</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog44-16x9.webp" alt="eb2 niw chips">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">The journey of a chips engineer</h3>

          <div class="blog-paragraph">Meet Kevin, <span style="background-color: rgba(68, 206, 111, 0.25)">a seasoned <span style="font-weight: 500; font-style: italic">chips engineer</span>, who embarked on the EB-2 NIW immigration pathway via <span style="font-weight: 500; font-style: italic">consular processing</span>. Faced with geopolitical uncertainties surrounding Taiwan's semiconductor industry and buoyed by the Biden administration's Chips Act,</span> Kevin sought a pathway to immigration to the United States. His journey, <a href="/ai" style="text-decoration: underline">powered by SelfPetitionAI</a>, underscores the intersection of technological expertise and immigration ambition. Let's explore Kevin's journey and the role of <a href="/ai" style="text-decoration: underline">artificial intelligence</a> in his successful immigration application.</div>

          <div class="signupSelfPetition row justify-content-center align-items-center">
            <div class="col-xl-6 col-lg-7">
              <signup-self-petition></signup-self-petition>
            </div>
          </div>

          <div class="blog-paragraph">Kevin's journey began with a pragmatic approach to navigating the <span style="font-weight: 500; font-style: italic">EB-2 NIW pathway</span>. As a chips engineer specializing in semiconductor design, Kevin strategically positioned himself to leverage the <span style="font-weight: 500; font-style: italic">Biden administration's Chips Act</span> and geopolitical dynamics surrounding Taiwan. With a focus on Prong 1 of the EB-2 NIW criteria, he highlighted the critical role of his <span style="font-weight: 500; font-style: italic">semiconductor</span> work in advancing <span style="font-weight: 500; font-style: italic">U.S. technological leadership</span> and <span style="font-weight: 500; font-style: italic">economic security</span>.</div>

          <div class="blog-paragraph">Kevin's profession wasn't fully addressed in Self Petition USA's existing <router-link :to="{ name: 'Home', params: { location: 'Templates' }}" style="text-decoration: underline">Starter Kit templates</router-link>. Recognizing the <span style="background-color: rgba(68, 206, 111, 0.25)">need for a more <span style="font-weight: 500; font-style: italic">tailored solution</span> that better represented his background, Kevin opted for <a href="/ai" style="text-decoration: underline">SelfPetitionAI</a>. This advanced alternative allowed him to develop a <span style="font-weight: 500; font-style: italic">13-page cover letter</span> and <span style="font-weight: 500; font-style: italic">five reference letters</span> within <span style="font-weight: 500; font-style: italic">four days</span>,</span> each highlighting his professional achievements and contributions to the U.S. semiconductor industry. Throughout the process, Kevin <span style="font-weight: 500; font-style: italic">could request changes as needed</span>, without worrying about limited iterations, ensuring that the final documents accurately reflected his unique qualifications and narrative.</div>


          <div class="signupSelfPetition row justify-content-center align-items-center">
            <div class="col-xl-6 col-lg-7">
              <form id="AiSample" @submit.prevent="signupEmail()" class="mt-4">
                  <div class="row">
                      <div class="col-sm-7">
                          <input type="email"  name="AiSample" id="AiSample" class="form-control"  v-model="email" placeholder="Enter your email" style="border-radius: 4px;">
                          <p style="font-size: 10px; color:#495057">Request samples drafted by SelfPetitionAI</p>
                          <div class="mt-4">
                              <p style="color: #3b2d9b; font-weight: 500; margin-bottom: 20px; font-size: 14px;">{{ message }}</p>
                          </div>
                      </div>
                      <div class="col-sm-5 col-6 offset-sm-0 offset-3" style="padding: 0px">
                          <button type="submit" class="btn btn-primary">Get AI samples</button>
                      </div>
                  </div>
              </form>
            </div>
          </div>
          
          <div class="blog-paragraph">With his comprehensive documentation in hand, Kevin submitted his <span style="font-weight: 500; font-style: italic">I-140 petition</span> and <span style="font-weight: 500; font-style: italic">got it approved</span>. Now he awaits the Visa Bulletin to determine when his priority date becomes current. In general, <span style="background-color: rgba(68, 206, 111, 0.25)">filling out the forms required by USCIS he found rather straightforward, however he leveraged <span style="font-weight: 500; font-style: italic">Self Petition USA Discord Community</span> to get a better understanding of recently updated <span style="font-weight: 500; font-style: italic">ETA-9089 form</span>.</span>  His journey underscores the importance of strategic positioning and personalized assistance in navigating the complexities of the immigration process.</div>

          <div class="blog-paragraph">Professionals like Kevin rely on <a href="/ai" style="text-decoration: underline">SelfPetitionAI</a> to streamline their immigration applications. As Kevin awaits the Visa Bulletin, his journey serves as a testament to the importance of strategic planning and personalized assistance in achieving immigration ambitions.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                <a class="btn btn-primary btn-assess" href="/ai">Learn more</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>
<script>

import SignupSelfPetition from "../components/SignupSelfPetition.vue";

export default {
  name: 'SuccessStoryChipsEngineer',
  components: {
    SignupSelfPetition
  },
  data() {
    return {
      email: '',
      message: ''
    }
  },
  methods: {
    signupEmail() {
      if(!this.validateEmail(this.email)) {
        this.message = "The email address is badly formatted.";
      } else {
        const url = `/api/contact/landing`;
        const requestOptions = {
          method: "POST",
          headers: {"Content-Type" : "application/json"},
          body: JSON.stringify({ name: 'AiSample', email: this.email, message: 'Requested AI samples' })
        };
        fetch(url, requestOptions).then(() => {
          this.message = "🎉 We will send you AI samples within 24h 🎉";
        })
      }
    },
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(String(email).toLowerCase());
    }
  }
}
</script>
