<template>

  <div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalHeaderGreenCard v-else></ExternalHeaderGreenCard>
    <div>
      <SuccessStoryFrontendEngineer></SuccessStoryFrontendEngineer>
    </div>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
      <ExternalFooterGreenCard v-else></ExternalFooterGreenCard>
  </div>

</template>

<script>
import ExternalHeaderGreenCard from "../components/ExternalHeaderGreenCard";
import ExternalFooterGreenCard from "../components/ExternalFooterGreenCard";
import SuccessStoryFrontendEngineer from "../blog-components/SuccessStoryFrontendEngineer";


export default {
  name: 'SuccessStoryFrontendEngineerView',
  components: {
    SuccessStoryFrontendEngineer,
    ExternalHeaderGreenCard,
    ExternalFooterGreenCard,
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
  },
  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
    }
  },
  metaInfo() {
    return {
      title: "EB2 NIW approval for Frontend Engineer - SELF PETITION USA",
      meta: [
        { vmid: 'description', name: 'description', content:  "This case study examines the journey of Pawan, a frontend software engineer at a leading FAANG company, who successfully navigated the complex process of obtaining an EB2 National Interest Waiver (NIW) using Selfpetition AI. Initially relying on his company for green card sponsorship, Pawan had to pivot his approach due to layoffs that put the company's sponsorship processes on hold. This innovative AI-driven platform provided a tailored approach to crafting a compelling petition, highlighting Pawan's unique qualifications and the national importance of his endeavor."},
      ]
    }
  }
}
</script>
