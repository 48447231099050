<template>
  <div class="container">

    <div class="page-title-area2">
      <div class="article-margin">
          <div class="d-table"><div class="d-table-cell">
            <div class="article-tags">
              <a href="#">EB-2 NIW BLOG</a>
            </div>
            <h1 style="font-weight: 600; margin-top: 30px; text-align: left">What evidence can you use for EB-2 NIW?</h1>
          </div>
        </div>
      </div>
    </div>

    <section class="blog-details-area">
      <div class="article-content">
        <div class="article-margin">
          <div class="entry-meta">
            <ul>
              <li><vue-feather type="clock"></vue-feather> <a href="#">December 12, 2022</a></li>
              <li><vue-feather type="user"></vue-feather> <a href="#">Tom</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <img src="../resources/blog/blog2-16x9.webp" alt="NIW petition guides for non-academia professionals">
      </div>
      <div class="article-margin">
        <div class="article-content">
          <h3 class="subtitle">Supporting evidence</h3>

          <div class="blog-paragraph">There are many types of <span style="background-color: rgba(68, 206, 111, 0.25)">evidence that can be used to support EB-2 NIW application. Below you will find some examples. This list is not exhaustive and depends on your profession and type of arguments</span> that you want to use in the main application letter:</div>

          <ul class="features-list">
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Professional degrees and certificates:</span> Diplomas, degrees, and certificates from reputable universities or institutions in your field of expertise.</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Awards and recognition:</span> Awards and recognition received from professional organizations or government agencies.</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Publications:</span> Articles, books, and other published materials that you have written.</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Research and discoveries:</span> Research projects, patents, and other innovations you have made.</li>
            
            <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
              <div class="col-xl-7 col-lg-9">
                <div class="row justify-content-center">
                  <router-link class="btn btn-primary" :to="{ name: 'Home', params: { location: 'Templates' }}">EB-2 NIW Templates</router-link>
                </div>
              </div>
            </div>

            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Recommendation letters:</span> <a href="/home" style="text-decoration: underline">Recommendation letters to support your application</a> by providing an assessment of your ability and potential impact on the national interest. These could come from co-workers, clients, individuals who cited your research paper, members of the association that you are part of etc.</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Expert witness letters:</span> Written by individuals with expertise in the individual's field, where they attest the value and impact of your work. Expert witness fees can range from a few hundred dollars to several thousand dollars, depending on the field and the number of letters required.</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Employment history:</span> Your employment history by showing your professional experience and achievements.</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Testimony from colleagues:</span> Testimony from colleagues, supervisors, and other professionals in your field.</li>
            <li><vue-feather type="check"></vue-feather><span style="font-weight: 500; font-style: italic">Articles in the press or media</span> in general about yourself, the work you are involved in or your company.</li>
          </ul>

          <div class="blog-paragraph">Check <a href="/home" style="text-decoration: underline">Starter Kit for EB-2 NIW</a>, which includes self-petition Green Card templates for professionals:</div>

          <PriceOffer style="margin-bottom: 4rem"></PriceOffer>
          <CarouselGreenCard style="margin-bottom: 4rem"></CarouselGreenCard>

          <div class="blog-paragraph">In conclusion, to obtain an EB-2 NIW, you need to provide sufficient evidence to support your application. By presenting a <span style="font-weight: 500; font-style: italic">combination of the above-mentioned types</span> of evidence, you can demonstrate your value and potential impact on the national interest of the United States.</div>

          <div class="row service-action-btns" style="margin-top: 3rem; margin-bottom: 3rem">
            <div class="col-xl-7 col-lg-9">
              <div class="row justify-content-center">
                  <a href="/are-you-eligible" class="btn btn-primary btn-assess">Are you eligible for EB-2 NIW?</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>
<script>

import PriceOffer from "../components/PriceOffer.vue";
import CarouselGreenCard from "../components/CarouselGreenCard.vue";

export default {
  name: 'EB2NIWEvidence',
  components: {
    PriceOffer,
    CarouselGreenCard
  }
}
</script>
